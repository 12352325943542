<template>
    <div v-if="books != 'Author Booklist detail not found'">
    <looping-rhombuses-spinner
      :animation-duration="2500"
      :rhombus-size="15"
      color="#febe10"
      v-if="books.length == 0 && books != 'Author Booklist detail not found'"
      class="loader-spinner" 
    />
    <div class="my-5">
      <p class="s-h6 fw-normal --text-dark" v-if="books.length > 1"> Titles by {{ author_name }}</p>
    </div>
    <div v-for="book in books">
      <div class="row p-5 mx-0 mb-5 -bg-light-gray" v-if="book_isbn!=book.isbn">
        <div class="col-sm-6 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center align-items-center">
          <router-link :to="getBookUrl(book)" width="270px">
            <img alt="No_Image_Available" 
              height="400px" 
              width="100%"
              :src="
                'https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/' +
                book.isbn +
                '.jpg'
              " @error="noCoverImage"
            />
          </router-link>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <div class="d-flex flex-column justify-content-center h-100">
            <div class="mt-3">
              <h4 class="s-h4 --text-dark fw-semibold">
                {{ book.nonsortprefix }} {{ book.title }}
              </h4>
              <p class="mb-0 s-h7 --text-dark" v-if="book.package_type">
                {{ book.isbn }}
                <span class="ps-2">{{ book.package_type }}</span>
              </p>
              <p v-else class="mb-0 s-h7 --text-dark">
                {{ book.isbn }}
              </p>
              <p class="mb-0 s-h7 --text-dark">
                {{ book.pubdate | formatDate }}
              </p>
              <p class="mb-0 s-h7 --text-dark"> AUD RRP: ${{ book.retail_price_aus }}
              </p>
            </div>
            <div class="mt-3">
              <p class="s-h7 --text-dark" v-html="book.cover_blurb"></p>
            </div>
            <router-link :to="getBookUrl(book)">
              <img src="/static/css/images/affirm/Group55.svg" alt="Group55" width="25px" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import Vue from "vue";

Vue.config.productionTip = false;
Vue.filter("formatDate", function (value) {
  if (value) {
      return new Date(value).toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      )
  }
});
export default {
  name: "affirm-author-book-list",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ["author_name", "author_id","book_isbn"],
  data() {
    return {
      books: [],
      title: null,
      loadbookVisible: 12,
      step: 12,
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
    pubdate: function (pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();
      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      return status;
    },
  },
  computed: {
    visibleCompanies() {
      return this.books.slice(0, this.loadbookVisible);
    },
  },
  mounted() {
    let self = this;
    let author = this.author_name;
    let author_id = this.author_id;
    if (author_id){
      axios
        .get(`/get_author_data/?author_id=${author_id}`)
        .then(function (data) {
          self.books = data.data.authors[0].books;
          if (self.books.length == 0) {
            self.books = "Author Booklist detail not found";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    else{
      axios
        .get(`/search_api/?author=${author}&sort=date_desc`)
        .then(function (data) {
          self.books = data.data.books;
          if (self.books.length == 0) {
            self.books = "Author Booklist detail not found";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  },
};
</script>

<style>
.img-size {
  margin: 5px;
}
</style>
