<template>
  <div v-if="featured">
    <div class="moduletable" v-if="featured.url != '/'">
      <div class="custom">
        <p>
          <router-link :to="featured.url">
            <img :src="featured.hero_image" width="380" height="590" />
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "feature-item",
  data() {
    return {
        featured: {
            url: '/',
            hero_image: null
        }
    };
  },
  mounted() {
    // axios request to get featured image for specific url, might take it from store
          let self = this;
      axios
        .get("/blog_posts/", {
          params: {
            display: 'feature',
            page: this.$route.path,
            limit: 1
          }
        })
        .then(function (response) {
          let responed = response.data.blog_cards_list[0];
          if (responed != undefined){
            if(responed.url){
              self.featured.url = responed.url
            }
            if(responed.hero_image){
              self.featured.hero_image = responed.hero_image
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
  }
};
</script>

<style>
</style>

