<template>
  <!-- start about author-->
  <section id="about-author" class="about -bg-brown">
    <div>
      <div class="">
        <div class="latest-in-news" v-if="authors.length > 0">
          <div class="custom-carousel-icon">
            <carousel
              :items="3"
              :responsive="{ 320: { items: 1 } }"
              :loop="true"
              :dots="false"
              :margin="20"
              :mouseDrag="authors.length > 1 ? true : false"
              :autoplay="authors.length > 1 ? true : false"
            >
              <div class="row p-5" v-for="author in authors">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-7">
                  <div class="d-flex flex-column justify-content-center h-100">
                    <div class="mt-2">
                      <h4 class="s-h4 --text-dark fw-semibold">
                        {{ author.firstname }} {{ author.lastname }}
                      </h4>
                      <p
                        class="s-h7 --text-dark"
                        v-html="author.author_bio"
                      ></p>
                    </div>
                    <div class="d-flex align-items-center mt-5">
                      <div class="me-3">
                        <router-link
                          :to="{ path: '/author/' + author.idauthor }"
                          class="p-3 rounded-circle -bg-light-gray -w-50 -h-50 align-items-center d-flex"
                        >
                          <img
                            src="/static/css/images/affirm/Group55.svg"
                            alt="Group55"
                            width=""
                          />
                        </router-link>
                      </div>
                      <div class="">
                        <router-link
                          :to="{ path: '/author/' + author.idauthor }"
                          class="text-dark fw-semibold"
                        >
                          <p
                            class="text-decoration-underline --text-dark s-h7 mb-0"
                          >
                            View Author Profile
                          </p>
                        </router-link>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-12 col-lg-6 col-xl-5">
                  <div
                    class="d-flex align-items-center -h-300 -w-300 mx-auto overflow-hidden rounded-circle author_box_border"
                  >
                    <img
                      v-if="author.author_image"
                      v-bind:src="author.author_image"
                      alt="author.png"
                      class="w-100 d-block m-auto"
                      @error="noCoverImage"
                    />
                    <img
                      v-else
                      v-bind:src="noCoverImage"
                      alt="author.png"
                      class="w-100 d-block m-auto"
                      @error="noCoverImage"
                    />
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end about author-->
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import carousel from "vue-owl-carousel";
export default {
  name: "affirm-book-author",
  components: {
    LoopingRhombusesSpinner,
    carousel,
  },
  mixins: [Book],
  props: ["isbn"],
  data() {
    return {
      authors: [],
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src =
        "/static/css/images/affirm/author_photo_coming_soon.jpg";
    },
  },
  computed: {},
  mounted() {
    let self = this;
    axios
      .get(`/get_book_author_data/${this.isbn}/`)
      .then(function (data) {
        self.authors = data.data.authors;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>

<style>
.img-size {
  margin: 5px;
}

.latest-in-news .custom-carousel-icon .owl-nav {
  display: none !important;
}
</style>
