<template>
  <section id="bookseller" class="bookseller -bg-green py-5">
    <div
      class="-bg-green --text-dark h-100 d-flex align-items-center justify-content-center"
    >
      <div class="st-container">
        <div class="w-100 text-center">
          <span class="text-center text-white">{{
            this.$store.state.common_info_message
          }}</span>
          <p v-if="error" class="text-center text-white">{{ error }}</p>
        </div>
        <!-- start bookselller -->

        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center mb-3 mb-lg-5">
              <h1 class="s-h2 --text-dark">Bookseller login</h1>
            </div>

            <div class="d-flex justify-content-center">
              <form class="text-center">
                <div class="mb-3 w-400">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50 s-h6 --text-dark"
                    type="text"
                    placeholder="ADS ID"
                    id="accountnumberInput"
                    name="accountnumber"
                    aria-label=".form-control-lg example"
                    v-model="account_num"
                    required
                  />
                </div>
                <button
                  @click="logIn($event)"
                  class="btn --bg-dark text-white rounded-0 d-flex justify-content-center align-items-center -h-50 w-100 login-btn"
                >
                  <img
                    src="/static/css/images/affirm/light-arrow2-svg.png"
                    alt="arrow-right-icon"
                    class="me-3 text-white"
                    width="25px"
                  />
                  <p class="s-h6 mb-0 me-4 text-white">Login</p>
                  <p></p>
                </button>
                <div class="mt-4">
                  <p class="s-h6 --text-dark">
                    Need an account?<a
                      class="--text-dark link text-decoration-underline ps-2 pe-none"
                      >
                        <br/></br>Please contact Alliance Distribution Services (ADS)
                      </a
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end bookselller -->
      </div>
    </div>
  </section>
  <!-- End Featured authors -->
</template>
<script>
import store from "../store";
import { LoopingRhombusesSpinner } from "epic-spinners";
export default {
  name: "affirm-login",
  components: {
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      logged_in: false,
      show_spinner: false,
      account_num: null,
      error: null,
      host: this.$store.state.site_data.host,
    };
  },
  methods: {
    logIn(e) {
      e.preventDefault();
      if (this.account_num == null) {
        this.error = "Account Number is required";
        return;
      } else {
        let self = this;
        this.$store
          .dispatch("addBooksellersNum", this.account_num)
          .then((res) => {
            if (res.status == 200) {
              store.commit("CLEAR_COMMON_INFO_MESSAGE");
              store.commit("CLEAR_REDIRECT_URL");
              self.$router.push({ path: "/bookseller" });
            }
          })
          .catch((err) => {
            console.log(err);
            self.error = "Please enter a correct Account Number";
          });
      }
    },
  },
  mounted() {
    let account_num = this.$store.state.booksellers_num;
    let add_new_class = document.getElementById("affirm-header");
    let add_new_class_footar = (document.getElementsByTagName(
      "footer"
    )[0].style.display = "none");
    let remode_class = document.getElementById("for-affirm");
    add_new_class.classList.add("-bg-green");
    remode_class.classList.remove("container");
  },
};
</script>
