<template>
  <div class="card-section mt-3 mb-5">
    <div>
      <div class="row">
        <div class="col-12">
          <div>
            <h4 class="fw-bold s-h5 --text-dark">{{ title }}</h4>
          </div>
        </div>
      </div>
      <div class="row gy-5">
        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="books.length == 0"
          class="loader-spinner"
        />
        <div
          v-for="book in visibleCompanies"
          :key="book.id"
          :id="book.id"
          class="col-12 col-md-6 col-lg-4 col-xl-3"
        >
          <div class="book-list main-card h-100">
            <div class="upper-card">
              <img
                alt="No_Image_Available"
                width="100%"
                :src="
                  'https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/' +
                  book.isbn +
                  '.jpg'
                "
                @error="noCoverImage"
              />
            </div>
            <router-link :to="getBookUrl(book)" v-if="pubdate(book.pubdate)">
              <div class="hover-card">
                <div class="inner-h-card">
                  <div class="">
                    <h5 class="--text-dark">
                      {{ book.nonsortprefix }} {{ book.title }}
                    </h5>
                    <h5 class="--text-dark">{{ book.author }}</h5>
                  </div>
                  <div>
                    <p v-html="book.key_note_blurb" class="--text-dark">
                      The Near Daphne Experience is a riotous romp about the
                      unedifying search for love, and the most original book
                      you’ll read this year.
                    </p>
                  </div>
                  <div>
                    <router-link
                      class="--text-dark"
                      :to="getBookUrl(book)"
                      target="_self"
                      ><img
                        src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                        width="30px"
                    /></router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          @click="loadbookVisible += step"
          v-if="loadbookVisible < books.length"
          class="cursor-pointer -w-223 mx-auto"
        >
          <div class="text-center">
            <p class="s-h6 --text-dark">Load more</p>
            <img
              src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
              width="25px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";

export default {
  name: "affirm-book-list",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ["placement", "book_limit", "page"],
  data() {
    return {
      books: [],
      title: null,
      loadbookVisible: this.book_limit ? this.book_limit : 12,
      step: this.book_limit ? this.book_limit : 12,
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
    // getCoverLinkMedium(book) {
    //   if (book.coverurl == "y" || book.coverurl == "Y") {
    //     return `https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/${book.isbn}.jpg`;
    //   } else {
    //     return "/static/css/images/affirm/No_Image_Available.jpg";
    //   }
    // },
    pubdate: function (pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();
      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      return status;
    },
  },
  computed: {
    visibleCompanies() {
      return this.books.slice(0, this.loadbookVisible);
    },
  },
  mounted() {
    let self = this;
    // sidebar placement is the default, default is null for backwards compatibility
    let placement = this.placement ? this.placement : "bottom";
    let page = this.page ? this.page : this.$route.path;
    axios
      .get("/get_bestsellers/", {
        params: {
          page: page,
          placement: placement,
        },
      })
      .then(function (data) {
        if (data.status == 200) {
          self.books = data.data.books;
          self.title = data.data.title;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>

<style>
.img-size {
  margin: 5px;
}
</style>
