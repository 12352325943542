<template>
  <div class="l-main-block mobileFullWidth clearfix full-width">
  <div class="l-main mobileFullWidthWithMargin">
      <div class="wrapper">
    <div class="">
        <div>
            <h1 class='thank-you'>Thank you!</h1>
            <p class="order-complete-text">An invoice has been sent to your email address. We will notify you via email once your items have been shipped.</p>
            <router-link to='/' class='go-to-home-btm'>Continue Shopping</router-link>
        </div>
      </div>
      </div>
    </div>
</div>
</template>

<script>
  import axios from "axios";
export default {
    name: 'thank-you',
    data(){
        return {}
    },
     methods: {
      getReplyUrl() {
      let domain = window.location.host;
          let guest_email = localStorage.getItem('guest_email')
          let cart_id = this.$store.state.cart.basket_pk;
          if (guest_email && cart_id){
             axios.get("https://" + domain + "/complete-transaction/?guest_email=" + guest_email +'&cart_id='+cart_id);
             localStorage.removeItem('guest_email')
             localStorage.removeItem('cart_type');
             window.location = window.location.href.split('?')[0]
          }
        }
    },
    mounted() {
      this.getReplyUrl()
      this.$store.dispatch('emptyCart');
      document.title = 'Thank You'
     
  },
};
</script>
<style>
.thank-you{
    color: green;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
}
.order-complete-text{
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
</style>