<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div class="item-page" v-if="logged_in">
        <h1>Booksellers</h1>
        <p></p>
        <p></p>
        <h2>Bookseller Downloads</h2>
        <p>
          Key title information and jacket images are available here for most
          titles published and distributed by Allen &amp; Unwin and Murdoch
          Books in Australia.
        </p>
        <p>
          Jpeg images are available to download for use on bookseller websites
          as a
          <strong>zip file</strong> in a standard size of 72dpi at 200 pixels
          wide.
        </p>
        <p>
          Larger images are available for key titles to download individually at
          300 dpi at full size - simply click on the 'Download cover' button
          that appears below the book cover image on book details pages.
        </p>
        <p>
          Monthly
          <strong>ONIX</strong> files are files of titles published and
          distributed by Allen &amp; Unwin in Australia. They contain title
          information where available, including: basic title information
          keynotes, cover blurbs, author biography, subject etc. Subject
          categories used are the BIC categories. Monthly ONIX files are
          available below - click on the publication month to see all available
          files for that month.
        </p>
        <p>
          If you have any queries regarding the information we supply here
          please contact
          <span class="text-color">
            <strong>webgoddess@allenandunwin.com</strong>
          </span>
        </p>
        <p>
          <strong>Onix file of all available titles</strong>
        </p>
        <p>
          To assist our customers in maintaining the accuracy of their
          databases, please find below an Onix file of all our titles. This file
          is updated daily.
        </p>
        <p>
          <a href="http://assets.allenandunwin.com/files/AU_ONIX.xml"
            >Download ONIX file of all available titles</a
          >
          (Australia &amp; New Zealand)
        </p>
        <p>
          <strong>Stocklists: print on demand titles</strong>
        </p>
        <p>
          <a
            href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/POD-AUS-Nov-16.pdf"
            target="_blank"
            class="doclink"
            >Download the POD stocklist</a
          >
        </p>
        <p>
          <strong>UBD Returns Guide</strong>
        </p>
        <p>
          <a
            href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/UBD_Returns_Policy_Penguin_16_September_2015.pdf"
            target="_blank"
            class="doclink"
            >Download the UBD Returns Guide</a
          >
        </p>
        <p>
          Contact UBD for clarification and further information:
          <br />email:
          <span class="text-color">
            <strong>aftersales@unitedbookdistributors.com.au</strong>
          </span>
          <br />phone: 1800 33 10 15
        </p>
        <p>
          <strong>Email banners</strong>
        </p>
        <p>
          <a
            href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/March-2024-email-signatures.zip"
            target="_blank"
            class="doclink"
            >Download March 2024 email signatures (zip file)</a>
        </p>
        <p>
          <strong>NewBooks</strong>
        </p>
        <p>
          <a href="/media/new-books-brochure" target="_blank" class="doclink"
            >Download our New Books brochures</a
          >
        </p>
        <div v-if="host == 'murdoch'">
          <h4 class="mooblock-title mb165_1t">
            July 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>July 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUS+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/July+2024+Images.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            June 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>June 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Australia</a
                >
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/June+2024+Images.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+JUN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+JUN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>May 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Australia</a
                >
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2024+IMAGES.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>April 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2024+Images.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>March 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2024+Images.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+MAR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+MAR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            February 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>February 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2024+images.zip"
                  target="_blank"
                  class="doclink">
                  Jpeg images</a>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            January 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>January 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA++JANUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JANUARY+2024+final+subsheet+(1).pdf"
                  target="_blank"
                  class="doclink"
                >
                January - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA++JANUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+MUR+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            December 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>December 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS_Murdoch_AUSTRALIA_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Murdoch_NEW_ZEALAND_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA_DECEMBER_2023_Low_Res_Murdoch_kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW_ZEALAND_DECEMBER_2023_Low_Res_Murdoch_kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/December_2023_Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+DEC+2023.pdf"
                target="_blank"  
                class="doclink"
                  >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NEW+ZEALAND+MUR+NEW+BOOKS+DEC+2023.pdf"
                target="_blank"  
                class="doclink"
                  >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            November 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>November 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+NOVEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+NOVEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+NOV+2023.pdf"
                  target="_blank"
                  class="doclink"
                  >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+NOV+2023.pdf"
                  target="_blank"
                  class="doclink"
                  >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            October 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>October 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+OCTOBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+OCTOBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+MUR+NEW+BOOKS+October+2023.pdf"
                  class="doclink"
                  target="_blank"
                  >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+October+2023.pdf"
                  class="doclink"
                  target="_blank"
                  >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            September 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>September 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUSTRALIA+SEPTEMBER+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            August 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>August 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+AUGUST+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                August - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+AUGUST+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                August - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+AUGUST+2023+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                August - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+AUGUST+2023+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                August - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+MUR+NEW+BOOKS+AUGUST+2023.pdf"
                  target="_blank"
                  class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="javascript:void(0);" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            July 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>July 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+JULY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JULY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JULY+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+MUR+NEW+BOOKS+JULY+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+JULY+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            June 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>June 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JUNE+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+MUR+NEW+BOOKS+JUNE+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+JUNE+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>May 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2023+Low+Res+Murdoch+kit.pdf
"
                  target="_blank"
                  class="doclink"
                >
                  May - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>April 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>March 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            February 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>February 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL-+AUS+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            January 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>January 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JANUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL-+AUS+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
        </div>
        <div v-if="host == 'allenandunwin'">
          <h4 class="mooblock-title mb165_1t">
            July 2024 files
            <i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>July 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUS+JULY+2024+final+subsheet.pdf" 
                target="_blank"
                class="doclink"
              >
              July - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUS+JULY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUS+JULY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2024+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2024+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2024+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/July+2024+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+July2024_Onix.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+July_2024_Onix_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            June 2024 files
            <i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>June 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+JUNE+2024+final+subsheet.pdf" 
                target="_blank"
                class="doclink"
              >
              June - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+JUNE+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              June - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+JUNE+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              June - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2024+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              June - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2024+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              June - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2024+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              June - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/June+2024+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Subsheet_2402201259.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Subsheet_2402201259_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+JUNE+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+JUNE+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2024 files
            <i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>May 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+MAY+2024+final+subsheet.pdf" 
                target="_blank"
                class="doclink"
              >
              May - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+MAY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              May - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+MAY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              May - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2024+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              May - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2024+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              May - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2024+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              May - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2024+IMAGES.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_Subsheet_2401241219.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM_May_2024_Booknet.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2024 files
            <i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>April 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+APRIL+2024+final+subsheet.pdf" 
                target="_blank"
                class="doclink"
              >
              April - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+APRIL+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              April - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+APRIL+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              April - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2024+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              April - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2024+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              April - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2024+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              April - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2024+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+April_Subsheet_1312230925.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_Subsheet_1312230925_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2024 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>March 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+MARCH+2024+final+subsheet.pdf" target="_blank"
                class="doclink"
              >
              March - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+MARCH+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              March - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+MARCH+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              March - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2024+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              March - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2024+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              March - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2024+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              March - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2024+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Bloomsbury_Subsheet_March_Onix011120231203.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Bloomsbury_Subsheet_March_Onix011120231203_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+MARCH+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+MARCH+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
          </div>
        </div>
          <h4 class="mooblock-title mb165_1t">
            February 2024 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>February 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+FEBRUARY+2024+final+subsheet.pdf" target="_blank"
                class="doclink"
              >
              February - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+FEBRUARY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              February - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+FEBRUARY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              February - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2024+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              February - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2024+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              February - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2024+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              February - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2024+images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_Subsheet_Onix270920231408.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_Subsheet_Onix270920231408_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
          </div>
        </div>
          <h4 class="mooblock-title mb165_1t">
            January 2024 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>January 2024 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA++JANUARY+2024+final+subsheet.pdf" target="_blank"
                class="doclink"
              >
              January - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS_Children's_AUSTRALIA__JANUARY_2024_final_subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              January - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA++JANUARY+2024+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              January - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA++JANUARY+2024+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              January - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA++JANUARY+2024+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              January - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA++JANUARY+2024+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              January - Bloomsbury</a
              >
            </p>
            <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+Jan2024_Indent_Onix.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_Subsheet_Onix310820230837.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_Subsheet_Onix310820230837_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/AUS+KIDS+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
            December 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>December 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS_Allen_%26_Unwin_AUSTRALIA_DECEMBER_2023_final_subsheet.pdf" target="_blank"
                class="doclink"
              >
              December - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS_Children_s_AUSTRALIA_DECEMBER_2023_final_subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              December - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS_Bloomsbury_AUSTRALIA_DECEMBER_2023_final_subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              December - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              December - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              December - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              December - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/December_2023_Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_no_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_Indent_no_packs.xml"
                target="_blank"
                class="doclink"
              >
              ONIX file A&U Indent</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_Subsheet_Onix240820231044.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_Subsheet_Onix240820231044_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+DEC+2023.pdf"
              target="_blank"
              class="doclink">
                Allen & Unwin New Books Brochure
              </a>
            </p>
            <!-- <p>
              <a href="JavaScript:void(0);"
                class="doclink">
                Books for Children and Young Adults</a
              >
            </p> -->
          </div>
        </div>

        <h4 class="mooblock-title mb165_1t">
            November 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>November 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+NOVEMBER+2023+final+subsheet.pdf" target="_blank"
                class="doclink"
              >
              November - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+NOVEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              November - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+NOVEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              November - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+NOVEMBER+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              November - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+NOVEMBER+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              November - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+November+2023+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              November - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2023+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_no_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/November_Subsheet_Onix190720231002+(2).zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/November_Subsheet_Onix190720231002_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+NOV+2023.pdf"
                target="_blank"
                class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+NOV+2023.pdf"
                target="_blank"
                class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          September 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>September 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUSTRALIA+SEPTEMBER+2023.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_no_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135_BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
              href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+NEW+BOOKS+SEPT+2023.pdf"
               target="_blank"
              class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          October 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>October 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+OCTOBER+2023+final+subsheet.pdf" target="_blank"
                class="doclink"
              >
              October - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+OCTOBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              October - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+OCTOBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              October - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+OCTOBER+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              October - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+OCTOBER+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              October - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+OCTOBER+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              October - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2023+IMAGES.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_no_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October2023_blm_Subsheet_Onix190620230859.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October2023_blm_Subsheet_Onix190620230859BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+October+2023.pdf"
              target="_blank" class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+October+2023.pdf"
              target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          September 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>September 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+SEPTEMBER+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+SEPTEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUSTRALIA+SEPTEMBER+2023.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_no_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135_BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
              href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+NEW+BOOKS+SEPT+2023.pdf"
               target="_blank"
              class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          August 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>August 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+AUGUST+2023+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+AUGUST+2023+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+AUGUST+2023+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2023+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+August_Subsheet_Onix211220221136.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+August_Subsheet_Onix211220221136_BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
              href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+NEW+BOOKS+August+2023.pdf"
              target="_blank" class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+August+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          July 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>July 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JULY+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JULY+2023+IMAGES.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms+July_Subsheet_Onix211220221136.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms+July_Subsheet_Onix211220221136_BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
              href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+NEW+BOOKS+July+2023.pdf"
              target="_blank"
              class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+July+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
          <h4 class="mooblock-title mb165_1t">
            June 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>June 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JUNE+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JUNE+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+June_Subsheet_Onix211220221136.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+June_Subsheet_Onix211220221136_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+NEW+BOOKS+June+2023.pdf" target="_blank" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+AUS+KIDS+NEW+BOOKS+June+2023.pdf" target="_blank" class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>May 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+MAY+2023+final+subsheet.pdf
"
                  target="_blank"
                  class="doclink"
                >
                  May - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MAY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_May_Subsheet_Onix211220221136.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_May_Subsheet_Onix211220221136_BN.zip
"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>April 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+APRIL+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BloomsburyApril_Subsheet_Onix071220221400.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BloomsburyAprilApril_Subsheet_Onix071220221400_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>March 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+MARCH+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_March_Subsheet_Onix071020221551.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_March_Subsheet_Onix071020221551_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            February 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>February 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+FEBRUARY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Feb_Subsheet_Onix140920221551.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Feb_Subsheet_Onix140920221551_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            January 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>January 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JANUARY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JANUARY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+JANUARY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_Jan_Subsheet_Onix300820221418_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            December 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>December 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2022+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2022+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2022+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/DECEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/blooms_Dec_Subsheet_Onix270720221555.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/blooms_Dec_Subsheet_Onix270720221555_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            November 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>November 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+November+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+November+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+November+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/noV22_a%26u.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/nov22_a%26u_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Nov22+Bloomsbury_Subsheet_Onix060720221021.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Nov22+Bloomsbury_Onix060720221021_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+NEW+BOOKS+NOV+2022.pdf"
                  class="doclink"
                  target="_blank"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+NOV+2022(1).pdf"
                  class="doclink"
                  target="_blank"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            October 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>October 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+October+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+October+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+October+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+Onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+of2449_packs_booknet_revised.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms_Oct_Subsheet_Onix060720220952.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms_Oct_Subsheet_Onix060720220952_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+KIDS+NEW+BOOKS+OCT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            September 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>September 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+September+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+September+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+September+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/SEPTEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AU_ONIX_September2022_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Sept_Subsheet_Onix040520221636.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Sept_Subsheet_Onix040520221636_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+NZ+KIDS+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>

          <h4 class="mooblock-title mb165_1t">
            August 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>August 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Allen+%26+Unwin+AUSTRALIA+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Children's+AUSTRALIA+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Bloomsbury+AUSTRALIA+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+August+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+August+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+August+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2022+ONIX+212.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Aug_Subsheet_Onix210320220946.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Aug_Subsheet_Onix210320220946_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
            </div>
          </div>
        </div>
        <div v-if="host == 'allenandunwinnz'">
          <h4 class="mooblock-title mb165_1t">
            July 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>July 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="hhttps://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JULY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2024+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2024+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2024+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                July - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/July+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/July_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+July2024_Onix.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+July_2024_Onix_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+JULY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            June 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>June 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JUNE+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2024+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2024+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2024+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/June+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Subsheet_2402201259.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/June_2024_Subsheet_2402201259_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+JUNE+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+JUNE+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+JUN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>May 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+MAY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2024+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2024+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2024+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                May - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2024+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
            <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/May_2024_Subsheet_2401241219.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM_May_2024_Booknet.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+MAY+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>April 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+APRIL+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2024+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2024+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2024+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                April - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
              href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BLM+April_Subsheet_1312230925.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April_Subsheet_1312230925_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+APR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>March 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MARCH+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2024+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2024+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2024+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2024+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                March - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/March_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Bloomsbury_Subsheet_March_Onix011120231203.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Bloomsbury_Subsheet_March_Onix011120231203_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+MARCH+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+MARCH+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+MAR+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            February 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>February 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+FEBRUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2024+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2024+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2024+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                February - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2024+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_2024_Indent_monthly_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_Subsheet_Onix270920231408.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/February_Subsheet_Onix270920231408_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+MUR+NEW+BOOKS+FEB+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            January 2024 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>January 2024 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JANUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Children's_NEW_ZEALAND_JANUARY_2024_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JANUARY+2024+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JANUARY+2024+final+subsheet+(1).pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2024+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2024+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2024+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2024+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                January - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2024+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_2024_monthly_no_packs_booknet.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+Jan2024_Indent_Onix.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_Subsheet_Onix310820230837.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/January_Subsheet_Onix310820230837_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>
            <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/NZ+KIDS+NEW+BOOKS+JAN+2024.pdf"
                  target="_blank"
                  class="doclink"
                >
                Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="JavaScript:void(0);"
                  class="doclink"
                >
                Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            December 2023 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>December 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Allen_%26_Unwin_NEW_ZEALAND_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Children_s_NEW_ZEALAND_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Bloomsbury_NEW_ZEALAND_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ_Murdoch_NEW_ZEALAND_DECEMBER_2023_final_subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                December - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/December_2023_Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_2023_Indent_no_packs.xml"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Indent</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_Subsheet_Onix240820231044.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/December_Subsheet_Onix240820231044_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NEW+ZEALAND+NEW+BOOKS+DEC+2023.pdf"
                target="_blank"  
                class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);"
                  class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NEW+ZEALAND+MUR+NEW+BOOKS+DEC+2023.pdf"
                target="_blank"  
                class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            November 2023 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>November 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+NOVEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+NOVEMBER+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+NOVEMBER+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+NOVEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+NOVEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                November - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+November+2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/November_Subsheet_Onix190720231002+(2).zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/November_Subsheet_Onix190720231002_BN.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+NOV+2023.pdf"
                  target="_blank"
                  class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+NOV+2023.pdf"
                  target="_blank"
                  class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+NOV+2023.pdf"
                  target="_blank"
                  class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
              October 2023 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>October 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+OCTOBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+OCTOBER+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+OCTOBER+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+OCTOBER+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+OCTOBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                October - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_october2023_monthly_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October2023_blm_Subsheet_Onix190620230859.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October2023_blm_Subsheet_Onix190620230859BN.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+NEW+BOOKS+October+2023.pdf"
                target="_blank"
                class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+KIDS+NEW+BOOKS+October+2023.pdf"
                target="_blank"
                class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+October+2023.pdf"
                target="_blank"
                class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
            <h4 class="mooblock-title mb165_1t">
              September 2023 files<i class="arrow"></i>
            </h4>
            <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>September 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+SEPTEMBER+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                September - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUSTRALIA+SEPTEMBER+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+September+2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+September_Subsheet_Onix0505221135_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+KIDS+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+SEPT+2023.pdf" target="_blank" class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            August 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>August 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+AUGUST+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              August - Murdoch</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+AUGUST+2023+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+AUGUST+2023+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+AUGUST+2023+Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+AUGUST+2023+Murdoch+kit.pdf"
                target="_blank"
                class="doclink"
              >
              August - Murdoch</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2023+Images.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs.zip"
                target="_blank"
                class="doclink"
              >
              ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2023_packs_booknet.zip"
                target="_blank"
                class="doclink"
              >
              ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+August_Subsheet_Onix211220221136.zip"
                target="_blank"
                class="doclink"
              >
              ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+August_Subsheet_Onix211220221136_BN.zip"
                target="_blank"
                class="doclink"
              >
              ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+NEW+BOOKS+August+2023.pdf" target="_blank" class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+KIDS+NEW+BOOKS+August+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+AUGUST+2023.pdf"target="_blank" class="doclink">
                Murdoch Books New Books Brochure</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          July 2023 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>July 2023 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - adult trade fiction & non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JULY+2023+final+subsheet.pdf"
                target="_blank"
                class="doclink"
              >
              July - Murdoch</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2023+Low+Res+A%26U+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2023+Low+Res+Childrens+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2023+Low+Res++Bloomsbury+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JULY+2023+Low+Res+Murdoch+kit.pdf"
                target="_blank"
                class="doclink"
              >
              July - Murdoch</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JULY+2023+IMAGES.zip"
                target="_blank"
                class="doclink"
              >
                Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+July+2023_booknet.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file A&U Booknet</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms+July_Subsheet_Onix211220221136.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury</a
              >
            </p>
            <p>
              <a
                href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms+July_Subsheet_Onix211220221136_BN.zip"
                target="_blank"
                class="doclink"
              >
                ONIX file Bloomsbury Booknet</a
              >
            </p>

            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+NEW+BOOKS+July+2023.pdf" target="_blank" class="doclink">
                Allen & Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+KIDS+NEW+BOOKS+July+2023.pdf" target="_blank" class="doclink">
                Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+JULY+2023.pdf" target="_blank" class="doclink">
                Murdoch Books New Books Brochure</a
              >
            </p>
          </div>
        </div>
          <h4 class="mooblock-title mb165_1t">
            June 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>June 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+JUNE+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JUNE+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                June - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/JUNE+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+June2023_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+June_Subsheet_Onix211220221136.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+June_Subsheet_Onix211220221136_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+NEW+BOOKS+June+2023.pdf" target="_blank" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+KIDS+NEW+BOOKS+June+2023.pdf" target="_blank" class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/Final+-+NZ+MUR+NEW+BOOKS+JUNE+2023.pdf" target="_blank" class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            May 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>May 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+MAY+2023+final+subsheet.pdf
"
                  target="_blank"
                  class="doclink"
                >
                  May - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MAY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MAY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  May - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MAY+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U+May+2023_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_May_Subsheet_Onix211220221136.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_May_Subsheet_Onix211220221136_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+May+2023.pdf" target="_blank" class="doclink">
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            April 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>April 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+APRIL+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+APRIL+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  April - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/APRIL+2023+IMAGES.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/April+2023+monthly_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BloomsburyApril_Subsheet_Onix071220221400.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/BloomsburyAprilApril_Subsheet_Onix071220221400_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+April+2023.pdf"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            March 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>March 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+MARCH+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+MARCH+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  March - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/MARCH+2023+images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_March_2023_monthly_no_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_March_Subsheet_Onix071020221551.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_March_Subsheet_Onix071020221551_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+March+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            February 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>February 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+FEBRUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+FEBRUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  February - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/FEBRUARY+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_feb_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Feb_Subsheet_Onix140920221551.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Feb_Subsheet_Onix140920221551_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            January 2023 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>January 2023 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+JANUARY+2023+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2023+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2023+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2023+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+JANUARY+2023+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  January - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/January+2023+Images.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/A%26U_Jan_monthy_packs_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_Jan_Subsheet_Onix300820221418.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury_Jan_Subsheet_Onix300820221418_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>

              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+FEB+2023.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            December 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>December 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2022+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2022+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2022+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2022+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/DECEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/blooms_Dec_Subsheet_Onix270720221555.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/blooms_Dec_Subsheet_Onix270720221555_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="- https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            November 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>November 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+NOVEMBER+2022+final+subsheet+(1).pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+November+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+November+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+November+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+November+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/noV22_a%26u.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/nov22_a%26u_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+NOV+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+NOV+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+NOV+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            October 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>October 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+October+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+October+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+October+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+October+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+Onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+of2449_packs_booknet_revised.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms_Oct_Subsheet_Onix060720220952.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Blooms_Oct_Subsheet_Onix060720220952_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+OCT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+OCT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>

          <h4 class="mooblock-title mb165_1t">
            September 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>September 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Murdoch</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2022+Low+Res+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2022+Low+Res+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2022+Low+Res++Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2022+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/SEPTEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AU_ONIX_September2022_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  ONIX file A&U Booknet</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Sept_Subsheet_Onix040520221636.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Bloomsbury+Sept_Subsheet_Onix040520221636_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+NZ+KIDS+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>

          <h4 class="mooblock-title mb165_1t">
            August 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>August 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Allen+%26+Unwin+NEW+ZEALAND+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - adult trade fiction & non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Children's+NEW+ZEALAND+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Bloomsbury+NEW+ZEALAND+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Bloomsbury</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+August+2022+A%26U+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - adult fiction and non-fiction</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+August+2022+Childrens+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - children and young adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+August+2022+Bloomsbury+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+August+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Murdoch</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2022+ONIX+212.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Aug_Subsheet_Onix210320220946.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/Aug_Subsheet_Onix210320220946_BN.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Bloomsbury Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Allen & Unwin New Books Brochure</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+KIDS+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Books for Children and Young Adults</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  Murdoch Books New Books Brochure</a
                >
              </p>
            </div>
          </div>
        </div>
        <div v-if="host == 'murdoch'">
          <h4 class="mooblock-title mb165_1t">
            December 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>December 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+DECEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/AUSTRALIA+DECEMBER+2022+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+DECEMBER+2022+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  December - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/DECEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AllenAndUnwin_dec_2022_onix_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href=" https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+DEC+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            November 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>November 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+NOVEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+NOVEMBER+2022+final+subsheet+(1).pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+November+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+November+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  November - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/NOVEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/noV22_a%26u.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/nov22_a%26u_booknet.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink">
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            October 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>October 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+OCTOBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+October+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+October+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/OCTOBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+Onix.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/October+2022+of2449_packs_booknet_revised.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file Booknet</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+OCT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+OCT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
          <h4 class="mooblock-title mb165_1t">
            September 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>September 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+SEPTEMBER+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+September+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/NEW+ZEALAND+SEPTEMBER+2022+Low+Res+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  September - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/SEPTEMBER+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/AU_ONIX_September2022_packs.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX A&U</a
                >
              </p>
              <p>
                <a href="JavaScript:void(0);" class="doclink"> ONIX Booknet</a>
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+SEPT+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>

          <h4 class="mooblock-title mb165_1t">
            August 2022 files<i class="arrow"></i>
          </h4>
          <div class="mooblock-el mb165_1e">
            <div class="mooblock-el__inner">
              <h4>
                <strong>August 2022 files</strong>
              </h4>
              <p>
                <strong>Order forms (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/AUS+Murdoch+AUSTRALIA+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/orderforms/NZ+Murdoch+NEW+ZEALAND+AUGUST+2022+final+subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - New Zealand</a
                >
              </p>
              <p>
                <strong>Sales kits (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/Australia+August+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/kits/New+Zealand+August+2022+Murdoch+kit.pdf"
                  target="_blank"
                  class="doclink"
                >
                  August - New Zealand</a
                >
              </p>
              <p>
                <strong>Download (zip files):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/images/AUGUST+2022.zip"
                  target="_blank"
                  class="doclink"
                >
                  Jpeg images</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/onix/August+2022+ONIX+212.zip"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file A&U</a
                >
              </p>
              <p>
                <strong>New Books Brochure (PDF):</strong>
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+AUS+MUR+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure Australia</a
                >
              </p>
              <p>
                <a
                  href="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/newbooks/FINAL+-+NZ+MUR+NEW+BOOKS+AUG+2022.pdf"
                  target="_blank"
                  class="doclink"
                >
                  New Books Brochure New Zealand</a
                >
              </p>
            </div>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          January 2021 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>January 2021 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+JANUARY+2021+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                January - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+JANUARY+2021+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                January - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+JANUARY+2021+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                January - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jan2021-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
              >
                January - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jan2021-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
              >
                January - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jan2021-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
              >
                January - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/January-2021_JPEGs-1.zip"
                target="_blank"
                class="doclink"
              >
                January Jpeg images - Part 1</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/January-2021_JPEGs-2.zip"
                target="_blank"
                class="doclink"
              >
                January Jpeg images - Part 2</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/January-2021-ONIX.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of January titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/January-2021-ONIX-Bloomsbury.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of January titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/JANUARY+2021+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                January - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/JANUARY+2021+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                January - Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          December 2020 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>December 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+DECEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                December - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+DECEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                December - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+DECEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                December - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Dec2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
              >
                December - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Dec2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
              >
                December - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Dec2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
              >
                December - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/December-2020_JPEGs.zip"
                target="_blank"
                class="doclink"
              >
                December Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/December-2020-ONIX.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of December titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/December-2020-ONIX-Bloomsbury.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of December titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/DECEMBER+2020+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                December - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+KIDS+NEW+BOOKS+DEC+2020.pdf"
                target="_blank"
                class="doclink"
              >
                December - Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Best+Books+for+Schools+August+-+December+2020+(5).pdf"
                target="_blank"
                class="doclink"
              >
                Best Books for Schools: August - December 2020</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          November 2020 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>November 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+NOVEMBER+2020+Subsheet+(1).pdf"
                target="_blank"
                class="doclink"
              >
                November - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+NOVEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                November - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+NOVEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                November - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Nov2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
              >
                November - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Nov2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
              >
                November - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Nov2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
              >
                November - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/November-2020_JPEGs.zip"
                target="_blank"
                class="doclink"
              >
                November Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/November-2020-ONIX.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of November titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/November-2020-ONIX-Bloomsbury.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of November titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/NOVEMBER+2020+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                November - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/NOVEMBER+2020+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                November - Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Best+Books+for+Schools+August+-+December+2020.pdf"
                target="_blank"
                class="doclink"
              >
                Best Books for Schools: August - December 2020</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          October 2020 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>October 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+OCTOBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                October - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+OCTOBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                October - children and young adults</a
              >
            </p>
            <p>
              <strong>
                <a
                  href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+OCTOBER+2020+Subsheet.pdf"
                  target="_blank"
                  class="doclink"
                >
                  October - Bloomsbury</a
                >
              </strong>
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Oct2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
              >
                October - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Oct2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
              >
                October - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Oct2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
              >
                October - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/October-2020_JPEGs.zip"
                target="_blank"
                class="doclink"
              >
                October Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/October-2020-ONIX.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of October titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/October-2020-ONIX-Bloomsbury.XML"
                target="_blank"
                class="doclink"
              >
                ONIX file of October titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/OCTOBER+2020+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                October - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/OCTOBER+2020+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                October - Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Best+Books+for+Schools+August+-+December+2020+(1).pdf"
                target="_blank"
                class="doclink"
              >
                Best Books for Schools: August - December 2020</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          September 2020 files<i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>September 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+SEPTEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                September - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Sep2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
              >
                September - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+SEPTEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Sep2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
              >
                September - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+SEPTEMBER+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
              >
                September - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Sep2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
              >
                September - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/September-2020_JPEGs.zip"
                target="_blank"
                class="doclink"
              >
                September Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/September-2020-ONIX.xml"
                target="_blank"
                class="doclink"
              >
                ONIX file of&nbsp;September titles</a
              >
            </p>
            <p>
              <strong>
                <a
                  href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/September-2020-ONIX-Bloomsbury.XML"
                  target="_blank"
                  class="doclink"
                >
                  ONIX file of September titles - Bloomsbury</a
                >
              </strong>
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/SEPTEMBER+2020+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                September - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/SEPTEMBER+2020+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                September - Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Best+Books+for+Schools+August+-+December+2020+(2).pdf"
                target="_blank"
                class="doclink"
              >
                Best Books for Schools: August - December 2020</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          August 2020 files
          <i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb165_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>August 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+AUGUST+2020+Subsheet+(1).pdf"
                target="_blank"
                class="doclink"
                >August - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+AUGUST+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
                >August - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+AUGUST+2020+Subsheet+2.pdf"
                target="_blank"
                class="doclink"
                >August - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Aug2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
                >August - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Aug2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
                >August - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Aug2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
                >August - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/August-2020_JPEGs.zip"
                target="_blank"
                class="doclink"
                >August Jpeg images</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/August-2020-ONIX.xml"
                target="_blank"
                class="doclink"
                >ONIX file of August titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/August-2020-ONIX-Bloomsbury.xml"
                target="_blank"
                class="doclink"
                >ONIX file of August titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUGUST+2020+AUS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                August - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUGUST+2020+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
              >
                August - Books for Children and Young Adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Best+Books+for+Schools+August+-+December+2020+(4).pdf"
                target="_blank"
                class="doclink"
              >
                Best Books for Schools: August - December 2020</a
              >
            </p>
          </div>
        </div>
        <h4 class="mooblock-title mb165_1t">
          July 2020 files
          <i class="arrow"></i>
        </h4>
        <div class="mooblock-el mb24_1e">
          <div class="mooblock-el__inner">
            <h4>
              <strong>July 2020 files</strong>
            </h4>
            <p>
              <strong>Order forms (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Allen+%26+Unwin+AUSTRALIA+JULY+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
                >July - adult trade fiction &amp; non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Children's+AUSTRALIA+JULY+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
                >July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/AUS+Bloomsbury+AUSTRALIA+JULY+2020+Subsheet.pdf"
                target="_blank"
                class="doclink"
                >July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Sales kits (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jul2020-saleskit-AU.pdf"
                target="_blank"
                class="doclink"
                >July - adult fiction and non-fiction</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jul2020-saleskit-AU-Kids.pdf"
                target="_blank"
                class="doclink"
                >July - children and young adults</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Jul2020-saleskit-AU-BLM.pdf"
                target="_blank"
                class="doclink"
                >July - Bloomsbury</a
              >
            </p>
            <p>
              <strong>Download (zip files):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/July-2020_JPEGs-Part1.zip"
                target="_blank"
                class="doclink"
                >July Jpeg images Part 1</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/July-2020_JPEGs-Part2.zip"
                target="_blank"
                class="doclink"
                >July Jpeg images Part 2</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/Australia+July+2020+ONIX+212+(2).xml"
                target="_blank"
                class="doclink"
                >ONIX file of July titles</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/July-2020-ONIX+Bloomsbury.xml"
                target="_blank"
                class="doclink"
                >ONIX file of July titles - Bloomsbury</a
              >
            </p>
            <p>
              <strong>New Books Brochure (PDF):</strong>
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/JULY+2020+AUS+NEW+BOOKS.pdf.pdf"
                target="_blank"
                class="doclink"
                >July - Allen &amp; Unwin New Books Brochure</a
              >
            </p>
            <p>
              <a
                href="https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/files/bookseller/JULY+2020+AUS+KIDS+NEW+BOOKS.pdf"
                target="_blank"
                class="doclink"
                >July - Books for Children and Young Adults</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="item-page" v-if="!logged_in">
        <h1 style="color: #41525c">Booksellers</h1>
        <p></p>
        <p>
          To access restricted Bookseller content please enter your UBD account
          number below.
        </p>
        <p></p>
        <p v-if="error" class="error">{{ error }}</p>
        <div class="anv-submit">
          <input type="hidden" name="anv-submit" value="1" />

          <label for="accountnumberInput">Account number</label>
          <input
            type="text"
            id="accountnumberInput"
            name="accountnumber"
            v-model="account_num"
          />

          <input
            class="button button--color1"
            type="submit"
            @click="logIn()"
            value="Submit"
          />
        </div>
        <p></p>
        <p></p>
        <p></p>
        <p>&nbsp;</p>
      </div>
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="show_spinner"
        class="loader-spinner"
      />
    </div>
  </div>
</template>

<script>
import { LoopingRhombusesSpinner } from "epic-spinners";
import axios from "axios";
export default {
  name: "booksellers",
  components: {
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      logged_in: false,
      show_spinner: false,
      account_num: null,
      error: null,
      host: this.$store.state.site_data.host,
    };
  },
  methods: {
    logIn() {
      let self = this;
      this.$store
        .dispatch("addBooksellersNum", this.account_num)
        .then((res) => {
          self.logged_in = true;
        })
        .catch((err) => {
          console.log(err.data);
          self.error = "Please enter a correct Account Number";
        });
    },
  },
  mounted() {
    let account_num = this.$store.state.booksellers_num;
  },
};
</script>

<style>
/* .text-color {
  color: #54c7c6;
} */
.error {
  color: red;
}
</style>