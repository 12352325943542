<template>
  <default>
    <div class="wrapper">
      <bread-crumb></bread-crumb>
      <div class="l-main-wrapper mobileFullWidth clearfix">
        <slot/>
      </div>
      <side-bar></side-bar>
    </div>
    <div class="clear"></div>
  </default>
</template>

<script>
import Default from './DefaultMurdoch';
import BreadCrumb from '../../components/BreadCrumb';
import SideBar from '../../components/SideBar';

export default {
    components:{
        Default,
        BreadCrumb,
        SideBar
    },
}
</script>

<style>

</style>