<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main-top mobileFullWidthWithMargin">
      <div class="moduletable vmss_search_filter--boxy-styled">
        <h3>Children's & Young Adult books by age</h3>
        <div class="vmss_search_filter" id="vmms_search_kids">
          <input type="hidden" name="searchword" value="" />
          <form
            id="vmss_search_filter_formkids"
            class="vmss_search_filter_form_class"
            method="get"
            action="/search"
          >
            <div class="search">
              <label id="searchfilter_36_-lbl" for="searchfilter_36_" class="">
                Age
              </label>
              <div class="selecter" tabindex="0">
                <select
                  id="searchfilter_36_"
                  name="age"
                  tabindex="-1"
                  class="selecter-element select-age"
                >
                  <option value="0 18" selected="selected">- Any age -</option>
                  <option value="0 3">Up to three</option>
                  <option value="4 6">Four to six</option>
                  <option value="7">Seven</option>
                  <option value="8">Eight</option>
                  <option value="9">Nine</option>
                  <option value="10">Ten</option>
                  <option value="11">Eleven</option>
                  <option value="12">Twelve</option>
                  <option value="13">Thirteen</option>
                  <option value="14">Fourteen</option>
                  <option value="15">Fifteen</option>
                  <option value="16 18">Sixteen to Eighteen</option>
                </select>
                <span class="selecter-selected selected new-class"
                  >- Any age -</span
                >
                <div class="selecter-options">
                  <span class="selecter-item selected" value>- Any age -</span>
                  <span class="selecter-item" data-value="0 3"
                    >Up to three</span
                  >
                  <span class="selecter-item" data-value="4 6"
                    >Four to six</span
                  >
                  <span class="selecter-item" data-value="7">Seven</span>
                  <span class="selecter-item" data-value="8">Eight</span>
                  <span class="selecter-item" data-value="9">Nine</span>
                  <span class="selecter-item" data-value="10">Ten</span>
                  <span class="selecter-item" data-value="11">Eleven</span>
                  <span class="selecter-item" data-value="12">Twelve</span>
                  <span class="selecter-item" data-value="13">Thirteen</span>
                  <span class="selecter-item" data-value="14">Fourteen</span>
                  <span class="selecter-item" data-value="15">Fifteen</span>
                  <span class="selecter-item" data-value="16 18"
                    >Sixteen to Eighteen</span
                  >
                </div>
              </div>
              <button
                class="button button--color2 icon--search2"
                style="font-family: 'Open Sans', sans-serif"
              >
                Search
              </button>
              <div class="clear"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="l-main mobileFullWidthWithMargin">
      <div class="item-page">
        <h1>Children’s & Young Adult Books</h1>
        <p>
          Allen & Unwin is home to the best books for kids of any age. We
          publish across a wide range, from picture books and board books to
          young adult fiction and non-fiction. Our many talented authors and
          illustrators include Amie Kaufman, Jay Kristoff, Garth Nix, E.
          Lockhart, Ursula Dubosarsky, Lili Wilkinson, Scott Westerfeld, Anh Do,
          Paul Jennings, Leigh Hobbs, Alison Lester, Terry Denton, Anna
          Fienberg, Karen Foxlee, and many more.
        </p>
        <h6>Latest Children's Books</h6>
        <div class="l-main-top mobileFullWidthWithMargin" v-if="show_top_books">
          <div class="moduletable">
            <div class="vmss_mixed_image">
              <div class="vmss_mixed_image__item" v-for="book in top_books">
                <div class="vmss_mixed_image__image-wrap">
                  <router-link
                    class="vmss_mixed_image__item__image"
                    :to="getBookUrl(book)"
                    target="_self"
                    :title="book.title"
                  >
                    <img
                      :src="
                        'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                        book.isbn +
                        '.jpg'
                      "
                      alt="A Murder at Malabar Hill"
                    />
                  </router-link>
                  <div class="ie-hover-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6>Latest Young Adult Books</h6>
        <div
          class="l-main-top mobileFullWidthWithMargin"
          v-if="show_young_adult_books"
        >
          <div class="moduletable">
            <div class="vmss_mixed_image">
              <div
                class="vmss_mixed_image__item"
                v-for="book in top_young_adult_books"
              >
                <div class="vmss_mixed_image__image-wrap">
                  <router-link
                    class="vmss_mixed_image__item__image"
                    :to="getBookUrl(book)"
                    target="_self"
                    :title="book.title"
                  >
                    <img
                      :src="
                        'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                        book.isbn +
                        '.jpg'
                      "
                      alt="A Murder at Malabar Hill"
                    />
                  </router-link>
                  <div class="ie-hover-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="moduletable link-box box-style">
        <div class="custom link-box">
          <p class="link-box__button-wrap img-wrap">
            <a href="/browse/kids/downloadable-activities">
              <img src="/static/ActivitySheets.png" alt="" class="img-set" />
            </a>
          </p>
          <p>
            <a href="/browse/kids/downloadable-activities" style="color: white">
              <p class="heading-text">Activity Sheets</p>
              <p class="subheading-text">
                Download the latest activity sheets here
              </p>
            </a>
          </p>
        </div>
      </div>
      <div class="moduletable link-box box-style">
        <div class="custom link-box">
          <p class="link-box__button-wrap img-wrap">
            <a href="/browse/kids/albert-street-books">
              <img src="/static/ASB_logo.png" alt="" class="img-set" />
            </a>
          </p>
          <p>
            <a href="/browse/kids/albert-street-books" style="color: white">
              <p class="heading-text">Albert Street Books</p>
              <p class="subheading-text">
                Check out the latest Albert Street titles here
              </p>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Book from "../mixins/Book";

export default {
  name: "browse-kids",
  mixins: [Book],
  data() {
    return {
      top_books: [],
      top_young_adult_books: [],
      show_top_books: false,
      show_young_adult_books: false,
    };
  },
  methods: {
    getTopCategoryBooks() {
      let self = this;
      let category = "childrens";
      axios
        .get(`/get_top_category_books/${category}`)
        .then(function (data) {
          self.top_books = data.data.books;
          self.show_top_books = true;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getTopYoungAdultBooks() {
      let self = this;
      let category = "young-adult";
      axios
        .get(`/get_top_category_books/${category}`)
        .then(function (data) {
          self.top_young_adult_books = data.data.books;
          self.show_young_adult_books = true;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getTopCategoryBooks();
    this.getTopYoungAdultBooks();
  },
};
</script>
<style>
.heading-text {
  font: bold 1.5em "Open Sans", sans-serif;
  margin-bottom: 0;
  margin-top: 1em;
}
.subheading-text {
  font: bold 1.23em "Open Sans", sans-serif;
}
.box-style {
  background: #febe10;
  width: 45%;
}
.img-wrap {
  width: 30%;
}
.selecter {
  height: 0% !important;
}

@media screen and (max-width: 767px) {
  img.img-set {
    width: 75px !important;
    float: left !important;
  }
}

@media screen and (min-width: 542px) and (max-width: 767px) {
  img.img-set {
    margin-left: 60px !important;
  }
}
@media screen and (min-width: 439px) and (max-width: 541px) {
  img.img-set {
    margin-left: 40px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 541px) {
  img.img-set {
    margin-left: 20px !important;
  }
}
@media screen and (max-width: 359px) {
  img.img-set {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1199px) {
  img.img-set {
    width: 100px !important;
  }
}
</style>
