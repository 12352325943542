<template>
  <div class="p-search" id="email">
    <div class="footer_newsletter">
      <div class="align-text container m-auto centr-align d-block">
        <div class="text_left">
          <h2 class="news_txt">
            Subscribe to our newsletter to keep up to date with copyright
          </h2>
        </div>
      </div>

      <mailchimp-subscribe
        url="https://copyright.us2.list-manage.com/subscribe/post-json"
        user-id="c12aa922878924ad25007c9c8"
        list-id="41c8137ef9"
      >
        <template v-slot="{ subscribe, setEmail, error, success, loading }">
          <form class="subscribe-mailchimp" @submit.prevent="subscribe">
            <input
              class="form-control input-sm border-line"
              placeholder="Email address"
              type="email"
              @input="setEmail($event.target.value)"
            />
            <button class="search-button button" type="submit">
              SUBSCRIBE
            </button>
            <div v-if="error">
              <p><span v-html="error"></span></p>
            </div>
            <div v-if="success">Successfully Subscribed</div>
            <div v-if="loading">Loading…</div>
          </form>
        </template>
      </mailchimp-subscribe>
      <br />
      <div class="align-text container m-auto centr-align d-block">
        <div class="text_left">
          <p v-if="$route.path == '/'" class="country-ack">
            The Australian Copyright Council acknowledges the Traditional
            Custodians of the lands on which our Redfern office is located, the
            Gadigal people of the Eora nation and all Traditional Owners of
            Country throughout Australia.<br />
            We pay our respects to all Elders past, present and emerging and
            recognise their continuing great creative and cultural expressions.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import layoutMixin from "../mixins/Layout";
import newsLetterMixin from "../mixins/Newsletter";
import MobileVersion from "../components/MobileVersion";
import MailchimpSubscribe from "vue-mailchimp-subscribe";

export default {
  components: {
    MobileVersion,
    MailchimpSubscribe,
  },
  mixins: [layoutMixin, newsLetterMixin],
  data() {
    return {
      newsletterEmail: null,
      showMenu: false,
    };
  },
  methods: {},

  computed: {
    iconMobileClass() {
      return this.showMenu ? "st-menu-open" : "";
    },
  },
};
</script>

<style>
.subscribe label {
  height: 3.312em;
}
.featured-image {
  min-height: 1920px;
  overflow: hidden;
}

.search-button {
  background-color: #f6793c;
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 2px;
}
.country-ack {
  margin-bottom: 0px;
  margin-top: 15px;
}
</style>
