<template>
  <div class="l-main-top mobileFullWidthWithMargin" v-if='show_top_books'>
    <div class="moduletable">
      <div class="vmss_mixed_image">
        <div class="vmss_mixed_image__item" v-for="book in top_books">
          <div class="vmss_mixed_image__image-wrap">
            <router-link
              class="vmss_mixed_image__item__image"
              :to="getBookUrl(book)"
              target="_self"
              :title="book.title"
            >
            <img class="top-category-images" v-if="host== 'acc'"
              :src="'https://dimoacc.s3-ap-southeast-2.amazonaws.com/covers/'+ book.isbn +'.jpg'"
              @error="noCoverImage "
            />
            <img class="top-category-images" v-else
              :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+ book.isbn +'.jpg'"
              @error="noCoverImage"
            />
            </router-link>
            <div class="ie-hover-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Book from '../mixins/Book';

export default {
  name: "top-book-categorys",
  mixins: [Book],
  data() {
    return {
        host:'',
        top_books: [],
        show_top_books: false,
    };
  },
  methods: {
    noCoverImage(event) {
      if (this.$store.state.site_data.host== 'acc'){
        event.target.src = "/static/images/acc/No_Image_Available_724x1024.jpg";  
      }
      else if (this.$store.state.site_data.host == 'allenandunwin' || this.$store.state.site_data.host == 'allenandunwinnz'){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
      }
    },
    getTopCategoryBooks() {
      let self = this;
      let path = this.$route.path;
      let category = path.split("/").pop();
      axios
        .get(`/get_top_categorys_books/`)
        .then(function(data) {
          self.top_books = data.data.books;
          self.show_top_books = true;
        })
        .catch(function(err) {
          console.log(err);
        });
    },     
  },
  mounted() {
    this.host = this.$store.state.site_data.host;
    this.getTopCategoryBooks();
  }
};
</script>

<style>
</style>