<template>
  <div class="sort-field">
    <label id="ordering-lbl" for="ordering">Sort by</label>
    <div
      class="selecter min-width-selecter"
      :class="[isSortClosed ? 'closed' : 'open']"
      tabindex="0"
      @click="isSortClosed = !isSortClosed"
    >
      <span class="selecter-selected">{{ getSort }}</span>
      <div class="selecter-options">
        <span
          class="selecter-item"
          v-for="(sort, i) in sorts"
          @click="selected_sort_index = i"
          >{{ sort.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sort-books",
  data() {
    return {
      isSortClosed: true,
      selected_sort_index: 0,
      sorts: [
        {
          name: "Relevant",
          code: "rel",
        },
        {
          name: "A-Z",
          code: "alph",
        },
        {
          name: "By Publication Date (Descending)",
          code: "date_desc",
        },
        {
          name: "By Publication Date (Ascending)",
          code: "date_asc",
        },
        {
          name: "Price (Descending)",
          code: "price_desc",
        },
        {
          name: "Price (Ascending)",
          code: "price_asc",
        },
      ],
    };
  },
  computed: {
    getSort() {
      return this.sorts[this.selected_sort_index].name;
    },
  },
  mounted() {
    this.host = this.$store.state.site_data.host;
    if (this.host == "murdochuk"){
      this.sorts[2].name = "By Publication Date (Newest)";
      this.sorts[3].name = "By Publication Date (Latest)";
    }
    else if (this.host == "allenandunwinnz" || this.host == "allenandunwin"){
      this.selected_sort_index = 0;
      this.sorts[2].name = "By Publication Date (Newest)";
      this.sorts[3].name = "By Publication Date (Latest)";
    }
  },
  watch: {
    selected_sort_index: function (oldIndex, newIndex) {
      if (oldIndex != newIndex) {
        this.$emit(
          "sort-search-books",
          this.sorts[this.selected_sort_index].code
        );
        this.$refs.search_comp.search_books();
      }
    },
  },
};
</script>

<style>
.sort-field {
  float: right;
  margin-top: 0px !important;
}
.min-width-selecter {
  min-width: 200px;
  margin-left: 10px;
}
</style>
