<template>
  <!-- start authors section -->
  <div>
    <section id="authors" class="-bg-brown">
      <div class="st-container">
        <div class="py-5 mb-5">
          <div class="row --text-dark">
            <div class="col-sm-12 col-md-12">
              <div class="d-flex align-items-center h-100">
                <h1 class="s-h1 fw-semibold --text-dark mb-4">
                  Authors & Illustrators
                </h1>
              </div>
              <p class="s-h6 --text-dark">Featured</p>
            </div>
            <looping-rhombuses-spinner
              :animation-duration="2500"
              :rhombus-size="15"
              color="#febe10"
              v-if="authors.length == 0"
              class="loader-spinner"
            />
            <div class="col-sm-12 col-md-12">
              <div class="py-4 mt-3 authors" v-if="authors.length > 0">
                <div class="-bg-pink py-5 shadow-lg">
                  <div class="latest-author">
                    <div class="custom_carosel">
                      <carousel
                        :items="1"
                        :responsive="{ 1000: { items: 1 } }"
                        :loop="true"
                        :dots="false"
                        :navText="['❮', '❯']"
                        :margin="20"
                      >
                        <div class="st-container" v-for="author in authors">
                          <a class="link">
                            <div class="row align-items-center">
                              <div
                                class="col-12 col-md-5 d-flex justify-content-center align-items-center"
                              >
                                <div
                                  class="m-h-307 overflow-hidden rounded-circle border"
                                >
                                  <img
                                    v-if="author.author_image"
                                    v-bind:src="author.author_image"
                                    width="100%"
                                    class="owlslide-img gray-hover"
                                    @error="noCoverImage"
                                  />
                                  <img
                                    v-else
                                    v-bind:src="noCoverImage"
                                    width="100%"
                                    class="owlslide-img gray-hover"
                                    @error="noCoverImage"
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-7">
                                <div class="inner-col mt-3 pe-md-5">
                                  <h3 class="s-h5">
                                    {{ author.firstname }} {{ author.lastname }}
                                  </h3>
                                  <p
                                    class="s-h7 carousel-scroll"
                                    v-html="author.author_bio"
                                  ></p>
                                  <router-link
                                    :to="{ path: '/author/' + author.idauthor }"
                                    class="link"
                                  >
                                    <div
                                      class="col-button align-items-center d-flex"
                                    >
                                      <img
                                        src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                                        class="orange-arrow"
                                        width="25px"
                                      />
                                      <div>
                                        <p class="mb-0 s-h7 ps-3">Read More</p>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- end authors section -->
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import carousel from "vue-owl-carousel";
export default {
  name: "affirm-author-feature",
  components: {
    LoopingRhombusesSpinner,
    carousel,
  },
  mixins: [Book],
  props: ["isbn"],
  data() {
    return {
      authors: [],
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src =
        "/static/css/images/affirm/author_photo_coming_soon.jpg";
    },
  },
  computed: {},
  mounted() {
    let self = this;
    axios
      .get(`/get_author_data/?order=1`)
      .then(function (data) {
        self.authors = data.data.authors;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
