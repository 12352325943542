<template>
  <div>
    <div class="l-main-block mobileFullWidth clearfix">
      <div class="l-main mobileFullWidthWithMargin">
        <div class="bfClearfix">
          <div id="ff_formdiv1" class="bfFormDiv">
            <div class="bfPage-l">
              <div class="bfPage-r">
                <div class="bfPage-m bfClearfix">
                  <form
                    name="ff_form1"
                    id="ff_form1"
                    enctype="multipart/form-data"
                    accept-charset="utf-8"
                    class="bfQuickMode"
                    @submit.prevent="submit('/submit_contact_us_form/')"
                  >
                    <div id="bfPage1" class="bfPage">
                      <section class="bfPageIntro">
                        <h1 style="color: #41525c;">Contact Us</h1>
                      </section>
                      <span class="bfErrorMessage" v-if="errors">
                        <ul>
                          <li v-for="(err, key) in errors">{{key}} {{err[0].message}}</li>
                        </ul>
                      </span>
                      <div class="bfNoSection" id="bfQuickModeSection45148">
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap1">
                          <label id="bfLabel1">Name</label>
                          <input
                            class="ff_elem"
                            type="text"
                            id="ff_elem1"
                            name="name"
                            v-model="message.name"
                          />
                        </section>
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap2">
                          <label id="bfLabel2">
                            Email<span class="bfRequired">*</span>
                          </label>
                          <input
                            class="ff_elem au_email_input"
                            type="email"
                            name="email"
                            id="ff_elem2"
                            v-model="message.email"
                            required
                          />
                        </section>
                        <br>
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap4">
                          <label id="bfLabel4">My enquiry is about</label>
                          <select class="selecter resources-input-profile enquiry_bfLabelLeft" name="about" id="about-inquery">
                            <option :value="option.id" v-for="option in about_choices">{{option.name}}</option>
                          </select>
                        </section>
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap3">
                          <label id="bfLabel3" for="ff_elem3">Message</label>
                          <textarea
                            cols="20"
                            rows="5"
                            class="ff_elem"
                            name="message"
                            id="ff_elem3"
                            v-model="message.message"
                            required
                          ></textarea>
                        </section>
                      </div>
                      <div v-if="status != 'submitted'">
                        <vue-recaptcha
                          ref="recaptcha"
                          sitekey="6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"
                          @verify="onCaptchaVerified"
                        ></vue-recaptcha>
                        <button
                          type="submit"
                          id="bfSubmitButton"
                          class="bfSubmitButton button"
                          :disabled="status==='submitting' || !captchaComplete"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                      <h3
                        v-if="status === 'submitted'"
                        class="success-message"
                      >Thank you for your enquiry</h3>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-details-padding" v-if="host == 'allenandunwin' || host == 'allenandunwinnz'">
      <h4 class="mooblock-title mb165_1t">Contact details<i class="arrow"></i></h4>
      <div class="mooblock-el mb165_1e">
        <div class="mooblock-el__inner">
        <p>
          Submit your enquiry via our <a href="/contact-us">Contact Us form</a>
        </p>
        <h3><br />Sydney office</h3>
        <p>
          Allen &amp; Unwin<br />PO Box 8500<br />St Leonards, NSW 1590
        </p>
        <p>
          located at:<br />83 Alexander St<br />Crows Nest, NSW 2065<br />AUSTRALIA
        </p>
        <p>
          Phone: (61 2) 8425 0100<br />Fax: (61 2) 9906 2218
        </p>
        <p>
          <strong>Sydney office directions</strong><br />Allen &amp; Unwin's Sydney office is located at 83 Alexander St, Crows Nest (on the corner of Ernest St). If you're coming by taxi, it's about a 10 minute trip from the city. By public transport, you can catch a 273 bus to Alexander St from either Wynyard (from Stand G in Carrington St) or Chatswood station. Our office is about a 15 minute walk from either St Leonards or North Sydney railway station. The Alexander Street council car park (between Falcon and Burlington Sts) provides two hours free parking.<br />
          <a href="https://www.google.com/maps/place/83+Alexander+St,+Crows+Nest+NSW+2065,+Australia/@-33.825738,151.202452,16z/data=!4m2!3m1!1s0x6b12aec275fd29e1:0xc9d8a5e64b2c5e8c?hl=en" target="_blank">View map</a> (this link will take you to Google Maps).
        </p>
        <h3><br />Melbourne Office</h3>
        <p>406 Albert Street<br />East Melbourne, Vic 3002</p>
        <p>Phone: (61 3) 9665 5000<br />Fax: (61 3) 9665 5050</p>
        <p>
          <strong>Melbourne office directions</strong><br />Allen &amp; Unwin’s Melbourne office is located at 406 Albert Street, East Melbourne. This is between Gisborne and Lansdowne Streets, behind St Patrick’s Cathedral. It is close to Parliament train station, and the 112, 109, 86 and 96 tram routes. Commercial car parking is available at 410 Albert St.<br /><a href="https://www.google.com/maps?f=q&amp;hl=en&amp;time&amp;date&amp;ttype&amp;q=406+Albert+St,+East+Melbourne+VIC+3002,+Australia&amp;sll=-33.825988,151.20271&amp;sspn=0.009608,0.015535&amp;ie=UTF8&amp;cd=2&amp;geocode=0,-37.809524,144.977324&amp;ll=-37.809428,144.977324&amp;spn=0.009138,0.015535&amp;z=16&amp;iwloc=addr&amp;om=1" target="_blank">View map</a> (this link will take you to Google Maps).
        </p>
        <h3><br />New Zealand Office</h3>
        <p>Level 2, 10 College Hill<br />Freemans Bay<br />Auckland 1011<br />New Zealand</p>
        <p>
          Phone: (64 9) 377 3800<br />Fax: (64 9) 377 3811<br /><a href="https://www.google.com.au/maps/place/10+College+Hill,+Freemans+Bay,+Auckland+1011/@-36.8486019,174.7477736,895m/data=!3m2!1e3!4b1!4m5!3m4!1s0x6d0d478d78481d3f:0xfdd784b4f34b4973!8m2!3d-36.8486062!4d174.7499623?hl=en" target="_blank">View map</a> (this link will take you to Google Maps).
        </p>
        <h3><br />London Office</h3>
        <p>Ormond House<br />26-27 Boswell Street<br />London<br />WC1N 3JZ</p>
        <p>
          Tel: 00 44 20 7269 1610<br />Email: <a href="mailto:UK@allenandunwin.com">UK@allenandunwin.com</a><br /><em>Please note: Allen &amp; Unwin UK cannot accept any unsolicited manuscripts, in either electronic or printed form. No material will be returned.</em>
        </p>
        </div>
      </div>  
    </div>  
  </div>
</template>

<script>
import Form from '../mixins/Form.js';
import layoutMixin from "../mixins/Layout";

export default {
  name: "contact-us",
  mixins: [Form,layoutMixin],
  data() {
    return {
      message: {
        name: "",
        email: "",
        message: "",
        about: ""
      },
      errors: null
    };
  },
  methods: {},
  computed: {
    about_choices: function() {
      return this.$store.state.site_data.contact_us_about_choices;
    }
  },
  mounted() {}
};
</script>

<style>
.selecter {
  border: 1px solid #ddd;
  height: 50px;
  background-color: #fff;
}
.success-message {
  color: #0baf0b;
  margin-top: 26px;
}
.contact-details-padding{
  padding-left: 50px;
  padding-right:50px;
}
.au_email_input{
    width:50% !important;
}
.enquiry_bfLabelLeft{
    width:50% !important;
}
</style>