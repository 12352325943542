<template>
  <div class="l-main-top mobileFullWidthWithMargin" v-if='show_top_books'>
    <div class="moduletable">
      <div class="vmss_mixed_image">
        <div class="vmss_mixed_image__item" v-for="book in top_books">
          <div class="vmss_mixed_image__image-wrap">
            <router-link
              class="vmss_mixed_image__item__image"
              :to="getBookUrl(book)"
              target="_self"
              :title="book.title"
            >
              <img
                :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+ book.isbn +'.jpg'"
                alt="A Murder at Malabar Hill"
              />
            </router-link>
            <div class="ie-hover-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Book from '../mixins/Book';

export default {
  name: "top-book-shelf",
  mixins: [Book],
  data() {
    return {
        top_books: [],
        show_top_books: false,
    };
  },
  methods: {
    getTopCategoryBooks() {
      let self = this;
      let path = this.$route.path;
      let category = path.split("/").pop();
      axios
        .get(`/get_top_category_books/${category}`)
        .then(function(data) {
          self.top_books = data.data.books;
          self.show_top_books = true;
        })
        .catch(function(err) {
          console.log(err);
        });
    },     
  },
  mounted() {
    this.getTopCategoryBooks();
  }
};
</script>

<style>
</style>