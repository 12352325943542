<template>
  <div class="moduletable">
    <top-book-shelf></top-book-shelf>
    <div class="fiction-sort">
      <h1 style="display: inline-block">Fiction</h1>
      <sort-book @sort-search-books="sortBooks"></sort-book>
    </div>
    <div class="vmss_search">
      <div class="divider-line mobileHide"></div>
    </div>
    <div class="vmss_mixed_author">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="books.length == 0"
        class="loader-spinner"
      />
      <div class="vmss_mixed_author__item" v-for="book in books">
        <div class="image-wrap">
          <router-link :to="getBookUrl(book)">
            <img
              :src="
                'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                book.isbn +
                '.jpg'
              "
              @error="noCoverImage"
            />
          </router-link>
        </div>
        <div class="text-wrap">
          <router-link :to="getBookUrl(book)">
            <h5 class="title">{{ book.title }}</h5>
          </router-link>
          <p v-if="host == 'allenandunwin'" class="date">
            <b>Published: </b>
            <span v-if="book.uk_pub_date">{{
              book.uk_pub_date | ChangeDateFormat
            }}</span>
            <span v-else>{{ book.pubdate|ChangeDateFormat }}</span>
          </p>
          <p v-else class="date">
            <b>Published: </b>
            <span v-if="book.uk_pub_date">{{
              book.uk_pub_date | ChangeDateFormatNZ
            }}</span>
            <span v-else>{{ book.pubdate | ChangeDateFormatNZ }}</span>
          </p>
          <p v-html="book.key_note_blurb" class="desc"></p>
        </div>
      </div>
    </div>
    <div class="pagination pagination-mg">
      <p class="counter">Page {{ page_number }} of {{ num_pages }}</p>
      <ul>
        <li class="pagination-start">
          <a class="pagenav page-disable" v-if="page_number == 1"> Start </a>
          <router-link to="/browse/books/fiction?page=1" class="pagenav" v-else
            >Start</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == 1"> Prev </a>
          <router-link
            :to="'/browse/books/fiction?page=' + prev_page"
            class="pagenav"
            v-else
            >Prev</router-link
          >
        </li>
        <li v-for="page_num in prev_pages">
          <router-link
            :title="page_num"
            :to="'/browse/books/fiction?page=' + page_num"
            class="pagenav"
            >{{ page_num }}</router-link
          >
        </li>
        <li>
          <span class="pagenav">{{ page_number }}</span>
        </li>
        <li v-for="page_num in next_pages">
          <router-link
            :title="page_num"
            :to="'/browse/books/fiction?page=' + page_num"
            class="pagenav"
            >{{ page_num }}</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == num_pages">
            Next
          </a>
          <router-link
            :to="'/browse/books/fiction?page=' + next_page"
            class="pagenav"
            v-else
            >Next</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == num_pages">
            End
          </a>
          <router-link
            :to="'/browse/books/fiction?page=' + num_pages"
            class="pagenav"
            v-else
            >End</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import Vue from "vue";
import SortBook from "../components/FictionSortBooks";
import BestSellerList from "../components/BestSellerList";
import TopBookShelf from "../components/TopBookShelf";

Vue.config.productionTip = false;
Vue.filter("ChangeDateFormat", function (value) {
  if (value) {
    return new Date(value).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }
          )
  }
});
Vue.filter('ChangeDateFormatNZ', function(value) {
  if (value) {
    return new Date(value).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'short',
            }
          )
  }
});


export default {
  name: "author-list",
  components: {
    BestSellerList,
    SortBook,
    "top-book-shelf": TopBookShelf,
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ["placement"],
  data() {
    return {
      books: [],
      show_price: false,
      format: "single-col",
      limit: 5,
      title: null,
      num_pages: 1,
      page_num: 1,
      next_page: null,
      prev_page: null,
      next_pages: [],
      prev_pages: [],
      page_number: 1,
      page_numb: 1,
      host: this.$store.state.site_data.host,
    };
  },

  methods: {
    sortBooks(code) {
      this.fication_pagination(code);
    },
    noCoverImage: function (event) {
      if (this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
      }
    },
    fication_pagination(search_sort) {
      let sort_code = search_sort;

      if (!search_sort) {
        sort_code = localStorage["fiction_sort_code"];
      }
      localStorage["fiction_sort_code"] = sort_code;
      if (!sort_code) {
        sort_code = "title";
      }
      if (this.$route.query.page) {
        this.page_numb = this.$route.query.page;
      } else {
        this.page_numb = 1;
      }
      let self = this;
      let placement = this.placement ? this.placement : "bottom";
      let host = this.$store.state.site_data.host;
      axios
        .get(`/get_bestsellers/?${host}&page_number=${this.page_numb}`, {
          params: {
            page: this.$route.path,
            sort: sort_code,
            placement: placement,
          },
        })
        .then(function (data) {
          var Host = "uk";
          if (data.status == 200) {
            // if  ((document.location.hostname).search(Host) != -1 ){
            //   self.books = JSON.parse(data.data.books).sort((a, b) => new Date(b.uk_pub_date));

            // }
            // else{
            //    self.books = JSON.parse(data.data.books).sort((a, b) => new Date(b.pubdate) - new Date(a.pubdate));
            // }
            self.books = data.data.books;
            self.limit = data.data.book_limit;
            self.format = data.data.style;
            self.show_price = data.data.show_price;
            self.title = data.data.title;
            self.next_page = data.data.next_page;
            self.next_pages = data.data.next_pages;
            self.prev_pages = data.data.prev_pages;
            self.num_pages = data.data.num_pages;
            self.prev_page = data.data.prev_page;
            self.page_number = data.data.page_number;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },

  mounted() {
    if (this.$route.query.page) {
      this.page_numb = this.$route.query.page;
    } else {
      this.page_numb = 1;
    }
    localStorage["fiction_sort_code"] = "date_asc";
    this.fication_pagination();

    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query.page"() {
      if (this.$route.query.page) {
        this.page_numb = this.$route.query.page;
      }
      this.fication_pagination();
      window.scrollTo(0, 0);
    },
    $route() {
      this.fication_pagination();
    },
  },
};
</script>
<style>
.pagination-mg ul {
  margin: 2.143em 0 2.143rem 0;
}
a.pagenav.page-disable {
  opacity: 0.5;
}

a.pagenav.page-disable:hover {
  background: #72838d;
}
</style>
