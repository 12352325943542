<template>
  <div  class="l-main-block mobileFullWidth clearfix">
    <form id="la-form" class="" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">

      
      <div class="form-group">
        <label for="prefix">Prefix</label>
        <select v-model="user.prefix"  class="search-input prefix-input resources-input-profile">
          <option value="attorney">Atty</option>
          <option value="doctor">Dr</option>
          <option value="mister">Mr</option>
          <option value="married_woman">Mrs</option>
          <option value="single_or_married_woman">Ms</option>
          <option value="professor">Prof</option>
        </select>
        <span class="error-span"> {{ errors.prefix }} </span>
      </div>

      <div class="form-group">
        <label for="first_name">First Name</label>
        <input
          class="form-control"
          type="text"
          v-model="user.first_name"
          placeholder="First Name"
          id="first_name"
        />
        <span class="error-span"> {{ errors.first_name }} </span>
      </div>

      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input
          class="form-control"
          type="text"
          v-model="user.last_name"
          placeholder="Last Name"
          id="last_name"
        />
        <span class="error-span"> {{ errors.last_name }} </span>
      </div>

      <div class="form-group">
        <label for="company">Organization</label>
        <input
          class="form-control"
          type="text"
          v-model="user.company"
          placeholder="Enter an Organization"
          id="company"
        />
        <span class="error-span"> {{ errors.company }} </span>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <div class="input-outer">
        <input
          class="form-control"
          type="text"
          v-model="user.email"
          placeholder="Email"
          id="email"
        />
        <span id="email_errors" class="error-span"> {{ errors.email }} </span>
        </div>
      </div>

      <div class="form-group">
        <label for="phone">Phone</label>
        <input
          class="form-control"
          type="text"
          v-model="user.phone"
          placeholder="Phone"
          id="phone"
        />
        <span class="error-span"> {{ errors.phone }} </span>
      </div>    

      <div class="form-group">
        <label for="address">Address</label>
        <input
          class="form-control"
          type="text"
          v-model="user.address"
          placeholder="Address"
          id="address"
        />
        <span class="error-span"> {{ errors.address }} </span>
      </div>

      <div class="form-group">
        <label for="address">Address2</label>
        <input
          class="form-control"
          type="text"
          v-model="user.address2"
          placeholder="Address2"
          id="address2"
        />
        <span class="error-span"> {{ errors.address2 }} </span>
      </div>

      <div class="form-group">
        <label for="city">City</label>
        <input
          class="form-control"
          type="text"
          v-model="user.city"
          placeholder="City"
          id="city"
        />
        <span class="error-span"> {{ errors.city }} </span>
      </div>

      <div class="form-group">
        <label for="state">State</label>
        <select v-model="user.state"  class="search-input state-input resources-input-profile">
          <option value="none"></option>
          <option value="australian_capital_territory">Australian Capital Territory</option>
          <option value="new_south_wales">New South Wales</option>
          <option value="northern_territory">Northern Territory</option>
          <option value="queensland">Queensland</option>
          <option value="south_australia">South Australia</option>
          <option value="tasmania">Tasmania</option>
          <option value="victoria">Victoria</option>
          <option value="western_australia">Western Australia</option>
        </select>
        <span class="error-span"> {{ errors.state }} </span>
      </div>

      <div class="form-group">
        <label for="Postal Code">Postal Code</label>
        <input
          class="form-control post-code"
          type="text"
          v-model="user.postal_code"
          id="postal_code"
        />
        <span class="error-span"> {{ errors.postal_code }} </span>
      </div>

       <div class="form-group">
        <label for="country">Country</label>
        <select v-model="user.country"  class="search-input country-input resources-input-profile"><option value="Australia">Australia</option></select>
        <span class="error-span"> {{ errors.country }} </span>
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="password"
            v-model="user.password1"
            placeholder="Password"
            id="password"
          />
          <span id="pass_errors" class="error-span"> {{ errors.password1}} </span>
        </div>
      </div>

      <div class="form-group">
        <label for="Confirm Password">Confirm Password</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="password"
            v-model="user.password2"
            placeholder="Confirm Password"
            id="confirm_password"
          />
          <span class="error-span"> {{ errors.password2}} </span>
        </div>
      </div>

      <div class="form-group">
        <div class="signup-btn">
          <button class="button bt-wte-c bold-f-w">Submit</button>
        </div>
      </div>
      <br>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"

export default {
  components: { Multiselect },
  data() {
    return {
      user: {
        prefix:'',
        first_name: '',
        last_name:'',
        company: '',
        email:'',
        phone:'',
        country:'',
        address:'',
        city:'',
        state:'',
        postal_code:'',
        username:'',
        password1:'',
        password2:'',
      },
      errors: {
        prefix:'', 
        email:'',        
        username:'',
        password1:'',
        password2:'',
      }
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    SubmitForm() {
      let self = this
      let form_signup = new FormData()
      const email = self.user.email
      const username = email.split('@')[0]

      form_signup.append('username', username)
      form_signup.append('password1', self.user.password1)
      form_signup.append('password2', self.user.password2)
      form_signup.append('email', self.user.email)

      let profile_data = new FormData()
      profile_data.append('username', username)
      profile_data.append('first_name', self.user.first_name)
      profile_data.append('last_name', self.user.last_name)
      profile_data.append('company', self.user.company)
      profile_data.append('phone', self.user.phone)
      profile_data.append('address', self.user.address)
      profile_data.append('city', self.user.city)
      profile_data.append('postal_code', self.user.postal_code)

      axios
        .post(`/dj-rest-auth/registration/`,
          form_signup,
        )
        .then(function (response) {
          axios
            .post(`/accounts/profile-sign-up/`,
              profile_data,
            )
            .then(function (response) {
              self.$store.commit('confirm_email')
              self.$router.push({ name: 'home'})
            })
            .catch(function (err) {
              console.log(err)
            })
          })
        .catch(function (err) {
          const errors = err.response.data

          for(var key in errors){
            self.errors[`${key}`] = errors[`${key}`][0]
          }
          console.log(self.errors)
        })
    },
    createUser() {
      console.log("users", this.user);
    },
  },
};
</script>

<style>

.bt-wte-c {
    background-color: #72838d;
    color: white;
    border-radius: 2px;
}
  .hr_line {
    display: none;
  }
  .post-code{
    max-width: 23%;
  }
  .signup-btn{
    width:100%;
  }
  .btn-success{
    float: right;
  }
  .submit-button {
    width: 422px;
  }
  .form-group {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    position: relative;
    width:425px;
  }
  .form-group label {
    min-width: 175px;
    margin: 0;
  }
  .form-group select {
      width: 100%;
      border-radius: 0 ;
  }
  footer.l-footer #email {
    width: 100%;
    margin-right: 43%;
    float: left;
  }
  .resources-input-profile {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    border: 3px solid #e1e1e1;
    background: #fff;
    padding: 0.750em 1.250em;
    font-family: 'Arial';
    font-size: 1.143em;
    line-height: 1.438em;
    color: #8c9295;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    height: 46px;
    border-color: #e1e1e1;
    border-width:3px; 
  }


  form#la-form {
      padding-left: 160px;
  }

  .form-group {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    position: relative;
    width:425px;
  }

  .form-group label {
    min-width: 175px;
    margin: 0;
  }

  footer.l-footer #email {
    width: 100%;
    margin-right: 43%;
    float: left;
  }

  .acc-home-head-line{
    margin-bottom: 5px;
    width:98%;
  }
  .success-span-register {
    color: green;
    text-align: center;
    width: 100%;
    margin-bottom: 14px;
    float: left;
  }

  #email_errors{
    bottom: -35px;
}
  #pass_errors{
    bottom: -35px;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">