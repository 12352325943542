<template>
  <div class="container" id="for-affirm">
    <div v-if="!page_type">
      <div class="">
        <!-- <div id="static" v-html="content" v-if="content"></div> -->

        <div
          v-if="host == 'allenandunwin'"
          class="l-main-wrapper mobileFullWidth custom-box clearfix"
          style="width: 100%"
        >
          <div id="static-content"></div>
        </div>

        <div v-else class="l-main-wrapper mobileFullWidth custom-box clearfix">
          <div id="static-content"></div>
        </div>

        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="!content"
          class="loader-spinner"
        />
        <div id="newsletter">
          <Newsletter></Newsletter>
        </div>
        <div id="resources-search-inputs">
          <div class="search-category">
            <div class="inner-search-category-first">
              <h1 style="color: #41525c; margin-bottom: 0px">
                Search by category
              </h1>
              <br />
              <div class="searchinner">
                <select
                  v-model="category_select"
                  class="search-input resources-input"
                >
                  <option value="LZO">Art</option>
                  <option value="LZA">Architecture</option>
                  <option value="LZB">Business</option>
                  <option value="LZC">Design</option>
                  <option value="LZD">Education</option>
                  <option value="LZE">Film</option>
                  <option value="LZF">Galleries & Museums</option>
                  <option value="LZG">Government</option>
                  <option value="LZH">Computer Games</option>
                  <option value="LZI">Libraries</option>
                  <option value="LZJ">Music</option>
                  <option value="LZK">Photography</option>
                  <option value="LZL">Publishing</option>
                  <option value="LZM">Websites</option>
                  <option value="LZN">Writing</option>
                </select>
                <!-- <select v-model="category_select"  class="search-input resources-input">
                <option v-for="data in bic1_data" :value="data.value">{{data.discription}}</option>
              </select> -->
                <button
                  class="resource-search-button"
                  v-on:click="categoryData"
                >
                  Go
                </button>
              </div>
            </div>

            <img
              style="width: 100%; margin-bottom: 5px"
              src="/static/css/images/acc/heading_underline.png"
              class="acc-home-head-line resources-mobile-head-line"
            />
            <div class="inner-search-keyword">
              <h1 style="color: #41525c; margin-bottom: 0px">
                Search by keyword
              </h1>
              <br />
              <router-link to="/search?page=1&imprint=info"
                >fact sheets</router-link
              >
              | <router-link to="/search?page=1&imprint=acc">books</router-link>
              <!-- |  <router-link to="/search#Guides">creators</router-link> -->
              <!-- <div class="searchinner">
              <input v-model="resourceSearchForm.resourcesearch" name="resourcesearch" type="text"  v-on:keyup="enterKeyAsClick" placeholder="Where do I want to go?" class="search-input resources-input">
              <button v-on:click="resourcehrefData" class="resource-search-button">SEARCH</button>
            </div> -->
            </div>
            <br /><br />
          </div>
          <br /><br /><br /><br /><br />
          <h1 style="color: #41525c; margin-bottom: 0px">Popular Resources</h1>
          <div class="popular-resources">
            <acc-bestsellers></acc-bestsellers>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <acc-blog></acc-blog>
    </div>
    <div class="l-main-block mobileFullWidthWithMargin" v-if="!logged_in" id="item_page">
      <div class="item-page">
        <h1 style="color: #41525c">Booksellers</h1>
        <p></p>
        <p>
          To access restricted Bookseller content please enter your UBD account
          number below.
        </p>
        <p></p>
        <p v-if="error" class="error">{{ error }}</p>
        <div class="anv-submit">
          <input type="hidden" name="anv-submit" value="1" />

          <label for="accountnumberInput">Account number</label>
          <input
            type="text"
            id="accountnumberInput"
            name="accountnumber"
            v-model="account_num"
          />

          <input
            class="button button--color1"
            type="submit"
            @click="logIn()"
            value="Submit"
          />
        </div>
        <p></p>
        <p></p>
        <p></p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Vue from "vue";
import BestSellerList from "../components/BestSellerList";
import TopBookShelf from "../components/TopBookShelf";
import AuthorList from "../components/AuthorList";
import BookList from "../components/BookList";
import AccBlog from "../components/AccBlog";
import Newsletter from "../views/au/AUNewsletterComp";

export default {
  name: "static",
  components: {
    LoopingRhombusesSpinner,
    "acc-bestsellers": BestSellerList,
    TopBookShelf,
    AuthorList,
    BookList,
    "acc-blog": AccBlog,
    Newsletter,
  },
  data() {
    return {
      logged_in: false,
      show_spinner: false,
      account_num: null,
      error: null,
      urls: "",
      content: "",
      title: null,
      host: "",
      page_type: null,
      category_select: "",
      bic1_data: "",
      resourceSearchForm: {
        resourcesearch: null,
      },
    };
  },
  methods: {
    logIn() {
      let self = this;
      let item_page = document.getElementById("item_page");
      let div_au = document.getElementById("allenandunwin");
      let div_nz = document.getElementById("allenandunwinnz");
      let div_mu = document.getElementById("murdoch");
      let div_uk = document.getElementById("murdochuk");

      this.$store
      .dispatch("addBooksellersNum", this.account_num)
      .then((res) => {
        if(res.status == '200'){
          self.logged_in = true;
          item_page.style.display = "block"
          if (this.host == 'allenandunwin') {
              div_au.style.display = "block";
          } else if (this.host == 'allenandunwinnz') {
              div_nz.style.display = "block";
          }else if (this.host == 'murdoch') {
            div_mu.style.display = "block";
          }
          else if (this.host == 'murdochuk') {
            div_uk.style.display = "block";
          }
        }
      })
      .catch((err) => {
        item_page.style.display = "none";
        self.error = "Please enter a correct Account Number";
      });
    },
    resourcehrefData() {
      let url = "/search#" + this.resourceSearchForm.resourcesearch;
      window.location.replace(url);
    },
    enterKeyAsClick: function (e) {
      if (e.keyCode === 13) {
        let url = "/search#" + this.resourceSearchForm.resourcesearch;
        window.location.replace(url);
      }
    },
    categoryData() {
      let url = "/search?page=1&category=" + this.category_select;
      window.location.replace(url);
    },
    fetch_static_content(path) {
      let self = this;
      axios
        .get("/get_static_page" + path)
        .then(function (response) {
          // adding a on-the-fly vue component with the static content of the page.
          // Vue.extend creates a new instance of vue and mounts the component on that new instance.
          // That is why the vue router and store must be passed else it will be undifined.
          let static_content = Vue.extend({
            router: self.$router,
            store: self.$store,
            template: response.data.content,
            components: { BestSellerList, TopBookShelf, AuthorList, BookList },
          });
          new static_content().$mount("#static-content");
          self.content = response.data.content;
          self.title = response.data.title;
          self.page_type = response.data.page_type;
          // if(self.page_type){
          //   let page_type = self.page_type;
          //   if(!page_type.endsWith('s')){
          //     page_type = page_type + 's';
          //   }
          //   self.$route.meta.breadcrub.splice(self.$route.meta.breadcrub.length-1, 0, {
          //     name: page_type,
          //     link: page_type
          //   });
          // }
          //change the breadcrub title of the current page to the title of the static page
          let last_bread =
            self.$route.meta.breadcrub[self.$route.meta.breadcrub.length - 1];
          if (!last_bread.link) {
            last_bread.name = self.title;
          } else {
            self.$route.meta.breadcrub.push({
              name: self.title,
            });
          }
          document.title = response.data.title;
        })
        .catch(function (err) {
          if (!self.$route.params.path.includes("404")) {
            window.location.replace("/404/");
          }
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    let url = window.location.href;
    if (url.includes("about_us#") && this.host == "acc") {
      this.urls = "true";
    }
    this.fetch_static_content(to.path);
    next();
  },
  mounted() {
    let self = this;
    axios.get(`/get_bic1_category_data/`).then(function (data) {
      self.bic1_data = data.data.bic1_data;
    });
    this.host = this.$store.state.site_data.host;
    let path = this.$route.path;
    this.fetch_static_content(path);
    let resource_search = document.getElementById("resources-search-inputs");
    let news_letter = document.getElementById("newsletter");
    let url = window.location.href;
    if (url.includes("resources") && this.host == "acc") {
      resource_search.style.display = "block";
    } else {
      resource_search.style.display = "none";
    }
    if (path == "/resources/for-teachers" && this.host == "allenandunwin") {
      news_letter.style.display = "block";
    } else {
      news_letter.style.display = "none";
    }
    let add_new_class = document.getElementById("affirm-header");
    let remode_class = document.getElementById("for-affirm");
    let find_app_id = document.getElementById("st-pusher");
    if (this.host == "affirm") {
      remode_class.classList.add("st-container");
      remode_class.classList.remove("container");
      if (path == "/about/") {
        add_new_class.classList.add("-bg-green");
        remode_class.classList.remove("container");
        remode_class.classList.remove("st-container");
      } else if (path == "/privacy-policy/") {
        add_new_class.classList.add("-bg-pink");
        remode_class.classList.remove("container");
        remode_class.classList.remove("st-container");
      } else if (path == "/submissions") {
        find_app_id.classList.add("-bg-dark-pink");
        remode_class.classList.remove("container");
        remode_class.classList.remove("st-container");
      } else if (path == "/catalogues") {
        find_app_id.classList.add("-bg-green");
        remode_class.classList.remove("container");
        remode_class.classList.remove("st-container");
      }
    }
    let item_page = document.getElementById("item_page");
    if(path == "/booksellers" && this.host == 'allenandunwin'){
        item_page.style.display = "block";
    } else if(path == "/booksellers" && this.host == 'allenandunwinnz'){
        item_page.style.display = "block";
    }
    else if(path == "/booksellers" && this.host == 'murdoch'){
        item_page.style.display = "block";
    }
    else if(path == "/booksellers" && this.host == 'murdochuk'){
        item_page.style.display = "block";
    }

    let account_num = this.$store.state.booksellers_num;
  },
  // created() {
  // }
};
</script>
<style>
.mb-5{
  margin-bottom: 40px;
}
#allenandunwin{
  display: none;
}
#allenandunwinnz{
  display: none;
}
#murdoch{
  display: none;
}
#murdochuk{
  display: none;
}
#item_page{
  display: none;
}
#newsletter .iIDDUy {
  margin-left: 0;
}
#newsletter .news-page {
  background: none !important;
}
#newsletter .dLkilY {
  margin-left: 0;
}
.bestseller-books {
  display: none;
}
.vmss_mixed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.vmss_mixed__item {
  width: calc(90% / 5);
}
.vmss_mixed__item a {
  display: inline-block;
  width: 100%;
}
p.image-wrap img {
  width: 100%;
  min-width: 120px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}
#resources-search-inputs {
  /* margin-left: 175px; */
  margin-bottom: 50px;
  width: 90%;
}
.inner-search-keyword {
  width: 60%;
  float: left;
}
.resources-input {
  width: 217px;
  height: 38px;
}
.inner-search-category-first {
  width: 39%;
  float: left;
}
.w-auto.text-info-center {
  width: auto;
}
.marker-color ::marker {
  color: #e84643;
  font-weight: 600;
  font-size: 17px;
}

@media screen and (min-width: 3000px) and (max-width: 3840px) {
  .inner-search-keyword {
    width: 15%;
    float: left;
  }
}
</style>
