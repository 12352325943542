<template>
  <div class="st-container">
    <div class="l-main-block mobileFullWidth clearfix">
      <div class="l-main mobileFullWidthWithMargin">
        <div class="blog">
          <div class="pt-5">
            <div class="row --text-dark">
              <div class="col-12">
                <h1 class="s-h1 mb-3 mb-xl-4 --text-dark">News & features</h1>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-between">
                <div>
                  <vue-tabs>
                    <v-tab class=" me-3 me-lg-4 mb-3 mb-lg-0" title="All">
                      <all-news></all-news>
                    </v-tab>
                    <v-tab title="News">
                      <news></news>
                    </v-tab>
                    <v-tab title="Features">
                      <features></features>
                    </v-tab>
                  </vue-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---->
</template>
<script>
import all_news from "../components/news/all_news";
import features from "../components/news/features";
import news from "../components/news/news";
import {VueTabs, VTab} from 'vue-nav-tabs'
//you can also import this in your style tag
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: "affirm-news",
  components: {'all-news': all_news,'features': features,'news':news,'vue-tabs': VueTabs, 'v-tab':VTab },
  mixins: [],
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style>
a.pagenav.page-disable {
  opacity: 0.5;
}

a.pagenav.page-disable:hover {
  background: #72838d;
}
</style>
