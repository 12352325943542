<template>
  <div class="l-main-block mobileFullWidth clearfix">
  <form id="la-form" class="" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">
    <div class="l-main mobileFullWidthWithMargin">
    <img v-if="host=='acc'" src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line">
    </div>
    <div class="forget-main">
      <span class="success-span-register" v-html="success_data"></span>
      <h1>Enter your email</h1>
      <span style="color:red;float:right;"  v-html="errors.non_field_errors"></span>
      <div class="form-group">
        <label style="min-width:100px;" for="email">Email</label>
        <div class="input-outer">
          <input class="form-control" type="text" v-model="email" placeholder="Email" id="login_username"/>
          <span class="error-span">{{ errors.email }}</span>
        </div>
      </div>
      <button id="reset-pasw" class="btn btn-success button bold-f-w reset-btn" v-on:click="send_email">Send</button>
    </div>
  </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = "csrftoken"

export default {
  components: { Multiselect },
  data() {
    return {
      errors: {
        non_field_errors: '',
      },
      email:'',
      success_data: '',
      host : '',
    };
  },
  computed: {},
  watch: {},
  mounted() {
  this.host = this.$store.state.site_data.host

  let resetpassword = document.getElementById('reset-pasw');
    if (this.host=="acc"){
      resetpassword.style.backgroundColor = '#f6793c';

    }else{
      resetpassword.style.backgroundColor='#72838d';
    }
  },
  methods: {
    SubmitForm() {
      let self = this
      let formData = new FormData()

      formData.append('email', self.email)
    },
    send_email() {
      console.log('111-111-111')
      let self = this
      let formData = new FormData()
      formData.append('email', self.email)

      axios
        .post(`/check_user_email/`,
          {"email":self.email}
        )
        .then(function (response) {
          if(response.data['is_email']){
            axios
              .post(`/dj-rest-auth/password/reset/`,
                formData,
              )
              .then(function (response) {
                console.log(response)
                self.success_data = response.data.detail
              })
              .catch(function (err) {
                const errors = err.response.data

                for(var key in errors){
                  self.errors[`${key}`] = errors[`${key}`][0]
                }
              })
          }
          else{
              self.errors.non_field_errors = '<p>Your email does not exist in the database. <a href="/sign-up">Please click here to re-register for this updated version of our website.</a></p>'
          }
        })
        .catch(function (err) {
          const errors = err.response.data

          for(var key in errors){
            self.errors[`${key}`] = errors[`${key}`][0]
          }
        })

      
    }
  },
};
</script>

<style>
.hr_line {
  display: none;
}

.form-group label {
  margin-top: 16px;
  display: inline-block;
}

.login-main{
  width:425px;
}
.error-span {
  color: red;
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0px;
  width: 100%;
}

.success-span {
  color: green;
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0px;
  width: 100%;
}
.forget-main{
  width: 504px;
}
.reset-btn{
  float:left;
  margin-left:260px;
}

@media screen and (min-width: 3000px)  and (max-width: 3840px) {
  #la-form {
    width: 100%;
    max-width: 100%;
    padding-right: 1px;
    padding-left: 258px;
    padding-top: 104px;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">