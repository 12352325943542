<template>
      <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div class="bfClearfix">
        <div id="ff_formdiv3" class="bfFormDiv">
          <div class="bfPage-l">
            <div class="bfPage-r">
              <div class="bfPage-m bfClearfix">
                <form
                    name="ff_form1"
                    id="ff_form1"
                    enctype="multipart/form-data"
                    accept-charset="utf-8"
                    method="POST"
                    class="bfQuickMode"
                    v-on:submit.prevent="SubmitForm"
                    >
                  <div id="bfPage1" class="bfPage">
                    <section class="bfPageIntro">
                      <img alt="top" src="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/hero_images/allenandunwin/top_banner.png" />
                      <h1>The Australion/Vogel's Literary Award is one of the richest and the most prestigious award in Australia for an unpublished manuscript by a young writer.</h1>
                    </section>
                    <div class="bfNoSection" id="bfQuickModeSection50928">
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap65">
                        <label id="bfLabel65">
                          Full name
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="full_name"
                          id="ff_elem65"
                          v-model="vogels.full_name"
                        />
                        <span class="error-span1" >{{ errors.full_name }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap66">
                        <label id="bfLabel66">
                          Date of birth
                        </label>
                        <input
                          class="ff_elem date-picker"
                          type="date"
                          name="date_of_birth"
                          value
                          id="ff_elem66"
                          v-model="vogels.date_of_birth"
                        />
                        <span class="error-span1" >{{ errors.date_of_birth }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap67" style="margin-top: 25px;">
                        <label id="bfLabel67">
                          Manuscript title
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="manuscript_title"
                          id="ff_elem67"
                          v-model="vogels.manuscript_title"
                        />
                        <span class="error-span1" >{{ errors.manuscript_title }}</span>
                      </section>
                      <div class="bfNoSection" id="section-label-description">
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap67">
                        <label id="bfLabel67">
                          Address
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="address_1"
                          id="ff_elem67"
                          v-model="vogels.address_1"
                        />
                        <span class="error-span1" >{{ errors.address_1 }}</span>
                      </section>
                      </div>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap69">
                        <label id="bfLabel69">State</label>
                        <input class="ff_elem" type="text" name="state" id="ff_elem69" v-model="vogels.state" />
                        <span class="error-span1" >{{ errors.state }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap592">
                        <label id="bfLabel592">Postcode</label>
                        <input class="ff_elem" type="text" name="postcode" id="ff_elem592" v-model="vogels.postcode" />
                        <span class="error-span1" >{{ errors.postcode }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap70">
                        <label id="bfLabel70">
                          Phone primary
                        </label>
                        <input class="ff_elem" type="text" name="phone_primary" id="ff_elem70" v-model="vogels.phone_primary" />
                        <span class="error-span1" >{{ errors.phone_primary }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap70">
                        <label id="bfLabel70">
                          Phone secondary
                        </label>
                        <input class="ff_elem" type="text" name="phone_secondary" id="ff_elem70" v-model="vogels.phone_secondary"  />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap72">
                        <label id="bfLabel72">
                          Email address
                        </label>
                        <input class="ff_elem" type="email" name='email_address' id="ff_elem72" v-model="vogels.email_address" />
                        <span class="error-span1" >{{ errors.email_address }}</span>
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap72">
                        <label id="bfLabel72">
                          Word count
                        </label>
                        <input class="ff_elem" type="text" name='word_count' id="ff_elem72" v-model="vogels.word_count" />
                        <span class="error-span1" >{{ errors.word_count }}</span>
                      </section>
                      <div class="bfNoSection" id="section-label-description">
                        <section class="bfSectionDescription">
                          <p>
                            <label
                              class="bf-label"
                            >How did you find out about the award?</label>
                          </p>
                        </section>
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap68">
                          <textarea
                            cols="20"
                            rows="5"
                            class="ff_elem"
                            name="question_1"
                            id="ff_elem68"
                            v-model="vogels.question_1"
                          ></textarea>
                          <span class="error-span1" >{{ errors.question_1 }}</span>
                        </section>
                      </div>
                      <div class="mb-25">
                          <h4 class="mooblock-title mb165_1t">Condition of Entry<i class="arrow"></i></h4>
                          <div class="mb165_1e expanded">
                            <div class="mooblock-el__inner">
                              <span>• Entrants must be aged under 35 years of age on 31 May 2019 (that is, born after May 1984).</span><br>
                              <span>• Entries must be lodged by 31 May 2019.</span><br>
                              <span>• Entrants must normally be residents of Australia.</span><br>
                              <span>• The manuscript should be a work of fiction, Australian history or biography.</span><br>
                              <span>• It must be a minimum length of 30,000 words and a maximum of 100,000 words.</span><br>
                              <span>• The manuscript must be an original work, written entirely by the entrant and it must be written in English.</span><br>
                              <span>• It cannot be under the consideration of any other publisher or entered into any other award.</span><br>
                              <span>• No more than 10% of the manuscript can have been previously published in print form, or in electronic form, on a commercial basis.</span><br>
                              <span>• Allen & Unwin will publish the winning entry, and will have exclusive worldwide publishing rights
                              to it, and to any other entry they feel is of sufficient merit.</span><br>
                              <span>• Manuscripts are to be uploaded below with this form, accompanied by an entry form and a one-page synopsis.</span><br>
                              <span>• An entry fee of $25 is applicable to each manuscript entered and should be submitted to Allen & Unwin Pty Ltd, BSB 082-212, account no. 507671436. Please ensure that the entrant’s name is clearly identified in the relevant field when paying the entry fee.</span><br>
                              <span>• An electronic acknowledgement will be sent on receipt of entry.</span><br>
                              <span>• The judges’ decision is final and no correspondence will be entered into.</span><br>
                              <span>• The judges shall have the discretion to divide the prize equally between authors of entries they consider of equal merit. If, in their opinion, no entry is worthy of the prize, no winner shall be chosen. No entrant may win the prize in successive years.</span><br>
                              <span>• The winner will be told in strict confidence during September 2019, at which time the winner must agree to keep this news absolutely confidential until the simultaneous announcement and publication of the winning entry in 2020.</span><br>
                              <span>• In submitting this entry form, each entrant gives their agreement to the above conditions of entry</span>
                            </div>
                          </div>  
                        </div>
                        <section class="bfElemWrap bfLabelRight" id="bfElemWrap59">
                          <label class="bf-label" style="font-size: 16px; font-weight: 600;">I have read and accept the above conditions of entry</label>
                          <input class="ff_elem custom-check" type="checkbox" id="ff_elem73" name="accepted_conditions" v-model="vogels.accepted_conditions"/>
                          <span class="error-span1" >{{ errors.accepted_conditions }}</span>
                        </section>
                        <div class="mb-25">
                          <h4 class="mooblock-title">Upload PDF Files<i class="arrow"></i></h4>
                          <div class="mb165_1e">
                            <div class="mooblock-el__inner">
                            <label id="bfLabel70">
                              Manuscript
                            </label>
                              <input class="custom-file-input" ref="files" multiple="multiple" type="file" name="manuscript" accept="application/pdf" title="Manuscript" id="ff_elem70" required />
                              <span class="error-mgs" >{{ errors.manuscript }}</span>
                              <br>
                        <label id="bfLabel70">
                          One Page Synopsis
                        </label>
                        <input class="custom-file-input" ref="files" multiple="multiple" title="One Page Synopsis" type="file" id="fileInput" accept="application/pdf" name="synopsis" required />
                        <span class="error-mgs" >{{ errors.synopsis }}</span>
                              </div>
                             </div>
                        </div>
                        <div class="mb-25">
                          <span class="bg-red">Reminder instructions on paying the $25 entry fee are included above in the Conditions on Entry.</span>
                        </div>
                    </div>
                    <div v-if="status != 'submitted'">
                        <vue-recaptcha
                          ref="recaptcha"
                          sitekey="6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"
                          @verify="onCaptchaVerified"
                        ></vue-recaptcha>
                        <button v-if="submit_btn=='Submit'" 
                          type="submit"
                          id="bfSubmitButton"
                          class="bfSubmitButton button"
                          :disabled="status==='submitting' || !captchaComplete"
                        >
                          <span>Submit</span>
                        </button>
                        <span class="btn button orng-wte-c bold-f-w disabled-btn" v-else >Submitting</span>
                      </div>
                    <h3
                      v-if="status === 'submitted'"
                      class="success-message"
                    >Thank you for your enquiry</h3>
                  </div>
                </form>
                <div class="mt-115">
                  <img alt="bottom" src="https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/hero_images/allenandunwin/bottom_banner.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import axios from 'axios'
import Form from '../mixins/Form.js';
export default {
  name: "vogels-entry",
  mixins: [Form],
  data() {
    return {
      submit_btn:'Submit',
      vogels: {
        full_name: '',
        date_of_birth: '',
        manuscript_title: '',
        address_1: '',
        state: '',
        postcode:'',
        phone_primary: '',
        phone_secondary: '',
        email_address:'',
        word_count: '',
        question_1: '',
        accepted_conditions:'',
        manuscript: '',
        synopsis: ''

      },
      errors: {
        full_name: '',
        date_of_birth: '',
        manuscript_title: '',
        address_1: '',
        state: '',
        postcode:'',
        phone_primary: '',
        phone_secondary: '',
        email_address:'',
        word_count: '',
        question_1: '',
        accepted_conditions:'',
        manuscript: '',
        synopsis: ''
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    SubmitForm() {
      if (this.vogels.full_name == '' ){
        this.errors.full_name = 'Full Name Field is required'
      }
      if (this.vogels.date_of_birth==''){
        this.errors.date_of_birth = 'Date of birth Field is required'
      }
      if (this.vogels.manuscript_title==''){
        this.errors.manuscript_title = 'Manuscript title Field is required'
      }
      if (this.vogels.address_1==''){
        this.errors.address_1 = 'Address Field is required'
      }
      if (this.vogels.state==''){
        this.errors.state='State Field is required' 
      }
      if (this.vogels.postcode==''){
        this.errors.postcode='Postcode Field is required' 
      }
      if (this.vogels.word_count==''){
        this.errors.word_count='Word count Field is required' 
      }
      if (this.vogels.phone_primary==''){
        this.errors.phone_primary='Phone primary Field is required' 
      }
      if (this.vogels.phone_secondary==''){
        this.errors.phone_secondary = 'Phone secondary Field is required'
      }
      if (this.vogels.email_address==''){
        this.errors.email_address='Email Address Field is required' 
      }
      if (this.vogels.accepted_conditions==''){
        this.errors.accepted_conditions='Accepted conditions Field is required' 
      }
      if (this.vogels.full_name != '' && this.vogels.date_of_birth!='' && this.vogels.manuscript_title!='' && this.vogels.address_1!='' && this.vogels.state!='' && this.vogels.postcode!='' && this.vogels.phone_primary!='' && this.vogels.phone_secondary!='' && this.vogels.email_address!='' && this.vogels.accepted_conditions!='' && this.vogels.word_count!=''){
        this.errors.full_name = ''
        this.errors.date_of_birth = ''
        this.errors.manuscript_title = ''
        this.errors.address_1 = ''
        this.errors.state = ''
        this.errors.postcode = ''
        this.errors.phone_primary = ''
        this.errors.phone_secondary = ''
        this.errors.email_address = ''
        this.errors.accepted_conditions = ''
        this.errors.word_count= ''
      }
      else
      {
        return
      }
      let self = this
      let formData = new FormData()

      // for synopsis file
      const fileInput = document.querySelector('input[name="synopsis"]');
      const file = fileInput.files[0];

      // for manuscript file
      const filemanuscript = document.querySelector('input[name="manuscript"]');
      const filefilemanuscript = filemanuscript.files[0];

      // for recaptcha response
      const recaptcha = document.getElementsByClassName('g-recaptcha-response')[0].value

      formData.append('full_name', self.vogels.full_name)
      formData.append('date_of_birth', self.vogels.date_of_birth)
      formData.append('manuscript_title', self.vogels.manuscript_title)
      formData.append('address_1', self.vogels.address_1)
      formData.append('state', self.vogels.state)
      formData.append('postcode', self.vogels.postcode)
      formData.append('phone_primary', self.vogels.phone_primary)
      formData.append('phone_secondary', self.vogels.phone_secondary)
      formData.append('word_count',self.vogels.word_count)
      formData.append('question_1',self.vogels.question_1)
      formData.append('email_address', self.vogels.email_address)
      formData.append('accepted_conditions', self.vogels.accepted_conditions)
      formData.append('synopsis', file)
      formData.append('manuscript',filefilemanuscript)
      formData.append('recaptchaToken',recaptcha)
      self.submit_btn='submitting'
      axios.post(`/submit_vogels_entry/`,
          formData,
        )
        .then(function (data) {
          alert('Your request was submitted sucessfully. Someone will be in contact with you shortly.');
          setTimeout( () => self.$router.push({ path: '/'}), 6000);
        })
        .catch(function (err) {
        })
        
    },
  },
};
</script>
<style>
.error-mgs{
  color: red;
  position: absolute;
  width: 100%;
}
.error-span1 {
    color: red;
    position: absolute;
    right: 0;
    bottom: 0px;
    margin: 0px;
    width: 100%;
}
 .mt-115{
    margin: 137px 0px 39px 114px;
}
.bg-red{
  color: red;
}
.mb-25{
  margin-bottom: 25px;
}
input[type="file"] {
  position: relative;
  margin: 0px 0px 25px 58px;
}

input[type="file"]::file-selector-button {
  width: 136px;
  color: transparent;
}

/* Faked label styles and icon */
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 40px;
  color: #0964b0;
  content: "Upload File";
}
/* date-field */
input[type="date"] {
  width: 50%;
  outline: none;
  border-radius: 3px;
  color: #8c9295;
  box-sizing: border-box;
  max-width: 100%;
  border: 3px solid #e1e1e1;
  background: #fff;
  padding: 0.75em 1.25em;
  font-size: 1.143em;
  line-height: 1.438em;
  color: #8c9295;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
}
/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}
</style>