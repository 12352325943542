<template>
  <!-- start Bookseller section -->
  <section id="reseller" class="py-3 -bg-brown">
    <div class="st-container">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-4 text-center">
            <b>Son of Sin</b> is available via the following retailers:
          </h5>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="reseller-grid_card my-3">
            <div
              class="reseller-inner_card text-center d-flex justify-content-center align-items-center px-3 py-5 bg-white shadow"
            >
              <div
                class="-w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-2"
              >
                <img
                  src="/static/css/images/affirm/file.png"
                  alt="Group55.svg"
                  width="100%"
                  class=""
                />
              </div>
              <div>
                <h6 class="s-h6 mb-0 fw-semibold text-start">
                  Book<br />Depository
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <a
            href="#"
            class="link d-flex align-items-center justify-content-center mt-4"
          >
            <div
              class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center me-3"
            >
              <img
                src="/static/css/images/affirm/Group55.svg"
                alt="Group55.svg"
                width="17px"
                class=""
              />
            </div>
            <div class="">
              <p class="mb-0 --text-dark fw-semibold text-decoration-underline">
                Find my local bookseller
              </p>
            </div>
          </a>
        </div>
        <div class="col-12">
          <div class="my-5">
            <p
              class="mb-0 s-h8 --text-dark fw-semibold d-flex align-items-center justify-content-center"
            >
              Also available as
              <a href="#" class="link text-decoration-underline ps-2"> eBook</a
              ><span class="ps-1">or</span>
              <a href="#" class="link text-decoration-underline ps-2">
                Audiobook</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end Bookseller section -->
</template>
<script>
export default {
  name: "affirm-resellers",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {
    let add_new_class = document.getElementById("affirm-header");
    let remode_class = document.getElementById("for-affirm");
    add_new_class.classList.add("-bg-dark-pink");
    remode_class.classList.remove("container");
  },
};
</script>
