<template>
<div class="l-main-block mobileFullWidth clearfix full-width">
  <div class="l-main mobileFullWidthWithMargin">
      <div class="wrapper">
    <div class="">
        <div>
            <h3 class='not-found-text text-size'>Page not found</h3>
            <h3 class='not-found-text'>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</h3>
            <router-link to='/' class='go-to-home-btm'>GO TO HOMEPAGE</router-link>
        </div>
      </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'not-found',
}
</script>

<style>
.full-width{
    width: 100% !important;
}
.go-to-home-btm{
    display: block;
    margin: 0 auto;
    width: 178px;
    padding: 10px 20px;
    background-color: #fbbe0f;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    margin-top: 50px;
}
.not-found-text{
    display: block;
    margin: 0 auto;
    text-align: center;
}
.text-size{
    font-size: 1.6em;
    margin-top: 3%;
    margin-bottom: 3%;    
}
@media screen and (min-width: 772px) and (max-width: 1200px) {
  .not-found-text{
    margin-right: 4%;
  }
}
</style>