<template>
  <div class="l-main-block mobileFullWidth clearfix">
  <form id="la-form" class="" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">
    <div class="l-main mobileFullWidthWithMargin">
    <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line">
    </div>

    <br>

    <div class="login-main">
      <span class="success-span-register">{{ success_data }}</span>
      <h1>Create a new password</h1>
      <span style="color:red;float:right;" v-if="errors.non_field_errors">{{ errors.non_field_errors }}</span>

      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-outer">
          <input class="form-control" type="password" v-model="new_password1" placeholder="Enter password" id="password1"/>
          <span class="error-text">{{ errors.new_password1 }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="password">Confirm Password</label>
        <div class="input-outer">
          <input class="form-control" type="password" v-model="new_password2" placeholder="Confirm password" id="password2"/>
          <span class="error-text">{{ errors.new_password2 }}</span>
        </div>
      </div>
      <div v-if="host=='acc'">
        <button class="btn btn-success login-button button orng-wte-c bold-f-w" v-on:click="save_new_password" style="float:right;">Save New Password</button>
      </div>
      <div v-else>
        <button class="btn btn-success login-button button orng-wte-c bold-f-w" v-on:click="save_new_password" style="background-color:#72838d;">Save New Password</button>
      </div>
      <br><br><br><br>
    </div>
  </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = "csrftoken"

export default {
  components: { Multiselect },
  data() {
    return {
      errors: {
        non_field_errors: '',
        new_password1: '',
        new_password2: ''
      },
      new_password1: '',
      new_password2: '',
      success_data: '',
      host : '',
    };
  },
  
  computed: {},
  watch: {},
  mounted() {
    this.host = this.$store.state.site_data.host
  },
  
  methods: {
    SubmitForm() {
      let self = this
      let formData = new FormData()
    },
    save_new_password() {
      let self = this
      let formData = new FormData()

      formData.append('new_password1', self.new_password1)
      formData.append('new_password2', self.new_password2)
      formData.append('uid', this.$route.params.uid)
      formData.append('token', this.$route.params.token)

      axios
        .post(`/dj-rest-auth/password/reset/confirm/`,
          formData,
        )
        .then(function (response) {
          self.success_data = response.data.detail
          self.errors.new_password1 = ''
          self.errors.new_password2 = ''
        })
        .catch(function (err) {
          const errors = err.response.data

          for(var key in errors){
            self.errors[`${key}`] = errors[`${key}`][0]
          }
        })
    }
  },
};
</script>

<style>
.hr_line {
  display: none;
}

.form-group label {
  margin-top: 16px;
  display: inline-block;
}

.form-group:nth-of-type(2) {
  min-height: 92px
}

.login-main{
  width:425px;
}
.error-text {
  color: red;
}

.success-span {
  color: green;
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0px;
  width: 100%;
}


@media screen and (min-width: 3000px)  and (max-width: 3840px) {
  #la-form {
    width: 100%;
    max-width: 100%;
    padding-right: 1px;
    padding-left: 258px;
    padding-top: 104px;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">