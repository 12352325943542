<template>
  <div class="st-container">
    <!-- start Bookseller section -->
    <section id="Bookseller" class="Bookseller py-5">
      <div class="row">
        <div class="col-sm-12 col-md-12 mb-4">
          <h1 class="s-h1 --text-dark mb-0">Bookseller information</h1>
        </div>
      </div>
            <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-0">Download Daily Updated Onix File</h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="grid_card my-3">
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">Download</h6>
              </div>
              <a
                href="https://dimoonix.s3.ap-southeast-2.amazonaws.com/affirm/dailyonix.xml"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
            </div>
          </div>
        </div>
      <hr class="my-5 bg-dark" />
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-0">Download ONIX files</h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="grid_card my-3">
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">MAY<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/ONIX3-MAY-2023.xml"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">APR<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/ONIX-APRIL-2023.xml"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-5 bg-dark" />
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-0">
            Download Booknet compatible ONIX files
          </h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="grid_card my-3">
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">MAY<br />2023</h6>
              </div>
              <div
                class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
              >
                <img
                  src="/static/css/images/affirm/Group55.svg"
                  alt="Group55.svg"
                  width="17px"
                  class="rotate-90"
                />
              </div>
            </div>
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">APR<br />2023</h6>
              </div>
              <div
                class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
              >
                <img
                  src="/static/css/images/affirm/Group55.svg"
                  alt="Group55.svg"
                  width="17px"
                  class="rotate-90"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-5 bg-dark" />
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-0">Download order forms</h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="grid_card my-3">
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">MAY<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/ORDER_FORM_MAY_2023.pdf"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
            <div
              class="main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-brown --text-dark"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">APR<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/ORDER_FORM_APRIL_2023.pdf"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-light-brown d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-5 bg-dark" />
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h5 class="s-h5 --text-dark mb-0">
            Download AI files (complete monthly catalogues)
          </h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="grid_card my-3">
            <div
              class="--text-dark main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-light-green"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">MAY<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/AI_MAY_2023.pdf"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-green d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
            <div
              class="--text-dark main-grid_card my-2 text-center d-flex flex-column justify-content-center align-items-center px-3 py-5 -bg-light-green"
            >
              <div>
                <h6 class="--text-dark s-h6 fw-semibold">APR<br />2023</h6>
              </div>
              <a
                href="https://dimoaffirm.s3.amazonaws.com/hero_images/affirm/AI_APRIL_2023.pdf"
                download
                target="_blank"
                class="--text-dark"
              >
                <div
                  class="rounded-circle -w-50 -h-50 -bg-green d-flex align-items-center justify-content-center"
                >
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group55.svg"
                    width="17px"
                    class="rotate-90"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end Bookseller section -->
  </div>
  <!-- End Featured authors -->
</template>
<script>
export default {
  name: "affirm-bookseller",
  methods: {
},
  mounted() {},
  data() {
    return {};
  },
};
</script>
