<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div class="bfClearfix">
        <div id="ff_formdiv3" class="bfFormDiv">
          <div class="bfPage-l">
            <div class="bfPage-r">
              <div class="bfPage-m bfClearfix">
                <form
                  name='ff_form1'
                  id="ff_form1"
                  enctype="multipart/form-data"
                  accept-charset="utf-8"
                  class="bfQuickMode"
                  @submit.prevent="submit('/submit_review_requests_form/')"
                >
                  <div id="bfPage1" class="bfPage">
                    <section class="bfPageIntro">
                      <h1>Request review copies or monthly updates</h1>
                      <p>Please fill in the form below&nbsp;to request review&nbsp;copies,&nbsp;or just&nbsp;fill in the first part of the form if you&nbsp;want&nbsp;to sign up to receive&nbsp;our monthly email update.</p>
                      <p>
                        PLEASE NOTE: all media enquiries &amp; requests for Bloomsbury titles must be directed to Bloomsbury - ph 02 8820 4900 or email
                        <a
                          href="mailto:au@bloomsbury.com"
                        >au@bloomsbury.com</a>
                      </p>
                      <p>
                        An asterisk (
                        <span style="color: #ff0030;">*</span> ) indicates mandatory fields
                      </p>
                    </section>
                    <span class="bfErrorMessage" v-if="errors">
                      <ul>
                        <li v-for="(err, key) in errors">{{key}} {{err[0].message}}</li>
                      </ul>
                    </span>
                    <div class="bfNoSection" id="bfQuickModeSection50928">
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap65">
                        <label id="bfLabel65">
                          Full name
                          <span class="bfRequired">*</span>
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="full_name"
                          id="ff_elem65"
                          style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: pointer;"
                          required
                        />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap66">
                        <label id="bfLabel66">
                          Title/Position
                          <span class="bfRequired">*</span>
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="title_position"
                          value
                          id="ff_elem66"
                          required
                        />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap67">
                        <label id="bfLabel67">
                          Company/ Program/ Website
                          <span class="bfRequired">*</span>
                        </label>
                        <input
                          class="ff_elem"
                          type="text"
                          name="company_program_website"
                          id="ff_elem67"
                          required
                        />
                      </section>
                      <div class="bfNoSection" id="section-label-description">
                        <section class="bfSectionDescription">
                          <p>
                            <label
                              class="bf-label"
                            >Description of your publication, program or website:</label>
                          </p>
                          <p
                            class="bf-label__desc"
                          >If you're not currently on our database, please give a brief outline eg. Circulation, readership, frequency, areas of interest etc</p>
                        </section>
                        <section class="bfElemWrap bfLabelLeft" id="bfElemWrap68">
                          <textarea
                            cols="20"
                            rows="5"
                            class="ff_elem"
                            name="description_company_program_website"
                            id="ff_elem68"
                          ></textarea>
                        </section>
                      </div>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap69">
                        <label id="bfLabel69">Street Address 1</label>
                        <input class="ff_elem" type="text" name="address_1" id="ff_elem69" />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap592">
                        <label id="bfLabel592">Street Address 2</label>
                        <input class="ff_elem" type="text" name="address_2" id="ff_elem592" />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap70">
                        <label id="bfLabel70">
                          Telephone
                          <span class="bfRequired">*</span>
                        </label>
                        <input class="ff_elem" type="text" name="telephone" id="ff_elem70" required />
                      </section>
                      <section class="bfElemWrap bfLabelLeft" id="bfElemWrap72">
                        <label id="bfLabel72">
                          Email
                          <span class="bfRequired">*</span>
                        </label>
                        <input class="ff_elem" type="email" name='email' id="ff_elem72" required />
                      </section>
                      <div class="bfNoSection" id="bfQuickModeSection97316">
                        <br>
                        <section class="bfSectionDescription">
                          <p>
                            <label class="bf-label">Books</label>
                          </p>
                          <p>Enter each item individually and tick the Interview box if you would like to request an interview</p>
                        </section>
                        <div class="bfNoSection" id="section-book-field">
                          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap253">
                            <label id="bfLabel253">Book name</label>
                            <input class="ff_elem" type="text" name="book_name_1" id="ff_elem253" />
                          </section>
                          <section class="bfElemWrap" id="bfElemWrap75">
                            <label id="bfLabel75">Interview</label>
                            <input
                              class="ff_elem custom-check"
                              type="checkbox"
                              name="book_interview_1"
                              id="ff_elem75"
                            />
                          </section>
                        </div>
                        <div class="bfNoSection" id="section-book-field">
                          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap254">
                            <input class="ff_elem" type="text" name="book_name_2" id="ff_elem254" />
                          </section>
                          <section class="bfElemWrap" id="bfElemWrap255">
                            <input
                              class="ff_elem custom-check"
                              type="checkbox"
                              id="ff_elem255"
                              name="book_interview_2"
                            />
                          </section>
                        </div>
                        <div class="bfNoSection" id="section-book-field">
                          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap256">
                            <input class="ff_elem" type="text" id="ff_elem256" name="book_name_3" />
                          </section>
                          <section class="bfElemWrap" id="bfElemWrap257">
                            <input
                              class="ff_elem custom-check"
                              type="checkbox"
                              id="ff_elem257"
                              name="book_interview_3"
                            />
                          </section>
                        </div>
                        <div class="bfNoSection" id="section-book-field">
                          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap258">
                            <input class="ff_elem" type="text" id="ff_elem258" name="book_name_4" />
                          </section>
                          <section class="bfElemWrap" id="bfElemWrap259">
                            <input
                              class="ff_elem custom-check"
                              type="checkbox"
                              id="ff_elem259"
                              name="book_interview_4"
                            />
                          </section>
                        </div>
                        <div class="bfNoSection" id="section-book-field">
                          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap260">
                            <input class="ff_elem" type="text" id="ff_elem260" name="book_name_5" />
                          </section>
                          <section class="bfElemWrap" id="bfElemWrap261">
                            <input
                              class="ff_elem custom-check"
                              type="checkbox"
                              id="ff_elem261"
                              name="book_interview_5"
                            />
                          </section>
                        </div>
                      </div>
                    </div>
                    <div v-if="status != 'submitted'">
                      <vue-recaptcha
                        ref="recaptcha"
                        sitekey="6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"
                        @verify="onCaptchaVerified"
                      ></vue-recaptcha>
                      <button
                        type="submit"
                        id="bfSubmitButton"
                        class="bfSubmitButton button"
                        :disabled="status==='submitting' || !captchaComplete"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                    <h3
                      v-if="status === 'submitted'"
                      class="success-message"
                    >Thank you for your enquiry</h3>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../mixins/Form.js';

export default {
  name: "review-requests", 
  mixins: [Form],
  data() {
    return {
      errors: null
    };
  }
};
</script>

<style>
</style>