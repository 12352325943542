<template>
    <default>
        <div class="wrapper">
            <bread-crumb></bread-crumb>
            <div class="l-main-wrapper mobileFullWidth clearfix" :class="{'left-left': isLeftStyle}">
                <slot/>
            </div>
            <side-bar></side-bar>
        </div>
    </default>
</template>

<script>
import Default from './Default';
import BreadCrumb from '../components/BreadCrumb';
import SideBar from '../components/SideBar';

export default {
    components:{
        Default,
        BreadCrumb,
        SideBar
    },
    computed: {
        isLeftStyle(){
            let style = this.$route.meta.style;
            if(style == 'left'){
                return true;
            }
            return false;
        }
    }
}
</script>

<style>
.left-left {
    float:left !important;
}
</style>