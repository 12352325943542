<template>
  <search from="category" ref="search_comp">
    <div class="l-main-top mobileFullWidthWithMargin" v-if="show_top_books">
      <div class="moduletable">
        <div class="vmss_mixed_image">
          <div class="vmss_mixed_image__item" v-for="book in top_books">
            <div class="vmss_mixed_image__image-wrap">
              <router-link
                class="vmss_mixed_image__item__image"
                :to="getBookUrl(book)"
                target="_self"
                :title="book.title"
              >
                <img
                  :src="
                    'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                    book.isbn +
                    '.jpg'
                  "
                  alt=""
                />
              </router-link>
              <div class="ie-hover-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sort-books @sort-search-books="sortBooks"></sort-books>
  </search>
</template>

<script>
import Search from "./SearchComponent";
import axios from "axios";
import Book from "../mixins/Book";
import SortBooks from "../components/SortBooks";

export default {
  name: "search-category",
  mixins: [Book],
  components: { Search, SortBooks },
  data() {
    return {
      top_books: [],
      show_top_books: false,
    };
  },
  methods: {
    getTopCategoryBooks() {
      let self = this;
      let category = this.$route.params.term;
      axios
        .get(`/get_top_category_books/${category}`)
        .then(function (data) {
          self.top_books = data.data.books;
          self.show_top_books = true;
          console.log(data);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    sortBooks(code) {
      this.$refs.search_comp.search_books(code);
    },
  },
  mounted() {
    this.getTopCategoryBooks();
  },
};
</script>

<style>
</style>