<template>
  <div class="l-main-block mobileFullWidth clearfix">
  <form id="la-form" class="" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">
    <div class="mobileFullWidthWithMargin">
    <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line">
    </div>

    <br>

    <div class="login-main">
      <span class="">{{ this.$store.state.common_info_message }}</span>

      <h1>Login to your account</h1>
      <span style="color:red;display: block; text-align: right; margin-bottom: 3px;" v-if="errors.non_field_errors">{{ errors.non_field_errors }}</span>

      <div class="form-group width-100">
        <span style="margin-right: -430px;" class="error-span error-login">{{ errors.email }}</span>
        <span style="margin-right: -430px;" class="error-span error-login" v-if='is_email_confirm'>{{is_email_confirm }}</span>
        <label for="email">Email</label>
        <div class="input-outer">
          <input class="form-control width-100" type="text" v-model="user.email" placeholder="Email" id="login_username"/>
          
          <router-link class="forget-pwd" :to="{ name: 'forget-password'}">Forget Password?</router-link>
        </div>
      </div>

      <div class="form-group width-100" >
        <label for="password">Password</label>
        <div class="input-outer width-100">
          <input class="form-control" type="password" v-model="user.password" placeholder="Enter your password" id="login_password"/>
          <span class="error-span">{{ errors.password }}</span>
        </div>
      </div>
      <button class="btn btn-success login-button button orng-wte-c bold-f-w" v-on:click="login" style="float:right;">Login</button>
      
      <br><br><br><br>
      <h1>Don't have an account yet?</h1>
        <router-link class="button orng-wte-c bold-f-w" :to="{ name: 'signup'}">Register here</router-link>
    </div>
  </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"
import store from '../store'

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = "csrftoken"

export default {
  components: { Multiselect },
  data() {
    return {
      loginError:'',
      is_email_confirm:'',
      errors: {
        non_field_errors: '',
        email: '',
        password: ''
      },
      user: {
        email:'',
        password:'',
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    SubmitForm() {
      let self = this
      let formData = new FormData()

      formData.append('email', self.user.email)
      formData.append('password', self.user.password)

    },
    createUser() {
      console.log("users", this.user);
    },
    login() {
      let self = this
      let formData = new FormData()
      formData.append('email', self.user.email)
      formData.append('password', self.user.password)
      axios
        .post(`/check_user_email_confirmation/`,
          {"email":self.user.email}
        )
      .then(function (response) {
        console.log(">>>>>>>",response.data['is_email'])
        if(response.data['is_email']){
        self.is_email_confirm=''

          axios
            .post(`/dj-rest-auth/login/`,
              formData,
            )
            .then(function (response) {
              self.$store.dispatch('saveUserKey', response.data.key)
              const redirect_url = store.getters.url_after_login
              if (redirect_url) {
                store.commit('CLEAR_COMMON_INFO_MESSAGE')
                store.commit('CLEAR_REDIRECT_URL')
                self.$router.push({ path: redirect_url})
              }else{
                self.$router.push({ name: 'home'})
              }
            })
            .catch(function (err) {
              console.log('222-222-222')
              console.log(err)
              console.log('222-222-222')
              const errors = err.response.data

              for(var key in errors){
                self.errors[`${key}`] = errors[`${key}`][0]
              }
            })
          }
          else{
              self.is_email_confirm = 'Email Not Found'
          }
        console.log(response.data['is_email'])
        })
        .catch(function (err) {
          const errors = err.response
          console.log(errors)
        })
    }
  },
  //beforeRouteLeave: (to, from, next) => {
  //  const redirect_url = store.getters.url_after_login

  //  if (redirect_url) {
  //    store.commit('CLEAR_COMMON_INFO_MESSAGE')
  //    store.commit('CLEAR_REDIRECT_URL')
  //    next(redirect_url)
  //    return
  //  }
  //  console.log('333-333-333')
  //  next(to.url)

  //}
};
</script>

<style>
.hr_line {
  display: none;
}

.form-group label {
  margin-top: 16px;
  display: inline-block;
}

.login-main{
  width:425px;
}
.error-span {
  color: red;
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0px;
  width: 100%;
}

.success-span {
  color: green;
  position: absolute;
  right: 0;
  bottom: -20px;
  margin: 0px;
  width: 100%;
}

.forget-pwd {
  float: right;
  margin-right: 3px;
  margin-top: 7px;
  position: absolute;
  right: 0;
  bottom: -21px;
}
.input-outer {
  position: relative;
  text-align: center;
}



.forget-pwd {
  float: right;
  margin-right: 3px;
  margin-top: 7px;
  position: absolute;
  right: 0;
  bottom: -21px;
}
.input-outer {
  position: relative;
  text-align: center;
  width: 100%;
}



@media screen and (min-width: 3000px)  and (max-width: 3840px) {
  #la-form {
    width: 100%;
    max-width: 100%;
    padding-right: 1px;
    padding-left: 258px;
    padding-top: 104px;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">