<template>
  <div class="l-main-block mobileFullWidth clearfix">

      <div class="l-main mobileFullWidthWithMargin">
      <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line">
      </div>
      <h1>Confirm Email</h1>
      <h3 class="confirm-email-h3" v-if="email_confirmed">Email is confirmed successfully</h3>
      <h3 class="confirm-email-h3" v-else>Email is not confirmed</h3>
    </div>

</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = "csrftoken"

export default {
  components: { Multiselect },
  data() {
    return {
      email_confirmed: false
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
  beforeMount() {
    let self = this
    let formData = new FormData()
    formData.append('key', this.$route.params.key)

    axios
      .post(`/dj-rest-auth/registration/verify-email/`,
        formData,
      )
      .then(function (response) {
        if (response.data.detail == 'ok') {
          self.email_confirmed = true
          console.log('111-111-111')
          console.log(self.email_confirmed)
          console.log('111-111-111')
        }
      })
      .catch(function (err) {
      })
  }
};
</script>

<style>
  .hr_line {
    display: none;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">