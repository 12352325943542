<template>
  <span>
    <div class="st-pusher" id="st-pusher">
      <div :class=" $route.path == '/submissions' ? 'sub-missions' : '' ">
      <affirm-header></affirm-header>
      <div class="l-content">
        <slot></slot>
      </div>
      </div>
    </div>
    <affirm-footer v-if="$route.path !='/contact-us'"></affirm-footer>
  </span>
</template>

<script>
import layoutMixin from "../../mixins/Layout";
import AffirmFooter from "../../components/AffirmFooter";
import AffirmHeader from "../../components/AffirmHeader";

export default {
  mixins: [layoutMixin],
  components: {
    AffirmFooter,
    AffirmHeader,
  },
  data() {
    return {};
  },
  computed: {},
  mounted (){
    let host = this.$store.state.site_data.host;
    if (host == "affirm") {
      let remode_class = document.getElementById("app");
      remode_class.classList.remove("st-container");
    }
  },
};
</script>
<style>
</style>
