<template>
		<Default>
		  <slot/>
		</Default>
</template>

<script>
import Default from './defaultacc';

export default {
  components:{
    Default,
  },
}
</script>