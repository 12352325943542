<template>
  <div class="moduletable" :class="extra_classes" v-if="showCart">
    <!-- Virtuemart 2 Ajax Card -->
    <div class="vmCartModule" id="vmCartModule">
      <router-link class="total_products" to="/cart">
        <strong>My Cart</strong>
        <span> {{cartProductNum}}</span> item/s
      </router-link>
      <div class="show_cart" rel="nofollow" v-if="show_extra_link">
        <router-link to="/cart">Show Cart</router-link>
      </div>
      <div style="clear:both;"></div>
    </div>
  </div>
</template>

<script>
import Cart from "../mixins/Cart"

export default {
  name: "cart",
  mixins: [Cart],
  data() {
    return {}
  },
  props: ["extra_classes", "show_extra_link"],
  computed: {
    showCart() {
      return this.$store.state.site_data.ecommerce
    },
  },
  created() {
    let self = this
    self.$store.dispatch("checkCartStatus").then(() => {
      console.log("cart updated")
    })
  },
  mounted() {},
}
</script>

<style>
</style>