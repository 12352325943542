<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line" style="padding-left:160px;width: calc(100% - 160px); margin-bottom: 5px;">
    <form id="la-form" class="la_form legal-advice-form" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">
      <div class="form-group l-main mobileFullWidthWithMargin">
        <label class="h4-label" for="role">Role</label><br />
        <multiselect class="drop_down" v-model="user.role" :multiple="true" :options="roles">
        </multiselect>
      </div><br>
      <div class="form-group">
        <label class="h4-label" for="phone_number">Phone Number</label><br />
        <input
          class="form-control phone-input"
          type="text"
          v-model="user.phone_number"
          placeholder="phone_number"
          id="phone_number"
        />
      </div><br>
      <div class="form-group">
        <label class="h4-label" for="copyright_use">Copyright use</label><br />
        <multiselect
          v-model="user.copyright_use"
          :multiple="true"
          :options="copyrightOptions"
          class="drop_down"
        >
        </multiselect>
      </div><br>
      <div class="form-group">
        <label class="h4-label" for="your_membership">Your Memberships</label><br />
        <multiselect
          v-model="user.your_membership"
          :multiple="true"
          :options="MembershipsOptions"
          class="drop_down"
        >
        </multiselect>
      </div><br>
      <div class="form-group">
        <label class="h4-label" for="postcode">Post code</label><br />
        <input
          class="form-control post-codet"
          type="text"
          v-model="user.postcode"
          placeholder="post code"
          id="postcode"
        />
      </div><br>
      <div class="form-group">
        <label  class="h4-label" for="description">Detail of your enquiry</label><br />
        <textarea
          class="form-control enquiry-detail"
          v-model="user.description"
          placeholder="description"
          id="description"
          rows="10"
          cols="50"
        >
        </textarea>
      </div><br>
      <div class="form-group attached-file">
        <label class="h4-label" for="file">Attached file</label><br />
        <input class="attached-file-input"
          ref="files"
          multiple="multiple"
          type="file"
          @change="onFilePicked"
        />

      </div>
      
      <span class="success-message">{{success_message}}</span>
      <br />
      <div class="row button_left">
        <div class="col-md-12 btn_left pull-righ">
          <button v-if="submit_btn=='Submit'" class="btn btn-success button orng-wte-c bold-f-w">Submit</button>
          <span class="btn button orng-wte-c bold-f-w disabled-btn" v-else >Submitting</span>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  data() {
    return {
      success_message: '',
      submit_btn:'Submit',
      user: {
        role: '',
        phone_number: '',
        postcode: '',
        description: '',
        copyright_use: '',
        your_membership: '',
        image: '',
        files: '',
      },
      selected: null,
      roles: [
        'Academic',
        'Broadcaster',
        'Lawyer',
        'Librarian',
        'Musician',
        'Performer',
        'Producer',
        'Publisher',
        'Researcher',
        'Student',
        'Teacher',
        'Visual Artist',
        'Writer',
        'Other',
      ],
      MembershipsOptions: [],
      copyrightOptions: [
        'I am the user of the copyright material',
        'I am the creator of the copyright material',
        'I am the owner of the copyright material',
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let self = this
    axios
      .get('/legal-advice/create/')
      .then(function (response) {
        self.MembershipsOptions = response.data
      })
  },
  methods: {
    SubmitForm() {
      let self = this
      let formData = new FormData()

      const new_files = this.$refs.files.files
      let files = []

      for( var i = 0; i < new_files.length; i++ ){
        let file = new_files[i];

        files.push(file)
        formData.append(`file${i}`, file)
      }

      formData.append('role', self.user.role)
      formData.append('phone_number', self.user.phone_number)
      formData.append('postcode', self.user.postcode)
      formData.append('description', self.user.description)
      formData.append('copyright_use', self.user.copyright_use)
      formData.append('membership', self.user.your_membership)
      formData.append('file', self.user.image)
      formData.append('files', files)
      formData.append('username',this.$store.state.user.username)

      self.submit_btn='submitting'
      axios
        .post(`/legal-advice/create/`,
          formData,
        )
        .then(function (data) {
          alert('Your request was submitted sucessfully. Someone will be in contact with you shortly.');
          setTimeout( () => self.$router.push({ path: '/'}), 6000);


        })
        .catch(function (err) {
        })
    },
    createUser() {
      console.log("users", this.user);
    },
    onFilePicked(event) {
      const files = event.target.files;
      // let filename = files[0].name;
      // const fileReader = new FileReader();
      // fileReader.addEventListener("load", () => {
      //   this.imageUrl = fileReader.result;
      // });
      // fileReader.readAsDataURL(files[0]);
      this.user.image = files[0];
    },
  },
};
</script>

<style>
  *{
    font-family: 'Arial', sans-serif;
  }
  .hr_line {
    display: none;
  }
  .form-group label {
    min-width: 200px;
    margin: 0;
  }
  .multiselect__option--highlight {
    background: #ed783b;
    outline: none;
    color: #fff;
  }
   .multiselect__tags input {
    border: 0px;
  }
  .multiselect--active input{
    position: absolute;
    width: 0px;
    max-width: 90%;
    padding: 0px
  }
  .multiselect__placeholder, .multiselect--active .multiselect__placeholder{
    padding: 5px 0px;
    margin: 0px;
}
  .multiselect__option--highlight:after{
    display: none;
  }
  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #ed783b;
    content: attr(data-deselect);
    display: none;
}
  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #ed783b !important;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .h4-label {
    font-size: 1.286em;
    font-weight: bold;
    color: #41525c;
    margin: 0 0 1em 0;
  }

  /* .la_form{
    width:510px;
  } */
  .button_left, .btn_left{
    width: 100%;
    float: left;
    text-align: left;
  }
   .button_left, .btn_left button{
    float: left;
   }

  .drop_down .multiselect__tags{
    padding: 0.572em 1.250em;
    border: 3px solid #e1e1e1;
    border-radius: 0;
  }
  .success-message {
    color: #0baf0b;
    padding-left: 160px;
  }
  .enquiry-detail, .phone-input, .attached-file-input{
    width: 100%;
  }
  .attached-file{
    width:50%;
  }
  .disabled-btn{
    background-color:#767676 !important;
  }
  .legal-advice-form .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 700px;
  }
  .multiselect__tags {
    width:100%;
  }

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">
