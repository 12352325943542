export default {
    methods: {
        getBookUrl: function (book) {
            let author = '';
            if (book.author) {
                author = book.author.replace(/ /g, '-');
                author = author + '-';
            }
            let title = '';
            if (book.title) {
                title = book.title.replace(/ /g, '-');
                title = title.replace('#', '');
                title = title.replace('?',"");
                title = title + '-';
            }
            return `/browse/book/${author}${title}${book.isbn}`;
        }
    }
}