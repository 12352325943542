<template>
  <div id="app" class="st-container" :class="iconMobileMurdochClass">
    <div class="st-pusher">
      <div class="st-content">
        <murdoch-header></murdoch-header>
        <label id="open-side-bar" class="mobile-menu-label mobileOnly" @click="showMurdochMenu = !showMurdochMenu"></label>
        <div class="l-content">
          <slot></slot>
        </div>
        <murdoch-footer></murdoch-footer>
      </div>
    </div>
    <mobile-version></mobile-version>
  </div>
</template>

<script>
import layoutMixin from '../../mixins/Layout';
import MurdochFooter from '../../components/MurdochFooter';
import MurdochHeader from '../../components/MurdochHeader';
import MobileVersion from '../../components/MobileVersion';

export default {
  mixins: [layoutMixin],
  components: {
    MurdochFooter,
    MurdochHeader,
    MobileVersion
  },
  data() {
    return {
      showMurdochMenu: false
    };
  },
  computed: {
    iconMobileMurdochClass() {
      return this.showMurdochMenu ? 'st-menu-open' : '';
    }
  }
};
</script>

<style>
</style>