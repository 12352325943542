import Vue from 'vue'
import VueRouter from 'vue-router'
import Static from '../views/Static'
import AccProductPage from '../views/AccProductPage.vue'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import SearchCategory from '../views/SearchCategory.vue'
import BrowseBook from '../views/BrowseBook.vue'
import ContactUs from '../views/ContactUs.vue'
import ReviewRequests from '../views/ReviewRequests.vue'
import Booksellers from '../views/Booksellers.vue'
import InspectionCopy from '../views/InspectionCopy.vue'
import Newsletter from '../views/Newsletter.vue';
import NotFound from '../views/NotFound.vue';
import Cart from '../views/Cart.vue';
import Checkout from '../views/Checkout.vue';
import ThankYou from '../views/ThankYou.vue';
import AdvancedSearch from '../views/AdvancedSearch.vue';
import Login from '../views/Login.vue';
import SignUp from '../views/SignUp.vue';
import ConfirmEmail from '../views/ConfirmEmail.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import SetNewPassword from '../views/SetNewPassword.vue';
import Profile from '../views/Profile.vue';
import LegalAdvice from '../views/LegalAdvice.vue';
import LegalAdviceRequest from '../views/LegalAdviceRequest.vue';
import Competitions from '../views/Competitions.vue';
import News from '../views/News.vue';
import Events from '../views/Events.vue';
import AccBlog from '../views/AccBlog.vue';
import AccEvent from '../views/AccEvent.vue';
import Activities from '../views/AccEventActivities.vue';
import store from '../store';



const isAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }

  if(to.name == 'legal-advice'){
    const message = 'You must be logged into an account to submit a legal request form'
    store.commit('SET_COMMON_INFO_MESSAGE', message)
    store.commit('SET_REDIRECT_URL', to)
  }

  next('/login')
}
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home" }]
    },
    component: Home
  },
  {
    path: '/browse/book/:url',
    name: 'browse-book',
    component: AccProductPage,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: 'left'
    },
  },
  {
    path: "/product-page",
    component: AccProductPage,
    name: 'product-page',
    meta:{
      breadcrub:[{ name:'Home',link:'home'},
      {name: 'product-page'},
      {name:""}]
    }
  },
  {
    path: "/browse",
    component: Search,
    name: 'browse',
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
        { name: "" }
      ]
    },
  },
  {
    path: '/browse/books/:url(\\d+)',
    name: 'browse-book',
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: 'left'
    },
  },
  {
    path: '/browse/books/:term',
    name: 'category-search',
    component: SearchCategory,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ]
    },
    children: [
      {
        path: ':page',
        component: SearchCategory,
        meta: {
          Breadcrumb: [
            { name: "Home", link: "home" },
            { name: "Browse", link: "search" },
          ]
        }
      }
    ]
  },
  {
    path: '/browse/book/:url',
    name: 'browse-book',
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: 'left'
    },
  },
  {
    path: '/browse/:path*',
    name: 'browse-static',
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
      ]
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Search" }]
    },
  },
  {

    path: "/events",
    name: "events",
    component: AccEvent,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "events" }]
    },
  },
  {
    path: "/events/details/:id",
    name: "Activities",
    component: Activities,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Activities" }]
    },
  },
   {
    path: "/newsletters",
    name: "newsletters",
    component: Newsletter,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Subscribe to Newsletter' }]
    },
  },

  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Contact Us" }]
    },
  },
  {
    path: "/404/",
    name: "not-found",
    component: NotFound,
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home", link: "home" }],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Login User'}]
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Initial User Screen'}]
    },
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUp,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Sign Up'}]
    },
  },
  {
    path: '/account-confirm-email/:key',
    name: 'confirm-email',
    component: ConfirmEmail,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Confirm Emails'}]
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Forget Password'}]
    },
  },
  {
    path: '/reset/confirm/:uid/:token',
    name: 'set-new-password',
    component: SetNewPassword,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Set New Password'}]
    },
  },
  {
    path: '/account-confirm-email/:key',
    name: 'confirm-email',
    component: ConfirmEmail,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Confirm Emails'}]
    },
  },
  {
    path: '/au-media/review-requests',
    name: 'review-requests',
    component: ReviewRequests,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: "Review Requests"}]
    },
  },
  {
    path: '/resources/inspection_copies',
    name: 'inspection_copies',
    component: InspectionCopy,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: "Inspection Copies"}]
    },
  },
  {
    path: "/thank-you/",
    name: "thank-you",
    component: ThankYou,
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home", link: "home" }]
    },

  },
  {
    path: "/booksellers",
    name: "booksellers",
    component: Booksellers,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: 'Booksellers'}]
    }
  },
  {
    path: '/browse/news',
    name: 'news',
    component: News,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'News' }
      ]
    },
  },
  {
    path: '/browse/news/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "News", link: "news"}
      ]
    }
  },
  {
    path: '/browse/events',
    name: 'events',
    component: Events,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'Events' }
      ]
    },
  },
  {
    path: '/browse/events/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "Events", link: "events"}
      ]
    }
  },
  {
    path: '/browse/competitions',
    name: 'competitions',
    component: Competitions,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'Competitions' }
      ]
    },
  },
  {
    path: '/browse/competitions/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "Competitions", link: "competitions"}
      ]
    }
  },
  {
    path: "/cart/",
    name: "cart",
    component: Cart,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Shopping Cart'}]
    },
  },
  {
    path: "/advanced-search/",
    name: "advanced-search",
    component: AdvancedSearch,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Advanced Search'}]
    },
  },
  {
    path: "/legal-advice-service/",
    name: "legal-advice",
    component: LegalAdvice,
    beforeEnter: isAuthenticated,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Legal Advice'}]
    },
  },
  {
    path: "/legal-advice-request/",
    name: "legal-advice-request",
    component: LegalAdviceRequest,
    beforeEnter: isAuthenticated,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Legal Advice Request'}]
    },
  },
  {
    path: "/checkout/",
    name: "checkout",
    component: Checkout,
    meta: {
      breadcrub: [{ name: "Home", link: "home" },{name: 'Shopping Cart', link: 'cart'}, { name: 'Checkout'}]
    },
  },
  {
    path: "/:path*",
    name: "static",
    component: Static,
    meta: {
      static: true,
      breadcrub: [{ name: "Home", link: "home" }]
    },
  },

];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })

  export default router
