<template>
  <div class="">
    <div class="st-container">
      <!-- start book fiction -->
      <section id="Distribution-partners-book" class="py-5">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <div class="-bg-light-gray p-5">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators rounded-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="/static/css/images/affirm/Calm-Cover@2x.png"
                      class="d-block m-auto -w-300"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/static/css/images/affirm/9781922806390-2-400x612@2x-.png"
                      class="d-block m-auto -w-300"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/static/css/images/affirm/9781922806390-2-400x612@2x-.png"
                      class="d-block m-auto -w-300"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/static/css/images/affirm/9781922806390-2-400x612@2x-.png"
                      class="d-block m-auto -w-300"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <div class="me-1 d-flex align-items-center">
                <img
                  src="/static/css/images/affirm/Group55.svg"
                  alt="down-arrow"
                  height="7px"
                  class="rotate-90"
                />
              </div>
              <div class="">
                <p class="s-h10 mb-0 --text-dark">
                  Download high-res cover photo
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <div>
              <p class="s-h7 --text-dark">Books - Non-Fiction</p>
            </div>
            <div>
              <h3 class="s-h3 mb-0 --text-dark">Calm</h3>
            </div>
            <div>
              <p class="s-h5s mb-4 lh-sm --text-dark">by The School of Life</p>
            </div>

            <div>
              <p class="s-h7 --text-dark">
                Few life skills are as neglected, yet as important, as the
                ability to remain calm. Our very worst decisions and
                interactions are almost invariably the result of a loss of calm
                – and a descent into anxiety and agitation. Surprisingly, but
                very fortunately, our power to remain calm can be rehearsed and
                improved.
              </p>
            </div>
            <div>
              <p class="s-h7 --text-dark">
                We don’t have to remain where we are now: our responses to
                everyday challenges can dramatically alter. We can educate
                ourselves in the art of
                <span id="dots">...</span>
                <span id="more"
                  >remaining calm not through slow breathing or special teas but
                  through thinking. This is a book that patiently unpacks the
                  causes of our greatest stresses and gives us a succession of
                  highly persuasive, beautiful and sometimes dryly comic
                  arguments with which to defend ourselves against panic and
                  fury.
                </span>
              </p>

              <a
                onclick="myFunction()"
                id="myBtn"
                class="link --text-dark text-decoration-underline cursor-pointer"
                >Read more...</a
              >
            </div>
            <div class="mt-4">
              <div class="d-flex align-items-center mb-3 mb-md-4">
                <div class="me-3">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt=""
                    class="p-3 rounded-circle -bg-brown -w-50 -h-50"
                  />
                </div>
                <div>
                  <a href="" class="text-dark fw-semibold">
                    <p class="text-decoration-underline s-h7 mb-0">
                      Read an excerpt
                    </p>
                  </a>
                </div>
              </div>
              <div class="">
                <p class="s-h7 fw-bold mb-1">Buy now</p>
                <a href="#" class="link">
                  <div
                    class="d-flex align-items-center animation-slide position-relative"
                  >
                    <div class="img">
                      <img
                        src="/static/css/images/affirm/Group55.svg"
                        alt=""
                        class="me-3"
                      />
                    </div>
                    <div class="content">
                      <p class="mb-0 s-h8">Hardback RRP $39.99</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end book fiction -->

      <!-- start publication -->
      <section id="publication" class="publication mb-5">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div
              class="d-flex flex-column justify-content-between h-100 pb-4 pb-md-0"
            >
              <div class="">
                <p class="fw-semibold s-h8 --text-dark">Publication details</p>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">Package</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">Paperback</p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">RRP</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">$29.99</p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">ISBN</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">9781922711038</p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">Extent</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">288 pages</p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">Format.</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">153 x 234 mm</p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">Category</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">
                    FA-Modern & contemporary fiction (post c 1945)
                  </p>
                </div>
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 w-25 s-h8 --text-dark">List</p>
                  <p class="mb-0 w-75 s-h8 --text-dark">Affirm Press</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 h-100">
            <div>
              <div class="">
                <div>
                  <p class="fw-semibold s-h8 --text-dark">Enquiries</p>
                </div>
                <div>
                  <p class="mb-0 s-h8 --text-dark">
                    Keiran Rogers, Sales Director
                  </p>
                  <p class="mb-0 s-h8 --text-dark">Ph: 0419 554 517</p>
                  <p class="mb-0 s-h8 --text-dark">
                    Email: keiran.rogers@affirmpress.com.au
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3 mb-md-4 mt-4">
              <div class="me-3">
                <img
                  src="/static/css/images/affirm/Group55.svg"
                  alt="Group 55"
                  class="p-3 rounded-circle -bg-light-muted -w-50 -h-50"
                />
              </div>
              <div>
                <a href="" class="text-dark fw-semibold">
                  <p class="text-decoration-underline s-h7 mb-0 --text-dark">
                    Bookseller login
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end publication-->

      <!-- start about school-->
      <section id="about-schools" class="about-school -bg-brown mb-5">
        <div class="row p-5">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-7">
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="mt-2">
                <h4 class="s-h4 --text-dark">About the author</h4>
                <p class="s-h7 --text-dark">
                  Omar Sakr is an award-winning Arab Australian poet, born of
                  Lebanese and Turkish Muslim migrants. The Lost Arabs
                  (University of Queensland Press, 2019), won the 2020 Prime
                  Minister’s Literary Awards for Poetry and was shortlisted for
                  the NSW Premier’s Literary Awards, the John Bray Poetry Award,
                  and the Colin Roderick Award. Omar’s poetry has been published
                  in English, Arabic, and Spanish, in numerous journals and
                  anthologies. Omar has performed his work nationally and
                  internationally. He lives in Sydney.
                  <b>Son of Sin</b> is his first novel
                </p>
              </div>
              <div>
                <img
                  src="/static/css/images/affirm/Group55.svg"
                  alt="Group 55"
                  width="25px"
                />
              </div>
              <div></div>
            </div>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 col-xl-5">
            <div>
              <img
                src="/static/css/images/affirm/Group218.png"
                alt="about-schoold"
                class="-w-300 d-block m-auto"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- end about school-->
      <affirm-book-list placement="bottom-left"></affirm-book-list>
    </div>
  </div>

  <!-- End Featured authors -->
</template>
<script>
import AffirmBookList from "../components/AffirmBookList";
export default {
  name: "affirm-distribution-book",
  components: {
    "affirm-book-list": AffirmBookList,
  },
  methods: {},
  mounted() {},
  data() {
    return {};
  },
};
</script>
