<template>
  <span>
  <!-- modal -->
   <div class="modal-for-new">
    <transition name="modal">
      <div v-if="isOpen">
        <div class="overlay" @click.self="isOpen = false;">

          <div class="modal">
            <p class="modal-close" @click.self="isOpen = !true;">x</p>
            <p></p>
            <p><br>Knowledge from<br> Copyright Fundamentals <br>is presumed for this<br> session.</p>
            <p><span class="modal-red-btn" @click="getlookupdata">Continue to cart</span></p>
          </div>
        </div>
      </div>
    </transition>
    </div>
    <!-- end modal -->
    <a
      v-if="pop_up == '1'"
      name="addtoevent"
      class="modal-btn"
      value="Add to Cart"
      @click="isOpen = !isOpen;"
    >
    Add
    </a>
    <a
      v-else
      name="addtoevent"
      class="modal-btn"
      value="Add to Cart"
      @click="getlookupdata"
    >
    Add
    </a>

    <modal :id="'id_reg_' + test1" :name="'modal_' + test1" height="auto">
      <div class="pop_form01">
        <form
          id="la-form"
          :name="test1"
          class="la-form2"
          method="POST"
          enctype="multipart/form-data"
          v-on:submit.prevent="SubmitForm"
        >
          <div>
            <br />
            <div class="text-center">
              <h1>Event Registration</h1>
              <p>You are registering for the<br> {{title}} webinar.</p>
            </div>
            <br />
            <div class="pop_form" v-for="(fields, index) in lookup_fields">
              <label class="sr-only">{{ fields.label }}</label>
              <input
                type="text"
                :placeholder="fields.label"
                :data-id="fields.id"
                class="lable_value"
                id=""
                :data-required="fields.mandatory"
              />
              <span class="error-msg" :id="fields.id" style="display: none"
                >This filed is required
              </span>
            </div>
            <br />
          </div>
          <div class="btn-right">
            <button class="btn btn-warn" v-on:click="signup">Register</button>
          </div>
        </form>
      </div>
    </modal>
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "add-to-event",
  props: ["isbn", "title", "value", "type", "activity_id", "pop_up"],
  data() {
    return {
      qty: 1,
      isOpen: false,
      lookup_fields: [],
      test1: "",
      user: [],
      errors: {
        label: "",
        description: "",
        value_type: "",
        display_order: "",
        mandatory: "",
      },
      lookup_id: "",
      registartion_id: "",
    };
  },
  methods: {
    getlookupdata() {
      let self = this;
      let ac_id = this.activity_id;
      self.test1 = ac_id;
      axios
        .post(`/lookup_data/${ac_id}/`)
        .then(function (response) {
          if (response.data.data.length > 0) {
            self.lookup_fields = response.data.data;
            self.$modal.show(`modal_${self.test1.toString()}`);
          } else {
            console.log("--------in else");
          }
        });
      document.getElementsByClassName('overlay')[0].remove() 
    },
    addProductToCart() {
      let self = this;
      this.$store
        .dispatch("addProduct", {
          isbn: this.isbn,
          qty: this.qty,
          type: this.type,
          cost: this.value,
          registartion_id: self.registartion_id,
        })

        .then(function (data) {
          self.$modal.show({
            template: `
                    <div class="modal-cont-body w-450">
                    <div class="dialog-content">
                      <div class="dialog-c-title">${self.title}</div>
                    </div>
                    <div class="vue-dialog-buttons">
                    <button class="vue-dialog-button" style="flex: 1 1 80%;" @click="$router.push('/cart'), $emit('close')">Show Cart</button>
                    <button @click="$emit('close')" class="vue-dialog-button" style="flex: 1 1 100%;">Continue Shopping</button>
                    </div>
                    </div>
                    `,
          });
        });
    },

    SubmitForm() {
      let self = this;
      let formData = new FormData();
      // form_signup.append('activity_type', self.user.activity_type)
      formData.append("value", self.user.label);
      formData.append("activity", this.activity_id);
      formData.append("event_type_registration", "");
    },
    signup() {
      let self = this;
      // let form = document.getElementById("la-form");
      var inputs = $(".lable_value");
      let data_list = [];
      for (var i = 0; i < inputs.length; i++) {
        let data_dict = {};

        let value = $(inputs[i]).val();
        let is_required = $(inputs[i]).attr("data-required");
        let activity_lookup_id = $(inputs[i]).attr("data-id");

        if (is_required == 1 && (value.trim() == null || value.trim() == "")) {
          $(`#${activity_lookup_id}`).css("display", "block");

          return false;
        }
        $(`#${activity_lookup_id}`).css("display", "none");
        if (value.trim() != null && value.trim() != "") {
          data_list.push({
            event_type_registration: activity_lookup_id,
            value: value,
            activity: this.activity_id,
          });
        }
      }

      let formData = new FormData();
      let id = this.$route.params.id;
      // formData.append('value', self.user.label)
      // formData.append('activity', this.activity_id)
      formData.append("data", JSON.stringify(data_list));
      axios
        .post(
          `/get_register_data/`,
          formData
        )
        .then(function (response) {
          let id_regstr = document.getElementsByClassName("v--modal-overlay");
          let id_addtocart = document.getElementById("id_addcart");
          if (response.status == 201) {
            $(".lable_value").val("");
            $(".pop_form").empty();
            for (let i = 0; i < id_regstr.length; i++) {
              id_regstr[i].style.display = "none";
            }
            // $('#id_reg').modal('hide')
            self.registartion_id = response.data.registration_id;
            self.addProductToCart();

            // id_addtocart.style.display = 'block';
            self.$modal.hide(`modal_${self.test1.toString()}`);
          } else {
            $(".lable_value").val("");
            const errors = response.data;

            for (var key in errors) {
              self.errors[`${key}`] = errors[`${key}`][0];
            }
          }
        })
        .catch(function (err) {
          const errors = err.response;
          $(".lable_value").val("");
          $(".pop_form").empty();

          for (var key in errors) {
            self.errors[`${key}`] = errors[`${key}`][0];
          }
        });
    },
    removeQty() {
      if (this.qty > 1) {
        this.qty -= 1;
      }
    },
    getAddToCartClass() {
      if (this.place == "search") {
        return "search-add-to-cart";
      }
      return "";
    },
  },
  computed: {},
};
</script>

<style>
h1{
  font-size: 20px;
  font-weight: bold;
  color: #41525c;
  margin: 0 0 1em 0;
}
.v--modal-box.v--modal {
  height: fit-content !important;

  max-height: fit-content !important;

  min-height: fit-content !important;

  top: 30px !important;
}

.v--modal-box.v--modal {
  background: none;

  box-shadow: none;

  width: -webkit-fit-content;

  max-width: fit-content;

  height: -webkit-fit-content;

  max-height: fit-content;

  min-height: 380px;

  padding: 35px;
}

.pop_form01 .modal-cont-body {
  width: fit-content;

  height: 500px;

  margin: 0 auto;

  background: #fff;

  max-height: fit-content;

  min-height: 380px;
}

.pop_form01 .pop_form {
  display: grid;

  grid-template-columns: 30% 70%;

  align-items: center;

  vertical-align: middle;

  position: relative;
}

.pop_form01 .btn-right {
  text-align: right;
}

.btn-right .btn.btn-warn {
  background: #333;

  padding: 8px 20px;

  border: 0;

  color: #fff;

  text-transform: uppercase;

  border-radius: 4px;

  font-weight: 600;
}

.error-msg {
  color: red;

  position: relative;

  right: 0px;

  bottom: 0px;

  margin-top: 4px;

  width: 100%;

  position: absolute;

  font-size: 12px;

  transform: translate(105px, 16px);
}

.la-form2 {
  padding-left: 0px !important;
}

#id_addcart {
  display: none;
}

.same_input {
  height: 40px;

  border: 3px solid #ddd;
}

.pop_form input {
  margin: 15px 0;
}

.pop_form .error-msg {
  position: absolute;

  bottom: 10px;
}
.lable_value{
  height: 50px;
}
.sr-only{
  font-size: 15px;
}
.text-center{
  text-align: center;
}
::placeholder{
  font-size: 15px;
}
.modal-btn{
  background-color: #f6793c;
  color: white;
  padding: 10px 20px;
}
.modal-for-new {
  margin-top: -10px;
}
</style>