<template>
  <div class="main-wrapper-body">
    <acc-header></acc-header>
    <div class="cont">
      <div class="main012">
        <div class="l-left mobileFullWidthWithMargin sidebar search-page-static">
          <!-- v-if="$route.path === '/search'" -->
          <div v-if="$route.path !='/product-page' && $route.path !='/' "  class="moduletable vmss_mixed--default">
            <!-- <acc-bestseller></acc-bestseller> -->
          </div>
        </div>
         <slot></slot>
         
      </div>
    </div>
     
        <!-- <acc-Email></acc-Email> -->
    <acc-footer></acc-footer>  
  </div>
</template>


<script>
import layoutMixin from '../../mixins/Layout';
import ACCHeader from '../../components/ACCHeader';
import ACCFooter from '../../components/ACCFooter';
import BestSellerList from '../../components/BestSellerList';
import AccEmailMenu from '../../components/AccEmailMenu';
export default {
  components: {
    'acc-footer': ACCFooter,
    'acc-header': ACCHeader,
    'acc-bestseller': BestSellerList,
    'acc-Email' : AccEmailMenu,
  },
  mixins: [layoutMixin],
};
</script>