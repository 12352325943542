<template>
  <div class="container">    

    <div class="res-align">
      <div></div>
      <div>
        <div class="mobileFullWidth clearfix ">
          <div id="static-content">
          </div>
        </div>

        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="!content"
          class="loader-spinner"
        />
        <div class="mobileFullWidth clearfix d-pad" >
          <div id="events">
            <div class="search-category">
              <div class="inner-search-category-first pr-2" v-for="event,index in events">
                <h3>{{event.name}}</h3>
                <p v-html="event.description"></p>
                <p>Click here to <router-link :to="{ name: 'Activities', params: { id: event.id }}">find out more</router-link></p>
                
              </div>
            </div>
           </div>
        </div>  
      </div>  
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Vue from 'vue';
export default {
  name: "static",
  components: {
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      events:[],
      urls: '',
      content: '',
      title: null,
      host: '',
      page_type: null,
      
    };
  },
  methods: {
    fetch_static_content(path) {
      let self = this;
      axios
        .get("/get_static_page" + path)
        .then(function (response) {
          // adding a on-the-fly vue component with the static content of the page.
          // Vue.extend creates a new instance of vue and mounts the component on that new instance.
          // That is why the vue router and store must be passed else it will be undifined.
          let static_content = Vue.extend({
            router: self.$router,
            store: self.$store,
            template: response.data.content,
          });
          new static_content().$mount('#static-content');
          self.content = response.data.content;
          self.title = response.data.title;
          self.page_type = response.data.page_type;
          let last_bread = self.$route.meta.breadcrub[self.$route.meta.breadcrub.length-1];
          if(!last_bread.link){
            last_bread.name = self.title;
          }else{
            self.$route.meta.breadcrub.push({
              name: self.title
            });
          }
        })
        .catch(function (err) {
          if (!self.$route.params.path.includes("404")) {
            window.location.replace("/404/");
          }
        });
    },
    get_index(index){
      console.log(index + 1)
      return index + 1
    }
  },
  beforeRouteUpdate(to, from, next) {
    let url = window.location.href;
    if (url.includes("about_us#")&&this.host=="acc"){
      this.urls="true";
    }
    this.fetch_static_content(to.path);
    next();
  },
  mounted() {
    let self = this;
    axios
      .get(`/get_event_data/`)
      .then(function (data) {
        self.events = data.data.data
    })
    this.host = this.$store.state.site_data.host
    let path = this.$route.path;
    this.fetch_static_content(path);
    let resource_search = document.getElementById('events');
    let url = window.location.href;
    if (url.includes("events")&&this.host=="acc"){
      resource_search.style.display='block';
    }else{
      resource_search.style.display='none';
    }
  }
  // created() {
  // }
};
</script>
<style>
.d-pad{
padding: 0px;
}
.search-category h3 {
    color: #050608cc;
}


  .bestseller-books{
    display: none;
  }
  .vmss_mixed {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .vmss_mixed__item {
    width: calc(90% / 5);
  }
  .vmss_mixed__item a {
    display: inline-block;
    width: 100%;
  }
  p.image-wrap img {
    width: 100%;
    min-width: 120px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  } 
  .inner-search-keyword{
    width: 60%;
    float: left;
  }
  .resources-input{
    width: 217px;
    height: 38px;
  }
  .inner-search-category-first{
    width:39%;
    float:left;
  }
  .w-auto.text-info-center{
    width:auto;
  }
  @media screen and (min-width: 3000px)  and (max-width: 3840px) {
    .inner-search-keyword {
      width: 15%;
      /*float: left;*/
    }
  }
  @media screen and (min-width: 3500px) and (max-width: 3840px){
    .res-align {
      display: grid;
      grid-template-columns: 11.5em auto;
    }
  }
  @media screen and (min-width: 3000px) and (max-width: 3499px){
    .res-align {
      display: grid;
      grid-template-columns: 346px auto;
    }
  }
   @media screen and (min-width: 2500px) and (max-width: 2999px){
    .res-align {
      display: grid;
      grid-template-columns: 300px auto;
    }
  }
  @media screen and (min-width: 2000px) and (max-width: 2499px){
    .res-align {
      display: grid;
      grid-template-columns: 269px auto;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1999px){
    .res-align{
      display: grid;
      grid-template-columns: 240px auto;
    }
  }
   @media screen and (min-width: 1200px) and (max-width: 1400px){
    .res-align{
      display: grid;
      grid-template-columns: 227px auto;
    }
  }
  
</style>