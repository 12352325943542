<template>
  <footer class="l-footer text-centr">
      <div  v-if="$route.path != '/search'">
        <acc-Email></acc-Email>
      </div >
    <div class="wrapper">
      <router-link
        class="logo-footer"
        to="/"
        >
      
        <img alt="Logo" src="../assets/acc-logo-footer.png" />
      </router-link>
      <div class="footer-content">
        <a href="/resources" class="text white-color bold-f-w font12">RESOURCES</a>
        <a href="/training/" class="text white-color bold-f-w font12">TRAINING</a>
        <a href="/legal-advice/" class="text white-color bold-f-w font12"><span style="margin-right:5px;">LEGAL</span> ADVICE</a>
        <a href="/contact" class="text white-color bold-f-w font12">CONTACT</a>
        <footer-menu></footer-menu>
      </div>
      <div class="social-icons">
       <a href="https://www.facebook.com/AustralianCopyrightCouncil/?ref=ts"> <img src="/static/css/images/acc/facebook.png"></a> 
        <a href="https://twitter.com/AusCopyright"><img src="/static/css/images/acc/twitter.png"></a> 
        <a href="https://www.linkedin.com/company/australian-copyright-council/"><img src="/static/css/images/acc/linkedin.png"></a> 
      </div>
      <p class="copyright white-color font12">&#169;2022 Australian Copyright Council | <router-link class="footer-links" to="/privacy"> Privacy Policy </router-link> | <router-link class="footer-links" to="/terms"> Terms and Conditions </router-link> | <router-link class="footer-links" to="/contact"> Contact Us</router-link></p>
    </div>
  </footer>
</template>

<script>
import AccEmailMenu from './AccEmailMenu';
  export default {
    name: "acc-footer",
      components: {
        'acc-Email' : AccEmailMenu,
      },
  };
</script>
