<template>
  <div class="vm-productview">
    <looping-rhombuses-spinner
      :animation-duration="2500"
      :rhombus-size="15"
      color="#febe10"
      v-if="show_spinner"
      class="loader-spinner"
    />
    <div class="st-container">
      <div v-if="!show_spinner" class="layout-set layout-set--4-6 item">
        <div class="">
          <!-- start book fiction -->
          <section id="fiction" class="fiction py-5">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-5 col-xl-5">
                <div class="-bg-white p-5 h-100">
                  <!-- Carousel -->
                  <div
                    id="demo-test"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <!-- Indicators/dots -->
                    <div class="carousel-indicators rounded-indicators"></div>

                    <div class="carousel-inner -bg-white">
                      <a
                        :title="book.isbn + '.jpg'"
                        rel="vm-additional-images"
                        class="carousel-item active"
                        :href="getCoverLinkMedium(book)"
                      >
                        <img
                          class="d-block m-auto -w-300"
                          :src="getCoverLinkMedium(book)"
                          :alt="book.isbn + '.jpg'"
                          @error="noCoverImage"
                        />
                      </a>

                      <!-- <div class="carousel-item active">
                        <img
                          src="la.jpg"
                          alt="Los Angeles"
                          class="d-block"
                          style="width: 100%"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          src="chicago.jpg"
                          alt="Chicago"
                          class="d-block"
                          style="width: 100%"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          src="ny.jpg"
                          alt="New York"
                          class="d-block"
                          style="width: 100%"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-7 col-xl-7">
                <div>
                  <p
                    class="s-h7 --text-dark"
                    v-if="book.bic1.marketing_main_description"
                  >
                    Books - {{ book.bic1.marketing_main_description }}
                  </p>
                </div>
                <div>
                  <h3 class="s-h3 mb-0 fw-bold --text-dark">
                    {{ book.book.nonsortprefix }} {{ book.book.title }}
                  </h3>
                </div>
                <div>
                  <p class="s-h4s mb-0 --text-dark lh-sm">
                    {{ book.book.subtitle }}
                  </p>
                </div>
                <div>
                  <p class="s-h5s --text-dark">by {{ book.book.author }}</p>
                </div>
                <div>
                  <p v-if="book.blurb.product_description" class="s-h7 --text-dark product_description" v-html="book.blurb.product_description"></p>
                  <p v-else class="s-h7 --text-dark cover_blurb" v-html="book.blurb.cover_blurb"></p>
                </div>
                <div class="mt-4">
                  <div
                    v-if="get_public_link"
                    class="d-flex align-items-center mb-3 mb-md-4"
                  >
                    <div class="me-3">
                      <img
                        src="/static/css/images/affirm/Group55.svg"
                        alt=""
                        class="p-3 rounded-circle -bg-light-gray -w-50 -h-50"
                      />
                    </div>
                    <div>
                      <a href="" class="text-dark fw-semibold">
                        <p
                          class="text-decoration-underline s-h7 mb-0 --text-dark"
                        >
                          Read an excerpt
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="buy-now">
                    <router-link
                      class="--text-dark s-h7 mb-0 text-dark fw-semibold"
                      :to="{ path : '/browse/books/bookseller/' + book.isbn }"
                    >Buy now</router-link>
                </div>
              </div>
            </div>

            <div class="row" v-if="cover_for_download_available(book)">
              <div class="col-12">
                <div class="d-flex align-items-baseline my-1">
                  <div class="me-1">
                    <img
                      src="/static/css/images/affirm/Group55.svg"
                      alt=""
                      height="7px"
                      class="rotate-90"
                    />
                  </div>
                  <div>
                    <a
                      class="text-dark fw-semibold link"
                      :href="getCoverLinkOriginal(book.isbn)"
                      :download="book.isbn + '.jpg'"
                      target="_blank"
                    >
                      <p class="s-h10 --text-dark mb-0">
                        Download high-res cover photo
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- end book fiction -->

          <section v-if="book.blurb.quotes">
            <div class="-bg-green book-multi-c">
              <!-- Carousel -->
              <div id="demo" class="carousel slide" data-bs-ride="carousel">
                <!-- The slideshow/carousel -->

                <div class="carousel-inner py-5">
                  <div class="carousel-item active py-2">
                    <div class="row m-h-300 --text-dark">
                      <div class="col-12 col-lg-5 h-100">
                        <div class="h-100 d-flex align-items-start ps-5">
                          <p class="s-h6s ps-5">
                            Praise for {{ book.book.title }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 col-lg-7">
                        <div class="h-100 pe-5 ms-5 ms-lg-0">
                          <div class="pe-5 ms-5 ms-lg-0">
                            <p class="s-h7" v-html="book.blurb.quotes"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                  </div>
                </div>
                <!-- <div class="">
                  <div class="count-slide">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <div class="">
                        <p class="s-h6">1 &nbsp;</p>
                      </div>
                      <div class="">
                        <p class="s-h4 fw-normal">/&nbsp;</p>
                      </div>
                      <div class="">
                        <p class="s-h6">4</p>
                      </div>
                    </div>
                  </div>
                  <div class="book-multi-c-pos">
                    <button
                      class="carousel-control-prev prev"
                      type="button"
                      data-bs-target="#demo"
                      data-bs-slide="prev"
                    >
                      <img
                        src="/static/css/images/affirm/img_418607.png"
                        alt="prev"
                        width="30px"
                        class="rotate-180"
                      />
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next next"
                      type="button"
                      data-bs-target="#demo"
                      data-bs-slide="next"
                    >
                      <img
                        src="/static/css/images/affirm/img_418607.png"
                        alt="next"
                        width="30px"
                      />
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div> -->
                <!-- Left and right controls/icons -->
              </div>
            </div>
          </section>
          <!-- start publication -->
          <section id="publication" class="publication my-5">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-5">
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="">
                    <p class="fw-semibold s-h8 --text-dark">
                      Publication details
                    </p>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">Package</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.package.description }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">AUD RRP</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        ${{ productPrice }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">ISBN</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">{{ book.isbn }}</p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">Extent</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.book.page_extent }} pages
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">Format.</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.book.height }} x {{ book.book.width }} mm
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">Category</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.bic1.marketing_main_description }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-25 s-h8 --text-dark">List</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.imprint.description }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center w-100"
                      v-if="book.book.pubdate"
                    >
                      <p class="mb-0 w-25 s-h8 --text-dark">Pub Date</p>
                      <p class="mb-0 w-75 s-h8 --text-dark">
                        {{ book.book.pubdate | formatDate }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-3 mb-md-4 mt-5"
                  ></div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-7 h-100">
                <div class="">
                  <p class="s-h6s --text-dark fw-semibold">
                    Enquiries
                    </p>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-75 s-h7 --text-dark">
                        <span>Sales enquiries:<a href="mailto:keiran.rogers@affirmpress.com.au" class="--text-dark link"> keiran.rogers@affirmpress.com.au</a> </span>
                      </p>
                    </div>
                    <div class="d-flex align-items-center w-100">
                      <p class="mb-0 w-75 s-h7 --text-dark">
                        <span>Media enquiries:<a href="mailto:publicity@affirmpress.com.au" class="--text-dark link"> publicity@affirmpress.com.au</a> </span>
                      </p>
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center mb-3 mb-md-4 mt-4">
                  <div class="me-3">
                    <router-link to="/login" class="text-dark fw-semibold">
                      <img
                        src="/static/css/images/affirm/Group55.svg"
                        alt="Group55"
                        class="p-3 rounded-circle -bg-light-gray -w-50 -h-50"
                      />
                    </router-link>
                  </div>
                  <div>
                    <router-link to="/login" class="text-dark fw-semibold">
                      <p
                        class="text-decoration-underline --text-dark s-h7 mb-0"
                      >
                        Bookseller login
                      </p>
                    </router-link>
                  </div>
                </div> -->
              </div>
            </div>
          </section>
          <section id="you-iframe" class="mb-3">
            <div class="book-multi-c" v-if="show_iframe">
              <!-- you tube iframe -->
              <div class="iframe-div">
                <iframe :src="you_tube_id" frameborder="0" allowfullscreen
                  class="iframe"></iframe>
              </div>
              <!-- end you tube iframe -->
            </div>
          </section>
          <!-- end publication-->
          <affirm-book-author v-bind="{ isbn: book.isbn }"></affirm-book-author>
          <!-- start movie-->
          <section id="movie" class="movie my-5">
            <div>
              <affirm-author-book-list
                v-bind="{ author_name: book.book.author,book_isbn:book.isbn }"
              ></affirm-author-book-list>
            </div>
          </section>
          <!-- end movie-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import AddToCart from "../components/AddToCart";
import AffirmAuthorBookList from "../components/AffirmAuthorBookList";
import AffirmBookAuthor from "../components/AffirmBookAuthor";
import Currency from "../mixins/Currency";
import VueGallery from "vue-gallery";
import Vue from "vue";
Vue.config.productionTip = false;
Vue.filter("formatDate", function (value) {
  if (value) {
    return new Date(value).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }
          )
  }
});
export default {
  name: "browse-book",
  components: {
    LoopingRhombusesSpinner,
    AddToCart,
    "affirm-author-book-list": AffirmAuthorBookList,
    "affirm-book-author": AffirmBookAuthor,
    VueGallery,
  },
  mixins: [Currency],
  data() {
    return {
      // textLength: false,
      book: {
        isbn: 0,
        title: "-",
        awards: [],
        book: {
          title: "-",
        },
        blurb: {
          keywords: null,
        },
        author: "-",
        retail_price_aus: 0.0,
        package_type: "-",
      },
      show_spinner: true,
      index: null,
      filename: null,
      show_iframe: false,
      you_tube_id: null,
    };
  },
  methods: {
    // shortText() {
    //   if (this.textLength) {
    //     let newText = this.book.blurb.cover_blurb.substring(0);
    //     return newText;
    //   } else {
    //     /*let newText = this.lorem.substring(0,50) + `...`;*/
    //     let newText = this.book.blurb.cover_blurb.substring(0, 300);
    //     return newText;
    //   }
    // },
    noCoverImage(event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
    cover_for_download_available(book) {
      if (book.book.coverurl == "y" || book.book.coverurl == "Y") {
        return true;
      }
      return false;
    },
    getCoverLinkMedium(book) {
      if (book.book.coverurl == "y" || book.book.coverurl == "Y"){
        return `https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/${book.isbn}.jpg`;
      }
      return "/static/css/images/affirm/No_Image_Available.jpg";
    },
    getCoverLinkOriginal(isbn) {
      return `https://s3-ap-southeast-2.amazonaws.com/dimoaffirm/covers/${isbn}.jpg`;
    },
  },
  metaInfo() {
    // TODO add title here
    return {
      meta: [{ name: "keywords", content: this.book.blurb.keywords }],
    };
  },
  computed: {
    productPrice() {
      let region = this.$store.state.site_data.region;
      return this.book.book[`retail_price_${region}`];
    },
    get_public_link() {
      for (let i = 0; i < this.book.links.length; i++) {
        let link = this.book.links[i];
        if (link.type == "asset") {
          return true;
        }
      }
    },
    get_pub_date() {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var dt = this.book.book.pubdate;
      const dateObj = new Date(dt);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      const date = dateObj.getDate().toString().padStart(2, "0");
      const pub_date = `${date} ${monthNames[month]} ${year}`;
      return pub_date;
    },
  },

  beforeCreate() {
    let self = this;
    let url = this.$route.params.url;
    let isbn = url.split("-");
    isbn = isbn[isbn.length - 1];

    this.$route.meta.breadcrub = [
      { name: "Home", link: "home" },
      { name: "Search", link: "browse" },
    ];
    axios
      .get(`/get_book_data/${isbn}/`)
      .then(function (data) {
        self.book = data.data;
        self.show_spinner = false;
        for (let i = 0; i < self.book.links.length; i++) {
          let link = self.book.links[i];
          if (link.source == "video_trailer" || link.link_only == 1) {
            self.you_tube_id = `https://www.youtube.com/embed/${link.filename}?autoplay=1&mute=1">`;
            self.show_iframe = true;
          }
        }
        document.title = `${self.book.book.title} - ${self.book.book.author} -${self.book.book.series}- ${self.book.il3_desc} - ${self.book.book.isbn} - ${self.$store.state.site_data.title}`;
        // self.metaInfo.meta.push({name: 'keywords', content: self.book.blurb.keywords});
        self.$route.meta.breadcrub.push({
          name: self.book.book.nonsortprefix + " " + self.book.book.title,
        });
      })
      .catch(function (err) {
        console.log(err);
        window.location = "/404/";
      });
    // invoke the title of the book to the breadcrub
    axios
      .get("/get_book_breadcrub/", {
        params: {
          isbn: isbn,
        },
      })
      .then(function (data) {
        let breadcrub_data = data.data.data;
        if(breadcrub_data!=undefined){
            for (let i = 0; i < breadcrub_data.length; i++) {
            const element = breadcrub_data[i];

            self.$route.meta.breadcrub.push({
              name: element.title,
              path: "/search?bic=" + element.code,
            });
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
<style>
.latest-in-news .owl-carousel .owl-stage{
  max-height: fit-content;
}
.buy-now{
  margin-top: 115px;
}
.iframe{
  width:853px;
  height:505px;
}
.iframe-div{
  position:relative;
  padding-top:56.25%;
}
.iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.iframe{
  width:853px;
  height:505px;
}
</style>
