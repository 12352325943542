<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="30"
        color="#febe10"
        v-if="!screen_loaded"
        class="loader-spinner"
      />
      <div class="item-page" v-if="screen_loaded">
        <h1>Subscribe to our newsletters</h1>
        <div
          class="news-page"
          style="min-height: calc(100vh - 15rem); padding-bottom: 10rem; padding-top: 5rem; background: #EEEEEE;"
        >
          <div class="l-center-container">
            <div class="sc-kAzzGY dLkilY">
              <img
                alt="Logo"
                src="https://img.createsend1.com/static/hsf/d/2A/D73/FFF/C67FD2F38AC4859C/headerImages/200212172556/AU-5_clear.png"
                class="sc-kGXeez iKxzjo"
              />
            </div>
            <div>
              <div class="sc-bdVaJa iIDDUy">
                <div>
                  <div>
                    <p
                      size="16px"
                      color="#434d5d"
                      class="sc-eNQAEJ fBOZaM"
                    >Sign up to receive newsletters from Allen &amp; Unwin to hear the latest news about books and authors tailored to your areas of interest, plus your opportunity to win giveaways!</p>
                    <div spacing="base" class="sc-dxgOiQ bguLUo"></div>
                  </div>
                  <form
                    class="js-cm-form"
                    id="ff_form1"
                    enctype="multipart/form-data"
                    accept-charset="utf-8"
                    @submit.prevent="submit('/subscribe_to_newsletters/')"
                    data-id
                  >
                    <input type="hidden" name="list_id" :value="list_id" />
                    <input type="hidden" name='campaign_id' :value="campaign_id" />
                    <div size="base" class="sc-jzJRlG bMslyb">
                      <div size="small" class="sc-jzJRlG liOVdz">
                        <div>
                          <label size="0.875rem" color="#5d5d65" class="sc-gzVnrw dEVaGV">
                            Name
                            <span class="sc-dnqmqq iFTUZ">*</span>
                          </label>
                          <input
                            aria-label="Name"
                            id="fieldName"
                            maxlength="200"
                            name="name"
                            required
                            class="sc-iwsKbI iMsgpL"
                          />
                        </div>
                      </div>
                      <div size="small" class="sc-jzJRlG liOVdz">
                        <div>
                          <label size="0.875rem" color="#5d5d65" class="sc-gzVnrw dEVaGV">
                            Email
                            <span class="sc-dnqmqq iFTUZ">*</span>
                          </label>
                          <input
                            autocomplete="Email"
                            aria-label="Email"
                            id="fieldEmail"
                            maxlength="200"
                            name="email"
                            v-model="newsletterEmail"
                            required
                            type="email"
                            class="js-cm-email-input sc-iwsKbI iMsgpL"
                          />
                        </div>
                      </div>
                      <div
                        size="small"
                        class="sc-jzJRlG liOVdz"
                        v-for="field in get_custom_fields_data"
                      >
                        <div>
                          <fieldset>
                            <label
                              size="0.875rem"
                              color="#5d5d65"
                              class="sc-gzVnrw dEVaGV"
                            >{{ field.FieldName }}</label>
                            <div v-for="option in field.FieldOptions">
                              <div class="sc-bwzfXH ebeRtN">
                                <input
                                  id="159592"
                                  :name="'fieldset_'+field.Key"
                                  type="checkbox"
                                  :value="option"
                                  class="sc-bZQynM eKOoKL"
                                />
                                <label
                                  size="0.875rem"
                                  color="#434d5d"
                                  for="159592"
                                  class="sc-bxivhb iHsWXX"
                                >{{option}}</label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div size="base" class="sc-jzJRlG bMslyb">
                        <div>
                          <div spacing="x-small" class="sc-dxgOiQ urQia"></div>
                          <div size="base" class="sc-jzJRlG bMslyb">
                            <div>
                              <div>
                                <div class="sc-bwzfXH ebeRtN">
                                  <input
                                    aria-required
                                    id="cm-privacy-consent"
                                    name="cm-privacy-consent"
                                    required
                                    type="checkbox"
                                    class="sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    size="0.875rem"
                                    color="#434d5d"
                                    for="cm-privacy-consent"
                                    class="sc-bxivhb iHsWXX"
                                  >I agree to receive email communication from Allen &amp; Unwin, and have read and agree to their Privacy Policy.</label>
                                  <div class="sc-EHOje jMsntP">*</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="sc-bwzfXH ebeRtN">
                                  <input
                                    id="cm-privacy-email"
                                    name="over_13"
                                    type="checkbox"
                                    class="sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    size="0.875rem"
                                    color="#434d5d"
                                    for="cm-privacy-email"
                                    class="sc-bxivhb iHsWXX"
                                  >I am over the age of 13</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p>
                            <router-link
                              size="16px"
                              color="#19a9e5"
                              to="/privacy-policy"
                              rel="noopener"
                              target="_blank"
                              class="sc-kpOJdX gHlLJa"
                            >Privacy policy</router-link>
                          </p>
                          <p>
                            <router-link
                              size="16px"
                              color="#19a9e5"
                              to="/terms-conditions"
                              rel="noopener"
                              target="_blank"
                              class="sc-kpOJdX gHlLJa"
                            >Terms of Use</router-link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="status != 'submitted'">
                      <vue-recaptcha
                        ref="recaptcha"
                        sitekey="6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"
                        @verify="onCaptchaVerified"
                      ></vue-recaptcha>
                      <button
                        type="submit"
                        id="bfSubmitButton"
                        size="1rem"
                        color="#fff"
                        class="js-cm-submit-button sc-jKJlTe jHkwuK"
                        :disabled="status==='submitting' || !captchaComplete"
                      >
                        <span>Subscribe</span>
                      </button>
                    </div>
                    <h3
                      v-if="status === 'submitted'"
                      class="success-message"
                    >Thank you for your subscribing to our newsletters</h3>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newsLetterMixin from "../../mixins/Newsletter";
import formMixin from "../../mixins/Form";
import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  name: "newsletter",
  mixins: [newsLetterMixin, formMixin],
  components: { LoopingRhombusesSpinner },
  data() {
    return {};
  }
};
</script>

<style>