<template>
  <footer class="py-5" style="background: #89988d">
    <div class="st-container">
      <div class="row">
        <div class="col-12">
          <div>
            <h5 class="fw-bolder s-h6 --text-dark">Join our mailing list</h5>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <div class="position-relative form-floating">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control ps-1 mb-0 s-h5"
                id="floatingInput"
                placeholder="name"
                v-model="subscribe_name"
              />
              <label for="floatingInput" class="ps-0 py-2 --text-dark"
                >Name</label
              >
            </div>
            <span class="error" v-if="name_error">{{name_error}}</span>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="position-relative">
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control ps-1 mb-0 s-h5"
                id="floatingInput"
                placeholder="Email@example.com"
                v-model="subscribe_email"
              />
              <label for="floatingInput" class="ps-0 py-2 --text-dark"
                >Email</label
              >
            </div>
            <span class="error" v-if="email_error">{{email_error}}</span>
            <span class="success" v-if="email_success">{{email_success}}</span>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="d-flex align-items-center h-100">
            <div class='cursor-pointer d-flex align-items-center'  @click='subscribe_mailchimp'>
              <div class="pe-2">
                <img
                  src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                  width="24px"
                />
              </div>
              <p class="mb-0 s-h6 ms-1 --text-dark">Sign up</p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-md-flex align-items-start mt-4 pb-md-5">
        <div class="">
          <div class="d-flex align-items-center pe-4">
            <div class="pe-2">
              <div class="form-group-c w-auto mb-0">
                <input type="checkbox" id="html" v-model="bookseller_checkbox"/>
                <label for="html"></label>
              </div>
            </div>
            <p class="mb-0 s-h8 --text-dark">Receive bookseller information</p>
          </div>
          <span class="error d-block mt-2" v-if="bookseller_error">{{bookseller_error}}</span>
        </div>
        <div class="">
          <div class="d-flex align-items-center mt-2 mt-md-0">
            <div class="pe-2">
              <div class="form-group-c w-auto mb-0">
                <input type="checkbox" id="css" v-model="newsletter_checkbox"/>
                <label for="css"></label>
              </div>
            </div>
            <p class="mb-0 s-h8 --text-dark">Receive our newsletter</p>
          </div>
          <span class="error d-block mt-2" v-if="newsletter_error">{{newsletter_error}}</span>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-md-4 s-h7">
          <div class="d-flex align-items-center mb-3 mb-md-5">
            <router-link to="/contact-us" class="--text-dark">
              <div class="me-2">
                <span
                  class="d-flex align-items-center justify-content-center rounded-circle --theme-bg-opcity -w-48 -h-48"
                  ><img
                    src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                    width="16px"
                /></span>
              </div>
            </router-link>
            <h5 class="mb-0 fw-semibold s-h7 --text-dark">Contact us</h5>
          </div>
          <div>
            <p class="s-h7 --text-dark">
              28 Thistlethwaite St<br />
              South Melbourne VIC 3205
            </p>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="d-md-flex align-items-end h-100">
            <p class="s-h7 --text-dark">
              Email: info@affirmpress.com.au <br />Ph: (03) 8695 9623
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-md-8 s-h9">
          <div class="">
            <h5 class="fw-bold s-h9 --text-dark">
              Affirm Press is located on Boon Wurrung Country
            </h5>
          </div>
          <div>
            <p class="s-h9 --text-dark">
              We acknowledge the Traditional Owners, the Wurundjeri and Boon
              Wurrung people of the Kulin Nation, on whose land we meet, share
              and work. We pay our respects to Elders past and present and
              extend our respect to Aboriginal and Torres Strait Islander people
              from all nations of this land.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-4 text-md-end">
          <div
            class="d-flex align-items-center justify-content-md-end h-100 social-icon"
          >
            <!-- twitter -->
            <div class="pe-3">
              <a
                href="https://twitter.com/affirmpress"
                target="_blank"
                class="fa fa-twitter --text-dark d-flex align-items-center justify-content-center text-decoration-none rounded-circle"
              ></a>
            </div>
            <!-- instagram -->
            <div class="pe-3">
              <a
                href="https://www.instagram.com/affirmpress/"
                target="_blank"
                class="fa fa-instagram --text-dark d-flex align-items-center justify-content-center text-decoration-none rounded-circle"
              ></a>
            </div>
            <!-- facebook -->
            <div class="">
              <a
                href="https://www.facebook.com/affirmpress"
                target="_blank"
                class="fa fa-facebook --text-dark d-flex align-items-center justify-content-center text-decoration-none rounded-circle"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-sm-4">
          <div class="d-flex align-items-center h-100">
            <router-link to="/" class="logo">
              <img
                alt="Logo"
                src="/static/affirm-logo.svg"
              />
            </router-link>
          </div>
        </div>
        <div class="col-12 col-sm-8 text-md-end py-4 ps-2">
          <div
            class="d-flex align-items-center justify-content-md-end h-100 s-h9"
          >
            <div class="d-inline pe-5">
              <p class="mb-0 --text-dark">Copyright © Affirm Press</p>
            </div>
            <div class="d-inline">
              <router-link class="--text-dark" to="/privacy-policy/"
                ><p class="mb-0 --text-dark">View privacy</p></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import layoutMixin from "../mixins/Layout";
import FooterMenu from "../components/FooterMenu";
import axios from "axios";

export default {
  name: "affirm-footer",
  mixins: [layoutMixin],
  components: { FooterMenu },
  data() {
    return {
      'subscribe_name': '',
      'subscribe_email': '',
      'email_error': null,
      'name_error': null,
      'email_success': null,
      'bookseller_checkbox': null,
      'newsletter_checkbox': null,
      'bookseller_error': null,
      'newsletter_error': null
    };
  },
  methods: {
    subscribe_mailchimp() {
      let self = this;
      let email = self.subscribe_email;
      let name = self.subscribe_name;

      let bookseller_checkbox = self.bookseller_checkbox;
      let newsletter_checkbox = self.newsletter_checkbox;

      self.name_error = null;
      self.email_error = null;
      self.email_success = null;
      self.bookseller_error = null;
      self.newsletter_error = null;

      if (name == null || name.trim() == ''){
        self.name_error = 'Name is required';
        return false;
      }
      if (email == null || email.trim() == ''){
        self.email_error = 'Email is required';
        return false;
      }
      if (bookseller_checkbox == null){
        self.bookseller_error = "This field is required";
        return false;
      }
      if (newsletter_checkbox == null){
        self.newsletter_error = "This field is required";
        return false;
      }

      let params = {
            'email': email,
            'name': name,
            'bookseller_checkbox': bookseller_checkbox,
            'newsletter_checkbox': newsletter_checkbox
          }
      axios
          .get("/subscribe_mailchimp/", {
            params: params
          })
          .then((res) => {
            self.page_loaded = true;
            if(res.data.status == 200){
              self.email_success = 'Successfully Submitted.'
            }
            else{
              let error = res.data.msg
              if (error.title == 'Member Exists'){
                  self.email_error = 'This email is already a list member.';
              }
              else{
                self.email_error = error.detail;
              }
            }
          });
    }


  },
  mounted() {
    let host = this.$store.state.site_data.host;
    if (host == "affirm") {
      let remode_class = document.getElementById("app");
      remode_class.classList.remove("st-container");
    }
  },
};
</script>

<style></style>
