<template>
  <div>
    <section id="author" class="py-5 -bg-brown" v-if="authors.length != 0">
      <div class="st-container">
        <div class="row pb-5">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <div
              class="d-flex justify-content-center align-items-center h-395 w-395 mx-auto overflow-hidden rounded-circle author_box_border"
            >
              <img
                v-if="authors.author_image"
                v-bind:src="authors.author_image"
                alt="1"
                width="100%"
                class="gray-hover"
                @error="noCoverImage"
              />
              <img
                v-else
                v-bind:src="noCoverImage"
                alt="1"
                width="100%"
                class="gray-hover"
                @error="noCoverImage"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-8">
            <div class="d-flex align-items-center h-100">
              <div class="p-4 mt-3">
                <div class="">
                  <p class="s-h7 mb-2 --text-dark">Authors</p>
                  <h2 class="s-h2 mb-4 mb-lg-3 --text-dark">
                    {{ authors.firstname }} {{ authors.lastname }}
                  </h2>
                </div>
                <div>
                  <p
                    class="s-h7 mb-2 --text-dark"
                    v-html="authors.author_bio"
                  ></p>
                </div>
                <div class="d-flex align-items-center">
                  <a
                    v-if="authors.twitter"
                    v-bind:href="authors.twitter"
                    target="_blank"
                    class="text-decoration-none me-4 rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown link-dark"
                  >
                    <i class="fa fa-twitter"></i>
                  </a>
                  <a
                    v-if="authors.instagram"
                    v-bind:href="authors.instagram"
                    target="_blank"
                    class="text-decoration-none me-4 rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown link-dark"
                  >
                    <i class="fa fa-instagram"></i>
                  </a>
                  <a
                    v-if="authors.facebook"
                    v-bind:href="authors.facebook"
                    target="_blank"
                    class="text-decoration-none rounded-circle -w-40 -h-40 d-flex align-items-center justify-content-center -bg-light-brown link-dark"
                  >
                    <i class="fa fa-facebook-f"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="st-container">
      <affirm-author-book-list
        v-if="authors.firstname"
        v-bind="{ author_name: author_name(), author_id: author_id()}"
      ></affirm-author-book-list>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import carousel from "vue-owl-carousel";
import AffirmAuthorBookList from "../components/AffirmAuthorBookList";
export default {
  name: "affirm-authors-details",
  components: {
    LoopingRhombusesSpinner,
    carousel,
    "affirm-author-book-list": AffirmAuthorBookList,
  },
  mixins: [Book],
  data() {
    return {
      authors: [],
    };
  },
  methods: {
    author_name() {
      return `${this.authors.firstname} ${this.authors.lastname}`;
    },
    author_id() {
      return this.$route.path.split("/")[2];
    },
    noCoverImage: function (event) {
      event.target.src =
        "/static/css/images/affirm/author_photo_coming_soon.jpg";
    },
  },
  computed: {},
  mounted() {
    let self = this;
    let author_id = self.$route.path.split("/")[2];
    axios
      .get(`/get_author_data/?author_id=${author_id}`)
      .then(function (data) {
        self.authors = data.data.authors[0];
      })
      .catch(function (err) {
        console.log(err);
      });
    let add_new_class = document.getElementById("affirm-header");
    let remode_class = document.getElementById("for-affirm");
    add_new_class.classList.add("-bg-dark-pink");
    remode_class.classList.remove("container");
  },
};
</script>
