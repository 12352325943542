var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register -bg-green py-5",attrs:{"id":"register"}},[_c('div',{staticClass:"--text-dark h-100 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"st-container"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center --text-dark"},[_c('div',[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-center"},[_c('form',{},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"pt-4 text-center"},[_c('p',{staticClass:"s-h6 --text-dark"},[_vm._v(" Already have an account?"),_c('a',{staticClass:"--text-dark link text-decoration-underline",attrs:{"href":"#"}},[_c('router-link',{staticClass:"--text-dark link text-decoration-underline",attrs:{"to":"/login"}},[_vm._v("Login here.")])],1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-3 mb-lg-5"},[_c('h1',{staticClass:"s-h2 --text-dark"},[_vm._v("Register")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"First Name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"Last name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"Business"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"ADS ID"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 w-388"},[_c('input',{staticClass:"form-control form-control-lg rounded-0 -h-50",attrs:{"type":"text","placeholder":"Confirm Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"exampleCheck1"}}),_c('label',{staticClass:"form-check-label s-h8 --text-dark",attrs:{"for":"exampleCheck1"}},[_vm._v("Accept "),_c('a',{staticClass:"--text-dark link text-decoration-underline",attrs:{"href":"#"}},[_vm._v("Terms & Conditions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"exampleCheck1"}}),_c('label',{staticClass:"form-check-label s-h8 --text-dark",attrs:{"for":"exampleCheck1"}},[_vm._v("Receive bookseller information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn --bg-dark text-white rounded-0 d-flex justify-content-center align-items-center -h-50 w-100 mt-5 register-btn"},[_c('img',{staticClass:"me-3 text-white",attrs:{"src":"/static/css/images/affirm/light-arrow2-svg.png","alt":"arrow-right-icon","width":"25px"}}),_c('p',{staticClass:"s-h6 mb-0 me-4 --text-white"},[_vm._v("Register")])])
}]

export { render, staticRenderFns }