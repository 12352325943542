<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <form id="la-form" class="" method="POST" action="" enctype="multipart/form-data" v-on:submit.prevent="SubmitForm">
      <div class="mobileFullWidthWithMargin">
        <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line">
      </div>
      <br>
      <div class="form-group">
        <label for="prefix">Prefix</label>
        <div class="input-outer">
          <select v-model="user.prefix"  class="search-input prefix-input resources-input-profile">
            <option value="attorney">Atty</option>
            <option value="doctor">Dr</option>
            <option value="mister">Mr</option>
            <option value="married_woman">Mrs</option>
            <option value="single_or_married_woman">Ms</option>
            <option value="professor">Prof</option>
          </select>
          <span class="error-span">{{ profile_errors.prefix }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="first_name">First Name</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.first_name"
            placeholder="First Name"
            id="first_name"
          />
          <span class="error-span">{{ profile_errors.first_name }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="last_name">Last Name</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.last_name"
            placeholder="Last Name"
            id="last_name"
          />
          <span class="error-span">{{ profile_errors.last_name }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="company">Organization</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.company"
            placeholder="Enter an Organization"
            id="company"
          />
          <span class="error-span">{{ profile_errors.company }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.email"
            placeholder="Email"
            id="email"
          />
          <span class="error-span">{{ profile_errors.email }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="phone">Phone</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.phone"
            placeholder="Phone"
            id="phone"
          />
          <span class="error-span">{{ profile_errors.phone }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="country">Country</label>
        <div class="input-outer">
          <select v-model="user.country"  class="search-input country-input resources-input-profile"><option value="Australia">Australia</option></select>
          <span class="error-span">{{ profile_errors.country }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="address">Address</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.address"
            placeholder="Address"
            id="address"
          />
          <span class="error-span">{{ profile_errors.address }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="city">City</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.city"
            placeholder="City"
            id="city"
          />
          <span class="error-span">{{ profile_errors.city }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="state">State</label>
        <div class="input-outer">
          <select v-model="user.state"  class="search-input state-input resources-input-profile">
            <option value="none"></option>
            <option value="australian_capital_territory">Australian Capital Territory</option>
            <option value="new_south_wales">New South Wales</option>
            <option value="northern_territory">Northern Territory</option>
            <option value="queensland">Queensland</option>
            <option value="south_australia">South Australia</option>
            <option value="tasmania">Tasmania</option>
            <option value="victoria">Victoria</option>
            <option value="western_australia">Western Australia</option>
          </select>
          <span class="error-span">{{ profile_errors.state }}</span>
        </div>
      </div>

      <div class="form-group">
        <label for="Postal Code">Postal Code</label>
        <div class="input-outer">
          <input
            class="form-control"
            type="text"
            v-model="user.postal_code"
            placeholder="Postal Code"
            id="postal_code"
          />
          <span class="error-span">{{ profile_errors.postal_code }}</span>
        </div>
      </div>

      <div class="form-group">
        <div class="save-btn">
          <button class="btn btn-success submit-btn button orng-wte-c bold-f-w" v-on:click="changeProfile">Save</button>
        </div>
      </div>

      <div class="password-section">
        <div class="input-outer">
          
        </div>

        <div class="form-group">
          <label for="password">Old Password</label>
          <div class="input-outer">
            <input
              class="form-control"
              type="password"
              v-model="user.old_password"
              placeholder="Old Password"
              id="old_password"
            />
            <span v-if="password_errors.old_password.length<40" class="error-span">{{ password_errors.old_password }}</span>
            <span v-else class="error-span-old-pwd">{{ password_errors.old_password }}</span>
          </div>
        </div><br>

        <div class="form-group">
          <label for="password">Password</label>
          <div class="input-outer">
            <input
              class="form-control"
              type="password"
              v-model="user.password"
              placeholder="Password"
              id="password"
            />
            <span class="error-span">{{ password_errors.new_password1 }}</span>
          </div>
        </div>

        <div class="form-group">
          <label for="Confirm Password">Confirm Password</label>
          <div class="input-outer">
            <input
              class="form-control"
              type="password"
              v-model="user.confirm_password"
              placeholder="Confirm Password"
              id="confirm_password"
            />
            <span class="error-span">{{ password_errors.new_password2 }}</span>
            <span class="success-span">{{ password_success_message }}</span>
          </div>
        </div>

        <div class="form-group">
          <div class="save-password-btn">
            <button class="btn btn-success submit-btn button orng-wte-c bold-f-w" v-on:click="changePassword">Save Password</button>
          </div>
        </div>
      </div>
      <br>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect"

export default {
  components: { Multiselect },
  data() {
    return {
      user: {
        prefix:'',
        first_name: '',
        last_name:'',
        company: '',
        email:'',
        phone:'',
        country:'',
        address:'',
        city:'',
        state:'',
        postal_code:'',
        old_password:'',
        password:'',
        confirm_password:'',
      },
      password_success_message: '',
      password_errors: {
        details: '',
        old_password: '',
        new_password1: '',
        new_password2: '',
      },
      profile_errors: {
        prefix: '',
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        phone: '',
        country: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
      }
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let self = this

    axios
      .get('/accounts/profile/')
      .then(function (response) {
        self.user = response.data
      })
  },
  methods: {
    SubmitForm() {
      console.log('Submiit button')
    },
    changeProfile() {
      let self = this
      let formData = new FormData()
      formData.append('first_name', self.user.first_name)
      formData.append('last_name', self.user.last_name)
      formData.append('company', self.user.company)
      formData.append('email', self.user.email)
      formData.append('phone', self.user.phone)
      formData.append('address', self.user.address)
      formData.append('city', self.user.city)
      formData.append('postal_code', self.user.postal_code)
      formData.append('password', self.user.password)
      formData.append('confirm_password', self.user.confirm_password)
      formData.append('prefix', self.user.prefix)
      formData.append('country', self.user.country)
      formData.append('state', self.user.state)

      axios
        .post(`/accounts/profile/`,
          formData,
        )
        .then(function (response) {
          self.user = response.data
        })
        .catch(function (err) {
          const errors = err.response.data

          // for(var key in errors){
          //   self.password_errors[`${key}`] = errors[`${key}`][0]
          // }
        })
    },
    changePassword() {
      let self = this
      let changePasswordForm = new FormData()
      changePasswordForm.append('old_password', self.user.old_password)
      changePasswordForm.append('new_password1', self.user.password)
      changePasswordForm.append('new_password2', self.user.confirm_password)

      console.log('111-111-111')
      console.log(changePasswordForm)
      console.log('111-111-111')

      axios
        .post(`/dj-rest-auth/password/change/`,
          changePasswordForm,
        )
        .then(function (response) {
          console.log(response.data)
          self.password_success_message = response.data.detail
        })
        .catch(function (err) {
          const errors = err.response.data
          self.password_errors = {}

          for(var key in errors){
            self.password_errors[`${key}`] = errors[`${key}`][0]
          }

          console.log('222-222-222')
          console.log(errors)
          console.log('-----------')
          console.log(self.password_errors)
          console.log('222-222-222')
        })
    },
  },
};
</script>

<style>
  .save-btn, .save-password-btn{
    width:100%;
  }
  .password-section {
    margin-top: 59px;
  }
  input[type=text], input[type=password], input[type=email], textarea, .selecter .selecter-selected {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  border: 3px solid #e1e1e1;
  background: #fff;
  padding: 0.750em 1.250em;
  font-family: 'Arial';
  font-size: 1.143em;
  line-height: 1.438em;
  color: #8c9295;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">