<template>
  <div class="moduletable" :class="extra_classes" v-if="showCart">
    <!-- Virtuemart 2 Ajax Card -->
    <div class="vmCartModule" id="vmCartModule">
<!--       <router-link class="total_products" to="/cart">
        <strong>My Cart</strong>
        <span> {{cartProductNum}}</span> item/s
      </router-link> -->
      <div class="show_cart" rel="nofollow" v-if="cartProductNum > 0">
        <router-link to="/cart" class="carts_item"><img src="../assets/cart-logo.png"  class="cart-img"><span>{{cartProductNum}}</span></router-link>
      </div>
      <div style="clear:both;"></div>
    </div>
  </div>
</template>

<script>
import Cart from "../mixins/Cart";

export default {
  name: "cart",
  mixins: [Cart],
  data() {
    return {};
  },
  props: ["extra_classes", "show_extra_link"],
  computed: {
    showCart() {
      return this.$store.state.site_data.ecommerce;
    },
  },
  created() {
    let self = this;
    self.$store.dispatch("checkCartStatus").then(() => {
      console.log("cart updated");
    });
  },
  mounted() {},
};
</script>

<style>

.carts_item{
    position: relative;
}

.carts_item span {
    background: #ff793c;
    border-radius: 50%;
    color: white;
    position: absolute;
    width: 22px;
    height: 21px;
    top: -2px;
    display: flex;
    right: -2px;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
}
</style>