<template>
  <header class="l-header mobileFullWidthWithPadding">
    <div class="wrapper">
      <router-link to="/" class="logo">
        <img alt="Logo" :src="logo" />
      </router-link>
      <div class="l-header__top mobileHide">
        <cart :show_extra_link='true'></cart>
      </div>
      <div class="l-header__top-search mobileHide" id='search-bar'>
        <search-bar></search-bar>
      </div>
      <div class="clear"></div>
      <!--  <div class="custom social-icons mobileFullWidthWithPadding mobileHide social-center top-icon" >
            <p>
              <a class="icon-f" href="https://www.facebook.com/MurdochBooks" target="_blank"><span>Facebook</span>
              </a>
              <a class="icon-t" href="https://twitter.com/murdochbooks" target="_blank">
                <span>Twitter</span>
              </a>
              <a class="icon-y" href="http://www.youtube.com/user/MurdochBooks" target="_blank">
              <span>Youtube</span>
              </a>
              <a class="icon-g" href="https://plus.google.com/112084429770754843718/about" target="_blank">
                <span>Google plus</span>
              </a> 
              <a class="icon-i" href="http://instagram.com/murdochbooks" target="_blank">
                <span>Instegram</span>
              </a> 
              <a class="icon-p" href="http://www.pinterest.com/murdochbooks/" target="_blank"><span>Pinterest</span>
              </a>
            </p>
        </div> -->

      <div class="l-header__navigation mobileHide">
        <div class="moduletable main-menu">
          <header-menu></header-menu>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </header>
</template>

<script>
import layoutMixin from "../mixins/Layout";
import SearchBar from "../components/SearchBar";
import HeaderMenu from "../components/HeaderMenu";
import Cart from '../components/Cart'

export default {
  name: "au-header",
  mixins: [layoutMixin],
  components: {
    SearchBar,
    HeaderMenu,
    Cart
  },
  data() {
    return {
      allenandunwin: process.env.VUE_APP_ALLENANDUNWIN,
      allenandunwinnz: process.env.VUE_APP_ALLENANDUNWINNZ,
      dropDownClass: "closed"
    };
  },
  methods: {
    toggleDomainDropDown() {
      this.dropDownClass = this.dropDownClass == "closed" ? "open" : "closed";
    },   
  },
  computed: {
    resolveDomainMenu() {
      let domain = window.location.host;
      let data;
      if (domain.includes(this.allenandunwin)) {
        data = {
          domain: this.allenandunwinnz,
          name: "AUS",
          otherName: "NZ"
        };
      } else {
        data = {
          domain: this.allenandunwin,
          name: "NZ",
          otherName: "AUS"
        };
      }
      return data;
    },
  }
};
</script>

<style>
@media screen and (max-width: 767px) {
  .l-header .wrapper:after {
    right: -47px !important;
    height: 7px !important;
  }  
}
</style>