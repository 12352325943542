<template>
  <div class="top-padding">
    <div class="container m-auto">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="show_spinner"
        class="loader-spinner"
      />
      <div v-if="!show_spinner" class="l-page-block">
        <div class="l-page-block">
          <div class="d-flex">
            <div class="block-1 breadcrum-align block-center">
              <div class="section-left text-centr width100">
                <img
                  :src="getCoverLinkSmall(book.isbn)"
                  style="width: 229px"
                  @error="noCoverImage"
                />
              </div>
              <div class="product-code bold-f-w text-centr font10"></div>
              <div v-if="productPrice > 0" class="quantity_sec top-p d-flex">
                <div v-if="book.book.imprint != 'Fact sheets'">
                  <span class="left-text font10">QUANTITY</span>
                  <div class="d-flex justify-content-between">
                    <span class="less-val">
                      <img
                        src="/static/css/images/acc/minus.png"
                        class="icon_img"
                    /></span>
                    <span class="value-text">1</span>
                    <span class="add-more-val"
                      ><img
                        src="/static/css/images/acc/plus-blue.png"
                        class="icon_img"
                    /></span>
                  </div>
                </div>
              </div>
              <div v-if="productPrice > 0" class="right_btn width100">
                <!--  <a v-if="book.book.imprint!='Fact sheets'" class="add-button orng-wte-c bold-f-w font12">ADD TO CART NOW</a> -->
                <add-to-cart
                  :isbn="book.isbn"
                  :title="book.book.nonsortprefix + ' ' + book.book.title"
                  :book="book"
                  place="browse-book"
                ></add-to-cart>
              </div>

              <div
                v-if="get_other_links.length && productPrice <= 0"
                class="product-button width100"
              >
                <div v-for="link in get_other_links">
                  <a
                    v-if="link.source == 'factsheet'"
                    v-bind:href="link.url"
                    target="”_blank”"
                    onclick="_gaq.push(['_trackEvent','Download','PDF',this.href]);"
                    >Download Fact Sheet</a
                  >
                </div>
              </div>
            </div>
            <div class="block-2 width50-percent">
              <h3 class="product-name">{{ book.book.title }}</h3>
              <p
                class="font-medium-bold"
                v-html="book.blurb.key_note_blurb"
              ></p>
              <p class="font-medium" v-html="book.blurb.cover_blurb"></p>
              <br />

              <div class="comn_inline d-flex font12">
                <p class="title">Publish :</p>
                <p class="detail">{{ get_pub_date }}</p>
              </div>

              <div class="comn_inline d-flex font12">
                <p class="title">Author :</p>
                <p style="margin-left: 2px" class="detail">
                  {{ book.book.author }}
                </p>
              </div>
              <div class="comn_inline d-flex font12" style="margin-left: -20px">
                <p class="title last">ISBN :</p>
                <p style="margin-left: 12px" class="detail">{{ book.isbn }}</p>
              </div>
              <br />

              <div class="price">
                <h2 v-if="productPrice > 0" class="price">
                  ${{ productPrice }}
                </h2>
                <h2 v-else class="price">FREE</h2>
              </div>
              <hr class="dotted" />
              <div class="bottom_button d-flex"></div>
              <p class="product_discrpt font12"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="main-con">
        <div class="news-heading-1 text-centr width50-percent">
          <h2 class="comnn-tile">Related Resources</h2>
          <img src="/static/css/images/acc/heading_underline.png" />
          <p class="heading_sub font-wgt-900">
            We have many resources to help you with your copyright queries
          </p>
        </div>

        <div class="product-col d-flex" id="pro_page">
          <acc-bestseller></acc-bestseller>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import AddToCart from "../components/AddToCart";
import Currency from "../mixins/Currency";
import VueGallery from "vue-gallery";
import BestSellerList from "../components/BestSellerList";

export default {
  name: "acc-product-page",
  components: {
    LoopingRhombusesSpinner,
    AddToCart,
    VueGallery,
    "acc-bestseller": BestSellerList,
  },
  mixins: [Currency],
  data() {
    return {
      book: {
        isbn: 0,
        title: "-",
        awards: [],
        book: {
          title: "-",
        },
        blurb: {
          keywords: null,
        },
        author: "-",
        retail_price_aus: 0.0,
        package_type: "-",
      },
      cover_for_download_available: true,
      show_spinner: true,
      index: null,
      quantity: 1,
    };
  },
  methods: {
    noCoverImage(event) {
      event.target.src = "/static/images/acc/No_Image_Available.jpg";
      this.cover_for_download_available = false;
    },
    getCoverLinkSmall(isbn) {
      return `https://s3-ap-southeast-2.amazonaws.com/dimoacc/covers/${isbn}.jpg`;
    },
    getCoverLinkOriginal(isbn) {
      return `https://s3-ap-southeast-2.amazonaws.com/dimoacc/covers/${isbn}.jpg`;
    },
  },
  metaInfo() {
    // TODO add title here
    return {
      meta: [{ name: "keywords", content: this.book.blurb.keywords }],
    };
  },
  computed: {
    productPrice() {
      let region = this.$store.state.site_data.region;
      return this.book.book[`retail_price_${region}`];
    },
    age() {
      if (
        this.book.book.kids_readership_from &&
        this.book.book.kids_readership_to
      ) {
        return `${this.book.book.kids_readership_from} - ${this.book.book.kids_readership_to}`;
      }
      return null;
    },
    get_images() {
      let images_arr = [];
      for (let i = 0; i < this.book.links.length; i++) {
        const link = this.book.links[i];
        if (link.type == "image") {
          images_arr.push(link.url);
        }
      }
      return images_arr;
    },
    get_other_links() {
      let other_links = [];
      for (let i = 0; i < this.book.links.length; i++) {
        let link = this.book.links[i];
        if (link.type == "other") {
          link.source = link.source.replace(/_/g, " ");
          other_links.push(link);
        }
      }
      return other_links;
    },

    get_pub_date() {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      let dt = this.book.book.pubdate;
      const dateObj = new Date(dt);

      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      const date = dateObj.getDate().toString().padStart(2, "0");
      const pub_date = `${date} ${monthNames[month]} ${year}`;
      return pub_date;
    },
  },
  beforeCreate() {
    let self = this;
    let url = this.$route.params.url;
    let isbn = url.split("-");
    isbn = isbn[isbn.length - 1];

    this.$route.meta.breadcrub = [
      { name: "Home", link: "home" },
      { name: "Search", link: "browse" },
    ];
    axios
      .get(`/get_book_data/${isbn}/`)
      .then(function (data) {
        self.book = data.data;

        self.show_spinner = false;
        document.title = `${self.book.book.title} - ${self.book.book.author} - ${self.book.book.isbn} - ${self.$store.state.site_data.title}`;
        // self.metaInfo.meta.push({name: 'keywords', content: self.book.blurb.keywords});
        self.$route.meta.breadcrub.push({
          name: self.book.book.nonsortprefix + " " + self.book.book.title,
        });
      })
      .catch(function (err) {
        console.log(err);
        window.location = "/404/";
      });
    // invoke the title of the book to the breadcrub
    axios
      .get("/get_book_breadcrub/", {
        params: {
          isbn: isbn,
        },
      })
      .then(function (data) {
        let breadcrub_data = data.data.data;

        for (let i = 0; i < breadcrub_data.length; i++) {
          const element = breadcrub_data[i];

          self.$route.meta.breadcrub.push({
            name: element.title,
            path: "/search?bic=" + element.code,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
