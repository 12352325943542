<template>
  <search from="category" ref="search_comp" v-if="host != 'affirm'">
    <div id="vmss_search_form_component" class="vmss_search">
      <div class="vmss_search_form" style>
        <top-book-categorys></top-book-categorys>
        <h1>Search results</h1>
        <fieldset class="adminform">
          <div class="result-filters">
            <div
              class="itemwrap"
              id="inputdiv_general_SPHINX_WORD_NAME_IN_REQUEST"
            >
              <div class="searchword">
                <div class="input-wrap input-text">
                  <input
                    type="text"
                    name="term"
                    id="general_SPHINX_WORD_NAME_IN_REQUEST"
                    v-model="search_term_on_input"
                    @keypress.enter="search_books_form"
                  />
                </div>
                <div class="input-wrap">
                  <div
                    class="button button--color1 search_again"
                    @click="search_books_form"
                  ></div>
                </div>
                <sort-book @sort-search-books="sortBooks"></sort-book>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="divider-line mobileHide"></div>
      </div>
      <div>
        <div class="clear"></div>
      </div>
    </div>
  </search>

  <AffirmSearch v-else>
    <div id="vmss_search_form_component" class="vmss_search st-container">
      <div class="vmss_search_form" style>
        <h2 class="mt-5 mb-4 pt-1 s-h2 --text-dark fw-semibold">
          Search Results
        </h2>
        <fieldset class="adminform">
          <div class="result-filters">
            <div
              class="itemwrap"
              id="inputdiv_general_SPHINX_WORD_NAME_IN_REQUEST"
            ></div>
          </div>
        </fieldset>
        <div class="divider-line mobileHide"></div>
      </div>
      <div>
        <div class="clear"></div>
      </div>
    </div>
  </AffirmSearch>
</template>

<script>
import Search from "./SearchComponent";
import AffirmSearch from "./AffirmSearchComponent";
import SortBook from "../components/SortBooks";
export default {
  name: "search-main",
  components: { Search, SortBook, AffirmSearch },
  data() {
    return {
      search_term_on_input: "",
      host: this.$store.state.site_data.host,
    };
  },
  methods: {
    search_books_form() {
      this.$router.push({
        name: "search",
        query: {
          page: 1,
          keyword: this.search_term_on_input,
        },
      });
      this.$refs.search_comp.search_books();
    },
    sortBooks(code) {
      this.$refs.search_comp.search_books(code);
    },
  },
  mounted() {
    document.title = `Book Search - ${this.$store.state.site_data.title}`;
  },
  beforeRouteUpdate(to, from, next) {
    // check if there is a next page
    if (to.query.keyword) {
      this.search_term_on_input = decodeURI(to.query.keyword);
    }
    next();
  },
  mounted() {
    let current_url = window.location.href;
    if (current_url.indexOf("#") !== -1) {
      current_url = current_url.split("#");
      this.search_term_on_input = current_url[1];
      this.search_books_form();
    }
    let term = this.$route.query.keyword;
    if (term) {
      this.search_term_on_input = decodeURI(term);
    }
  },
};
</script>

<style>
.search_again {
  width: 4em;
  height: 3.313em;
  float: left;
  background-image: url("/static/css/images/icon/search.svg");
  background-size: 23px 23px;
}
.vmss_search .searchword .input-text {
  width: auto !important;
}

@media screen and (max-width: 767px) {
  .vmss_search .searchword .input-text {
    width: 30% !important;
  }
}

.vmss_search .sort-field {
  float: right;
  margin-top: 0px !important;
}
.selecter.min-width-selecter.open .selecter-options {
  display: block !important;
}
.selecter.min-width-selecter.closed .selecter-options {
  display: none !important;
}
</style>
