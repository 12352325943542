<template>
  <div class="l-left mobileFullWidthWithMargin sidebar">
    <feature-item></feature-item>
    <best-seller-list placement='sidebar'></best-seller-list>
</div>
</template>

<script>
import BestSellerList from './BestSellerList';
import FeatureItem from '../components/FeatureItem';
export default {
    name: 'side-bar',
    props: ['position'],
    components:{
        BestSellerList,
        FeatureItem,
    },
    data(){
        return {

        }
    }
}
</script>

<style>
.sidebar{
    float: left !important;
}
</style>