<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div id="system-message-container"></div>
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="show_spinner"
        class="loader-spinner"
      />
      <div class="search-page" v-if="!show_spinner">
        <form
          class="vmss-advanced-search"
          id="vmss_search_form_component_advanced"
        >
          <h1>Advanced Search</h1>
          <p>
            Enter keywords and details below to improve your search results.
          </p>

          <div class="search-form">
            <div
              class="itemwrap"
              id="inputdiv_general_SPHINX_WORD_NAME_IN_REQUEST"
            >
              <label
                id="general_SPHINX_WORD_NAME_IN_REQUEST-lbl"
                for="general_SPHINX_WORD_NAME_IN_REQUEST"
                class
                >Keyword</label
              >
              <input
                type="text"
                name="searchword"
                v-model="form.keyword"
                id="general_SPHINX_WORD_NAME_IN_REQUEST"
                value
              />
            </div>
          </div>

          <div class="sphinxfilterForm">
            <fieldset class="adminform">
              <ul class="adminformlist">
                <li>
                  <label id="category-lbl" for="category" class>Category</label>
                  <div class="selecter closed" tabindex="0">
                    <select
                      id="category"
                      v-model="form.category"
                      name="category"
                      tabindex="-1"
                      class="selecter-selected"
                    >
                      <option value="null" selected="selected">- Any -</option>
                      <template v-for="(value, key) in bic_categories">
                        <option :value="value.code">- {{ key }}</option>
                      </template>
                    </select>
                    <div class="dropdown-arrow"></div>
                  </div>
                </li>
                <li>
                  <label id="title-lbl" for="title" class>Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    v-model="form.title"
                  />
                </li>
                <li>
                  <label id="author-lbl" for="author" class>Author</label>
                  <input
                    type="text"
                    name="author"
                    id="author"
                    v-model="form.author"
                  />
                </li>
                <li>
                  <label id="isbn-lbl" for="isbn" class>ISBN</label>
                  <input
                    type="text"
                    name="isbn"
                    id="isbn"
                    value
                    v-model="form.isbn"
                  />
                </li>
                <li>
                  <label id="series-lbl" for="series" class>Series</label>
                  <input
                    type="text"
                    name="series"
                    id="series"
                    value
                    v-model="form.series"
                  />
                </li>
                <li>
                  <label id="publisher-lbl" for="publisher" class
                    >Publisher</label
                  >
                  <div class="selecter closed" tabindex="0">
                    <select
                      id="publisher"
                      name="publisher"
                      v-model="form.publisher"
                      tabindex="-1"
                      class="selecter-selected"
                    >
                      <option value="null" selected="selected">- Any -</option>
                      <option
                        v-for="publisher in publishers"
                        :value="publisher.idil3"
                      >
                        {{ publisher.description }}
                      </option>
                    </select>
                    <div class="dropdown-arrow"></div>
                  </div>
                </li>
                <li>
                  <label id="imprint-lbl" for="imprint" class>Imprint</label>
                  <div class="selecter closed" tabindex="0">
                    <select
                      id="imprint"
                      v-model="form.imprint"
                      name="imprint"
                      tabindex="-1"
                      class="selecter-selected"
                    >
                      <option value="null" selected="selected">- Any -</option>
                      <option
                        v-for="imprnt in imprint"
                        :value="imprnt.idimprint"
                      >
                        {{ imprnt.description }}
                      </option>
                    </select>
                    <div class="dropdown-arrow"></div>
                  </div>
                </li>
                <li>
                  <label id="book_format-lbl" for="book_format" class
                    >Format</label
                  >
                  <div class="selecter closed" tabindex="0">
                    <select
                      id="book_format"
                      v-model="form.format"
                      name="book-format"
                      tabindex="-1"
                      class="selecter-selected"
                    >
                      <option value="null" selected="selected">- Any -</option>
                      <option
                        v-for="format in formats"
                        :value="format.format_id"
                      >
                        {{ format.description }}
                      </option>
                    </select>
                    <div class="dropdown-arrow"></div>
                  </div>
                </li>
                <li>
                  <label id="age-lbl" for="age" class>Age</label>
                  <div class="selecter closed" tabindex="0">
                    <select
                      id="age"
                      name="age"
                      v-model="form.age"
                      tabindex="-1"
                      class="selecter-selected"
                    >
                      <option value="null" selected="selected">
                        - Any age -
                      </option>
                      <option value="0 3">Up to three</option>
                      <option value="4 6">Four to six</option>
                      <option value="7">Seven</option>
                      <option value="8">Eight</option>
                      <option value="9">Nine</option>
                      <option value="10">Ten</option>
                      <option value="11">Eleven</option>
                      <option value="12">Twelve</option>
                      <option value="13">Thirteen</option>
                      <option value="14">Fourteen</option>
                      <option value="15">Fifteen</option>
                      <option value="16 18">Sixteen to Eighteen</option>
                    </select>
                    <div class="dropdown-arrow"></div>
                  </div>
                </li>
                <li>
                  <label id="pub_date-lbl" for="pub_date" class>Pub Date</label>
                  <div class="monthyear">
                    <div class="selecter closed" tabindex="0">
                      <select
                        id="pub_date1"
                        name="pub-date1"
                        tabindex="-1"
                        class="selecter-selected"
                        v-model="form.month"
                      >
                        <option value="null" selected="selected">
                          [Month]
                        </option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      <div class="dropdown-arrow"></div>
                    </div>
                    <div class="selecter closed" tabindex="0">
                      <select
                        id="pub_date2"
                        name="pub-date2"
                        tabindex="-1"
                        class="selecter-selected"
                        v-model="form.year"
                      >
                        <option value="null" selected="selected">[Year]</option>
                        <option v-for="year in getYears" :value="year">
                          {{ year }}
                        </option>
                      </select>
                      <div class="dropdown-arrow"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </fieldset>
          </div>
          <button
            class="button button--color1 button--icon-advanced-search"
            @click.prevent="submitSearch()"
          >
            Search
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  name: "advanced-search",
  components: { LoopingRhombusesSpinner },
  data() {
    return {
      form: {
        keyword: null,
        category: null,
        title: null,
        author: null,
        isbn: null,
        series: null,
        publisher: null,
        imprint: null,
        format: null,
        age: null,
        month: null,
        year: null,
      },
      show_spinner: true,
      bic_categories: [],
      publishers: [],
      imprint: [],
      formats: [],
      errors: [],
    };
  },
  computed: {
    getYears() {
      let current_year = new Date().getFullYear();
      let years = [];
      for (let i = current_year; i >= current_year - 100; i--) {
        years.push(i);
      }
      return years;
    },
  },
  methods: {
    submitSearch() {
      let form = this.form;
      form["page"] = 1;
      for (var key in form) {
        if (form[key] == "null") {
          form[key] = null;
        }
      }
      this.$store.dispatch("updateAdvancedForm", form);
      this.$router.push({
        name: "search",
        query: this.form,
        params: {
          term: "undefined",
        },
      });
    },
  },
  mounted() {
    // do axios call to get advanced search dropdown data
    let self = this;
    axios
      .get("/get_advanced_search_data/")
      .then(function (data) {
        self.bic_categories = data.data.categories;
        self.publishers = data.data.publishers;
        self.imprint = data.data.imprint;
        self.formats = data.data.formats;
        self.show_spinner = false;
      })
      .catch(function (err) {
        console.log(err);
      });
    let state_form = this.$store.state.advanced_search;
    if (state_form) {
      this.form = state_form;
    }
  },
};
</script>

<style>
.dropdown-arrow {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 3.375em;
  height: 100%;
  background: #fff0 url(../assets/select-replacement-arrow.png) no-repeat center
    center;
  background-size: 0.938em auto;
}
.selecter .selecter-selected {
  z-index: 9;
  background-color: transparent !important;
}
</style>
