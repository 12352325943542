<template>
  <default>
    <div class="wrapper">
      <!-- <bread-crumb></bread-crumb mobileFullWidth clearfixd-crumb> -->
      <div class="">
        <slot/>
      </div>
    </div>
    <div class="clear"></div>
  </default>
</template>

<script>
import Default from './DefaultAffirm';
import BreadCrumb from '../../components/BreadCrumb';
import SideBar from '../../components/SideBar';

export default {
    components:{
        Default,
        BreadCrumb,
        SideBar
    },
}
</script>

<style>

</style>