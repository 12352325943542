<template>
  <header id="l-header" class="l-header mobileFullWidthWithPadding wrapper">
    <div class="wrapper">
      <router-link to="/" id="header-logo" class="logo">
        <img alt="Logo" :src="logo" />
      </router-link>
      <div class="l-header__top mobileHide">
        <cart extra_classes='my-bag' :show_extra_link='true'></cart>
      </div>
      <search-bar class="search-position mobileHide search-right"></search-bar>
      <div class="clear"></div>
      <div class="l-header__navigation mobileHide">
        <div class="moduletable main-menu">
          <header-menu></header-menu>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderMenu from "../components/HeaderMenu";
import SearchBar from "../components/SearchBar";
import Cart from '../components/Cart'
import layoutMixin from "../mixins/Layout";

export default {
  name: "murdoch-header",
   mixins: [layoutMixin],
  data() {
    return {
      murdochuk: process.env.VUE_APP_MURDOCHUK,
      murdoch: process.env.VUE_APP_MURDOCH,
      dropDownClass: "closed"
    };
  },
  components: {
    HeaderMenu,
    SearchBar,
    Cart
  },
  methods: {
    toggleDomainDropDown() {
      this.dropDownClass = this.dropDownClass == "closed" ? "open" : "closed";
    }
  },
  computed: {
    resolveDomainMenu() {
      let domain = window.location.host;
      let data;
      if (domain.includes(this.murdochuk)) {
        data = {
          domain: this.murdoch,
          name: "UK",
          otherName: 'AUS'
        };
      } else {
        data = {
          domain: this.murdochuk,
          name: "AUS",
          otherName: "UK",
        };
      }
      return data;
    },
  },
   mounted() {
    let resource_search = document.getElementById('l-header');
    let header_log = document.getElementById('header-logo');
    if(this.host=="murdochuk"){
     resource_search.classList.add("b-shape");
     header_log.style.margin = "25px 0px 14px 0px";
    }
   }
};
</script>


<style>
    .search-position {    
  margin-top:10%;   
}
.search-right {
    position: absolute;
    right: 0;
}   
.search-right {
    position: absolute;
    right: 0;
}
.b-shape.wrapper:after {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  top: 0;   
  height: 1em;
  width: 100%;
}   
#l-header .wrapper:after {
    position: absolute;
    content: '';
    right: 0;
    left: unset;
    top: 0;
    height: 1em;
    width: 5000px;
}
.wrapper:after{   
  background-color: #004C6F;    
}
.l-header__top {
  min-height: 136px;
  float: right;
  clear: right;    
}
.main-menu .level1 > a, 
.main-menu .level1 > span { 
  position: relative; 
  font-size: 1.143em; /* 16px */  
  height: 3.526em; /*67px*/ 
  line-height: 3.526em; /*67px*/  
  box-sizing: border-box; 
}
</style>