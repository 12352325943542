<template>
  <div class="moduletable vmss_mixed--default pro_page_inner">
    <h3 class="h3-border bestseller-books" v-if="!title">.</h3>
    <h3 v-else class="bestseller-books">{{ title }}</h3>

    <div v-if="$route.path != '/product-page/'" class="vmss_mixed">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="books.length == 0"
        class="loader-spinner"
      />
      <div
        class="vmss_mixed__item"
        v-bind:class="format == 'double-col' ? 'double-col' : ''"
        v-for="book in books"
      >
        <router-link :to="getBookUrl(book)" v-if="pubdate(book.pubdate)">
          <p v-if="host == 'acc'" class="image-wrap">
            <img
              :src="
                'https://dimoacc.s3-ap-southeast-2.amazonaws.com/covers/' +
                book.isbn +
                '.jpg'
              "
              @error="noCoverImage"
            />
          </p>
          <p v-else-if="host != 'affirm'" class="image-wrap">
            <img
              :src="
                'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                book.isbn +
                '.jpg'
              "
              @error="noCoverImage"
              class="product_imgg"
            />
          </p>
          <br />
          <div class="text-wrap w-auto text-info-center best-text">
            <span class="title">{{ book.nonsortprefix }} {{ book.title }}</span>
            <span class="author">{{ book.author }}</span>
            <span class="price" v-if="show_price"
              >{{ currencySymbol }}{{ book.retail_price }}</span
            >
          </div>
        </router-link>
        <router-link
          v-if="readMoreBtn"
          class="readmore"
          :to="getBookUrl(book)"
          target="_self"
          >Read more</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Currency from "../mixins/Currency";
import Book from "../mixins/Book";

export default {
  name: "best-seller-list",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Currency, Book],
  props: ["readMoreBtn", "placement"],
  data() {
    return {
      host: "",
      books: [],
      show_price: false,
      format: "single-col",
      limit: 5,
      title: null,
    };
  },
  methods: {
    noCoverImage(event) {
      if (this.$store.state.site_data.host== 'acc'){
        event.target.src = "/static/images/acc/No_Image_Available.jpg";
      }
      else if (this.$store.state.site_data.host== 'affirm'){
        event.target.src = '/static/css/images/affirm/No_Image_Available.jpg';
      }
      else if (this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
      }
    },
    pubdate: function (pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();

      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      // if ((window.location.pathname).search('fiction') != -1  || (window.location.pathname).search('non-fiction') != -1 ){
      //   if ((year == today_year) && (month == today_month || month == today_month - 1)){
      //   status = true;
      // }

      // else{
      //   status = false;
      // }
      // }
      return status;
    },
  },
  computed: {
    get_pub_date(pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();

      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      if (
        window.location.pathname.search("fiction") != -1 ||
        window.location.pathname.search("non-fiction") != -1
      ) {
        if (
          year == today_year &&
          (month == today_month || month == today_month - 1)
        ) {
          status = true;
        } else {
          status = false;
        }
      }

      return status;
    },
  },
  computed: {},

  mounted() {
    this.host = this.$store.state.site_data.host;
    let self = this;
    // sidebar placement is the default, default is null for backwards compatibility
    let placement = this.placement ? this.placement : "sidebar";
    let host = this.$store.state.site_data.host;
    axios
      .get(`/get_bestsellers/?${host}`, {
        params: {
          page: this.$route.path,
          placement: placement,
        },
      })
      .then(function (data) {
        if (data.status == 200) {
          self.books = data.data.books;
          self.limit = data.data.book_limit;
          self.format = data.data.style;
          self.show_price = data.data.show_price;
          self.title = data.data.title;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>

<style>
.double-col {
  width: 39% !important;
  padding: 11px;
}
.double-col:nth-child(2n + 1) {
  clear: left;
  margin-left: 0;
}

.loader-spinner {
  margin: 0 auto;
  padding-bottom: 40px;
}
.moduletable.vmss_mixed--default > h3 {
  color: #41525b;
  font-size: 1.857em;
  margin: 0;
  padding: 10px;
  background-color: #f6f6f6 !important;
}
</style>
