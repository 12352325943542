<template>
  <div class="sort-field">
    <label id="ordering-lbl" for="ordering">Sort by</label>
    <div
      class="selecter min-width-selecter"
      :class="[isSortClosed ? 'closed' : 'open']"
      tabindex="0"
      @click="isSortClosed = !isSortClosed"
    >
      <span class="selecter-selected">{{ getSort }}</span>
      <div v-if="host == 'allenandunwin'" class="selecter-options">
        <span
          class="selecter-item"
          v-for="(sort, i) in sorts"
          @click="selected_sort_index = i"
          >{{ sort.name }}</span
        >
      </div>
      <div v-else class="selecter-options">
        <span
          class="selecter-item"
          v-for="(sort, i) in sortsNz"
          @click="selected_sort_index = i"
          >{{ sort.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fiction-sort-books",
  data() {
    return {
      isSortClosed: true,
      selected_sort_index: 0,
      sorts: [
        {
          name: "By Publication Date (Newest)",
          code: "date_asc",
        },
        {
          name: "Price (high to low)",
          code: "price_asc",
        },
        {
          name: "Price (low to high)",
          code: "price_desc",
        },
      ],
      sortsNz: [
        {
          name: "Title",
          code: "title",
        },
        {
          name: "By Publication Date (Newest)",
          code: "date_asc",
        },
        {
          name: "By Publication Date (Latest)",
          code: "date_desc",
        },
        {
          name: "Price (high to low)",
          code: "price_asc",
        },
        {
          name: "Price (low to high)",
          code: "price_desc",
        },
      ],
      host:this.$store.state.site_data.host,
    };
  },
  computed: {
    getSort() {
      if(this.host == "allenandunwin")
      {
          this.selected_sort_index = 0;
      }
      else
      {
          this.selected_sort_index = 1;
      }
      return this.sorts[this.selected_sort_index].name;
    },
  },
  mounted() {
  },
  watch: {
    selected_sort_index: function (oldIndex, newIndex) {
      if (oldIndex != newIndex || this.host == "allenandunwin") {
        this.$emit(
          "sort-search-books",
          this.sorts[this.selected_sort_index].code
        );
      }
      else if (oldIndex != newIndex){
        this.$emit(
          "sort-search-books",
          this.sortsNz[this.selected_sort_index].code
        );
      }
    },
  },
};
</script>

<style>
.sort-field {
  float: right;
  margin-top: 0px !important;
}
.min-width-selecter {
  min-width: 322px;
  margin-left: 10px;
}
</style>