<template>
  <div>
    <div class="bg-block1 mobileHide"></div>
    <div class="moduletable mobileHide">
      <div class="breadcrumbs mobileHide">
        <template v-for="(breadcrub, idx) in breadcrubList">
          <span
            class="pathway"
            @click="routeTo(idx)"
            :key="idx"
            :class="{'is_link' : !!breadcrub.link || !!breadcrub.path}"
          >{{breadcrub.name.replace('null',"")}}</span>
          <span>&gt;</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "bread-crumb",
  data() {
    return {
      breadcrubList: []
    };
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  methods: {
    updateList() {
      //this.breadcrubList = this.$route.meta.breadcrub;
      if (
        this.$route.meta.static ||
        this.$route.name == "category-search"
      ) {
        //get the dynamic breadcrub from the store, this is updated in Static.vue
        // this.getStaticBreadCrub();
      } else if (this.$route.name == "browse-book") {
        this.getBookBreadCrub();
      }
      this.breadcrubList = this.$route.meta.breadcrub;
    },
    getStaticBreadCrub() {
      //TODO check if title exists else split route at '/', make every first letter a capital letter and replace '-' with space
      let path = this.$route.path;
      let path_arr = path.split("/").slice(1);
      let breadcrub_arr = [];
      // if the breadcrub for the static page has more than one item which is the Home remove the rest and keep only the home link
      if (this.$route.meta.breadcrub.length > 1) {
        this.$route.meta.breadcrub = [{ name: "Home", link: "home" }];
      }
      for (let i = 0; i < path_arr.length; i++) {
        //split titles of the pages
        const str = path_arr[i];
        let upperStr = str.split("-").join(" ");
        upperStr = upperStr.charAt(0).toUpperCase() + upperStr.slice(1);
        //split paths
        let link = null;
        if (i == 0) {
          link = `/${path_arr[i]}`;
        } else {
          link = `/${path_arr[i - 1]}/${path_arr[i]}`;
        }
        let link_obj = {};

        if (i == path_arr.length - 1) {
          link_obj = {
            name: upperStr
          };
        } else {
          link_obj = {
            name: upperStr,
            link: link
          };
        }
        this.$route.meta.breadcrub.push(link_obj);
      }
    },
    getBookBreadCrub() {
      //get isbn from path and do axios get to server, get list with codes and category titles
      if (this.$route.meta.breadcrub.length > 1 && this.$route.meta.breadcrub.length < 3) {
        this.$route.meta.breadcrub = [
          { name: "Home", link: "home" },
          { name: "Search", link: "browse" }
        ];
      }
    },
    routeTo(pRouteTo) {
      //this.updateList();

      if (this.breadcrubList[pRouteTo].link) {
        if (this.breadcrubList[pRouteTo].link.includes("/")) {
          this.$router.push({ path: this.breadcrubList[pRouteTo].link });
        } else {
          this.$router.push({ name: this.breadcrubList[pRouteTo].link });
        }
      }else if(this.breadcrubList[pRouteTo].path) {
        this.$router.push({path: this.breadcrubList[pRouteTo].path});
      }
    }
  },
  mounted() {
    this.updateList();
  },
  computed: {}
};
</script>

<style>
.is_link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.bg-block1 {
  background: transparent;
}
</style>