export default {
    computed: {
        siteName() {
            return this.$store.getters.siteName;
        },
        logo() {
            return this.$store.state.site_data.logo;
        },
        host() {
            return this.$store.state.site_data.host;
        }
    }
}