<template>
<newsletter-comp></newsletter-comp>
</template>

<script>
import utils from '../utils'

const NewsletterComp = () => {
  let importFrom = null;
  let host = utils.resolve_host(window.location.hostname);

  if (host == 'murdoch' || host == 'murdochuk') {
    importFrom = './murdoch/';
  }
  else if (host == 'allenandunwin') {
    importFrom = './au/AU';
  }else if (host == 'allenandunwinnz') {
    importFrom = './au/NZ';
  }
  else if (host == 'acc'){
    importFrom = './au/ACC';
  }
  return import(importFrom + 'NewsletterComp');
}

export default {
    name: 'newsletter',
    components: {NewsletterComp},
    data(){
        return {

        }
    }
};
</script>

<style>
.iIDDUy {
  background: rgb(255, 255, 255);
  border-radius: 0.3125rem;
  max-width: 35.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 2.75rem;
  position: relative;
}
.ebeRtN {
  align-items: flex-start;
  display: flex;
}
.iHsWXX {
  font-size: 0.875rem;
  padding-left: 0.5rem;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(67, 77, 93);
}
.jMsntP {
  color: rgb(221, 54, 42);
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 0.125rem;
}
.eKOoKL {
  flex-shrink: 0;
  height: 1rem;
  position: relative;
  top: 0.08rem;
  width: 1rem;
}
.eKOoKLL {
  width: 1.571em;
  height: 1.571em;
  border: 2px solid #e1e1e1;
}
.dEVaGV {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(93, 93, 101);
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.iFTUZ {
  color: rgb(221, 54, 42);
}
.iMsgpL {
  -webkit-appearance: none;
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  box-shadow: rgba(142, 154, 173, 0.1) 0px 2px 0px 0px inset,
    rgb(210, 215, 223) 0px 0px 0px 1px inset, rgb(255, 255, 255) 0px 1px 0px 0px;
  color: rgb(67, 77, 93);
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 2.8125rem;
  outline: 0px;
  padding: 0.75rem 1rem;
  transition: box-shadow 0.2s ease 0s;
  width: 100%;
}
.iMsgpL:focus {
  box-shadow: transparent 0px 0px 0px 0px inset,
    rgb(80, 156, 246) 0px 0px 0px 1px inset,
    rgba(80, 156, 246, 0.25) 0px 0px 0px 2px;
}
.bMslyb {
  margin-bottom: 1.5rem;
}
.liOVdz {
  margin-bottom: 1rem;
}
.dLkilY {
  margin-left: auto;
  margin-right: auto;
  max-width: 29.125rem;
  padding-bottom: 3.125rem;
}
.iKxzjo {
  display: block;
  margin: 0px auto;
  max-width: 100%;
  max-height: 7.5rem;
}
.gHlLJa {
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(25, 169, 229);
}
.bguLUo {
  border-bottom: 1px solid rgb(210, 215, 223);
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.urQia {
  border-bottom: 1px solid rgb(210, 215, 223);
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.gnexaL {
  margin-bottom: 0.25rem;
}
.fMHIiy {
  background-color: rgb(252, 209, 6);
  border: none;
  border-radius: 3px;
  color: rgb(60, 64, 66);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}
.fMHIiy:hover {
  cursor: pointer;
}
.jHkwuK {
  background-color: #004C6F;
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}
.jHkwuK:hover {
  cursor: pointer;
}
.fBOZaM {
  white-space: pre-wrap;
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(67, 77, 93);
}
/* a,
abbr,
address,
article,
aside,
b,
blockquote,
body,
caption,
cite,
code,
dd,
details,
dialog,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
label,
legend,
li,
main,
menu,
nav,
object,
ol,
p,
pre,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
u,
ul {
  border: 0px;
  margin: 0px;
  padding: 0px;
}
*,
::after,
::before {
  box-sizing: inherit;
}
html {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6,
html {
  color: rgb(67, 77, 93);
  font-weight: 400;
  line-height: 1.5;
}
a {
  color: rgb(25, 169, 229);
  text-decoration: underline;
}
button,
label,
p {
  overflow-wrap: break-word;
  word-break: break-word;
} */
</style>