<template>
  <div v-if="host != 'affirm'" class="moduletable top-search" v-on-clickaway='clickOutside'>
    <div class="vmss_search" id="vmms_search_">
        <div id="vmss_search_form_module" class="vmss_search_form_module_class">
          <div class="search">
              <label for="mod-search-searchword">Search</label>
              <input type="text" placeholder="Title, author, keyword or ISBN" class="inputbox" id="mod-search-searchword" name="term" v-model='search_query' @click='openDropdown' @keydown.enter="search_terms">
              <input type="submit" class="image-button button--color2" value="Go" @click='search_terms'>
          </div>
        </div>
        <!-- <div class="search-results-container" v-if='search_query.length >= 1 && show_dropdown' :class="{'fixed-height' : search_results.length > 0}">
          <looping-rhombuses-spinner
              :animation-duration="2500"
              :rhombus-size="15"
              color="#febe10"
              v-if='search_results.length < 1'
              class="loader-spinner"
          />
          <div class="result-container" v-for='result in sub_search' :key='result.isbn'>
              <div class="result-thumbnail">
              <img :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' + result.isbn + '.jpg'" @error="noCoverImage" class="img-responsive" alt="book-result-thumbnail">
              </div>
              <div class="result-info">
                  <div class='result-book-title'>{{result.title}}</div>
                  <div>by {{result.author}}</div>
                  <div><b>ISBN:</b> {{result.isbn}}</div>
              </div>
              </div>
              <div class="more-resuls-link" v-if='search_results.length >= 10' @click="show_dropdown = false"><router-link :to="'/search/' + search_query +'/1/'">See More</router-link></div>
        </div> -->
    </div>
    <div class="advanced-search">
        <router-link class="button button--color1 button--icon-advanced-search" to="/advanced-search/">
        Advanced<span class="mobileHide"> Search</span>
        </router-link>
    </div>
  </div>
  <span  v-on-clickaway='clickOutside' v-else class="d-flex bg-light align-items-center rounded-pill overflow-hidden py-1 px-3 me-5" >
    <input
      class="form-control me-2 border-0 p-0 bg-light"
      type="text"
      placeholder="Search"
      aria-label="Search"
      name="search"
      v-model='search_query' 
      @click='openDropdown'
      @keydown.enter="search_terms"
    />
    <a value="Go" @click='search_terms' type="submit"
      ><i class="fa fa-search s-h9"></i
    ></a>
  </span>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { LoopingRhombusesSpinner } from 'epic-spinners'

import axios from 'axios';

export default {
    name:'search-bar',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
      LoopingRhombusesSpinner
    },
    data(){
      return {
        search_query:'',
        previous_search_term: null,
        show_dropdown: false,
        search_results: [
            // {
            //   'title': 'Foo',
            //   'author': 'Bar Master',
            //   'isbn': '1281728172'
            // }
        ],
        host : this.$store.state.site_data.host,
      }
    },
    methods: {
      search_terms: function(){
        let search_query = this.search_query;
        if (!search_query) {
          return false;
        }
        this.search_query = '';
        this.search_results = [];

        this.$router.push({path: `/search?keyword=${encodeURI(search_query)}&page=1`});
      },
      noCoverImage: function(event){
        if(this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
          event.target.src = 'https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg';
        } 
        else{
          event.target.src = "/static/Holding-Image-DIMO.jpg";
        }
        
      },
      clickOutside: function(){
        this.show_dropdown = false;
      },
      openDropdown: function(){
        if(this.search_query.length > 0 && this.search_results.length > 0){
          this.show_dropdown = true;
        }
      }
    },
    computed: {
      sub_search: function(){
        // this function returns 10 objects that are going to be rendered in the results html

        let term = this.search_query;

        let result = this.search_results.filter((item)=>{
          return Object.keys(item).some((key)=>{
            try {
              return item[key].toLowerCase().includes(term.toLowerCase());
            } catch (error) {
              console.log(error);
            }
          });
        });
        result.splice(10);
        return result;
      }
    },
    // watch: {
    //   search_query: function(value){
    //     // This function does a request to the backend after the user types 4 letters in the search bar.
    //     // The search_results variable is set to [] only when the user deletes his previous search term
    //     let self = this;
    //     if(value.length >= 4 && self.search_results.length < 1){
    //       self.show_dropdown = true;
    //       axios.get('/search_books/', {
    //         params:{
    //           'search_query': value
    //         }
    //       }).then(function(data){
    //         self.search_results = data.data.results;
    //         // if there are no results the dropdown should be shown for one 1sec for the ux and then close
    //         if(self.search_results.length == 0){
    //           setTimeout(() => {
    //             self.show_dropdown = false;
    //           }, 1000);
    //         }
    //       });
    //     }else if(value.length < 4){
    //       self.search_results = [];
    //     }
    //   }
    // }
}
</script>

<style>
.search-box > div > form{
    display: inline-flex;
    width: 100%;
  }

  .result-thumbnail > img {
    max-width: 50px;
    width: 50px;
    height: 80px;
  }

  .search-results-container {
    display: block;
    width:35%;
    border: 1px solid #ddd;
    padding: 10px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    margin-top: 53px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
  }

  .result-container{
    display: flex;
    margin-bottom: 10px;
    font-family: roboto;
  }

  .result-container:hover{
    cursor: pointer;
  }

  .result-container > div{
    display: inline-block;
  }
  .result-container > div > div{
    display: block;
  }

  .result-book-title{
    font-size: 14px;
    font-weight: bold;
  }

  .result-info {
    padding-left: 10px;
  }
  /* end loader css */
    .more-resuls-link > a {
      text-align: center;
      margin: 0 auto;
      display: block;
    }

    .load-spinner{
      margin: 0 auto;
    }

    .fixed-height{
      height: 300px;
      overflow-y: scroll;

    }

</style>