<template>
  <div id="app" class="st-container">
    <component :is="layout" v-if='site_loaded' :key='$route.path'>
      <router-view />
    </component>
    <looping-rhombuses-spinner
      :animation-duration="2500"
      :rhombus-size="20"
      color="#febe10"
      v-if="!site_loaded"
      class="loader-spinner"
    />
  </div>
</template>
<script>
const contents_layout = "contents";
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
export default {
  name: "app",
  components: { LoopingRhombusesSpinner },
  data() {
    return {
      logo: "../assets/logo.svg",
      site_loaded: false
    };
  },
  metaInfo(){
    return {}
  },
  created() {
    // this method is called once when the app is intitiated and its purposee is to load
    // the domains specific data that is saved in the store.
    let self = this;
    self.$store.dispatch("fetchSiteData").then(() => {
      console.log("site data loaded to store");
      self.site_loaded = true;
    });
  },
  computed: {
    layout() {
      let site_host = "";
      let host = this.$store.state.site_data.host;
      if (host == "murdoch" || host == "murdochuk") {
        site_host = "-murdoch";
      }
      else if (host == "acc"){
        site_host = "-acc";
      }
      else if (host == "affirm"){
        site_host = "-affirm";
      }
      
      return (
        (this.$route.meta.layout || contents_layout) + site_host + "-layout"
      );
    }
  },
  mounted() {}
};
</script>
<style>
</style>
