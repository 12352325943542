<template>
  <div class="l-right mobileFullWidthWithMargin">
    <div class="moduletable r-mod r-mod--two-cols r-mod--color-yellow">
      <div class="custom r-mod r-mod--two-cols r-mod--color-yellow">
        <h3 class="r-mod__title">{{title}}</h3>
        <!-- images & details - right-sidebar -->
        <!-- Only images - bottom-right -->
        <!-- Top block - top-right - -->
        <div class="&quot;r-mod__div-wrap" v-if="placement == 'top-right'">
          <div class="r-mod__div r-mod__div--1" v-for="book in books">
            <a :href="'/browse/books/'+book.isbn" target="_blank"
              ><img class="img-size" 
                :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+ book.isbn +'.jpg'"
                alt="Best Books for SchoolsAUG  DEC 2020 Cover 130px"
                width="130"
                height="190"
            /></a>
          </div>
          <p>
            <a href="" target="_blank" class="doclink">
              Download our Best Books for Schools 2020 August to December catalogue now.
            </a>
          </p>
          <h6 class="r-mod__div r-mod__div--1">
            <a href="/virtual-read-alouds">
              AN IMPORTANT NOTICE REGARDING VIRTUAL READ-ALOUDS
            </a>
          </h6>
        </div>

        <div class="r-mod__div-wrap" v-for="book in books" v-if="placement != 'top-right'">
          <div class="r-mod__div r-mod__div--1">
              <!-- <router-link :to="'/browse/books/'+book.isbn"></router-link> -->
            <a :href="'/browse/books/'+book.isbn" target="_blank"
              ><img class="img-size" 
                :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+ book.isbn +'.jpg'"
                alt="Josephine-s-Garden-130"
                width="130"
                height="199"
            /></a>
          </div>
          <div class="r-mod__div r-mod__div--2" v-if="placement == 'right-sidebar'">
            <h5>{{book.title}}</h5>
            <p>{{book.author}}</p>
            <p v-html="book.key_note_blurb">
              <br /><br /><br />
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";

export default {
  name: "book-list",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ['placement'],
  data() {
    return {
      books: [],
      title: null,
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src = "/static/images/acc/No_Image_Available.jpg";
    },
  },
  mounted() {
    let self = this;
    // sidebar placement is the default, default is null for backwards compatibility
    let placement = this.placement ? this.placement : "sidebar";
    let host = this.$store.state.site_data.host;
    axios
      .get(`/get_bestsellers/?${host}`, {
        params: {
          page: this.$route.path,
          placement: placement
        },
      })
      .then(function (data) {
        if (data.status == 200) {
          self.books = JSON.parse(data.data.books);
          self.title = data.data.title;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>

<style>
  .img-size{
    margin: 5px;
  }
</style>