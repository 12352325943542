<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div id="system-message-container"></div>
      <!-- <form
        ref="checkoutForm"
      > -->
        <div id="ProOPC" class="cart-view proopc-row">
          <div class="clear"></div>
          <div id="proopc-system-message"></div>
          <div class="proopc-finalpage">
            <div class="proopc-row">
              <h1 class="cart-page-title">
                Cart&nbsp;
                <span class="septa">/</span>&nbsp;
                <span>
                  <u>
                    <span id="proopc-cart-totalqty">{{ cartProductNum }}</span>
                  </u> item/s
                </span>
              </h1>
            </div>
            <div class="proopc-row">
              <div class="proopc-login-message-cont"></div>
              <!-- <div class="proopc-continue-link">
                <router-link
                  class="continue_link button button--color1"
                  to="/browse"
                >Continue Shopping</router-link>
              </div>-->
              <div>
                <ul>
                  <li class="error" v-if="generic_error">{{ generic_error }}</li>
                </ul>
              </div>
            </div>
            <div class="proopc">
              <div class="proopc-bt-address proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">1</div>Bill To
                </h3>
                <div class="inner-wrap">
                  <div class="edit-address">
                    <ul>
                      <li class="error" v-for="error in billing_address_errors">{{error}}</li>
                    </ul>
                    <form id="billing-address" autocomplete="off">
                      <div class="email-group">
                        <div class="inner form-group">
                          <label class="email" for="email_field">E-Mail *</label>
                          <input
                            type="text"
                            id="email_field"
                            name="email"
                            size="30"
                            v-model="billing_address.email"
                            class="required form-control"
                            maxlength="100"
                            style="width: 246px; background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;"
                          />
                        </div>
                      </div>
                      <div class="title-group">
                        <div class="inner form-group">
                          <label class="title" for="title">Title</label>
                          <select
                            id="title"
                            name="title"
          
                            v-model="billing_address.title"
                          >
                            <option value selected="selected">-- Select --</option>
                            <option value selected="selected"></option>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Miss">Miss</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Dr">Dr.</option>
                          </select>
                        </div>
                      </div>
                      <div class="first_name-group">
                        <div class="inner form-group">
                          <label class="first_name" for="first_name_field">First Name *</label>
                          <input
                            type="text"
                            id="first_name_field"
                            name="first_name"
                            v-model="billing_address.first_name"
                            size="30"
                            class="required form-control"
                            maxlength="32"
                          />
                        </div>
                      </div>
                      <div class="proopc-row group-enabled">
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="last_name-group">
                              <div class="inner form-group">
                                <label class="last_name" for="last_name_field">Last Name *</label>
                                <input
                                  type="text"
                                  id="last_name_field"
                                  name="last_name"
                                  size="30"
                                  class="required form-control"
                                  v-model="billing_address.last_name"
                                  maxlength="32"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="proopc-row group-enabled" v-if="host == 'acc'">
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="organisation-group">
                              <div class="inner form-group">
                                <label class="organisation" for="organisation_field">Organisation *</label>
                                <input
                                  type="text"
                                  id="organisation_field"
                                  name="organisation"
                                  size="30"
                                  class="required form-control"
                                  v-model="billing_address.organisation"
                                  maxlength="64"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="address_1-group">
                        <div class="inner form-group">
                          <label class="address_1" for="address_1_field">Address 1 *</label>
                          <input
                            type="text"
                            id="address_1_field"
                            name="address_1"
                            size="30"
                            v-model="billing_address.line1"
                            class="required form-control"
                            maxlength="64"
                          />
                        </div>
                      </div>
                      <div class="address_2-group">
                        <div class="inner form-group">
                          <label class="address_2" for="address_2_field">Address 2</label>
                          <input
                            type="text"
                            id="address_2_field"
                            name="address_2"
                            size="30"
                            v-model="billing_address.line2"
                            maxlength="64"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="proopc-row group-enabled">
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="zip-group">
                              <div class="inner form-group">
                                <label class="zip" for="zip_field">Zip / Postal Code *</label>
                                <input
                                  type="text"
                                  id="zip_field"
                                  name="zip"
                                  size="30"
                                  v-model="billing_address.postcode"
                                  class="required form-control"
                                  maxlength=
                                />
                              </div>
                            </div>
                            <div class="city-group">
                              <div class="inner form-group">
                                <label class="city" for="city_field">City *</label>
                                <input
                                  type="text"
                                  id="city_field"
                                  name="city"
                                  size="30"
                                  v-model="billing_address.city"
                                  class="required form-control"
                                  maxlength="32"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="virtuemart_state_id-group">
                        <div class="inner form-group">
                          <label class="state" for="state_1_field">State / Province / Region *</label>
                          <div class="inner form-group" v-if="billing_address.country != 'AU'">
                            <input
                              type="text"
                              id="state_1_field"
                              name="state_1"
                              size="30"
                              v-model="billing_address.state"
                              class="required"
                              style="width: 50%;"
                            />
                            </div>
                          <div v-else>
                           <select
                              id="state_1_field"
                              name="state_1"
                              v-model="billing_address.state"
                            >
                            <option value="0" selected="selected">-- Select --</option>
                            <option
                            v-for=" state in state_choices"
                            :value="state"
                            >{{state}}
                            </option>
                            </select>  
                            </div>  
                        </div>
                      </div>
                      <div class="virtuemart_country_id-group">
                        <div class="inner form-group">
                          <label class="virtuemart_country_id" for="virtuemart_country_id">Country *</label>
                          <select
                            id="virtuemart_country_id"
                            name="virtuemart_country_id"
                            class="vm-chzn-select required form-control"
                            v-model="billing_address.country"
                          >
                            <option value="0" selected="selected">-- Select --</option>
                            <option
                              :value="country.iso_3166_1_a2"
                              v-for="country in countries"
                              :key="country.iso_3166_1_a2"
                            >{{country.printable_name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="state-group">
                        <div class="inner">
                          <label class="state" for="state_1_field">State / Province / Region *</label>
                          <input
                            type="text"
                            id="state_1_field"
                            name="state_1"
                            size="30"
                            v-model="billing_address.state"
                            maxlength="32"
                            class="form-control"
                          />
                        </div>
                      </div> -->

                      

                      <div class="phone_1-group">
                        <div class="inner form-group">
                          <label
                            class="phone_1"
                            for="phone_1_field"
                          >Phone *</label>
                          <VuePhoneNumberInput
                                id="phoneNumber1"
                                default-country-code="AU"
                                v-model="billing_address.phone_number"
                                :loader="hasLoaderActive"
                                clearable
                                :error="hasErrorActive"
                                class="mb-2 form-control"
                                :show-code-on-list="true"
                              />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="proopc">
              <div class="proopc-st-address proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">2</div>Ship To
                </h3>
                <div id="proopc-st-address">
                  <div class="inner-wrap">
                    <label for="BTasST">
                      <input
                        class="inputbox"
                        type="checkbox"
                        name="STsameAsBT"
                        id="STsameAsBT"
                        v-model="same_as_billing"
                        :disabled="check_cart_type()"
                      />
                      Use billing address
                    </label>
                    <div class v-if="!same_as_billing">
                      <form id="shipping-address" autocomplete="off">
                        <ul>
                          <li class="error" v-for="error in shipping_address_errors">{{error}}</li>
                        </ul>
                        <div class="shipto_first_name-group">
                          <div class="title-group">
                            <div class="inner form-group">
                              <label class="title" for="title">Title</label>

                              <select
                                id="title"
                                name="title"
                                class="vm-chzn-select"
                                style="width: 95px;"
                                v-model="shipping_address.title"
                              >
                                <option value selected="selected">-- Select --</option>
                                <option value selected="selected"></option>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Miss">Miss</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Dr">Dr.</option>
                              </select>
                            </div>
                          </div>
                          <div class="inner form-group">
                            <label
                              class="shipto_first_name"
                              for="shipto_first_name_field"
                            >First Name *</label>
                            <input
                              type="text"
                              id="shipto_first_name_field"
                              name="shipto_first_name"
                              size="30"
                              v-model="shipping_address.first_name"
                              class="required"
                              maxlength="32"
                              style="width: 241px;"
                            />
                          </div>
                        </div>
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="proopc-row group-enabled">
                              <div class="shipto_last_name-group">
                                <div class="inner form-group">
                                  <label
                                    class="shipto_last_name"
                                    for="shipto_last_name_field"
                                  >Last Name *</label>
                                  <input
                                    type="text"
                                    id="shipto_last_name_field"
                                    name="shipto_last_name"
                                    size="30"
                                    v-model="shipping_address.last_name"
                                    class="required"
                                    maxlength="32"
                                    style="width: 122px;"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="proopc-row group-enabled" v-if="host == 'acc'">
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="shipto_organisation-group">
                              <div class="inner form-group">
                                <label class="shipto_organisation" for="shipto_organisation_field">Organisation</label>
                                <input
                                  type="text"
                                  id="shipto_organisation_field"
                                  name="shipto_organisation"
                                  size="30"
                                  class="form-control"
                                  v-model="shipping_address.organisation"
                                  maxlength="64"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="shipto_address_1-group">
                          <div class="inner form-group">
                            <label class="shipto_address_1" for="shipto_address_1_field">Address 1 *</label>
                            <input
                              type="text"
                              id="shipto_address_1_field"
                              name="shipto_address_1"
                              size="30"
                              v-model="shipping_address.line1"
                              class="required"
                              maxlength="64"
                              style="width: 241px;"
                            />
                          </div>
                        </div>
                        <div class="shipto_address_2-group">
                          <div class="inner form-group">
                            <label class="shipto_address_2" for="shipto_address_2_field">Address 2</label>
                            <input
                              type="text"
                              id="shipto_address_2_field"
                              name="shipto_address_2"
                              size="30"
                              v-model="shipping_address.line2"
                              maxlength="64"
                              style="width: 241px;"
                            />
                          </div>
                        </div>
                        <div class="proopc-row group-enabled">
                          <div class="proopc-row group-enabled">
                            <div class="proopc-row group-enabled">
                              <div class="shipto_zip-group">
                                <div class="inner form-group">
                                  <label
                                    class="shipto_zip"
                                    for="shipto_zip_field"
                                  >Zip / Postal Code *</label>
                                  <input
                                    type="text"
                                    id="shipto_zip_field"
                                    name="shipto_zip"
                                    size="30"
                                    v-model="shipping_address.postcode"
                                    class="required"
                                    maxlength="32"
                                    style="width: 122px;"
                                  />
                                </div>
                              </div>
                              <div class="shipto_city-group">
                                <div class="inner form-group">
                                  <label class="shipto_city" for="shipto_city_field">City *</label>
                                  <input
                                    type="text"
                                    id="shipto_city_field"
                                    name="shipto_city"
                                    size="30"
                                    v-model="shipping_address.city"
                                    class="required"
                                    maxlength="32"
                                    style="width: 122px;"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="shipto_virtuemart_country_id-group">
                          <div class="inner form-group">
                            <label
                              class="shipto_virtuemart_country_id"
                              for="shipto_virtuemart_country_id"
                            >Country *</label>
                            <select
                              id="shipto_virtuemart_country_id"
                              name="shipto_virtuemart_country_id"
                              class="vm-chzn-select required"
                              style="width: 265px;"
                              v-model="shipping_address.country"
                            >
                              <option value="0" selected="selected">-- Select --</option>
                              <option
                                :value="country.iso_3166_1_a2"
                                v-for="country in countries"
                                :key="country.iso_3166_1_a2"
                              >{{country.printable_name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="shipto_virtuemart_state_id-group">
                          <div class="inner form-group">
                            <label
                              class="shipto_virtuemart_state_id"
                              for="shipto_virtuemart_state_id"
                            >State / Province / Region *</label>
                            <input
                              type="text"
                              id="state_1_field"
                              name="state"
                              size="30"
                              v-model="shipping_address.state"
                              class="required"
                              maxlength="32"
                              style="width: 241px;"
                            />
                          </div>
                        </div>
                        <div class="shipto_phone_1-group">
                          <div class="inner form-group">
                            <label
                              class="shipto_phone_1"
                              for="shipto_phone_1_field"
                            >Phone * (with country code ex. +61)</label>
                            <input
                              type="text"
                              id="shipto_phone_1_field"
                              name="shipto_phone_1"
                              size="30"
                              v-model="shipping_address.phone_number"
                              class="required"
                              maxlength="32"
                              style="width: 241px;"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="proopc-shipments proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">3</div>Shipment
                </h3>
                <div id="proopc-shipments">
                  <form id="proopc-shipment-form">
                    <div class="loader-container" v-if="!page_loaded">
                      <looping-rhombuses-spinner
                        :animation-duration="2500"
                        :rhombus-size="15"
                        color="#febe10"
                        class="loader-spinner"
                      />
                    </div>
                    <div class="inner-wrap">
                      <span class="error" v-if="shipping_method_error">{{ shipping_method_error }}</span>
                      <fieldset>
                        <div v-for="method, i in shipping_methods" :key="i">
                          <input
                            type="radio"
                            name="virtuemart_shipmentmethod_id"
                            id="shipment_id_5"
                            :value="method.code"
                            v-model="selected_shipping_method"
                            checked="checked"
                            :disabled="check_cart_type()"
                          />
                          <label for="shipment_id_5">
                            <span class="vmshipment">
                              <span class="vmshipment_description" v-if="host != 'acc'"><b>{{method.name}}:</b>{{method.description}}</span>
                              <span class="vmshipment_description" v-else>{{method.description}}</span>
                              <!-- <span class="vmshipment_cost">(Discount/Fee: AUD $25.00)</span> -->
                            </span>
                          </label>
                          <div class="clear"></div>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                </div>
              </div>
              <!-- <div class="proopc-payments proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">4</div>Payment
                </h3>
                <div id="proopc-payments">
                  <div class="inner-wrap">
                    <form id="proopc-payment-form">
                      <fieldset>
                        <input
                          onclick="return ProOPC.setpayment(this);"
                          type="radio"
                          name="virtuemart_paymentmethod_id"
                          id="payment_id_7"
                          data-pmtype="nabxml"
                          data-paypalproduct="false"
                          value="7"
                          checked="checked"
                        />
                        <label for="payment_id_7">
                          <span class="vmpayment">Credit card</span>
                        </label>
                        <br />
                        <span class="vmpayment_cardinfo additional-payment-info nabxml show">
                          Please enter your Credit Card information:
                          <div class="proopc-creditcard-info">
                            <div class="proopc-row">
                              <div class="creditcard-label">
                                <label for="creditcardtype">Credit Card Type:</label>
                              </div>
                              <div>
                                <select id="cc_type_7" name="cc_type_7">
                                  <option value="Visa">Visa</option>
                                  <option value="Mastercard">Mastercard</option>
                                </select>
                              </div>
                            </div>
                            <div class="proopc-row">
                              <div class="creditcard-label">
                                <label for="cc_type">Credit Card Name:</label>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  class="inputbox"
                                  id="cc_cardname_7"
                                  name="cc_cardname_7"
                                  value
                                  autocomplete="off"
                                  onchange="ccError=razCCerror(7);"
                                />
                                <div id=" cc_cardname7"></div>
                              </div>
                            </div>
                            <div class="proopc-row">
                              <div class="creditcard-label">
                                <label for="cc_type">Credit Card Number:</label>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  class="inputbox"
                                  id="cc_number_7"
                                  name="cc_number_7"
                                  value
                                  autocomplete="off"
                                  onchange="ccError=razCCerror(7);
                            CheckCreditCardNumber(this . value, 7);
                            if (!ccError) {
                            this.value=\'\';}"
                                />
                                <div id="cc_cardnumber_errormsg_7"></div>
                              </div>
                            </div>
                            <div class="proopc-row">
                              <div class="creditcard-label">
                                <label for="cc_cvv">CVV:</label>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  class="inputbox"
                                  id="cc_cvv_7"
                                  name="cc_cvv_7"
                                  maxlength="4"
                                  size="5"
                                  value
                                  autocomplete="off"
                                />
                                <span
                                  class="hover-tootip"
                                  title="What is CVV?::CVV are three or four-digit number on the back of a credit card (on the front for American Express)."
                                >What is CVV?</span>
                              </div>
                            </div>
                            <div class="proopc-row">
                              <div class="creditcard-label">Expiration Date:</div>
                              <div>
                                <select id="cc_expire_month_7" name="cc_expire_month_7">
                                  <option value="0">MONTH</option>
                                  <option value="01">01</option>
                                  <option value="02">02</option>
                                  <option value="03">03</option>
                                  <option value="04">04</option>
                                  <option value="05">05</option>
                                  <option value="06" selected="selected">06</option>
                                  <option value="07">07</option>
                                  <option value="08">08</option>
                                  <option value="09">09</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                                /
                                <select
                                  id="cc_expire_year_7"
                                  name="cc_expire_year_7"
                                >
                                  <option value="0">YEAR</option>
                                  <option value="2020" selected="selected">2020</option>
                                  <option value="2021">2021</option>
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                  <option value="2026">2026</option>
                                  <option value="2027">2027</option>
                                </select>
                                <div id="cc_expiredate_errormsg_7"></div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div class="clear"></div>
                        <input
                          type="hidden"
                          name="proopc-savedPayment"
                          id="proopc-savedPayment"
                          value="7"
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>-->
              <div class="proopc-coupon proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">4</div>Coupon Discount
                </h3>
                <div id="proopc-coupon">
                  <div class="inner-wrap">
                    <div class="proopc-input-append proopc-row">
                      <input
                        type="text"
                        name="coupon_code"
                        size="20"
                        maxlength="50"
                        id="proopc-coupon-code"
                        alt="Enter your Coupon code"
                        v-model="discount_coupon"
                        data-default="Enter your Coupon code"
                        style="width: 166px; margin-right: 5px;"
                        
                      />
                      <button class=" checkout-save-btn" title="Save" @click="calculateCouponDiscount">Save</button>
                    </div>
                    <span class="error" v-if="coupon_error">{{coupon_error}}</span>
                    <span class="success" v-if="coupon_success">{{coupon_success}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="proopc last">
              <div class="proopc-cartlist proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">5</div>Shopping cart
                </h3>
                <div id="proopc-pricelist">
                  <div class="loader-container" v-if="!page_loaded">
                    <looping-rhombuses-spinner
                      :animation-duration="2500"
                      :rhombus-size="15"
                      color="#febe10"
                      class="loader-spinner"
                    />
                  </div>
                  <div class="inner-wrap product-summary">
                    <table class="proopc-cart-summery" width="100%" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="col-name" align="left">Name</th>
                          <th class="col-qty" align="center">Quantity</th>
                          <th class="col-total" align="right">Total</th>
                        </tr>
                      </thead>

                      <tbody class="proopc-cart-product" data-details=
"proopc-product-details1">
                        <tr
                          valign="top"
                          class="proopc-cart-entry1 proopc-p-list"
                          v-for="line, i in cartLines"
                        >
                          <td class="col-name">
                            {{line.title}}
                            <div class="proopc-p-price">
                              Price:
                              <div class="PricediscountedPriceWithoutTax" style="display: inline;">
                                <span
                                  class="PricediscountedPriceWithoutTax"
                                >{{currencySymbol}}{{line.price | currency}}</span>
                              </div>
                            </div>
                            <div class="proopc-p-sku">SKU: {{line.isbn}}</div>
                          </td>
                          <td class="col-qty" align="center">{{ line.quantity }}</td>
                          <td class="col-total" colspan="1" align="right">
                            <div class="PricesalesPrice" style="display : block;">
                              <span
                                class="PricesalesPrice"
                              >{{currencySymbol}}{{productTotalPrice(line) | currency}}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="proopc-subtotal">
                        <tr class="proopc-cart-sub-total">
                          <td class="sub-headings" colspan="2" align="left">Product prices result</td>
                          <td class="col-total" align="right">
                            <div class="PricesalesPrice" style="display : block;">
                              <span
                                class="PricesalesPrice"
                              >{{currencySymbol}}{{cartTotal() | currency}}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      
                      <tbody class="poopc-shipment-table">
                        <tr v-if="coupon_discount > 0">
                          <td class="shipping-heading" colspan="2" align="left">
                            <span>Coupon discount</span>
                          </td>
                          <td class="col-total" align="right">
                            <div class="PricesalesPriceShipment" style="display : block;">
                              <span
                                class="PricesalesPriceShipment"
                              >{{currencySymbol}}{{coupon_discount | currency}}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>


                      <tbody class="poopc-shipment-table">
                        <tr>
                          <td class="shipping-heading" colspan="2" align="left">
                            <span>Shipping Cost</span>
                            <!-- <span class="vmshipment_name">Courier</span>
                            <span
                              class="vmshipment_description"
                            >&nbsp;$25 for the first book and $10 for each book thereafter.</span>-->
                          </td>
                          <td class="col-total" align="right">
                            <div class="PricesalesPriceShipment" style="display : block;">
                              <span
                                class="PricesalesPriceShipment"
                              >{{currencySymbol}}{{shipping_cost | currency}}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody class="poopc-payment-table">
                        <tr>
                          <td class="payment-heading" colspan="2" align="left">
                            <span class="vmpayment_name">Credit card</span>
                          </td>
                          <td class="col-total" align="right"></td>
                        </tr>
                      </tbody>

                      <tbody class="proopc-grand-total">
                        <tr class="cart-sub-total" v-if="TotalDiscount() > 0">
                        <td class="payment-heading" colspan="2" align="left"><span>Discount</span></td>
                        <td class="col-total" align="right">
                          <div class="PricesalesPrice PricesalePrice">
                            <span class="PricesalesPrice">{{currencySymbol}}{{TotalDiscount()}}</span> 
                          </div>
                        </td>
                        </tr>

                        <tr class="grand-total">
                          <td class="sub-headings" colspan="2" align="left">Total:</td>
                          <td class="col-total" align="right">
                            <div class="PricebillTotal" style="display : block;">
                              <span
                                class="PricebillTotal"
                              >{{currencySymbol}}{{totalCost() | currency}}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="proopc-confirm-order proopc-section">
                <h3 class="proopc-process-title">
                  <div class="proopc-step">6</div>Confirm Purchase
                </h3>



<div v-if="isHidden" class="termsoverlay">
    <span class="termsoverlay-inn" v-on:click="isHidden = !isHidden"></span>
    <div class="termsnew">
        <div class="terms-header">
            <h3>Terms & conditions</h3>
            <button v-on:click="isHidden = !isHidden"> x </button>
        </div>
        <div class="smart-container">
            <div style="display:flex;" class="l-main-wrapper mobileFullWidth clearfix">
                <div class="item-page acc-rightdiv">
                    <h1 style="color: #41525c; margin-bottom:0px;">
                        <br /> General terms and conditions 
                    </h1>
                    <img style="width:100%; margin-bottom: 5px;height:5px;" src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line"> 
                    <h1 style="color: #41525c; margin-bottom:0px;">1. Information on this website  </h1>
                    <br>
                    <p>
                        The material on this website including all fact sheets, case notes and publications is for information purposes only. It is general legal information and is not intended to be legal advice.
                    </p>
                    <p>
                        The Australian Copyright Council (ACC) makes every effort to ensure that all the information on this website is current and accurate, the ACC does not warrant as to its quality or accuracy.
                    </p>
                    <p>
                        The ACC is not liable to users of the website for any loss or damage resulting from its use, including negligence.
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">2. Downloading & printing fact sheets  </h1>
                    <br>
                    <p>
                        You may download and print one copy of documents available from this site, for your reference. For anything else, please contact us at <a href="mailto:info@copyright.org.au">info@copyright.org.au.</a> 
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">3. Linking  </h1>
                    <br>
                    <p>
                        You may provide a link to any of the pages on this site. The ACC recommends that you use the abbreviated form of the URL for linking: <a href="http://www.copyright.org.au/">http://www.copyright.org.au/ </a> plus the text after the final forward slash. Please do not provide a direct link to documents on our site; instead, link to the page from which the document can be downloaded.
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">4. PDF  </h1>
                    <br>
                    <p>
                        Most of the documents available from this site - including all our fact sheets - are in Adobe Acrobat (.pdf) form, which you can view with Adobe Acrobat Reader. You may download Adobe Acrobat Reader from Adobe's site at <a href="http://get.adobe.com/reader">http://get.adobe.com/reader.</a>
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">5. Caching</h1>
                    <br>
                    <p>
                        You are <strong>not</strong> permitted to cache any ACC webpages pages on a local server other than in the course of normal internet service.
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">6.  Copyright  </h1>
                    <br>
                    <p>
                        Unless otherwise stated, copyright in material on this website is owned by the Australian Copyright Council(ACC).
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">7. Privacy  </h1>
                    <br>
                    <p>
                        Our Privacy Policy can be found <a href="/privacy">here.</a>
                    </p>
                    <br>
                    <h1 style="color: #41525c; margin-bottom:0px;">Payment terms </h1>
                    <img style="width:100%; margin-bottom: 5px;height:5px;" src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line"> 
                    <h1 style="color: #41525c; margin-bottom:0px;">1. Payments  </h1>
                    <br>
                    <div class="terms-ul">
                        <p>
                            <strong>1.1.</strong> All monetary amounts referred to on this site <a href="www.copyright.org.au">www.copyright.org.au,</a> are quoted in Australian dollars unless specifically stated otherwise.
                        </p>
                        <br>
                        <p>
                            <strong>1.2.</strong> All payments made to the Australian Copyright Council (ACC) are subject to the terms and conditions on this site. The ACC must be notified in writing within 14 days of any payments made in error.
                        </p>
                        <br>
                        <p>
                            <strong>1.3.</strong> All payments submitted through the ACC website are processed by Westpac using
                            PayWay.
                        </p>
                        <br>
                        <p>
                            <strong>1.4.</strong> When purchasing from the ACC, card details are transmitted through a secure server using PayWay. The ACC does not store or retain any credit card data after processing.
                        </p>
                        <br>
                        <p>
                            <strong>1.5.</strong> Books will be posted after payment.
                        </p>
                        <br>
                        <p>
                            <strong>1.6.</strong> E-books will be released after payment.
                        </p>
                        <br>
                    </div>
                        <h1 style="color: #41525c; margin-bottom:0px;">2. Postage </h1>
                        <br>
                        <p>
                            All postage is calculated at the point of checkout and added to your cart. See costs and times in
                            <strong>Delivery Cost and Times</strong> section below.
                        </p>
                        <br>
                        <h1 style="color: #41525c; margin-bottom:0px;">3. Refunds  </h1>
                        <br>
                    <div class="terms-ul">
                        <p>
                            <strong>3.1.</strong> Please choose carefully, the ACC does not offer a refund if you simply change your mind. You are entitled to a refund, exchange or credit note if the product you have purchased is faulty or does not match the description of the product/service. The ACC requires notification within 3 business days of any faulty or damaged item.
                        </p>
                        <br>
                        <p>
                            <strong>3.2.</strong>Refunds, credits or exchanges may apply to our books, e-books, seminars, & webinars.
                        </p>
                        <br>
                        <p>
                            <strong>3.3.</strong> Booking conditions for our events can be found below.
                        </p>
                        <br>
                        <p>
                            <strong>3.4.</strong> The cost of return postage will be paid by the ACC for any return of a faulty product.
                        </p>
                        <br>
                        <p>
                            <strong>3.5.</strong>The ACC will not offer a store credit for products that are returned damaged.
                        </p>
                        <br>
                        <p>
                            <strong>3.6.</strong> If the item you received is not what you ordered, please notify us within 5 business days of the recorded delivery date.
                        </p>
                        <br>
                    </div>
                        <h1 style="color: #41525c; margin-bottom:0px;">4. Customised Training   </h1>
                        <br>
                    <div class="terms-ul">
                        <p>
                            <strong>4.1.</strong> Information regarding customised training costs is available on completion and return of the <a download="Customised_Training_Request_Form.docx" href="/static/ACC_doc/ACC_Customised_Training_Request_Form.docx"> training request form</a>  to <a style="color: blue;" href="mailto:info@copyright.org.au">info@copyright.org.au.</a>
                        </p>
                        <br>
                        <p>
                            <strong>4.2.</strong>Customised training is invoiced in advance with half the cost of the required as payment on finalising the booking.
                        </p>
                        <br>
                        <p>
                            <strong>4.3.</strong>The remainder of the invoice plus expenses must be paid prior to the delivery of any customised training.
                        </p>
                        <br>
                    </div>
                        <br>
                        <h1 style="color: #41525c; margin-bottom:0px;">Printed book delivery costs & times </h1>
                        <img style="width:100%; margin-bottom: 5px;height:5px;" src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line"> 
                        <h1 style="color: #41525c; margin-bottom:0px;">1. Delivery within Australia  </h1>
                        <br>
                        <p>
                            Delivery within Australia within 5-10 working days of receiving payment. Our deliveries are sent
                            through the Australia Post standard delivery service. When placing your order, please make sure
                            your delivery address is correct as we cannot take responsibility for deliveries that go missing
                            due to incorrect delivery addresses.
                        </p>
                        <strong>Postage costs:</strong>
                        <p>
                    <table class ="terms-table">
                        <tr>
                            <td>1 book </td>
                            <td>$10</td>
                        </tr>
                        <tr>
                            <td>2-4 books </td>
                            <td>$15</td>
                        </tr>
                        <tr>
                            <td>5+ books</td>
                            <td>$20</td>
                        </tr>
                    </table>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">2. Express orders  </h1>
                        <br>
                        <p>
                            If you would like your order express posted with Australia Post delivery service an additional $12
                            for every 3kg, is payable. To request this service, please contact us at <a style="color: blue;" href="mailto:info@copyright.org.au">info@copyright.org.au.</a> 
                            once you have placed your order.
                        </p>
                        <h1 style="color: #41525c; margin-bottom:0px;">3. International Shipping  </h1>
                        <br>
                        <p>
                            Postage is $30 per book. To request this service, please contact us at <a style="color: blue;" href="mailto:info@copyright.org.au">info@copyright.org.au.</a>
                            once you have placed your order.
                        </p>
                        <br><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">Seminars and webinars booking conditions</h1>
                        <img style="width:100%; margin-bottom: 5px;height:5px;" src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line"> 
                        <h1 style="color: #41525c; margin-bottom:0px;">1. Booking Confirmations </h1>
                        <br>
                        <p>
                        <ul>
                            <li>Booking confirmation is sent by email within 5 working days after receipt of your order.</li>
                            <li>Please ensure your email address is correct.</li>
                        </ul>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">2. Payment </h1>
                        <br>
                        <p>
                        <ul>
                            <li>Payment is required in full, 5 working days prior to the purchased seminar/webinar.</li>
                        </ul>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">3. Special Packages </h1>
                        <br>
                        <p>
                        <ul>
                            <li><strong>Early Bird registrations</strong>(where applicable) close at 11:59pm on the Early Bird end date. </li>
                            <li><strong>Full Day Packages - </strong>Registrants receive a 10% discount when registering for all sessions on a seminar day, where applicable.</li>
                        </ul>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">4. Cancellations & Non-attendance </h1>
                        <br>
                        <p>
                        <ul>
                            <li>A full refund is payable for cancellations made 10 or more working days before the
                                purchased seminar.
                            </li>
                            <li>A 50% refund is payable for cancellations made 6 to 9 working days before the
                                purchased seminar.
                            </li>
                            <li>No refund will be given for cancellations made within 5 working days before the
                                purchased seminar.
                            </li>
                            <li>No refund will be given for a booked session which is missed without prior notice.</li>
                        </ul>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">5. Continuing Professional Development (CPD) Certificates </h1>
                        <br>
                        <p>
                        <ul>
                            <li>Our sessions may meet requirements for continuing professional development. Please
                                check with your accreditation body.
                            </li>
                            <li>Certificates of CPD attendance can be provided upon request to <a href="mailto:info@copyright.org.au">info@copyright.org.au.</a></li>
                        </ul>
                        </p><br>
                        <h1 style="color: #41525c; margin-bottom:0px;">6. Refreshments </h1>
                        <br>
                        <p>
                        <ul>
                            <li>The ACC provides light refreshments for morning and afternoon tea, where
                                applicable. 
                            </li>
                            <li>Lunch is generally not provided.</li>
                        </ul>
                        </p><br>
                </div>
            </div>
        </div>
    </div>
</div>


<div v-if="isfalse" class="termsoverlay">
    <span class="tos-modal-main termsoverlay-inn" v-on:click="isfalse = !isfalse"></span>
    <div id="shop-terms-conditions fancy-tos-container" class="termsnew">
        <div class="terms-header fancy-tos-head test">
            <h3>Terms & conditions</h3>
            <button v-on:click="isfalse = !isfalse"> x </button>
        </div>
        <div class="smart-container">
            <div class="item-page acc-rightdiv">
            <div class="mobileFullWidth clearfix">
                <div class="l-main mobileFullWidthWithMargin">
                    <div class="item-page">
                        <h1 class="mb-0">
                            Shopping online   
                        </h1>
                        <img src="/static/images/acc/au_heading_underline.png" class="au-home-head-line" id="au-home-line" style="width: calc(100% - 40px); display:block; box-shadow: none; padding: 0 10px;height: 10px;margin: 5px 0px 10px; background-color:#41525c;">
                        <h2>Shopping online</h2>
                        <p>
                            Welcome to shopping online at allenandunwin.com. If this is your first time, please take a few moments to read the information below.&nbsp;
                        </p>
                        <h5>How to make a purchase</h5>
                        <p>
                            To find a specific title, you can search by title, author, ISBN or keyword in the quick search box at the top of the page, or click on 'Advanced Search' for more options. You can also <a href="/books">browse all our books by subject category</a>.
                        </p>
                        <p>To purchase, click the 'add to basket' button.</p>
                        <p>
                        You can keep adding items to your shopping basket as you browse the online shop.
                        </p>
                        <p>
                            Each time an item is added, your shopping basket in the top right hand corner will be updated. You are able to edit and delete items from your shopping basket at any time by clicking on 'My Cart'.
                        </p>
                        <p>
                            Nothing is finalised until you submit your payment details, so feel free to add and remove items until you are happy with your choices.
                        </p>
                        <p>
                            To pay for your items, return to your shopping basket (top right hand corner of the website) and you will be prompted to sign in or continue as a guest. You will also be required to enter delivery and payment details.
                        </p>
                        <p>
                            Allen &amp; Unwin receives online payments using the following credit cards: <strong>VISA</strong> and <strong>MASTERCARD</strong>.
                        </p>
                        <h5>Returns</h5>
                        <p>
                            Take care when selecting your items as<strong> we don’t offer refunds or exchanges for online purchases unless they are faulty</strong>. If you're unsure or have a question about one of our products, do <a href="/contact-us">contact us</a> and we'll get back to you as soon as we can.
                        </p>
                        <h5>Availability Guide</h5>
                        <p>
                            <strong>Not Yet Published:</strong> Unfortunately, we cannot take orders ahead of publication. Please check the book's publication month and return then to place your order.
                        </p>
                        <p>
                            <strong>Out of Print:</strong> The book is no longer being reprinted and we have no stock available for sale.
                        </p>
                        <p>
                            <strong>Print on Demand:&nbsp;</strong>The book is printed to order and takes up to 10 working days from the date your order is placed to be despatched. We will debit your credit card on the next working day after we receive your order. Please note that any other title/s ordered at the same time will be held until the print-on-demand title is also ready for despatch.
                        </p>
                        <h5><span style="line-height: 1.4;">Online security</span></h5>
                        <p>
                            All online purchases are carried out within a secure browser window that uses 128 bit encryption to protect your personal information from being intercepted by an unauthorised party.
                        </p>
                        <p>
                            A secure browser window is indicated by a locked padlock symbol that appears at the foot of the browser. The URL should also change, to start with 'https://...' instead of 'http://...'
                        </p>
                        <p>
                            Allen &amp; Unwin will never ask you to provide personal information like your bank details, credit card details, your address, driving licence or passport numbers via email.
                        </p>
                        <h5>Shipping</h5>
                        <table style="width: 100%;" border="1" cellpadding="5" align="left">
                            <tbody>
                                <tr>
                                <td class="table-head" style="width: 20%;" align="left" valign="middle">Country</td>
                                <td class="table-head" style="width: 20%;" align="left" valign="middle">Delivery type</td>
                                <td class="table-head" style="width: 20%;" align="left" valign="middle">Delivery time</td>
                                <td class="table-head" style="width: 20%;" align="left" valign="middle">First book</td>
                                <td class="table-head" style="width: 20%;" align="left" valign="middle">Each book thereafter</td>
                                </tr>
                                <tr>
                                <td>Australia and New Zealand</td>
                                <td>Australia Post e-Parcel</td>
                                <td>5-7 days</td>
                                <td>Free</td>
                                <td>Free</td>
                                </tr>
                                <tr>
                                <td>International / all other countries</td>
                                <td>1st Class Airmail</td>
                                <td>2-3 weeks</td>
                                <td>$10.00</td>
                                <td>$5.00</td>
                                </tr>
                                <tr>
                                <td>International / all other countries</td>
                                <td>Courier</td>
                                <td>7-10 days</td>
                                <td>$25.00</td>
                                <td>$10.00</td>
                                </tr>
                            </tbody>
                        </table>
                            <p>&nbsp;</p>
                            <p>The cost of shipping will be added to the value of your order.</p>
                            <p>
                                You order will be delivered by Australia Post unless you have selected to use international courier. <strong>Please note the above delivery times are a guide only and cannot be guaranteed as there may be delays with Australia Post or other carriers.</strong> For delivery within Australia &amp; New Zealand, please allow up to 14 days for your book to arrive.
                            </p>
                            <h5>Important shipping information</h5>
                            <p>
                                Other than international courier orders, all orders are despatched as e-parcels with Australia Post. E-parcels must be signed for on delivery, and if you are not available to sign, a card will be left, the parcel will be taken to your local post office and it will need to be collected by you. Books not collected will be returned to our distributors.
                            </p>
                            <p>
                                Packages sent by international courier requires a signature on delivery and therefore cannot be delivered to a PO box. We suggest you have the package sent to an address where someone is always there, like a work address if possible.
                            </p>
                            <h5>Customer service contact</h5>
                            <p>
                                If you have a question about payment, delivery or availability or books, please 
                                <a href="/contact-us">contact us</a> and we will get back to you as soon as possible. Please quote your order reference number so we can easily find your order.
                            </p>
                            <p>Alternatively, you can send an email to <a href="mailto:weborders@allenandunwin.com">weborders@allenandunwin.com</a></p>
                            <h5>Frequently asked questions</h5>
                            <p>Please see our <a href="/frequently-asked-questions">Shopping FAQs</a> page.</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>


          <div id="proopc-confirm-order">
            <div class="inner-wrap">
              <div class="checkout-button-top">
                <span class="error" v-if="tos_error">{{tos_error}}</span>
                <label for="tosAccepted" class="checkbox prooopc-tos-label proopc-row">
                  <input
                    class="terms-of-service"
                    id="tosAccepted"
                    type="checkbox"
                    name="tosAccepted"
                    v-model="tos_checked"
                    value="1"
                  />
                  <div v-if="host == 'acc'" class="terms-of-service-cont">
                    <a v-on:click="isHidden = !isHidden"
                      href="#proopc-tos-fancy"
                      class="terms-of-service"
                      
                    > Check the box to read and accept terms and conditions, then confirm your purchase below.</a>

                  </div>
                  <div v-else-if="host == 'murdoch'" class="terms-of-service-cont">
                    <router-link
                      class="terms-of-service"
                      :to="'/terms-conditions'"
                    > Check the box to read and accept terms and conditions, then confirm your purchase below.</router-link>

                  </div>
                  <div v-else class="terms-of-service-cont">
                    <a v-on:click="isfalse = !isfalse"
                      href="#shop-terms-conditions"
                      class="terms-of-service"
                      
                    > Check the box to read and accept terms and conditions, then confirm your purchase below.</a>

                  </div>
                </label>
                <div class="proopc-row proopc-checkout-box">
                  <button
                    class="proopc-btn n proopc-btn-info"
                    id="proopc-order-submit"
                    @click="confirmOrder"
                  >Confirm Purchase</button>
                  <!-- <button
                    class="proopc-btn n proopc-btn-info"
                    id="proopc-order-submit"
                    type="submit"
                  >Confirm Purchase</button>-->

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import Cart from "../mixins/Cart";
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import VuePhoneNumberInput from "vue-phone-number-input";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "checkout",
  mixins: [Cart],
  components: { LoopingRhombusesSpinner,VuePhoneNumberInput},
  data() {
    return {
      isfalse: false,
      isHidden: false,
      same_as_billing: true,
      page_loaded: false,
      hasErrorActive: false,
      hasLoaderActive: false,
      countries: [],
      shipping_methods: [],
      shipping_method_error: null,
      selected_shipping_method: null,
      shipping_cost: 0,
      coupon_discount: 0,
      tos_checked: false,
      tos_error: null,
      discount_coupon: null,
      billing_address_errors: [],
      generic_error: null,
      coupon_error: null,
      coupon_success: null,
      billing_address: {
        email: null,
        title: null,
        first_name: null,
        last_name: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        phone_number: null,
        organisation:null
      },
      shipping_address_errors: [],
      shipping_address: {
        email: null,
        title: null,
        first_name: null,
        last_name: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        phone_number: null,
        organisation:null
      },
      paymentPopup: null,
      host : '',
      state_choices: [
        'Australian Capital Territory',
        'New South Wales',
        'Northern Territory',
        'Queensland',
        'South Australia',
        'Tasmania',
        'Victoria',
        'Western Australia'
      ],
    };
  },
  methods: {
    calculateCouponDiscount() {
        let cart_id = this.$store.state.cart.basket_pk;
        let self = this;
        self.page_loaded = false;
        let coupon_code = self.discount_coupon;
        if (coupon_code != null && coupon_code != ''){
        axios
          .get("/calculate_voucher_discount/", {
            params: {
              cart_id: cart_id,
              coupon_code: coupon_code
            },
          })
          .then((res) => {
            self.page_loaded = true;
            if(res.data.status == 200){
              self.coupon_discount = res.data.discount
              self.coupon_error = null
              self.coupon_success = res.data.msg
            }
            else{
              self.coupon_success = null
              self.discount_coupon = null
              self.coupon_error = res.data.msg
              self.coupon_discount = 0
            }
          });
      }
    },
    check_cart_type(){
     let self = this
      let type = localStorage.getItem('cart_type');
      if (type == "book"){
        return false;
      }
      self.selected_shipping_method = "domestic";
      return true;
    },
    calculateShipping() {
      let cart_id = this.$store.state.cart.basket_pk;
      let self = this;
      self.page_loaded = false;
      let shipping_country = self.shipping_address.country;
      if (self.same_as_billing){
        shipping_country = self.billing_address.country;
      }
      axios
        .get("/calculate_shipping/", {
          params: {
            cart_id: cart_id,
            shipping_country: shipping_country,
            billing_country: self.billing_address.country,
            selected_shipping_method: self.selected_shipping_method,
          },
        })
        .then((res) => {
          self.countries = res.data.countries;
          self.shipping_methods = res.data.methods;
          self.shipping_cost = res.data.shipping_cost;
          self.page_loaded = true;
          if (
            !res.data.selected_shipping_method ||
            self.shipping_methods.lenght == 1
          ) {
            self.selected_shipping_method = self.shipping_methods[0].code;
          }
        });
    }, 
    totalCost() {
      return this.shipping_cost + this.cartTotal() - this.coupon_discount;
    },
    resetErrors() {
      this.shipping_address_errors = [];
      this.billing_address_errors = [];
      this.generic_error = null;
    },
    confirmOrder() {
      let self = this;
      let country_code = document.getElementsByClassName('country-selector__input')[0];
      let phone_number = document.getElementsByClassName('input-tel__input')[0];
      self.billing_address.phone_number = country_code.value+phone_number.value
      let cart_id = this.$store.state.cart.basket_pk;
      let email_input = document.getElementById("email_field");
      email_input.reportValidity();
      this.resetErrors();
      // check required fields
      if (
        !this.billing_address.email ||
        !this.billing_address.first_name ||
        !this.billing_address.last_name ||
        !this.billing_address.line1 ||
        !this.billing_address.postcode ||
        !this.billing_address.city ||
        !this.billing_address.state ||
        !this.billing_address.country ||
        !this.billing_address.phone_number
      ) {
        this.billing_address_errors.push("Please fill all required (*) fields");
        window.scrollTo(0, 0);
        return;
      }
      if (
        (!this.shipping_address.first_name ||
          !this.shipping_address.last_name ||
          !this.shipping_address.line1 ||
          !this.shipping_address.postcode ||
          !this.shipping_address.city ||
          !this.shipping_address.state ||
          !this.shipping_address.country ||
          !this.shipping_address.phone_number) &&
        !this.same_as_billing
      ) {
        this.shipping_address_errors.push(
          "Please fill all required (*) fields"
        );
        window.scrollTo(0, 0);
        return;
      }
      if (!self.tos_checked) {
        self.shipping_method_error = null;
        self.tos_error = "Please accept the Terms of Service";
        window.scrollTo(0, 0);
        return;
      } else if (!self.selected_shipping_method) {
        self.tos_error = null;
        self.shipping_method_error = "Please select a shipping method";
        window.scrollTo(0, 0);
        return;
      } else if (!this.billing_address.email || !email_input.checkValidity()) {
        self.shipping_method_error = null;
        self.tos_error = null;
        this.billing_address_errors.push("Invalid Email");
        window.scrollTo(0, 0);
        return;
      } else if(this.host == 'acc' && !this.billing_address.organisation){
        this.billing_address_errors.push("Please fill organisation");
        window.scrollTo(0, 0);
        return;
      } else {
        self.shipping_method_error = null;
        self.tos_error = null;
        axios
          .post("/validate_checkout_data/", {
            shipping_address: self.shipping_address,
            billing_address: self.billing_address,
            shipping_method: self.selected_shipping_method,
            cart_id: cart_id,
            same_as_billing: self.same_as_billing,
            coupon_code: self.discount_coupon,
          })
          .then((res) => {
            console.log(res);
            let domain = window.location.host;
            if(domain.indexOf('copyright') != -1){
                localStorage.setItem('guest_email',self.billing_address.email)
                window.location = 'https://www.payway.com.au/MakePayment?BillerCode=296590&merchant_id=26273995&payment_reference='+cart_id+'&payment_amount_minimum='+(this.totalCost()).toFixed(2)+'&payment_amount='+(this.totalCost()).toFixed(2)+'&receipt_address='+self.billing_address.email
            }
            else{
            // self.$store.dispatch("emptyCart");
            localStorage.setItem('guest_email',self.billing_address.email)
            let stripe = Stripe(res.data.stripe_key);
            stripe.redirectToCheckout({sessionId: res.data.stripe_session_id}).then( (res) =>{
            });
            }
            // self.$router.push("thank-you");
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 500) {
              self.generic_error =
                "Something went wrong, please reload the page and try again.";
            }
            self.shipping_address_errors =
              err.response.data.shipping_address_errors;
            self.billing_address_errors =
              err.response.data.billing_address_errors;
            window.scrollTo(0, 0);
          });
      }
    },
    getReplyUrl() {
      let domain = window.location.host;
      let shipping_address_obj = {};
      for (var key in this.shipping_address) {
        if (this.shipping_address.hasOwnProperty(key)) {
          shipping_address_obj[
            "shipping_address_" + key
          ] = this.shipping_address[key];
        }
      }
      let billing_address_obj = {};
      for (var key in this.billing_address) {
        if (this.billing_address.hasOwnProperty(key)) {
          shipping_address_obj["billing_address_" + key] = this.billing_address[
            key
          ];
        }
      }
      let params_dict = {
        ...shipping_address_obj,
        ...billing_address_obj,
        shipping_method: this.selected_shipping_method,
        cart_id: this.$store.state.cart.basket_pk,
        same_as_billing: this.same_as_billing,
      };
      let params = new URLSearchParams(params_dict).toString();
      return "https://" + domain + "/complete-transaction/?" + params;
    },
  },
  mounted() {
    // get host 
    this.host = this.$store.state.site_data.host;
    //get shipping methods here
    let self = this
    axios
      .get('/accounts/profile/')
      .then(function (response) {
        self.billing_address['email'] = response.data.email
        self.billing_address['first_name'] = response.data.first_name
        self.billing_address['last_name'] = response.data.last_name
        self.billing_address['line1'] = response.data.address
        self.billing_address['phone_number'] = response.data.phone
        self.billing_address['postcode'] = response.data.postal_code
        self.billing_address['country'] = "AU"
        self.billing_address['state'] = response.data.state
        self.billing_address['city'] = response.data.city
      })
    self.shipping_address['country'] = 'AU'
    self.billing_address['country'] = 'AU'
    let cart_id = this.$store.state.cart.basket_pk;
    let has_ecommerce = this.$store.state.site_data.ecommerce;
    if (!cart_id || !has_ecommerce) {
      this.$router.push("not-found");
    } else {
      this.calculateShipping();
    }
    this.billing_address.email = this.$store.state.guest_email;
  },
  computed: {
    getDomain() {
      return window.location.host;
    },
    getPaymentAlertEmail() {
      return process.env.VUE_APP_ECOMMERCE_EMAIL;
    },
  },
  watch: {
    "shipping_address.country": function (val) {
      this.selected_shipping_method = null;
      this.calculateShipping();
    },
    "billing_address.country": function (val) {
      this.selected_shipping_method = null;
      this.calculateShipping();
    },
    "same_as_billing": function (val){
      this.selected_shipping_method = null;
      this.calculateShipping();
    },
    selected_shipping_method: function (val) {
      this.calculateShipping();
    },
  },
};
</script>
  <style>
    .success {
    color: green;
  }
  .mb-0{
    margin-bottom: 0px;
  }
  
  .tos-modal table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 63%;
  }
  .tos-modal td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .tos-modal tr:nth-child(even) {
    background-color: #dddddd;
  }
  #proopc-tos-fancy {
      max-width: max-content;
  }
    .loader-container {
      display: flex;
    }
    .error {
      color: red;
    }
    .product-summary {
      /* padding: 0px !important; */
    }
    .proopc-section {
      margin: 0 0 20px 0;
    }
    .separator{
      height: 20px;
    }
    .left-alignment{
      float:left;
      margin-left: 5px;
    }
    .top-alignment{
      padding-top:10px;
    }
    .bottom-alignment{
      padding-bottom:10px;
    }
    .shipping-tbody{
      border: 2px solid #b9b5b5; 
      vertical-align: top;
    }
    h3.proopc-process-title {
      display: flex;
      color: #ed783b;
      align-items: center;
    }
    .proopc-step {
      width: 30px;
      background: #ed783b;
      height: 30px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #fff;
      margin-right: 10px;
      border-radius: 50%;
    }
    .proopc-finalpage {
      padding: 0px 10px;
    }
    .proopc-finalpage select {
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      max-width: 100%;
      border: 3px solid #e1e1e1;
      background: #fff;
      padding: 0.750em 1.250em;
      font-family: 'Arial';
      font-size: 1.143em;
      line-height: 1.438em;
      color: #8c9295;
      border-radius: 0;
      outline: none;
      height: 46px;
      border-color: #e1e1e1;
      border-width: 3px;
    }
    .proopc-finalpage input[type=text], input[type=password], input[type=email], textarea, .selecter .selecter-selected{
        width: 100%;
    }
    .proopc-input-append.proopc-row {
        display: flex;
    }
    label.checkbox.prooopc-tos-label.proopc-row {
        display: flex;
    }
    .checkout-save-btn{
      background: #ed783b;
      border: 0;
      border-radius: 0;
      text-shadow: none;
      box-shadow: none;
      color: #fff;
      padding: 6px 18px 7px;
      font: bold 14px 'Open Sans', sans-serif;
      filter: none;
      /* margin-top: 10px; */
    }
    .tos-modal {
    padding: 30px;
    overflow: auto;
    height: calc(100% - 60px);
    width: calc(100% - 60px);
    position: relative;
  }
  .v--modal-box {
      max-width: 47vw;
      width: 100% !important;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
      max-height: 59vh;
      height: 100vh !important;
      top: 72px !important;
      bottom: 0 !important;
  }
  #proopc-tos-fancy .fancy-tos-body {
      height: 100vh;
      max-height: 48vh;
  }
  .v--modal-box {
      max-width: 47vw;
      width: 100% !important;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
      max-height: 59vh;
      height: 100vh !important;
      top: 72px !important;
      bottom: 0 !important;
      background:#fff !important;
  }
  #proopc-tos-fancy .fancy-tos-body {
      height: 100vh;
      max-height: 48vh;
  }
  /*======== modal css ============*/
  .termsoverlay {
      position: fixed;
      z-index: 999;
      background: rgba(0,0,0,0.5);
      width: calc(100% - 20px);
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
  }
  .termsnew {
      background: #fff;
      max-width: 700px;
      width: 100%;
      padding: 15px;
      position: relative;
      border-radius: 8px;
      max-height: 80vh;
      overflow: auto;
  }
  .termsnew .smart-container {
      padding: 0;
  }
  .termsnew .acc-rightdiv {
      width: 100%;
  }
  .termsnew .l-main-wrapper {
      padding: 0;
  }
  .termsnew .l-main-wrapper {
      padding: 0;
  }
  .terms-header {
      position: sticky;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      top: -15px;
      padding: 15px 0 15px 0;
      background: #fff;
      border-bottom: 1px solid #ddd;
      z-index: 9;
  }
  span.termsoverlay-inn {
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
  }
  .terms-header h3 {
      margin: 0;
  }
  .terms-header button {
      background: #fff;
      border: 1px solid #ddd;
      font-size: 17px;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      cursor:pointer;
  }
  .termsnew .l-main-wrapper {
      width: 100%;
  }
  .phone_1-group .country-selector__input:visited{
    border-color: #cbd4dc;
  }
  #ProOPC input[type="tel"]{
    caret-color: #cbd4dc;
    min-height: 32px ;
    border-color: #cbd4dc;
    box-shadow: #cbd4dc;
  }
  #ProOPC input[type="tel"]:hover{
    border-color: #cbd4dc;
    min-height: 32px ;
  }
  </style>