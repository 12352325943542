<template>
<div class="st-menu">
  <div class="l-mobile-navigation mobileOnly">
    <div class="l-mobile-navigation__top">
      <search-bar></search-bar>
      <cart :show_extra_link='true'></cart>
    </div>
    <div class="clear"></div>
    <div class="moduletable mobile-main-menu">
      <header-menu></header-menu>
    </div>
  </div>
</div>
</template>

<script>
import HeaderMenu from "../components/HeaderMenu";
import Cart from '../components/Cart';
import SearchBar from "../components/SearchBar"

export default {
    name: 'mobile-version',
    components: {
      HeaderMenu,
      Cart,
      SearchBar
    },    
};
</script>

<style>
</style>