//this file needs to be maintained with all the domains of from all environments

export default {
    resolve_host(domain){
        let hosts = {
            'allenandunwin': process.env.VUE_APP_ALLENANDUNWIN,
            'allenandunwinnz': process.env.VUE_APP_ALLENANDUNWINNZ,
            'murdochuk': process.env.VUE_APP_MURDOCHUK,
            'murdoch': process.env.VUE_APP_MURDOCH,
            'acc': process.env.VUE_APP_ACC,
            'affirm': process.env.VUE_APP_AFFIRM,
        };

        let host = null;
        for(var key in hosts) {
            if(hosts.hasOwnProperty(key)){
                if (domain == hosts[key]){
                    host = key;
                }
            }
        }

        return host;
    }
}

/*

'allenandunwin': 'A&U',
'allenandunwinnz': 'A&U NZ',
'murdoch': 'Murdoch',
'murdochuk': 'Murdoch UK'

*/