<template>
  <div class="container">
   <div class="res-align">
     <div></div>
     <div class="l-main-block mobileFullWidth clearfix w-custom">
       <div class="acc-leftsidebar"></div>
       <img src="/static/css/images/acc/heading_underline.png" class="acc-home-head-line" style="width: 100%; margin-bottom: 5px;">
       <div class="mobileFullWidthWithMargin">
         <looping-rhombuses-spinner :animation-duration="2500" :rhombus-size="15" color="#febe10" v-if="show_spinner" class="loader-spinner" />
         <div v-if="is_data">
           <div v-if="!show_spinner">
             <h4 class="huge-ttl">
               <p>{{event.name}}</p>
             </h4>
             <p class="py-4" v-html="event.description"></p>
             <div v-html="event.full_description"></div>
             <div v-if="is_cost > 0">
               <h4 class="huge-ttl">
                 <p>Please select the sessions you would like to attend</p>
               </h4>
               <div class="activity_main">
                 <div class="custom_flex" v-for="activity in event_activities">
                   <div v-if="show_event_cart(activity.activity_meta)==true" class="left_button">
                     <AddEvent :pop_up="activity.pop_up_flag" :activity_id="activity.id" :isbn="fetch_value(activity.activity_meta)" :title="activity.title" :type="type()" :value="fetch_cost(activity.activity_meta)" class="add_btn"></AddEvent>
                   </div>
                   <div class="right_text">
                     <!-- <div>{{activity.start}}</div> -->
                     <div>{{UpdateDateTime(activity.start)}} - {{UpdateEndDateTime(activity.end)}} AEDT</div>
                     <div>
                       <b>{{activity.title}}</b>
                     </div>
                     <div>
                       <p v-html="activity.description"></p>
                     </div>
                   </div>
                 </div>
               </div>
               <div class="submit_btn01">
                 <router-link class="btn_pink" :to="{ name: 'cart'}">Complete your registration</router-link>
               </div>
             </div>
           </div>
         </div>
         <div class="error_msg" v-else>
           <span class="mySlides slides">No activities found</span>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>
<script>
import axios from "axios";
import Vue from 'vue';
import AddEvent from '../components/AddEvent'
import { LoopingRhombusesSpinner } from "epic-spinners";
export default {
  name: "Activities",
  components: {
    LoopingRhombusesSpinner,
    AddEvent
  },
  data() {
    return {
        event: {
      },
      show_spinner: true,
      event_activities:[],
      value:[],
      ev_act_id :'',
      is_data :false,
      isOpen: false,
      is_cost: ''
    };

  },
  methods: {
    // get_index(index){
    //   console.log(index + 1)
    //   return index + 1
    // }
    type(data){
      return 'event_cart'
    },
    UpdateEndDateTime(date) {
      return Intl.DateTimeFormat(
            'en',
            {
              timeZone: 'Australia/Sydney',
              hour: 'numeric'
            }
          ).format(new Date(date))
    },
    UpdateDateTime(date) {
      let weekday = Intl.DateTimeFormat(
            'en',
            {
              timeZone: 'Australia/Sydney',
              weekday: 'long'
            }
          ).format(new Date(date))
      let date_obj = Intl.DateTimeFormat(
            'en',
            {
              timeZone: 'Australia/Sydney',
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }
          ).format(new Date(date))
      let hour = Intl.DateTimeFormat(
            'en',
            {
              timeZone: 'Australia/Sydney',
              hour: 'numeric'
            }
          ).format(new Date(date))
      return `${weekday} ${date_obj} - ${hour}`
    },
    show_event_cart(activity_meta) {
            for (let i = 0; i < activity_meta.length; i++) {
              let event = activity_meta[i];
              let status = false
              if(event){
                if(event['label'] == 'catalogueproduct'){
                status = true
                console.log('found paid event !')
                return status
                }
              }
              else{
                status = false
                console.log('not found any paid event !')
              }
            }
        },
    fetch_cost(activity_meta) {
            for (let i = 0; i < activity_meta.length; i++) {
              let event = activity_meta[i];
              let status = false
              if(event){
                if(event['label'] == 'cost'){
                return event.value
                }
              }
              else{
                console.log('not found event.label.value not == cost ?')
              }
            }
        },
    fetch_value(activity_meta) {
      for (let i = 0; i < activity_meta.length; i++) {
        let event = activity_meta[i];
        let status = false
        if(event){
          if(event['label'] == 'catalogueproduct'){
            return event.value
          }
        }
        else{
            console.log('not found event.label not == catalogueproduct !')
          }
      }
    }
  },
  computed: {},
  mounted() {
    let self = this;
    let id = this.$route.params.id;
    self.ev_act_id = id
    axios
      .get(`/get_activities/${id}/`)
      .then(function (data) {
        if (data.data.data.length > 0){
          self.event = data.data.data[0]['event'];
          self.event_activities = data.data.data;
          self.value = data.data.data[0]['activity_meta'];
          self.is_cost = self.value[0]['value'];
          self.is_data = true
          self.show_spinner = false;
          let slides = document.getElementsByClassName("mySlides")
          slides.visible = false;
        }
        else{
          let slides = document.getElementsByClassName("slides")
          slides.visible = true;
          self.is_data = false
          self.show_spinner = true;
        }
    })
  }
};
</script>
<style>
  .border-orange {
    width: 63%;
      left: 17.5%;
      top: 110px;
  }
  #app .w-custom{
    width: 800px;
      padding-right: 0px;
      margin: 60px 125px 0 0;
  }
  .py-4{
    padding: 40px 0 20px;
  }
  .res-align{
    display: grid;
    grid-template-columns: 165px auto;
  }
  .huge-ttl{
    margin-bottom: 0px;
  }
  .huge-ttl p {
      font-size: 34px;
      font-weight: 500;
      color: #000;
      margin-bottom: 0px;
  }
  .custom_flex {
      display: flex;
      margin: 30px 0 0;
  }
  .left_button {
      margin-right: 20px;
  }
  .add_btn{
    font-size: 14px;
      padding: 10px 25px;
      border: 1px solid transparent;
      /*border-radius: 3px;*/
  }
  .submit_btn01 {
      margin: 60px 0 40px;
  }
  .btn_pink {
      font-size: 14px;
      padding: 10px 25px;
      border: 1px solid transparent;
      /*border-radius: 3px;*/
      background: #f6793c;
      color: #fff;
      font-weight: 700;
  }
  .error_msg {
      text-align: center;
      display: none;
  }
  .error_msg span {
      font-size: 34px;
      font-weight: 700;
      text-shadow: 0px 2px 12px #b1b1b1;
      color: #000000;
      padding: 6rem 0;
      float: left;
      width: 100%;
  }
  .modal {
    width: 220px;
    margin: 0 auto;
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 4px 0px;
    box-shadow: 0 2px 4px 0px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    font-family: Helvetica,Arial,sans-serif;
  }
  .fadeIn-enter {
    opacity: 0;
  }

  .fadeIn-leave-active {
    opacity: 0;
    transition: all 0.2s step-end;
  }

  .fadeIn-enter .modal,
  .fadeIn-leave-active.modal {
    transform: scale(1.1);
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 18%);
    z-index: 999;
    transition: opacity 0.2s ease;
  }
  .modal-red-btn{
    color: #cf1919;
    cursor: pointer;
  }
  .modal-close{
    text-align: end;
    font-weight: 600;
    cursor: pointer;
    margin: 0;
  }
</style>