<template>
  <swiper :options="swiperOption" class="main-carousel">
    <looping-rhombuses-spinner
      :animation-duration="2500"
      :rhombus-size="30"
      color="#febe10"
      v-if="slides.length == 0"
      class="loader-spinner"
    />
    <swiper-slide v-for="slide in slides" class="car-slide">
      <div class="swipper-image">
        <a :href="slide.url">
          <img :src="slide.banner_image" @error="noCoverImage" />
        </a>
      </div>
    </swiper-slide>
    <!-- <swiper-slide class="car-slide" v-if="slides.length < 1">
      <div>
        <img src="/static/website_image2.png" alt @error="noCoverImage" />
      </div>
    </swiper-slide>-->
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { LoopingRhombusesSpinner } from "epic-spinners";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";

export default {
  name: "home-banner",
  components: {
    swiper,
    swiperSlide,
    LoopingRhombusesSpinner
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        keyboard: {
          enabled: true
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      slides: []
    };
  },
  methods: {
    noCoverImage: function(event) {
      if (this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
          event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_banner_Image.jpg";
      }
      else{
          event.target.src = "/static/Holding-Image-DIMO.jpg";
      }
      
    }
  },
  mounted() {
    let self = this;
    let host = this.$store.state.site_data.host;

    axios
      .get(`/get_banner_data/?${host}`, {
        params: {
          slide_limit: 5,
          page: this.$route.path
        }
      })
      .then(function(data) {
        if (data.status == 200) {
          self.slides = data.data.slides;
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }
};
</script>

<style>
.main-carousel {
  display: block;
}

.car-slide {
  background-color: #f6f6f6;
  display: flex;
}

.swipper-image > img {
  max-width: 250px;
}
.swippe-contents {
  padding-left: 15px;
  padding-top: 20px;
}
.loader-spinner {
  margin: 0 auto;
  padding-top: 13%;
}
</style>