import Currency from "../mixins/Currency"

export default {
    mixins: [Currency],
    computed: {
        cartProductNum() {
            let total_qty = 0;
            for (let i = 0; i < this.$store.state.cart.lines.length; i++) {
                const line = this.$store.state.cart.lines[i];
                total_qty += line.quantity;
            }
            return total_qty;
            // return this.$store.state.cart.lines.length;
        },
        cartLines() {
            return this.$store.state.cart.lines;
        },
        getCart(){
            return this.$store.state.cart;
        }
    },
    methods: {
        productTotalPrice(product) {
            return product.price * product.quantity;
        },
         TotalDiscount() {
            return parseInt(this.$store.state.cart.discount_applied);
        },
        cartTotal() {
            let products = this.$store.state.cart.lines;
            let discount_amount = parseInt(this.$store.state.cart.discount_applied);
            let total = 0.0;
            for (let i = 0; i < products.length; i++) {
                const product = products[i];
                total += product.quantity * product.price;
            }
            if (discount_amount > 0){
                return total - discount_amount;
            }
            return total;
        },
    },
    created(){
        let self = this;
        self.$store.dispatch("checkCartStatus").then(() => {
            console.log("cart updated");
          });
    },
    filters: {
        currency(amount) {
            const amt = Number(amount);
            const convert_amt = amt.toFixed(2)
            if(convert_amt == 0){
                return 'FREE'
            }
            return (
                (convert_amt && convert_amt.toLocaleString(undefined, { maximumFractionDigits: 2 })) ||
                "0"
            );
        }
    }
}