import BestSellerList from '../components/BestSellerList';
import HomeBanner from '../components/HomeBanner.vue';
import BlogCard from '../components/BlogCard.vue';
import EventItem from '../components/EventItem.vue';

import axios from 'axios'

export default {
  data() {
    return {
      blog_posts: [],
      events: []
    }
  },
  components: {
    BestSellerList,
    HomeBanner,
    BlogCard,
    EventItem
  },
  methods: {
    get_blog_posts(type, obj, limit) {
      let self = this;
      axios
        .get("/blog_posts/", {
          params: {
            display: 'homepage',
            type: type,
            limit: limit
          }
        })
        .then(function (response) {
          var data  = response.data.blog_cards_list;
          for (var i = 0; i < data.length; i++) {
            var new_dict = data[i]
            new_dict["id"]=i
            self[obj].push(new_dict)
            }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  },
  mounted() {
    this.get_blog_posts('news', 'blog_posts', this.blog_posts_limit);
    this.get_blog_posts('event', 'events', 5);
  }

}