<template>
  <section class="mb-5">
    <looping-rhombuses-spinner
      :animation-duration="2500"
      :rhombus-size="15"
      color="#febe10"
      v-if="authors.length == 0"
      class="loader-spinner"
    />
    <div class="">
      <div class="row">
        <div class="c0l-12">
          <div class="author-grid mt-3">
            <div class="my-3" v-for="author in authors">
              <div
                class="img-card overflow-hidden rounded-circle border author-img_box mx-auto d-flex align-items-center justify-content-center"
              >
                <div class="inner-img text-center">
                  <router-link :to="{ path: '/author/' + author.idauthor }">
                    <img
                      v-if="author.author_image"
                      v-bind:src="author.author_image"
                      alt=""
                      style="width: 100%"
                      class="transition"
                      @error="noCoverImage"
                    />
                    <img
                      v-else
                      v-bind:src="noCoverImage"
                      alt=""
                      style="width: 100%"
                      class="transition"
                      @error="noCoverImage"
                    />
                  </router-link>
                </div>
              </div>
              <div class="mt-2">
                <h5 class="fw-normal text-center s-h7 mb-1">
                  {{ author.firstname }} {{ author.lastname }}
                </h5>
              </div>
              <div class="text-center">
                <router-link :to="{ path: '/author/' + author.idauthor }">
                  <img
                    src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                    alt="arrow"
                    width="24px"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" @click="author_page_data()" v-if="authors.length >= 10">
        <div class="col-12">
          <div class="link --text-dark">
            <div class="text-center mt-5 cursor-pointer">
              <p class="--text-dark text-center mb-2">Load more</p>
              <div class="text-center">
                <img
                  src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                  alt="arrow"
                  width="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured authors -->
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
export default {
  name: "affirm-author-pages",
  props: ["active_menu"],
  components: {
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      authors: [],
      test: [],
      page: 1,
    };
  },
  methods: {
    author_page_data() {
      let self = this;
      axios
        .get(`/get_author_data/?page=${self.page}`)
        .then(function (data) {
          self.test = data.data.authors;
          if (self.page > 1) {
            for (let author of self.test) {
              self.authors.push(author);
            }
          } else {
            self.authors = self.test;
          }
          self.page++;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    noCoverImage: function (event) {
      event.target.src =
        "/static/css/images/affirm/author_photo_coming_soon.jpg";
    },
  },
  mounted() {
    this.author_page_data();
  },
};
</script>
