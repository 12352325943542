<template>
  <ul class="menu navbar-nav ms-auto mb-2 me-4 mb-lg-0">
    <li class="deeper parent level1 nav-item" v-for="menu_parent in headerMenu">
      <router-link class="nav-link" :to="menu_parent.url"
        >{{ menu_parent.title }}
      </router-link>
      <span v-if="menu_parent.categories != ''" class="mobileOnly">
        <i data-action="toggle" class="icon-indicator"></i>
      </span>
      <ul class="sub-ul">
        <li class="level2 nav-item" v-for="sub_menu in menu_parent.categories">
          <router-link class="nav-link" :to="sub_menu.url">{{
            sub_menu.title
          }}</router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import MenuMixin from "../mixins/Menu";

export default {
  name: "header-menu",
  mixins: [MenuMixin],
};
</script>

<style>
@media only screen and (max-width: 767px) {
  ul.menu li {
    position: relative;
  }
  ul.menu li a {
    width: 80%;
  }
  ul.menu li span {
    padding-right: 0px !important;
    max-width: 10%;
    width: 7%;
    position: absolute;
    overflow: hidden;
    top: 0px;
    right: 0px;
    height: 17px;
  }
  .mobile-main-menu .level1:hover .sub-ul {
    display: block !important;
  }
}
</style>
