<template>
  <section id="register" class="register -bg-green py-5">
    <div
      class="--text-dark h-100 d-flex align-items-center justify-content-center"
    >
      <div class="st-container">
        <!-- start bookselller -->
        <div
          class="d-flex justify-content-center align-items-center --text-dark"
        >
          <div>
            <div class="text-center mb-3 mb-lg-5">
              <h1 class="s-h2 --text-dark">Register</h1>
            </div>
            <div class="d-flex justify-content-center">
              <form class="">
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="Last name"
                  />
                </div>
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="Business"
                  />
                </div>
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="ADS ID"
                  />
                </div>
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="Password"
                  />
                </div>
                <div class="mb-3 w-388">
                  <input
                    class="form-control form-control-lg rounded-0 -h-50"
                    type="text"
                    placeholder="Confirm Password"
                  />
                </div>
                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    class="form-check-label s-h8 --text-dark"
                    for="exampleCheck1"
                    >Accept
                    <a
                      href="#"
                      class="--text-dark link text-decoration-underline"
                      >Terms & Conditions</a
                    ></label
                  >
                </div>
                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    class="form-check-label s-h8 --text-dark"
                    for="exampleCheck1"
                    >Receive bookseller information</label
                  >
                </div>
                <button
                  class="btn --bg-dark text-white rounded-0 d-flex justify-content-center align-items-center -h-50 w-100 mt-5 register-btn"
                >
                  <img
                    src="/static/css/images/affirm/light-arrow2-svg.png"
                    alt="arrow-right-icon"
                    class="me-3 text-white"
                    width="25px"
                  />
                  <p class="s-h6 mb-0 me-4 --text-white">Register</p>
                </button>
                <div class="pt-4 text-center">
                  <p class="s-h6 --text-dark">
                    Already have an account?<a
                      href="#"
                      class="--text-dark link text-decoration-underline"
                    >
                      <router-link
                        to="/login"
                        class="--text-dark link text-decoration-underline"
                        >Login here.</router-link
                      ></a
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- end bookselller -->
      </div>
    </div>
  </section>
  <!-- End Featured authors -->
</template>
<script>
export default {
  name: "affirm-signup",
  methods: {},
  mounted() {
    let add_new_class = document.getElementById("affirm-header");
    let add_new_class_footar = (document.getElementsByTagName(
      "footer"
    )[0].style.display = "none");
    let remode_class = document.getElementById("for-affirm");
    add_new_class.classList.add("-bg-green");
    remode_class.classList.remove("container");
  },
  data() {
    return {};
  },
};
</script>
