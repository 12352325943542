import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default {
  components: { VueRecaptcha },
  data() {
    return {
      captchaComplete: false,
      recaptchaToken: null,
      status: "",
    };
  },
  methods: {
    submit: function (post_url) {
      let self = this;
      if (self.checkedNames == false) {
        self.errors = "Privacy Policy is Required";
        self.status = "";
        return;
      }
      self.status = "submitting";
      let form = document.getElementById("ff_form1");
      let formData = new FormData(form);

      if (!self.recaptchaToken) {
        self.errors.push(
          "Captcha must be completed before you can submit the form"
        );
        self.$refs.recaptcha.reset();
        self.status = "";
        return;
      }
      formData.append("recaptchaToken", self.recaptchaToken);

      axios
        .post(post_url, formData)
        .then(function (res) {
          self.status = "submitted";
          self.errors = null;
        })
        .catch(function (err) {
          if (err.response.data.msg == "captcha_error") {
            self.errors = err.response.data.errors;
          } else {
            self.errors = JSON.parse(err.response.data.errors);
          }
          self.$refs.recaptcha.reset();
          self.status = "";
        });
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.captchaComplete = true;
      this.recaptchaToken = recaptchaToken;
    },
  },
};
