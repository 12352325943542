
<template>
  <div>
    <div class="container">
      <div class="mobmenu">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" @click="isShowing ^= true" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
        </span>
        <ul v-show="isShowing">
          <li>
            <a href="/resources">Resources</a>
          </li>
          <li>
            <a href="/training/">Training</a>
          </li>
          <li>
            <a href="/legal-advice/">Legal Advice</a>
          </li>
          <li>
            <a href="/about_us">About Us</a>
          </li>
          <li>
            <router-link style="color:white;" class="button orng-wte-c bold-f-w" :to="{ name: 'login'}">LOG IN</router-link>
          </li>
        </ul>
      </div>
      <div class="topnav top-p grid-d">
        <div class="logo">
          <a href="/">
            <img src="/static/css/images/acc/acclogo.png" class="logo-position">
          </a>
          <hr v-if="$route.name !='static' && $route.path !='/' " class="hr_line test">
        </div>
        <div class="mobileHide text-r login">
          <HeaderMenu></HeaderMenu>
          <div class="searchsec inline-b-d">
            <button class="searchtoogle" v-on:click="searchData">
              <img src="/static/images/search.png">
            </button>
            <div class="search-bottom" id="searchinput" style="display: block;">
              <div class="searchinner">
                <input class="search-input" type="text" v-model="searchForm.search" name="search" v-on:keyup="enterKeyAsClick" palceholder="search">
                <button v-on:click="hrefData">
                  <img src="/static/css/images/icon/search.svg">
                </button>
              </div>
            </div>
          </div>
          <div class="header-b text-r">
            <div class="logedin-user" v-if="user">
              <h3>
                <router-link :to="'/profile'">{{ user.username }}</router-link>
              </h3>
              <button class="button orng-wte-c bold-f-w" v-on:click="logout">LOGOUT </button>
            </div>
            <div style="display:flex;" v-else>
              <router-link style="color:white;" class="button orng-wte-c bold-f-w" :to="{ name: 'login'}">LOG IN</router-link>
            </div>
            <cart :show_extra_link='true'></cart>
          </div>
        </div>
        <div class="mobile-icon-v">
          <label id="open-side-bar" class="mobile-menu-label mobileOnly top-p"></label>
        </div>
      </div>
      <div v-if="$route.path !='/' ">
        <AccHeaderbar></AccHeaderbar>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
<script>
  import axios from 'axios'
  import layoutMixin from "../mixins/Layout";
  import HeaderMenu from "../components/HeaderMenu";
  import Static from '../views/Static';
  import AccHeaderbar from "../components/AccHeaderbar";
  import cart from "../components/acc_cart";

  export default {
    name: 'acc-header',
    components:{
      AccHeaderbar,
      HeaderMenu,
      cart
    },
    data(){
      return {
        searchForm:{
          search:null,
        },
        isShowing:false
      }
    },
    methods:{
      hrefData() {
        let url = "/search?page=1&keyword="+this.searchForm.search;
        window.location.replace(url);
      },
      enterKeyAsClick: function(e) {
        if (e.keyCode === 13) {
          let url = "/search?page=1&keyword="+this.searchForm.search;
          window.location.replace(url);
        }
      },
      searchData() {
        var x = document.getElementById("searchinput");
        if (x.style.display === "none") {
          x.style.display = "block";
          $('.search-input').focus();
        } else {
          x.style.display = "none";
        }
      },
      logout() {
        let self = this

        localStorage.removeItem('username')
        localStorage.removeItem('user_id')
        localStorage.removeItem('user_key')

        const username = localStorage.getItem('username')
        const id = localStorage.getItem('user_id')
        const key = localStorage.getItem('user_key')
        self.user = null

        axios
        .post(`/dj-rest-auth/logout/`)
        .then(function (response) {
          self.$store.dispatch('clearUser')
          self.$router.push('/')

        })
        .catch(function (err) {
        })
      },
    },
    mounted: function () {
      this.host = this.$store.state.site_data.host
      let resource_search = document.getElementById('signup-home-button');
      let url = window.location.href;
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        var x = document.getElementById("searchinput");
        x.style.display = "none";
      })
    },
    computed: {
      user () {
        let user = this.$store.state.user
        const username = localStorage.getItem('username')
        const id = localStorage.getItem('user_id')
        const key = localStorage.getItem('user_key')

        if (user && !user.username && username) {
          user = {
            username: username,
            id: id,
            key: key,
          }

        } else if (user && !user.username && !username) {
          user = null
        }

        return user
      }
    }
  };
</script>


<style>

  .searchsec {
    width: 30px;
    position: relative;
    z-index: 9999 !important;
  }
  .orng-wte-c{
    background-color: #f6793c;
    color: white;
    border-radius: 2px;
  }
  .search-bottom {
    position: absolute;
    background: #fff;
    right: 0;
    top: 22px;
    display: flex;
    padding: 0px;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
    display: none;
    z-index: 999999;
    width:220px;
  }
  .searchinner{
    display: flex;
  }
  .search-bottom button {
    width: 40px;
    background: #f6793c;
    border-radius: 0 4px 4px 0;
    border: 1px solid #f6793c;
  }
  .search-bottom input {
    padding: 6px 10px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #f6793c;
  }
  .search-bottom button img {
    width: 17px;
  }
  button.searchtoogle {border: none;display: flex;background: transparent;cursor: pointer;}
  button.searchtoogle img {
    width: 15px;
  }
  .logedin-user {
    display: inline-flex;
    align-items: center;
  }
  .header-b.text-r {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .logedin-user h3 {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
      .l-header .wrapper:after {
        right: -47px !important;
        height: 7px !important;
      }
      .mobmenu {
        /* position: relative; */
        float: right;
        z-index: 99;
        margin-top: 21px;
      }

    .mobmenu ul {
      margin: 0;
      padding: 16px;
      list-style: none;
      display: none;
      position: absolute;
      background: #fff;
      width: 90%;
      z-index: 99;
      left: 0;
      right: 0;
      margin-top: 77px;
      margin: auto;
      top: 93px;
      box-shadow: 0 4px 14px rgba(0,0,0,0.1);
      border-radius: 10px;
      display:none;
    }

    .mobmenu ul li a {
      color: #777;
      padding: 5px;
      display: block;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }

    .mobmenu:hover ul {
      display: block;
    }
    .sub-content{
      display:none;
    }

  }

  @media screen and (min-width: 770px) {
    .mobmenu{
      display:none;
    }
  }
  
</style>
