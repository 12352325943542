import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import SearchCategory from '../views/SearchCategory.vue'
import BrowseBook from '../views/BrowseBook.vue'
import Static from '../views/Static'
import ContactUs from '../views/ContactUs.vue'
import ReviewRequests from '../views/ReviewRequests.vue'
// import Booksellers from '../views/Booksellers.vue'
import InspectionCopy from '../views/InspectionCopy.vue'
import Newsletter from '../views/Newsletter.vue';
import NotFound from '../views/NotFound.vue';
import Cart from '../views/Cart.vue';
import Checkout from '../views/Checkout.vue';
import ThankYou from '../views/ThankYou.vue';
import News from '../views/News.vue';
import Events from '../views/Events.vue';
import AdvancedSearch from '../views/AdvancedSearch.vue';
import Competitions from '../views/Competitions.vue';
import BrowseKids from '../views/BrowseKids.vue';
import Login from '../views/Login_au.vue';
import Fiction from '../views/fiction.vue';
import Non_fiction from '../views/non_fiction.vue';
import SignUp from '../views/Signup_au.vue';
import ConfirmEmail from '../views/ConfirmEmail.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import SetNewPassword from '../views/SetNewPassword.vue';
import VogelsEntry from '../views/VogelsEntry.vue';


const isAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home" }]
    },
    component: Home
  },
  {
    path: "/browse",
    component: Search,
    name: 'browse',
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
        { name: "" }
      ]
    },
  },
  {
    path: '/browse/books/fiction',
    name: 'fiction',
    component: Fiction,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Fiction'}]
    },
  },
  {
    path: '/browse/books/non-fiction',
    name: 'non-fiction',
    component: Non_fiction,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Non-Fiction'}]
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Login User'}]
    },
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUp,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'SignUp User'}]
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Forget Password'}]
    },
  },
  {
    path: '/reset/confirm/:uid/:token',
    name: 'set-new-password',
    component: SetNewPassword,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Set New Password'}]
    },
  },
  {
    path: '/account-confirm-email/:key',
    name: 'confirm-email',
    component: ConfirmEmail,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Confirm Emails'}]
    },
  },
  {
    path: "/browse/books/childrens",
    component: BrowseKids,
    name: 'browse-kids',
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Childrens", link: "search" },
      ]
    },
  },
  {
    path: '/browse/books/:url(\\d+)',
    name: 'browse-book',
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: 'left'
    },
  },
  {
    path: '/browse/books/:term',
    name: 'category-search',
    component: SearchCategory,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ]
    },
    children: [
      {
        path: ':page',
        component: SearchCategory,
        meta: {
          Breadcrumb: [
            { name: "Home", link: "home" },
            { name: "Browse", link: "search" },
          ]
        }
      }
    ]
  },
  {
    path: '/browse/news',
    name: 'news',
    component: News,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'News' }
      ]
    },
  },
  {
    path: '/browse/news/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "News", link: "news"}
      ]
    }
  },
  {
    path: '/browse/events',
    beforeEnter(to, from, next) {
      window.open('https://www.allenandunwinblog.com/events', '_blank');
      next(false); // Prevent the router from continuing with navigation
    }
  },
  {
    path: '/browse/events',
    name: 'events',
    component: Events,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'Events' }
      ]
    },
  },
  {
    path: '/browse/events/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "Events", link: "events"}
      ]
    }
  },
  {
    path: '/browse/competitions',
    name: 'competitions',
    component: Competitions,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: 'Competitions' }
      ]
    },
  },
  {
    path: '/browse/competitions/:path*',
    component : Static,
    meta: {
      static: true,
      breadcrub: [
        {name: "Home", link: "home"},
        {name: "Competitions", link: "competitions"}
      ]
    }
  },
  {
    path: '/browse/book/:url',
    name: 'browse-book',
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: 'left'
    },
  },
  {
    path: '/browse/:path*',
    name: 'browse-static',
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
      ]
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Search" }]
    },
  },
  {
    path: "/newsletters",
    name: "newsletters",
    component: Newsletter,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Subscribe to Newsletter' }]
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Contact Us" }]
    },
  },
  {
    path: "/404/",
    name: "not-found",
    component: NotFound,
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home", link: "home" }]
    },
  },
  {
    path: '/au-media/review-requests',
    name: 'review-requests',
    component: ReviewRequests,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: "Review Requests"}]
    },
  },
  {
    path: '/resources/inspection_copies',
    name: 'inspection_copies',
    component: InspectionCopy,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: "Inspection Copies"}]
    },
  },
  {
    path: '/vogels-entry-request',
    name: 'vogels-entry',
    component: VogelsEntry,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, {name: "Vogels Entry"}]
    },
  },
  {
    path: "/thank-you/",
    name: "thank-you",
    component: ThankYou,
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home", link: "home" }]
    },

  },
  // {
  //   path: "/booksellers",
  //   name: "booksellers",
  //   component: Booksellers,
  //   meta: {
  //     breadcrub: [{ name: "Home", link: "home" }, {name: 'Booksellers'}]
  //   }
  // },
  {
    path: "/cart/",
    name: "cart",
    component: Cart,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Shopping Cart'}]
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      breadcrub: [{ name: 'Home', link: 'home' }, { name: 'Login User'}]
    },
  },
  {
    path: "/advanced-search/",
    name: "advanced-search",
    component: AdvancedSearch,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: 'Advanced Search'}]
    },
  },
  {
    path: "/checkout/",
    name: "checkout",
    component: Checkout,
    meta: {
      breadcrub: [{ name: "Home", link: "home" },{name: 'Shopping Cart', link: 'cart'}, { name: 'Checkout'}]
    },
  },
  {
    path: "/:path*",
    name: "static",
    component: Static,
    meta: {
      static: true,
      breadcrub: [{ name: "Home", link: "home" }]
    },

  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    const title = to.name
    const newtitle = `${title[0].toUpperCase()}${title.slice(1)}`;
    document.title = newtitle
    return { x: 0, y: 0 }
  }
})

export default router


// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: function () {
//   return import(/* webpackChunkName: "about" */ '../views/About.vue')
// }