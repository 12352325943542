import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import SearchCategory from "../views/SearchCategory.vue";
import BrowseBook from "../views/AffirmBrowseBook.vue";
import Static from "../views/Static";
import ContactUs from "../views/AffirmContactUs.vue";
import NotFound from "../views/NotFound.vue";
import News from "../views/AffirmNews.vue";
import Events from "../views/Events.vue";
import AffirmAuthors from "../views/AffirmAuthors.vue";
import AffirmAuthorsDetails from "../views/AffirmAuthorsDetails.vue";
import AffirmEvents from "../views/AffirmEvents.vue";
import AffirmBooks from "../views/AffirmBooks.vue";
import AffirmNewsandFeatures from "../views/AffirmNewsandFeatures.vue";
import AffirmLogin from "../views/AffirmLogin.vue";
import AffirmRegister from "../views/AffirmRegister.vue";
import AffirmBookseller from "../views/AffirmBookseller.vue";
import AffirmDistributionBook from "../views/AffirmDistributionBook.vue";
import AffirmResellers from "../views/AffirmResellers.vue";
import AffirmBooksellerCart from "../views/AffirmBooksellerCart";

import store from "../store";
const isAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated_bestseller_number) {
    next();
    return;
  }
  if (to.name == "affirm-bookseller") {
    const message = "You must be logged into an account view this page";
    store.commit("SET_COMMON_INFO_MESSAGE", message);
    store.commit("SET_REDIRECT_URL", to);
  }
  next("/login");
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home" }],
    },
    component: Home,
  },
  {
    path: "/browse",
    component: Search,
    name: "browse",
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
        { name: "" },
      ],
    },
  },
  {
    path: "/browse/books/:url(\\d+)",
    name: "browse-book",
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: "left",
    },
  },
  {
    path: "/browse/books/bookseller/:isbn",
    name: "Browse-Book-Bookseller",
    component: AffirmBooksellerCart,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Cart", link: "search" },
      ],
    },
  },
  {
    path: "/browse/books/:term",
    name: "category-search",
    component: SearchCategory,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
    },
    children: [
      {
        path: ":page",
        component: SearchCategory,
        meta: {
          Breadcrumb: [
            { name: "Home", link: "home" },
            { name: "Browse", link: "search" },
          ],
        },
      },
    ],
  },
  {
    path: "/browse/news",
    name: "affirm-news",
    component: News,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "News" }],
    },
  },
  {
    path: "/browse/news/:path*",
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "News", link: "news" },
      ],
    },
  },
  {
    path: "/browse/events",
    name: "events",
    component: Events,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Events" }],
    },
  },
  {
    path: "/browse/events/:path*",
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Events", link: "events" },
      ],
    },
  },
  {
    path: "/authors",
    name: "affirm-authors",
    component: AffirmAuthors,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/author/:id",
    name: "affirm-authors-details",
    component: AffirmAuthorsDetails,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/books",
    name: "affirm-books",
    component: AffirmBooks,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/news",
    name: "affirm-news",
    component: AffirmNewsandFeatures,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/login",
    name: "affirm-login",
    component: AffirmLogin,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/signup",
    name: "affirm-signup",
    component: AffirmRegister,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/browse-books/distribution/url",
    name: "affirm-distribution-book",
    component: AffirmDistributionBook,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/bookseller",
    name: "affirm-bookseller",
    component: AffirmBookseller,
    beforeEnter: isAuthenticated,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "affirm-bookseller" },
      ],
    },
  },
  {
    path: '/events',
    redirect: '/'
  },
  {
    path: "/events",
    name: "affirm-events",
    component: AffirmEvents,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/events/:path*",
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "affirm-events", link: "events" },
      ],
    },
  },
  {
    path: "/resellers",
    name: "affirm-resellers",
    component: AffirmResellers,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Authors" }],
    },
  },
  {
    path: "/authors/:path*",
    component: Static,
    meta: {
      static: true,
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Authors", link: "authors" },
      ],
    },
  },
  {
    path: "/browse/book/:url",
    name: "browse-book",
    component: BrowseBook,
    meta: {
      breadcrub: [
        { name: "Home", link: "home" },
        { name: "Browse", link: "search" },
      ],
      style: "left",
    },
  },
  {
    path: "/browse/:path*",
    name: "browse-static",
    component: Static,
    meta: {
      static: true,
      breadcrub: [{ name: "Home", link: "home" }],
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Search" }],
    },
  },
  {
    path: "/contact-us",
    name: "affirm-contact-us",
    component: ContactUs,
    meta: {
      breadcrub: [{ name: "Home", link: "home" }, { name: "Contact Us" }],
    },
  },
  {
    path: "/404/",
    name: "not-found",
    component: NotFound,
    meta: {
      layout: "default",
      breadcrub: [{ name: "Home", link: "home" }],
    },
  },
  {
    path: "/:path*",
    name: "static",
    component: Static,
    meta: {
      static: true,
      breadcrub: [{ name: "Home", link: "home" }],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    const title = to.name;
    const newtitle = `${title[0].toUpperCase()}${title.slice(1)}`;
    document.title = newtitle;
    return { x: 0, y: 0 };
  },
});

export default router;
