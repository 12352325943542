<template>
  <div>
    <homecomp></homecomp>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import homeMixin from '../mixins/Home'
import utils from '../utils'

const Homecomp = () => {
  let importFrom = null;
  let host = utils.resolve_host(window.location.hostname);

  if(host == 'murdoch' || host == 'murdochuk'){
    importFrom = './murdoch/';
  }
  else if(host == 'acc'){
    importFrom = './acc/';
  }
  else if(host == 'affirm'){
    importFrom = './affirm/';
  }
  else{
    importFrom = './au/';
  }
  return import(importFrom + 'Homecomp');
}

export default {
  name: 'home',
  components: {Homecomp},
  mixins: [homeMixin],
  mounted(){
    document.title = `Home - ${this.$store.state.site_data.title}`;
  }
}
</script>
<style>
.main-carousel{
  display: block;
}

.car-slide{
  background-color: #f6f6f6;
  display: flex;
}

.swipper-image > img{
  max-width: 250px;
}
.swippe-contents {
  padding-left: 15px;
  padding-top: 20px;
}
.loader-spinner{
  margin: 0 auto;
  padding-top: 13%;
}
</style>