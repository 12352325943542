<template>
  <div>
    <looping-rhombuses-spinner :animation-duration="2500" :rhombus-size="15" color="#febe10" v-if="show_spinner" class="loader-spinner" />
    <section v-if="!show_spinner" class="overflow-hidden position-relative z-index-99">
      <div class="st-container">
        <!-- start about us -->
        <div class="py-5">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <p class="s-h5 mb-4 mb-xl-5 --text-dark text-center lh-lg">
              <span class="capitalize-text">
                <b>{{ title }}</b>
              </span> is available via the following retailer links
            </p>
            <div class="col-sm-12 col-md-12 col-lg-12 text-cn">
              <div v-if="links.length == 0">
                <p>No retailer links found for this book!</p>
              </div>
              <div v-for=" link in links" :key="link.id">
                <p class="s-h6 mb-2 mb-lg-4">
                  <a :href="book_seller_url(link)" target="_blank">
                    <img class="img-back" v-bind:src="link.logo_link" />
                  </a>
                </p>
              </div>
              <div class="s-h6 mb-2 mb-lg-4 mt-2 new-page">
                <div class="me-3">
                  <a href='https://www.bookpeople.org.au/find-a-bookshop'  target="_blank"  class="text-dark fw-semibold">
                    <img src="/static/css/images/affirm/Group55.svg" alt="Group55" class="p-3 rounded-circle -bg-light-gray -w-50 -h-50" />
                  </a>
                </div>
                <div>
                  <a href='https://www.bookpeople.org.au/find-a-bookshop'  target="_blank" class="text-dark fw-semibold">
                    <p class="text-decoration-underline --text-dark s-h7 mt-3"> Or find your local independent bookstore here. </p>
                  </a>
                  <br />
                   <router-link to="" class="text-dark fw-semibold">
                    <p class="text-decoration-underline --text-dark s-h7 mt-3" @click="$router.go(-1)"> Return to book page </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end about us -->
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
export default {
  name: "affirm-authors-details",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  data() {
    return {
      links: [],
      isbn: '',
      show_spinner: true,
      title: '',
      author: ''
    };
  },
  methods: {
    book_seller_url(link){
      let url = (this.title).replace(/\s+/g, '-').replace('(', '').replace(')', '').toLowerCase();
      let author = this.author.replace(/\s+/g,'-').toLowerCase();
      let url_extension = link.url_extension.replace('{ISBN}', this.isbn).replace('{title}', url).replace('{author}', author)
      return `${link.main_url}${url_extension}`
    }
  },
  computed: {},
  mounted() {
    let self = this;
    let isbn = self.$route.path.split('/')[4]
      axios
        .get(`/get_book_data/${isbn}/`)
        .then(function (data) {
          self.isbn   = data.data.isbn
          self.book   = data.data;
          self.author = data.data.book.author;
          self.show_spinner = false;
          if(self.book.book.nonsortprefix){
            self.title = `${self.book.book.nonsortprefix} ${self.book.book.title}`;
          }
          else {
            self.title = `${self.book.book.title}`;
          }
        })
        .catch(function (err) {
          console.log(err);
          window.location = "/404/";
      });
      axios
        .get(`/buy-bookseller-links/?isbn=${isbn}`)
        .then(function (data) {
          self.links = data.data
          self.show_spinner = false;
        })
        .catch(function (err) {
          console.log(err);
      });
      
  },
};
</script>
<style>
.text-cn{
  text-align: center;
}
.img-back{
  background-color: aliceblue;
  width: 369px;
  height: 121px;
  min-width: 24%;
  object-fit: scale-down;
}
.new-page{
  display: inline-flex;
  width: auto;
  padding: 15px 83px 46px 0px;
}
.capitalize-text{
  text-transform: capitalize;
}
</style>