<template>
<div class="l-main-block mobileFullWidth clearfix">
  <div class="l-main mobileFullWidthWithMargin">
    <div class="bfClearfix">
      <form name="ff_form1" id="ff_form1" enctype="multipart/form-data" accept-charset="utf-8" class="bfQuickMode" @submit.prevent="submit('/save-inspection-copies/')">
        <div id="bfPage1" class="bfPage">
          <section class="bfPageIntro">
            <h1>Inspection Copy Request Form</h1>
            <p><span style="">An asterisk (&nbsp;</span><span class="asterisk_style">*</span><span>&nbsp;) indicates mandatory fields</span></p>
          </section>
          <span class="bfErrorMessage" style="display:none"></span>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap21">
            <label id="bfLabel21" for="ff_elem21">Full name<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="fullname" id="ff_elem21">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap22">
            <label id="bfLabel22" for="ff_elem22">Department<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="department" value="" id="ff_elem22">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap50">
            <label id="bfLabel50" for="ff_elem50">Institution<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="institution" value="" id="ff_elem50">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap51">
            <label id="bfLabel51" for="ff_elem51">Campus<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="campus" value="" id="ff_elem51">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap52">
            <label id="bfLabel52" for="ff_elem52">Postal Address<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="postal_address" value="" id="ff_elem52">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap58">
            <label id="bfLabel58" for="ff_elem58">Email<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="email" value="" id="ff_elem58">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap53">
            <label id="bfLabel53" for="ff_elem53">Course/Subject Name<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="subject_name" value="" id="ff_elem53">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap810">
            <label id="bfLabel810" for="ff_elem810">Course/Subject Code<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="subject_code" value="" id="ff_elem810">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap54">
            <label id="bfLabel54" for="ff_elem54">Number of students<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="num_students" value="" id="ff_elem54">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap917">
            <label id="bfLabel917" for="ff_elem917">Semester<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="semester" value="" id="ff_elem917">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap55">
            <label id="bfLabel55" for="ff_elem55">Year<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="year" value="" id="ff_elem55">
          </section>
          <section class="bfElemWrap bfLabelLeft" id="bfElemWrap811">
            <label id="bfLabel811" for="ff_elem811">Name of campus bookshop<span class="bfRequired">*</span> 
            </label>
            <input class="ff_elem" type="text" name="campus_name" value="" id="ff_elem811">
          </section>
          <section class="bfElemWrap bfLabelRight" id="bfElemWrap59">
            <label id="bfLabel59" for="ff_elem59">I would like to receive updates about new books in my subject area</label>
            <input class="ff_elem custom-check" type="checkbox" id="ff_elem73" name="update_flag"/>
          </section>
          <div class="bfNoSection" id="inspection-form__section-book">
            <section class="bfSectionDescription">
              <p><strong>Books</strong></p>
              <p>Enter each item individually.</p>
            </section>
            <section class="bfElemWrap bfLabelLeft" id="bfElemWrap140">
              <label id="bfLabel140" for="ff_elem140">Book name</label>
              <input class="ff_elem" type="text" name="book_name_1[]" value="" id="ff_elem140">
            </section>
            <section class="bfElemWrap bfLabelLeft" id="bfElemWrap153">
              <input class="ff_elem" type="text" name="book_name_2[]" value="" id="ff_elem153">
            </section>
            <section class="bfElemWrap bfLabelLeft" id="bfElemWrap154">
              <input class="ff_elem" type="text" name="book_name_3[]" value="" id="ff_elem154">
            </section>
            <section class="bfElemWrap bfLabelLeft" id="bfElemWrap155">
              <input class="ff_elem" type="text" name="book_name_4[]" value="" id="ff_elem155">
            </section>
            <section class="bfElemWrap bfLabelLeft" id="bfElemWrap156">
              <input class="ff_elem" type="text" name="book_name_5[]" value="" id="ff_elem156">
            </section>
          </div>
          <div v-if="status != 'submitted'">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"
              @verify="onCaptchaVerified"
            ></vue-recaptcha>
            <button
              type="submit"
              id="bfSubmitButton"
              class="bfSubmitButton button"
              :disabled="status==='submitting' || !captchaComplete"
            >
              <span>Submit</span>
            </button>
          </div>

          <h3 v-if="status === 'submitted'" class="success-message">
            Thank you for your Enquiry
          </h3>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import Form from '../mixins/Form.js';

export default {
  name: "inspection_copies",
  mixins: [Form],
  data() {
    return {
      errors: null,
      qwe: 1,
    };
  }
};
</script>

<style>
  .asterisk_style{
    font-family: arial, sans-serif; 
    font-size: 14px; 
    line-height: 19px; color: #ff0030;
  }
</style>
