var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-main-block mobileFullWidth clearfix"},[_c('div',{staticClass:"l-main mobileFullWidthWithMargin"},[_c('div',{staticClass:"bfClearfix"},[_c('form',{staticClass:"bfQuickMode",attrs:{"name":"ff_form1","id":"ff_form1","enctype":"multipart/form-data","accept-charset":"utf-8"},on:{"submit":function($event){$event.preventDefault();return _vm.submit('/save-inspection-copies/')}}},[_c('div',{staticClass:"bfPage",attrs:{"id":"bfPage1"}},[_vm._m(0),_c('span',{staticClass:"bfErrorMessage",staticStyle:{"display":"none"}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),(_vm.status != 'submitted')?_c('div',[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":"6LdaQfQUAAAAAKimcCajdykcLLBpJr7E2oIHvxKx"},on:{"verify":_vm.onCaptchaVerified}}),_c('button',{staticClass:"bfSubmitButton button",attrs:{"type":"submit","id":"bfSubmitButton","disabled":_vm.status==='submitting' || !_vm.captchaComplete}},[_c('span',[_vm._v("Submit")])])],1):_vm._e(),(_vm.status === 'submitted')?_c('h3',{staticClass:"success-message"},[_vm._v(" Thank you for your Enquiry ")]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfPageIntro"},[_c('h1',[_vm._v("Inspection Copy Request Form")]),_c('p',[_c('span',{},[_vm._v("An asterisk ( ")]),_c('span',{staticClass:"asterisk_style"},[_vm._v("*")]),_c('span',[_vm._v(" ) indicates mandatory fields")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap21"}},[_c('label',{attrs:{"id":"bfLabel21","for":"ff_elem21"}},[_vm._v("Full name"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"fullname","id":"ff_elem21"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap22"}},[_c('label',{attrs:{"id":"bfLabel22","for":"ff_elem22"}},[_vm._v("Department"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"department","value":"","id":"ff_elem22"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap50"}},[_c('label',{attrs:{"id":"bfLabel50","for":"ff_elem50"}},[_vm._v("Institution"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"institution","value":"","id":"ff_elem50"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap51"}},[_c('label',{attrs:{"id":"bfLabel51","for":"ff_elem51"}},[_vm._v("Campus"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"campus","value":"","id":"ff_elem51"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap52"}},[_c('label',{attrs:{"id":"bfLabel52","for":"ff_elem52"}},[_vm._v("Postal Address"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"postal_address","value":"","id":"ff_elem52"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap58"}},[_c('label',{attrs:{"id":"bfLabel58","for":"ff_elem58"}},[_vm._v("Email"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"email","value":"","id":"ff_elem58"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap53"}},[_c('label',{attrs:{"id":"bfLabel53","for":"ff_elem53"}},[_vm._v("Course/Subject Name"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"subject_name","value":"","id":"ff_elem53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap810"}},[_c('label',{attrs:{"id":"bfLabel810","for":"ff_elem810"}},[_vm._v("Course/Subject Code"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"subject_code","value":"","id":"ff_elem810"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap54"}},[_c('label',{attrs:{"id":"bfLabel54","for":"ff_elem54"}},[_vm._v("Number of students"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"num_students","value":"","id":"ff_elem54"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap917"}},[_c('label',{attrs:{"id":"bfLabel917","for":"ff_elem917"}},[_vm._v("Semester"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"semester","value":"","id":"ff_elem917"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap55"}},[_c('label',{attrs:{"id":"bfLabel55","for":"ff_elem55"}},[_vm._v("Year"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"year","value":"","id":"ff_elem55"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap811"}},[_c('label',{attrs:{"id":"bfLabel811","for":"ff_elem811"}},[_vm._v("Name of campus bookshop"),_c('span',{staticClass:"bfRequired"},[_vm._v("*")])]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"campus_name","value":"","id":"ff_elem811"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bfElemWrap bfLabelRight",attrs:{"id":"bfElemWrap59"}},[_c('label',{attrs:{"id":"bfLabel59","for":"ff_elem59"}},[_vm._v("I would like to receive updates about new books in my subject area")]),_c('input',{staticClass:"ff_elem custom-check",attrs:{"type":"checkbox","id":"ff_elem73","name":"update_flag"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bfNoSection",attrs:{"id":"inspection-form__section-book"}},[_c('section',{staticClass:"bfSectionDescription"},[_c('p',[_c('strong',[_vm._v("Books")])]),_c('p',[_vm._v("Enter each item individually.")])]),_c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap140"}},[_c('label',{attrs:{"id":"bfLabel140","for":"ff_elem140"}},[_vm._v("Book name")]),_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"book_name_1[]","value":"","id":"ff_elem140"}})]),_c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap153"}},[_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"book_name_2[]","value":"","id":"ff_elem153"}})]),_c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap154"}},[_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"book_name_3[]","value":"","id":"ff_elem154"}})]),_c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap155"}},[_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"book_name_4[]","value":"","id":"ff_elem155"}})]),_c('section',{staticClass:"bfElemWrap bfLabelLeft",attrs:{"id":"bfElemWrap156"}},[_c('input',{staticClass:"ff_elem",attrs:{"type":"text","name":"book_name_5[]","value":"","id":"ff_elem156"}})])])
}]

export { render, staticRenderFns }