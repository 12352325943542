<template>
  <div class="">
    <div class="st-container">
      <!-- start Events us -->
      <section id="Events" class="py-5">
        <div class="row">
          <div class="col-12">
            <h1 class="s-h1 mb-2 mb-xl-4 --text-dark">Events</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8">
            <div>
              <ul class="d-inline-block ps-0 --text-dark">
                <li
                  v-on:click="makeActive('All')"
                  :class="{ active_events: active === 'All' }"
                  class="d-inline-block s-h6 me-4 cursor-pointer"
                >
                  All
                </li>
                <li
                  v-on:click="makeActive('upcoming')"
                  :class="{ active_events: active === 'upcoming' }"
                  class="d-inline-block s-h6 me-4 cursor-pointer"
                >
                  Upcoming events
                </li>
                <li
                  v-on:click="makeActive('past')"
                  :class="{ active_events: active === 'past' }"
                  class="d-inline-block s-h6 me-4 cursor-pointer"
                >
                  Past events
                </li>
              </ul>
            </div>
          </div>
        </div>
        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="show_spinner"
          class="loader-spinner"
        />

        <div class="row gy-4">
          <div
            class="col-sm-12 col-md-12 col-lg-4 col-xl-4"
            v-for="blog in posts"
          >
            <div
              class="-bg-light-green p-4 mt-3 h-100 position-relative overflow-hidden"
            >
              <div
                class="past_event-tag position-absolute"
                v-if="blog.past_event_text"
              >
                <h6
                  class="s-h6 -bg-brown mb-0 text-center py-3 fw-normal --text-dark"
                >
                  Past Event
                </h6>
              </div>
              <div class="pt-lg-4 mb-3">
                <router-link :to="blog.url">
                  <div class="w-218 h-218 rounded-circle overflow-hidden">
                    <img
                      v-if="blog.hero_image"
                      :src="blog.hero_image"
                      alt=""
                      width="100%"
                      class=""
                      @error="noCoverImage"
                    />
                    <img
                      v-else
                      :src="noCoverImage"
                      alt=""
                      width="100%"
                      class=""
                      @error="noCoverImage"
                    />
                  </div>
                </router-link>
              </div>
              <div>
                <h5 class="s-h5 fw-normal mb-5 mt-2 --text-dark">
                  <router-link
                    class="text-decoration-none s-h5 fw-normal mb-5 mt-2 --text-dark"
                    :to="blog.url"
                    >{{ blog.title }}</router-link
                  >
                </h5>
              </div>
              <div class="mb-4 --text-dark">
                <p class="s-h7 mb-0 --text-dark" v-html="blog.summary"></p>
              </div>
            </div>
          </div>
          <p
            class="text-center --text-dark"
            v-show="posts.length == 0 && !show_spinner"
          >
            There are no {{ active }} events
          </p>
        </div>
        <form class="pagination-form mt-5">
          <div
            class="pagination d-flex flex-column-reverse justify-content-center align-items-center"
          >
            <p class="counter">Page {{ page }} of {{ num_pages }}</p>
            <div class="list-footer w-100">
              <ul class="w-100 text-center p-0">
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                >
                  <a
                    class="text-decoration-none text-white cursor-pointer pagenav page-disable"
                    v-if="page == 1"
                  >
                    Start
                  </a>
                  <router-link to="/events?page=1" class="pagenav" v-else
                    >Start</router-link
                  >
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                >
                  <a
                    class="text-decoration-none text-white cursor-pointer pagenav page-disable"
                    v-if="page == 1"
                  >
                    Prev
                  </a>
                  <router-link
                    :to="'/events?page=' + prev_page"
                    class="pagenav"
                    v-else
                    >Prev</router-link
                  >
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                  v-for="page_num in prev_pages"
                >
                  <router-link
                    :title="page_num"
                    :to="'/events?page=' + page_num"
                    class="pagenav"
                    >{{ page_num }}</router-link
                  >
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-white"
                >
                  <span class="pagenav">{{ page }}</span>
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                  v-for="page_num in next_pages"
                >
                  <router-link
                    :title="page_num"
                    :to="'/events?page=' + page_num"
                    class="text-decoration-none text-white cursor-pointer pagenav page-disable"
                    >{{ page_num }}</router-link
                  >
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                >
                  <a
                    class="text-decoration-none text-white cursor-pointer pagenav page-disable"
                    v-if="page == num_pages"
                  >
                    Next
                  </a>
                  <router-link
                    :to="'/events?page=' + next_page"
                    class="pagenav"
                    v-else
                    >Next</router-link
                  >
                </li>
                <li
                  class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
                >
                  <a
                    class="text-decoration-none text-white cursor-pointer pagenav page-disable"
                    v-if="page == num_pages"
                  >
                    End
                  </a>
                  <router-link
                    :to="'/events?page=' + num_pages"
                    class="pagenav"
                    v-else
                    >End</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </form>
      </section>
      <!-- end Events us -->
    </div>
  </div>
</template>
<script>
import { LoopingRhombusesSpinner } from "epic-spinners";
import BrowseBlogPosts from "../mixins/BrowseBlogPosts";
export default {
  name: "affirm-events",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [BrowseBlogPosts],
  data() {
    return {
      type: "event",
      event_data: "All",
      active: "All",
    };
  },
  methods: {
    DateFormat() {
      const current = new Date();
      var date = new Date().toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      )
      return date;
    },
    noCoverImage(event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
    makeActive: function (item) {
      this.active = item;
      this.event_data = item;
      this.fetchPosts();
    },
  },
  computed: {},
  watch: {},
  mounted() {
    let add_new_class = document.getElementById("affirm-header");
    let remode_class = document.getElementById("for-affirm");
    add_new_class.classList.add("-bg-white");
    remode_class.classList.remove("container");
  },
};
</script>
<style>
li.d-inline-block.s-h6.me-4.cursor-pointer.active_events {
  text-decoration: underline;
}
</style>
