import axios from "axios";

export default {
  data() {
    return {
      posts: [],
      show_spinner: true,
      num_pages: 1,
      page_num: 1,
      next_page: null,
      prev_page: null,
      next_pages: [],
      event_data: null,
      all_blog: null,
      prev_pages: [],
      page: 1,
    };
  },
  methods: {
    fetchPosts(page) {
      if (!page) {
        page = 1;
      }
      let self = this;
      axios
        .get(
          `/get_paginated_blog_posts?type=${self.type}&page=${page}&event_data=${self.event_data}&all_blog=${self.all_blog}`
        )
        .then(function (data) {
          self.posts = data.data.blog_posts;
          self.show_spinner = false;
          self.next_page = data.data.next_page;
          self.next_pages = data.data.next_pages;
          self.prev_pages = data.data.prev_pages;
          self.num_pages = data.data.num_pages;
          self.prev_page = data.data.prev_page;
          self.page = data.data.page;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  mounted() {
    let page = this.$route.query.page;
    this.fetchPosts(page);
  },
  beforeRouteUpdate(to, from, next) {
    // check if there is a next page
    if (to.query.page) {
      this.fetchPosts(to.query.page);
    }
    next();
  },
};
