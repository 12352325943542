<template>
  <div class="item">
    <router-link :to="event.url" class="image-button button--color1 icon--calendar"></router-link>
    <div class="text">
      <h4 class="newsflash-title">
        <router-link
          class="mod-articles-category-title"
          :to="event.url"
        >{{event.title}}</router-link>
      </h4>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "event-item",
  props: ["event"],
  data() {
    return {}
  },
  mounted() {}
};
</script>

<style>
</style>