<template>
  <div v-if="host!='affirm'">
  <div class="moduletable">
    <div class="vmss_mixed_author">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="books.length == 0"
        class="loader-spinner"
      />
      <div
        class="vmss_mixed_author__item teacher-notes-sidebar"
        v-for="book in books"
      >
        <div class="image-wrap">
          <router-link :to="getBookUrl(book)">
            <img
              :src="
                'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/' +
                book.isbn +
                '.jpg'
              "
              @error="noCoverImage"
            />
          </router-link>
        </div>
        <div class="text-wrap">
          <router-link :to="getBookUrl(book)">
            <h5 class="title">{{ book.nonsortprefix }} {{ book.title }}</h5>
          </router-link>
          <p v-if="host == 'allenandunwin'" class="date">
            <b>Published: </b>
            <span v-if="book.uk_pub_date">{{
              book.uk_pub_date | ChangeDateFormat
            }}</span>
            <span v-else>{{ book.pubdate | ChangeDateFormat }}</span>
          </p>
          <p v-else class="date">
            <b>Published: </b>
            <span v-if="book.uk_pub_date">{{
              book.uk_pub_date | ChangeDateFormatNZ
            }}</span>
            <span v-else>{{ book.pubdate | ChangeDateFormatNZ }}</span>
          </p>
          <p v-html="book.key_note_blurb" class="desc"></p>
        </div>
      </div>
    </div>
    <div class="pagination">
      <p class="counter">Page {{ page_number }} of {{ num_pages }}</p>
      <ul>
        <li class="pagination-start">
          <a class="pagenav page-disable" v-if="page_number == 1"> Start </a>
          <router-link :to="page_path + '?page=1'" class="pagenav" v-else
            >Start</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == 1"> Prev </a>
          <router-link
            :to="page_path + '?page=' + prev_page"
            class="pagenav"
            v-else
            >Prev</router-link
          >
        </li>
        <li v-for="page_num in prev_pages">
          <router-link
            :title="page_num"
            :to="page_path + '?page=' + page_num"
            class="pagenav"
            >{{ page_num }}</router-link
          >
        </li>
        <li>
          <span class="pagenav">{{ page_number }}</span>
        </li>
        <li v-for="page_num in next_pages">
          <router-link
            :title="page_num"
            :to="page_path + '?page=' + page_num"
            class="pagenav"
            >{{ page_num }}</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == num_pages">
            Next
          </a>
          <router-link
            :to="page_path + '?page=' + next_page"
            class="pagenav"
            v-else
            >Next</router-link
          >
        </li>
        <li class="pagination-prev">
          <a class="pagenav page-disable" v-if="page_number == num_pages">
            End
          </a>
          <router-link
            :to="page_path + '?page=' + num_pages"
            class="pagenav"
            v-else
            >End</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</div>
<div v-else>
  <div class="card-section mt-3 mb-5">
    <div>
      <div class="row">
        <div class="col-12">
          <div>
            <h4 class="fw-bold s-h5 --text-dark">{{ title }}</h4>
          </div>
        </div>
      </div>
      <div class="row gy-5">
        <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#febe10"
        v-if="books.length == 0"
        class="loader-spinner"
      />
        <div
          v-for="book in books_data"
          class="col-12 col-md-6 col-lg-4 col-xl-3"
        >
          <div class="book-list main-card h-100">
            <div class="upper-card">
              <!-- <img
                alt="No_Image_Available"
                width="100%"
                :src="
                  'https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/' +
                  book.isbn +
                  '.jpg'
                "
                @error="noCoverImage"
              /> -->
              <img
                alt="No_Image_Available"
                width="100%"
                :src="
                  'https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/' +
                  book.isbn +
                  '.jpg'
                "
                @error="noCoverImage"
              />
            </div>
            <router-link :to="getBookUrl(book)" v-if="pubdate(book.pubdate)">
              <div class="hover-card">
                <div class="inner-h-card">
                  <div class="">
                    <h5 class="--text-dark">
                      {{ book.nonsortprefix }} {{ book.title }}
                    </h5>
                    <h5 class="--text-dark">{{ book.author }}</h5>
                  </div>
                  <div>
                    <p v-html="book.key_note_blurb" class="--text-dark">
                      The Near Daphne Experience is a riotous romp about the
                      unedifying search for love, and the most original book
                      you’ll read this year.
                    </p>
                  </div>
                  <div>
                    <router-link
                      class="--text-dark"
                      :to="getBookUrl(book)"
                      target="_self"
                      ><img
                        src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                        width="30px"
                    /></router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          @click="loadbookVisible += step"
          v-if="loadbookVisible < books.length"
          class="cursor-pointer -w-223 mx-auto"
        >
          <div class="text-center">
            <p class="s-h6 --text-dark">Load more</p>
            <img
              src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
              width="25px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
import Vue from "vue";

Vue.filter("ChangeDateFormat", function (value) {
  if (value) {
    return new Date(value).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }
          )
  }
});
Vue.filter('ChangeDateFormatNZ', function(value) {
  if (value) {
    return new Date(value).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'short',
            }
          )
  }
});


export default {
  name: "author-list",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ["placement"],
  data() {
    return {
      books: [],
      show_price: false,
      format: "single-col",
      limit: 5,
      title: null,
      num_pages: 1,
      page_num: 1,
      next_page: null,
      prev_page: null,
      next_pages: [],
      prev_pages: [],
      page_number: 1,
      page_numb: 1,
      page_path: null,
      loadbookVisible: 12,
      step: 12,
      host: this.$store.state.site_data.host,
    };
  },

  methods: {
    noCoverImage: function (event) {
      if (this.$store.state.site_data.host== 'affirm'){
        event.target.src = '/static/css/images/affirm/No_Image_Available.jpg';
      }
      else if (this.$store.state.site_data.host == 'allenandunwin' || this.$store.state.site_data.host == 'allenandunwinnz'){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
        }
    },
    pubdate: function (pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();
      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      return status;
    },
    searchpagination(pageNum) {
      this.page_num = pageNum;
      this.fication_pagination();
    },
    fication_pagination() {
      if (this.$route.query.page) {
        this.page_numb = this.$route.query.page;
      } else {
        this.page_numb = 1;
      }
      let self = this;
      let placement = this.placement ? this.placement : "bottom";
      axios
        .get(`/get_bestsellers/?page_number=${this.page_numb}`, {
          params: {
            page: this.$route.path,
            placement: placement,
          },
        })
        .then(function (data) {
          var Host = "uk";
          if (data.status == 200) {
            // if  ((document.location.hostname).search(Host) != -1 ){
            //   self.books = data.data.books.sort((a, b) => new Date(b.uk_pub_date));

            // }
            // else{
            //    self.books = data.data.books.sort((a, b) => new Date(b.pubdate) - new Date(a.pubdate));
            // }
            self.books = data.data.books;
            self.limit = data.data.book_limit;
            self.format = data.data.style;
            self.show_price = data.data.show_price;
            self.title = data.data.title;
            self.next_page = data.data.next_page;
            self.next_pages = data.data.next_pages;
            self.prev_pages = data.data.prev_pages;
            self.num_pages = data.data.num_pages;
            self.prev_page = data.data.prev_page;
            self.page_number = data.data.page_number;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  computed: {
    books_data() {
      return this.books.slice(0, this.loadbookVisible);
    },
  },
  mounted() {
    this.page_path = this.$route.path;
    if (this.$route.query.page) {
      this.page_numb = this.$route.query.page;
    } else {
      this.page_numb = 1;
    }
    this.fication_pagination();
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query.page"() {
      if (this.$route.query.page) {
        this.page_numb = this.$route.query.page;
      }
      this.fication_pagination();
      window.scrollTo(0, 0);
    },
    $route() {
      this.fication_pagination();
    },
  },
};
</script>
<style>
a.pagenav.page-disable {
  opacity: 0.5;
}

a.pagenav.page-disable:hover {
  background: #72838d;
}
</style>

<style>
a.pagenav.page-disable {
  opacity: 0.5;
}
a.pagenav.page-disable:hover {
  background: #72838d;
}
</style>
