import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["cart"],
    }),
  ],
  state: {
    user_registered: false,
    common_info_message: "",
    site_data: {
      host: "-",
      logo: null,
      header_menu: [],
      footer_menu: [],
    },
    siteNames: {
      allenandunwin: "A&U",
      allenandunwinnz: "A&U NZ",
      murdoch: "Murdoch",
      murdochuk: "Murdoch UK",
      acc: "ACC",
      affirm: "Affirm",
    },
    booksellers_num: null,
    cart: {
      basket_pk: null,
      lines: [
        // {
        //   qty: 0,
        //   isbn: null,
        //   price: 0.00,
        //   url: '/foo-bar/',
        //   line_id: null,
        //   title: foo
        // }
      ],
    },
    advanced_search: {
      keyword: null,
      category: null,
      title: null,
      author: null,
      isbn: null,
      publisher: null,
      imprint: null,
      format: null,
      age: null,
      month: null,
      year: null,
    },
    user: {
      username: localStorage.getItem("username") || "",
      key: null,
    },
    guest_email: null,
    redirect: {
      name: "",
      url: "",
    },
  },
  mutations: {
    FETCH_SITE_DATA(state, site_data) {
      state.site_data = site_data;
    },
    ADD_PRODUCT(state, product) {
      state.cart.products.push(product);
    },
    UPDATE_CART(state, cart) {
      state.cart = cart;
    },
    ADD_GUEST_EMAIL(state, email) {
      state.guest_email = email;
    },
    EMPTY_CART(state) {
      state.cart.basket_pk = null;
      state.cart.lines = [];
    },
    UPDATE_DISCOUNT(state, discount) {
      state.cart.discount_applied = discount;
    },
    UPDATE_ADVANCED_FORM(state, form) {
      state.advanced_search = form;
    },
    EMPTY_ADVANCED_FORM(state) {
      state.advanced_search = null;
    },
    ADD_BOOKSELLERS_NUM(state, booksellers_num) {
      state.booksellers_num = booksellers_num;
    },
    SET_CURRENT_USER_KEY(state, key) {
      state.user.key = key;
    },
    GET_CURRENT_USER(state, user) {
      const key = state.user.key;
      state.user = user;
      state.user.key = key;
    },
    CLEAN_CURRENT_USER(state) {
      state.user = {};
    },
    confirm_email(state) {
      state.user_registered = true;
    },
    HIDE_SUCCESS_MESSAGES(state) {
      state.user_registered = false;
    },
    SET_COMMON_INFO_MESSAGE(state, message) {
      state.common_info_message = message;
    },
    CLEAR_COMMON_INFO_MESSAGE(state) {
      state.common_info_message = "";
    },
    SET_REDIRECT_URL(state, route) {
      state.redirect.name = route.name;
      state.redirect.url = route.path;
    },
    CLEAR_REDIRECT_URL(state) {
      state.redirect.name = "";
      state.redirect.url = "";
    },
  },
  getters: {
    siteName: (state) => {
      return state.siteNames[state.site_data.host];
    },
    currencySymbol: (state) => {
      let currency_symbol = "$";
      if (state.site_data.currency == "GBP") {
        currency_symbol = "£";
      }
      return `${state.site_data.currency} ${currency_symbol}`;
    },
    isAuthenticated: (state) => {
      if (state.user.username || state.user.key) {
        console.log("bbb-bbb-bbb");
        return true;
      }
    },
    isAuthenticated_bestseller_number: (state) => {
      if (state.booksellers_num) {
        return true;
      }
    },
    url_after_login: (state) => {
      return state.redirect.url;
    },
  },
  actions: {
    fetchSiteData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get_app_data/")
          .then((response) => {
            console.log(response);

            commit("FETCH_SITE_DATA", response.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    addProduct({ commit, state }, product) {
      if (state.site_data.host == "acc") {
        var cart_qty = $(".value-text").text();
        if (cart_qty == "") {
          cart_qty = 1;
        }
      } else {
        var cart_qty = product.qty;
      }
      if (product.type != "event_cart") {
        localStorage.setItem("cart_type", "book");
      }
      return new Promise((resolve, reject) => {
        axios
          .post("/add_to_cart/", {
            isbn: product.isbn,
            qty: cart_qty,
            cart_id: state.cart.basket_pk,
            type: product.type,
            value: product.cost,
            registartion_id: product.registartion_id,
          })
          .then((response) => {
            console.log(response);
            commit("UPDATE_CART", response.data.cart);
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    checkCartStatus({ commit, state }) {
      if (state.cart.basket_pk) {
        return new Promise((resolve, reject) => {
          axios
            .get("/check_cart_status/?cart_id=" + state.cart.basket_pk)
            .then((data) => {
              console.log(data);
              commit("UPDATE_DISCOUNT", data.data.cart.discount_applied);
              if (data.data.cart.is_book_product == false) {
                localStorage.removeItem("cart_type");
              }
              resolve(true);
            })
            .catch((err) => {
              console.log(err);
              commit("EMPTY_CART");
              localStorage.removeItem("cart_type");
            });
        });
      } else {
        commit("EMPTY_CART");
        localStorage.removeItem("cart_type");
      }
    },
    updateLine({ commit, state }, line) {
      return new Promise((resolve, reject) => {
        let line_obj = state.cart.lines[line.index];
        axios
          .post("/update_line_qty/", {
            line_pk: line_obj.line_pk,
            quantity: line.qty,
          })
          .then((response) => {
            commit("UPDATE_CART", response.data.cart);
            commit("UPDATE_DISCOUNT", response.data.cart.discount_applied);
            if (response.data.cart.is_book_product == false) {
              localStorage.removeItem("cart_type");
            }
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    addGuestEmail({ commit, state }, email) {
      commit("ADD_GUEST_EMAIL", email);
    },
    emptyCart({ commit, state }) {
      commit("EMPTY_CART");
    },
    updateAdvancedForm({ commit, state }, form) {
      commit("UPDATE_ADVANCED_FORM", form);
    },
    emptyAdvancedForm({ commit, state }) {
      commit("EMPTY_ADVANCED_FORM");
    },
    addBooksellersNum({ commit, state }, booksellers_num) {
      return new Promise((resolve, reject) => {
        axios
          .post("/get_booksellers_data/", {
            booksellers_num: booksellers_num,
          })
          .then((response) => {
            commit("ADD_BOOKSELLERS_NUM", booksellers_num);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveUserKey({ commit, state }, key) {
      console.log("SAVE USER KEY ACTION");
      commit("SET_CURRENT_USER_KEY", key);

      axios
        .get("/dj-rest-auth/user/")
        .then((response) => {
          const user = response.data;
          console.log("SAVE CURRENT USER ACTION");
          commit("GET_CURRENT_USER", user);

          localStorage.setItem("username", user.username);
          localStorage.setItem("user_id", user.pk);
          localStorage.setItem("user_key", user.key);
          localStorage.setItem("user_email", user.email);
        })
        .catch((err) => {
          reject(err);
        });
    },
    clearUser({ commit, state }) {
      console.log("CLEAR USER");
      commit("CLEAN_CURRENT_USER");
    },
  },
  modules: {},
});
