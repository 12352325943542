<template>
  <div class="mobileFullWidth clearfix">
    <div>
      <div>
        <div class="l-main-block mobileFullWidth clearfix">
          <div class="l-main mobileFullWidthWithMargin">
            <div class="blog">
              <h1>
                <span class="subheading-category">Competitions</span>
              </h1>
              <looping-rhombuses-spinner
                :animation-duration="2500"
                :rhombus-size="15"
                color="#febe10"
                v-if="show_spinner"
                class="loader-spinner"
              />
              <div class="items-row cols-1" v-for="blog in posts">
                <div class="item column-1">
                  <div class="img-intro-left block-1">
                    <router-link :to="blog.url">
                      <img :src="blog.hero_image" alt />
                    </router-link>
                  </div>
                  <div class="text-block block-2">
                    <h5>
                      <router-link :to="blog.url">{{blog.title}}</router-link>
                    </h5>
                    <p>{{blog.summary}}</p>
                    <p class="readmore">
                      <router-link class="button button--color1" :to="blog.url">Read more</router-link>
                    </p>
                  </div>
                  <div class="clear"></div>
                </div>
                <span class="row-separator"></span>
              </div>
              <div class="pagination">
                <p class="counter">Page {{page}} of {{num_pages}}</p>
                <ul>
                  <li class="pagination-start">
                    <a class="pagenav page-disable" v-if="page==1" > Start </a>
                    <router-link to="/browse/competitions?page=1" class="pagenav" v-else>Start</router-link>
                  </li>
                  <li class="pagination-prev">
                    <a class="pagenav page-disable" v-if="page==1" > Prev </a>
                    <router-link :to="'/browse/competitions?page='+prev_page" class="pagenav" v-else>Prev</router-link>
                  </li>
                  <li v-for="page_num in prev_pages">
                    <router-link
                      :title="page_num"
                      :to="'/browse/competitions?page=' + page_num"
                      class="pagenav"
                      >{{ page_num }}</router-link
                    >
                  </li>
                  <li>
                    <span class="pagenav">{{page}}</span>
                  </li>
                  <li v-for="page_num in next_pages">
                    <router-link
                      :title="page_num"
                      :to="'/browse/competitions?page='+page_num"
                      class="pagenav"
                    >{{page_num}}</router-link>
                  </li>
                  <li class="pagination-prev">
                    <a class="pagenav page-disable" v-if="page==num_pages" > Next </a>
                    <router-link
                      :to="'/browse/competitions?page=' + next_page"
                      class="pagenav"
                      v-else>Next</router-link
                    >
                  </li>
                  <li class="pagination-prev">
                    <a class="pagenav page-disable" v-if="page==num_pages" > End </a>
                    <router-link
                      :to="'/browse/competitions?page=' + num_pages"
                      class="pagenav"
                      v-else>End</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
</template>

<script>
import { LoopingRhombusesSpinner } from "epic-spinners";
import BrowseBlogPosts from '../mixins/BrowseBlogPosts';

export default {
  name: "competitions",
  components: { LoopingRhombusesSpinner },
  mixins: [BrowseBlogPosts],
  data() {
    return {
      type: 'competition',
    };
  },
};
</script>
<style>
a.pagenav.page-disable {
    opacity: 0.5;
}

a.pagenav.page-disable:hover {
    background: #72838d;
}
</style>