<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin acc-search">
      <div id="vmss-result-list-loading">
        <div id="vmss-result-list">
          <slot />
          <!-- <h2>Books</h2> -->
          <looping-rhombuses-spinner
            :animation-duration="2500"
            :rhombus-size="15"
            color="#febe10"
            v-if="show_spinner"
            class="loader-spinner"
          />

          <div class="st-container">
            <!-- best book section -->
            <section class="mb-5">
              <div class="row g-4">
                <div
                  class="col-12 col-md-6 col-lg-4 col-xl-3"
                  v-for="book in books"
                >
                  <div class="book-list main-card py-4 h-100">
                    <div class="upper-card">
                      <router-link class="vm-image-box" :to="getBookUrl(book)">
                        <img
                          :src="getCoverLinkMedium(book)"
                          width="100%"
                          @error="noCoverImage"
                        />
                      </router-link>
                    </div>
                    <a>
                      <div class="hover-card">
                        <div class="inner-h-card">
                          <div class="">
                            <h5 class="--text-dark"></h5>
                            <h5 class="--text-dark"></h5>
                          </div>
                          <div>
                            <router-link
                              class="link vm-image-box --text-decoration-none"
                              :to="getBookUrl(book)"
                            >
                              <p class="--text-dark --text-dark">
                                {{ book.nonsortprefix }} {{ book.title }}
                              </p>
                            </router-link>
                          </div>
                          <div>
                            <router-link
                              class="--text-dark"
                              target="_self"
                              :to="getBookUrl(book)"
                            >
                              <img
                                src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                                width="30px"
                              />
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <p
                  class="text-center --text-dark"
                  v-show="books.length == 0 && !show_spinner"
                >
                  There are no books for this search term
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <form class="pagination-form">
        <div class="pagination">
          <div class="list-footer w-100">
            <ul class="w-100 text-center p-0">
              <li
                class="pagination-prev d-inline-block border mx-1 px-3 py-2 -bg-green"
              >
                <router-link
                  :to="
                    '/search' + '?page=' + prev_page + getUrlParams(prev_page)
                  "
                  class="text-decoration-none text-white cursor-pointer"
                  >Prev</router-link
                >
              </li>
              <li class="d-inline-block mx-1 border px-3 py-2">
                <span class="pagenav --text-dark cursor-pointer">{{
                  page
                }}</span>
              </li>
              <li
                class="d-inline-block mx-1 border px-3 py-2"
                v-for="page_num in next_pages"
              >
                <router-link
                  :title="page_num"
                  :to="'/search' + '?page=' + page_num + getUrlParams(page_num)"
                  class="link --text-dark pagenav"
                  >{{ page_num }}</router-link
                >
              </li>

              <li
                class="d-inline-block mx-1 border pagination-next px-3 py-2 -bg-green"
              >
                <router-link
                  title="Next"
                  :to="
                    '/search' + '?page=' + next_page + getUrlParams(next_page)
                  "
                  class="pagenav text-decoration-none text-white text-whitecursor-pointer"
                  >Next</router-link
                >
              </li>
              <!-- <li class="pagination-end">
                  <router-link
                    title="End"
                    :to="'/search'+'?page=' + num_pages + getUrlParams(num_pages)"
                    class="pagenav"
                  >End</router-link>
                </li> -->
            </ul>
            <div class="counter text-center mt-2 mb-5">
              Page {{ page }} of {{ num_pages }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.pagination .list-footer ul li:first-child:hover,
.pagination .list-footer ul li:last-child:hover {
  background: #88988e;
}
.pagination .list-footer ul li:hover {
  background: #ddd;
}
.pagination .list-footer ul li.active {
  border: 1px dotted !important;
}
</style>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Currency from "../mixins/Currency";
import Book from "../mixins/Book";
import TopCategoryBook from "../components/TopCategoryBook";
export default {
  name: "affirm-search",
  components: {
    LoopingRhombusesSpinner,
    "top-book-categorys": TopCategoryBook,
  },
  mixins: [Currency, Book],
  data() {
    return {
      host: "",
      search_term: "",
      num_pages: 0,
      page_num: 1,
      next_page: null,
      prev_page: null,
      next_pages: [],
      prev_pages: [],
      search_items_range: "",
      show_spinner: true,
      page: 1,
      books: [],
    };
  },
  methods: {
    search_books(search_sort) {
      let self = this;
      self.books = [];
      self.show_spinner = true;
      let sort_code = search_sort;
      if (!search_sort) {
        sort_code = localStorage["name"];
      }
      localStorage["name"] = sort_code;
      if (!sort_code) {
        sort_code = "rel";
      }
      axios
        .get(`/search_api/?page=${this.page}`, {
          params: {
            sort: sort_code,
            bic: this.$route.query.bic,
            term: this.$route.params.term,
            ...this.$route.query,
          },
        })
        .then(function (data) {
          if (data.status == 200) {
            self.num_pages = data.data.num_pages;
            self.books = data.data.books;
            self.page = data.data.page;
            self.next_pages = data.data.next_pages;
            self.prev_pages = data.data.prev_pages;
            self.next_page = data.data.next_page;
            self.prev_page = data.data.prev_page;
            self.search_items_range = data.data.search_items_range;
            setTimeout(() => {
              self.show_spinner = false;
            }, 10);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.show_spinner = false;
        });
    },
    noCoverImage(event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
    getCoverLinkMedium(book) {
      if (book.coverurl == "y" || book.coverurl == "Y") {
        return `https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/${book.isbn}.jpg`;
      } else {
        return "/static/css/images/affirm/No_Image_Available.jpg";
      }
    },
    getUrlParams(page_num) {
      let params = this.$route.query;
      // params['page'] = page_num;
      delete params["page"];
      var queryString = Object.keys(params)
        .map((key) => {
          if (!params[key]) {
            return encodeURIComponent(key);
          } else {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          }
        })
        .join("&");
      return "&" + queryString;
    },
  },
  beforeRouteUpdate(to, from, next) {
    // check if there is a next page
    if (to.query.keyword) {
      this.search_term = to.query.keyword;
    }
    if (to.query.page) {
      this.page = to.query.page;
    }
    this.search_books();
    next();
    window.scrollTo(0, 0);
  },
  mounted() {
    localStorage["name"] = "rel";
    this.host = this.$store.state.site_data.host;
    // initial page_num variable if the link has a page number
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    } else {
      this.page = 1;
    }
    this.search_term = this.$route.query.keyword;
    this.search_books();
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query.page"() {
      if (this.$route.query.page) {
        this.page = this.$route.query.page;
      }
      this.search_term = this.$route.query.keyword;
      this.search_books();
      window.scrollTo(0, 0);
    },
    $route() {
      this.search_books();
    },
  },
};
</script>
