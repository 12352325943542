<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div id="system-message-container"></div>
      <div v-if="cartProductNum > 0">
        <div id="ProOPC" class="cart-view proopc-row">
          <div class="clear"></div>
          <div class="proopc-row">
            <h1 class="cart-page-title">
              <span>
                <strong>My Cart </strong>
                <u>
                  <span id="proopc-cart-totalqty">{{cartProductNum}}</span>
                </u> item/s </span>
            </h1>
          </div>
          <div id="proopc-pricelist" class="first-page">
            <fieldset>
              <div class="table-scroll">
                <div>
                  <div class="loader-container" v-if="loader_on">
                    <looping-rhombuses-spinner :animation-duration="2500" :rhombus-size="15" color="#febe10" class="loader-spinner" />
                  </div>
                  <table class="cart-summary proopc-table-striped">
                    <thead>
                      <tr>
                        <th class="col-name" align="left">Title</th>
                        <th class="col-sku" align="left">SKU</th>
                        <th class="col-price" align="left">Price</th>
                        <th class="col-qty" align="left">Quantity / Update</th>
                        <!-- <th class="col-tax" align="right">Tax</th> -->
                        <th class="col-total" align="right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr valign="top" class="sectiontableentry1 cart-p-list" :key="i" v-for="product, i in cartLines">
                        <td class="col-name" align="left">
                          <div class="proopc-row">
                            <div class="cart-images">
                              <img width="60px" v-if="host=='acc'" :src="'https://dimoacc.s3-ap-southeast-2.amazonaws.com/covers/'+ product.isbn +'.jpg' " @error="noCoverImage" />
                              <img width="60px" v-else :src="'https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/'+ product.isbn +'.jpg' " @error="noCoverImage" />
                            </div>
                            <div class="cart-product-description">
                              <div class="">
                                <router-link :to="product.url">{{product.title}}</router-link>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="col-sku" align="left">{{ product.isbn }}</td>
                        <td class="col-price" align="left">
                          <div class="PricediscountedPriceWithoutTax" style="display : block;">
                            <span class="PricediscountedPriceWithoutTax">{{currencySymbol}}{{ product.price }}</span>
                          </div>
                        </td>
                        <td class="col-qty cart-p-qty" align="left">
                          <div class="proopc-input-append" style="align-items: center;">
                            <input type="text" title="Update Quantity In Cart" class="inputbox input-ultra-mini" size="3" maxlength="4" name="quantity" :value="product.quantity" :ref="i+'_qty'" />
                            <button class="proopc-btn" name="update" title="Update Quantity In Cart" @click="updateLine(i)">
                              <i class="proopc-icon-refresh"></i>
                            </button>
                          </div>
                          <button class="remove_from_cart proopc-btn" title="Delete Product From Cart" @click="deleteLine(i)">
                            <i class="proopc-icon-trash"></i>
                          </button>
                        </td>
                        <td class="col-total" colspan="1" align="right">
                          <div class="PricesalesPrice" style="display : block;">
                            <span class="PricesalesPrice">{{currencySymbol}}{{productTotalPrice(product) | currency}}</span>
                          </div>
                        </td>
                      </tr>
                      <!--Begin of SubTotal, Tax, Shipment, Coupon Discount and Total listing -->
                      <tr class="blank-row">
                        <td colspan="6">&nbsp;</td>
                      </tr>
                      <tr class="cart-sub-total" v-if="TotalDiscount() > 0">
                        <td class="sub-headings" colspan="4" align="right">Discount</td>
                        <td class="col-total" align="right">
                          <div class="PricesalesPrice PricesalePrice">
                            <span class="PricesalesPrice">{{currencySymbol}}{{TotalDiscount()}}</span>
                          </div>
                        </td>
                      </tr>
                      <tr class="cart-sub-total">
                        <td class="sub-headings" colspan="4" align="right">Total</td>
                        <td class="col-total" align="right">
                          <div class="PricesalesPrice PricesalePrice">
                            <span class="PricesalesPrice" v-if="cartTotal() == 0">{{currencySymbol}}{{cartTotal()}}</span>
                            <span class="PricesalesPrice" v-else>{{currencySymbol}}{{cartTotal() | currency}}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
          <div id="proopc-system-message"></div>
          <div class="proopc-register-login">
            <div class="proopc-register">
              <h3>Checkout</h3>
              <div class="proopc-inner" style="min-height: 222px;">
                <div class="proops-login-inputs" v-if="user">
                  <div class="proopc-group">
                    <div class="proopc-input proopc-input-prepend pd-15">
                      <router-link style="margin-right:10px;color:white;" class="proopc-btn proopc-btn-inverse button orng-wte-c bold-f-w" :to="{ name: 'checkout'}">CHECKOUT</router-link>
                    </div>
                  </div>
                </div>
                <div class="pd-15" v-else>
                  <h4 class="proopc-subtitle">Checkout as guest</h4>
                  <h4 class="proopc-subtitle">Please enter a valid email address</h4>
                  <span class="error" v-if="guest_email_error">{{guest_email_error}}</span>
                  <div class="proopc-guest-form">
                    <div class="proopc-inner">
                      <div class="proopc-group">
                        <div class="proopc-input-group-level">
                          <label class="email full-input" for="email_field">E-Mail</label>
                        </div>
                        <div class="proopc-input proopc-input-append">
                          <input type="text" id="email_field" name="email" size="30" v-model="guest_email" class="required" maxlength="100" style="width: 230px;" />
                          <i class="status hover-tootip"></i>
                        </div>
                      </div>
                      <div class="proops-login-inputs">
                        <div class="proopc-group">
                          <div class="proopc-input proopc-input-prepend">
                            <button v-if="host=='murdochuk'||host=='murdoch'" class="proopc-btn proopc-btn-inverse" style="width: 230px; background-color: rgb(114, 131, 141);" @click="goToCheckout">
                              <i id="proopc-guest-process" class="proopc-button-process"></i>Checkout </button>
                            <button v-else class="proopc-btn proopc-btn-inverse" style="width: 230px;" @click="goToCheckout">
                              <i id="proopc-guest-process" class="proopc-button-process"></i>Checkout </button>
                          </div>
                        </div>
                      </div>
                      <br>OR <br>
                      <br>
                      <h4 class="proopc-subtitle">Checkout as member</h4>
                      <div class="proops-login-inputs">
                        <div class="proopc-group">
                          <div class="proopc-input proopc-input-prepend proopc-action">
                            <router-link v-if="host=='acc'" style="color:white;" class="button orng-wte-c bold-f-w" :to="{ name: 'login'}">LOG IN</router-link>
                            <router-link v-else style="color:white;background-color:#72838d;" class="button orng-wte-c bold-f-w" :to="{ name: 'login'}">LOG IN</router-link>
                            <router-link class="button bold-f-w" :to="{ name: 'signup'}" :class="host == 'acc' ? 'orng-wte-c' : 'btn-register'">Register</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h1>Cart empty</h1>
        <div class="proopc-empty-continue-link">
          <router-link class="button button--color1 router-link-active" to="/">Continue Shopping</router-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { LoopingRhombusesSpinner } from "epic-spinners"
import Cart from "../mixins/Cart"

export default {
  name: "cart",
  mixins: [Cart],
  components: { LoopingRhombusesSpinner },
  data() {
    return {
      host : '',
      lines: [],
      loader_on: false,
      guest_email: "",
      guest_email_error: null
    }
  },
  methods: {
    noCoverImage(event) {
      if (this.host == "acc"){
          event.target.src = "/static/images/acc/No_Image_Available.jpg"
      }
      else if (this.host == "allenandunwin" || this.host == "allenandunwinnz"){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg"
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg"
      }
      
      this.cover_for_download_available = false
    },

    updateLine(index) {
      let qty = this.$refs[index + "_qty"][0].value
      let self = this
      self.loader_on = true
      this.$store
        .dispatch("updateLine", {
          index: index,
          qty: qty
        })
        .then(data => {
          self.loader_on = false
        })
    },
    deleteLine(index) {
      let self = this
      self.loader_on = true
      this.$store
        .dispatch("updateLine", {
          index: index,
          qty: 0
        })
        .then(data => {
          self.loader_on = false
        })
    },
    goToCheckout() {
      if (this.guest_email.length > 1 && this.validEmail(this.guest_email)) {
        this.$store.dispatch("addGuestEmail", this.guest_email)
        this.$router.push("checkout")
      }else{
        this.guest_email_error = 'invalid email'
      }
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  mounted() {
    this.host = this.$store.state.site_data.host
    let has_ecommerce = this.$store.state.site_data.ecommerce
    if (!has_ecommerce) {
      this.$router.push("not-found")
    }
  },
  computed: {
      user () {
        let user = this.$store.state.user
        const username = localStorage.getItem('username')
        const id = localStorage.getItem('user_id')
        const key = localStorage.getItem('user_key')

        if (user && !user.username && username) {

          user = {
            username: username,
            id: id,
            key: key,
          }

        } else if (user && !user.username && !username) {
          user = null
        }

        return user
      }
    }
};

</script>

<style>
.proopc-input-append {
    display: inline-block;
} 
.proopc-group .proopc-btn-inverse {
    margin: 8px;
    width: auto; 
    display: inline-block;
}
.proopc-group .proops-login-inputs, .proopc-group .proopc-input-prepend {
    justify-content: left;
    text-align: left;
}

 .proopc-input-append {
    display: inline-block;
} 
.proopc-group .proopc-btn-inverse {
    margin: 8px;
    width: auto; 
    display: inline-block;
}
.proopc-group .proops-login-inputs, .proopc-group .proopc-input-prepend {
    justify-content: left;
    text-align: left;
}
.proopc-action{
    display:flex;
}
.loader-container {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 41%;
  z-index: 999;
}

.btn-register{
  color:white;
  background-color:#72838d;
  text-align: right;
  margin-left: 20px;
}
.proopc-register {
  width: 100% !important;
}
#ProOPC .proopc-btn{
  margin-left: 0px;
}
@media (min-width: 768px) {
.cart-quantity{
  float: left;
  margin-left: 25% !important;
  }
}
@media (max-width: 767px) {
  .cart-p-list td:nth-of-type(1):before { content: 'Title'; }
  .cart-p-list td:nth-of-type(2):before { content: 'SKU'; }
  .cart-p-list td:nth-of-type(3):before { content: 'Price '; }
  .cart-p-list td:nth-of-type(4):before { content: 'Quantity/Update'; }
  .cart-p-list td:nth-of-type(5):before { content: 'Total'; }
  .cart-product-description > div {
    margin-top: 0;
  }
  .blank-row{
    display: none !important;
  }
  .proopc-input{
      width:100%;
  }
}
@media screen and (min-width: 768px){
  .col-total {  
    border-right: 1px solid #ddd !important;  
  }

}
</style>