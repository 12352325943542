<template>
  <div class="bg-custom07">
    <div class="acc-leftsidebar"></div>
    <div class="border-orange"></div>
    <div class="container">
      <div class="">
        <div id="static" v-html="content" v-if="content"></div>
        <div id="static-content"></div>
        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="!content"
          class="loader-spinner"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Vue from "vue";

export default {
  name: "blog",
  components: {
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      content: "",
      title: null,
      host: "",
      page_type: null,
    };
  },
  methods: {
    fetch_static_content(path) {
      let self = this;
      axios
        .get("/get_static_page" + path)
        .then(function (response) {
          // adding a on-the-fly vue component with the static content of the page.
          // Vue.extend creates a new instance of vue and mounts the component on that new instance.
          // That is why the vue router and store must be passed else it will be undifined.
          let static_content = Vue.extend({
            router: self.$router,
            store: self.$store,
            template: response.data.content,
          });
          new static_content().$mount("#static-content");
          self.content = response.data.content;
          self.title = response.data.title;
          self.page_type = response.data.page_type;
          // if(self.page_type){
          //   let page_type = self.page_type;
          //   if(!page_type.endsWith('s')){
          //     page_type = page_type + 's';
          //   }
          //   self.$route.meta.breadcrub.splice(self.$route.meta.breadcrub.length-1, 0, {
          //     name: page_type,
          //     link: page_type
          //   });
          // }
          //change the breadcrub title of the current page to the title of the static page
          let last_bread =
            self.$route.meta.breadcrub[self.$route.meta.breadcrub.length - 1];
          if (!last_bread.link) {
            last_bread.name = self.title;
          } else {
            self.$route.meta.breadcrub.push({
              name: self.title,
            });
          }
        })
        .catch(function (err) {
          if (!self.$route.params.path.includes("404")) {
            window.location.replace("/404/");
          }
        });
    },
  },
  mounted() {
    let self = this;
    // axios
    //   .get(`/get_bic1_category_data/`)
    //   .then(function (data) {
    //     self.bic1_data = data.data.bic1_data;
    // })
    this.host = this.$store.state.site_data.host;
    let path = this.$route.path;
    this.fetch_static_content(path);
    // let resource_search = document.getElementById('resources-search-inputs');
    let url = window.location.href;
  },
  // created() {
  // }
};
</script>
<style>
/* .bg-custom07 img {
    display: none;
} */
/* .bg-custom07 h1 {
    font-size: 25px;
    margin-bottom: 0;
    color: #000;
    box-shadow: 0 0 10px #adadad;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 0px;
} */
.bg-custom07 .item-page > div {
  margin-top: 30px;
  padding: 10px;
  box-shadow: 0 0 10px #adadad;
}
#common_sec {
  width: 100%;
  float: left;
  margin-top: 5rem;
}
.top {
  background: #fe793c;
  padding: 10px;
  border-radius: 3px;
  border-bottom: 3px solid #ccc;
  margin: 0 40px 20px 20px;
}
.bottom {
  background: #ffc0cb;
  line-height: 25px;
  font-size: 16px;
  padding: 10px;
  border-radius: 3px;
  margin: 0 40px 20px 20px;
}
.bottom p {
  color: #fff;
}
.border-orange {
  float: left;
  position: absolute;
  height: 8px;
  width: 68%;
  left: 22.5%;
  top: -25px;
  background-image: url(../../../Oscar/static/css/images/acc/heading_underline.png);
}
@media only screen and (max-width: 3000px) {
  .border-orange {
    width: 63%;
    left: 15.8%;
    top: 55px;
  }
  .main012 .container {
    width: 90%;
    margin: 0 0 0 3.7%;
  }
}
@media only screen and (max-width: 2700px) {
  .border-orange {
    width: 63%;
    left: 16.3%;
    top: 55px;
  }
  .main012 .container {
    width: 90%;
    margin: 0 0 0 4.1%;
  }
}
@media only screen and (max-width: 2500px) {
  .border-orange {
    width: 63%;
    left: 17.2%;
    top: 55px;
  }
  .main012 .container {
    width: 90%;
    margin: 0 0 0 4.5%;
  }
}
@media only screen and (max-width: 2200px) {
  .border-orange {
    width: 63%;
    left: 17.5%;
    top: 55px;
  }
  .main012 .container {
    width: 90%;
    margin: 0 0 0 4.7%;
  }
}
@media only screen and (max-width: 2000px) and (min-width: 1600px) {
  .border-orange {
    width: 63%;
    left: 20%;
    top: 55px;
  }
  .main012 .container {
    width: 90%;
    margin: 0 0 0 92px;
  }
}
@media only screen and (max-width: 1024px) {
  .border-orange {
    width: 63%;
    left: 18%;
    top: 52px;
  }
}
@media only screen and (max-width: 956px) {
  .border-orange {
    width: 63%;
    left: 18%;
    top: 85px;
  }
  .bg-custom07 h1 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .border-orange {
    width: 63%;
    left: 17.5%;
    top: 75px;
  }
  .bg-custom07 h1 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .main012 .container {
    width: 100%;
    margin: 0 auto;
  }
  .border-orange {
    width: 75%;
    left: 12.5%;
    top: 72px;
  }
  .bg-custom07 h1 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .bg-custom07 h1 {
    font-size: 19px;
  }
  .border-orange {
    width: 76%;
    left: 11.5%;
    top: 68px;
  }
}
.bg-custom07 h1 {
  padding: 10px;
  margin-bottom: 0px;
}
</style>
