<template>
  <section id="book-1" class="">
    <div class="st-container">
      <!-- start about us -->
      <div class="pt-5">
        <div class="row --text-dark">
          <div class="col-12"></div>
          <div
            class="col-12 mt-4 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex justify-content-between align-items-end w-100">
              <div>
                <h1 class="s-h1 mb-5 mb-xl-5 --text-dark">Books</h1>
                <nav class="d-inline-block p-0 tabs">
                  <div class="tabs">
                    <a
                      v-on:click="makeActive('all_title')"
                      :class="{ active: active === 'all_title' }"
                      class="link me-3 s-h6 white-space-nowrap d-inline-block"
                      >All titles</a
                    >
                    <a
                      v-on:click="makeActive('fiction')"
                      :class="{ active: active === 'fiction' }"
                      class="link me-3 s-h6 white-space-nowrap d-inline-block"
                      >Fiction</a
                    >
                    <a
                      v-on:click="makeActive('non-fiction')"
                      :class="{ active: active === 'non-fiction' }"
                      class="link me-3 s-h6 white-space-nowrap d-inline-block"
                      >Non-Fiction</a
                    >
                    <a
                      v-on:click="makeActive('kids_teens')"
                      :class="{ active: active === 'kids_teens' }"
                      class="link me-3 s-h6 white-space-nowrap d-inline-block"
                      >Kids & Teens</a
                    >
                  </div>
                </nav>
              </div>
              <div
                class="moduletable vmss_search_filter--boxy-styled me-5"
                v-if="active == 'kids_teens'"
              >
                <h3 class="fw-bold mt-4 s-h6 --text-dark">
                  Children's & Young Adult books by age
                </h3>
                <div class="vmss_search_filter my-4" id="vmms_search_kids">
                  <input type="hidden" name="searchword" value="" />
                  <form
                    id="vmss_search_filter_formkids"
                    class="vmss_search_filter_form_class"
                    method="get"
                    action="/search"
                  >
                    <div class="search d-flex align-items-center">
                      <div>
                        <select
                          id="searchfilter_36_"
                          name="age"
                          tabindex="-1"
                          class="selecter-element select-age --sort-authors"
                        >
                          <option value="0 18" selected="selected">
                            - Any age -
                          </option>
                          <option value="0 3">Board books: Age 0-3</option>
                          <option value="0 5">Picture books: Age 0-5</option>
                          <option value="2 6">Picture books: Age 2-6</option>
                          <option value="3 7">Picture books: Age 3-7</option>
                          <option value="4 8">Picture books: Age 4-8</option>
                          <option value="5 8">Picture books: Age 5-8</option>
                          <option value="7 9">Picture books: Age 7-9</option>
                          <option value="9 12">Middle grade fiction: Age 9-12</option>
                          <option value="6 12">Non-fiction: Age 6-12</option>
                          <option value="12 14">Young Adult: Age 12-14</option>
                          <option value="13 18">Young Adult: Age 13-18</option>
                        </select>
                      </div>
                      <button
                        class="btn btn-dark ms-4 rounded-0"
                        style="font-family: 'Open Sans', sans-serif"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3 mt-lg-4">
            <div
              class="d-block d-sm-flex align-items-center justify-content-between"
            ></div>
            <div>
              <div class="dropdown mb-3 mb-sm-0">
                <select
                  name="sorts_authors"
                  id=""
                  class="--sort-authors date_dropdown"
                  @click="sorting_data($event)"
                >
                  <option value="date_asc">Publication Date (newest)</option>
                  <option value="title">Alphabetical</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="st-container">
      <div class="card-section mt-3 mb-5">
        <div>
          <div class="row">
            <div class="col-12">
              <div>
                <h4 class="fw-bold s-h5 --text-dark">{{ title }}</h4>
              </div>
            </div>
          </div>
          <div class="row gy-5">
            <looping-rhombuses-spinner
              :animation-duration="2500"
              :rhombus-size="15"
              color="#febe10"
              v-if="books.length == 0"
              class="loader-spinner"
            />
            <div
              v-for="book in visibleCompanies"
              :key="book.id"
              :id="book.id"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
            >
              <div class="book-list main-card h-100">
                <div class="upper-card">
                  <img
                    alt="No_Image_Available"
                    width="100%"
                    :src="
                      'https://s3-ap-southeast-2.amazonaws.com/dimo.affirm.lambda/images/medium/' +
                      book.isbn +
                      '.jpg'
                    "
                    @error="noCoverImage"
                  />
                </div>
                <router-link
                  :to="getBookUrl(book)"
                  v-if="pubdate(book.pubdate)"
                >
                  <div class="hover-card">
                    <div class="inner-h-card">
                      <div class="">
                        <h5 class="--text-dark">
                          {{ book.nonsortprefix }} {{ book.title }}
                        </h5>
                        <h5 class="--text-dark">{{ book.author }}</h5>
                      </div>
                      <div>
                        <p v-html="book.key_note_blurb" class="--text-dark"></p>
                      </div>
                      <div>
                        <router-link
                          class="--text-dark"
                          :to="getBookUrl(book)"
                          target="_self"
                          ><img
                            src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                            width="30px"
                        /></router-link>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div
              @click="loadbookVisible += step"
              v-if="loadbookVisible < books.length"
              class="cursor-pointer -w-223 mx-auto"
            >
              <div class="text-center">
                <p class="s-h6 --text-dark">Load more</p>
                <img
                  src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                  width="25px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end about us -->
  </section>
</template>
<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import Book from "../mixins/Book";
export default {
  name: "search",
  components: {
    LoopingRhombusesSpinner,
  },
  mixins: [Book],
  props: ["placement", "book_limit"],
  data() {
    return {
      active: "all_title",
      books: [],
      title: null,
      loadbookVisible: 30,
      sort_by: "date_asc",
      step: 30,
    };
  },
  methods: {
    sorting_data(event) {
      this.sort_by = event.target.value;
      this.makeActive(this.active);
    },
    pubdate: function (pub_date) {
      const dateObj = new Date(pub_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const dateObj1 = new Date();
      const today_year = dateObj1.getFullYear();
      const today_month = dateObj1.getMonth() + 1;
      var status = true;
      return status;
    },
    makeActive: function (item) {
      this.active = item;
      if (this.active == "all_title") {
        var page = "/book/";
      } else if (this.active == "fiction") {
        var page = "/fiction/";
      } else if (this.active == "non-fiction") {
        var page = "/non-fiction/";
      } else if (this.active == "kids_teens") {
        var page = "/books/childrens/";
      }
      let self = this;
      let sort_code = self.sort_by;
      let placement = this.placement ? this.placement : "bottom";
      axios
        .get("/get_bestsellers/", {
          params: {
            page: page,
            sort: sort_code,
            placement: placement,
          },
        })
        .then(function (data) {
          if (data.status == 200) {
            self.books = data.data.books;
            self.title = data.data.title;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      // sidebar placement is the default, default is null for backwards compatibility
    },
    noCoverImage: function (event) {
      event.target.src = "/static/css/images/affirm/No_Image_Available.jpg";
    },
  },
  computed: {
    visibleCompanies() {
      return this.books.slice(0, this.loadbookVisible);
    },
  },
  mounted() {
    this.makeActive(this.active);
  },
};
</script>
