<template>
    <div>
      <div class="row gy-4 pt-2 mt-3">
        <div class="col-12 col-md-4" v-for="blog in visiblenews">
          <div class="">
            <div class="img-intro-left block-1">
              <router-link :to="blog.url">
                <img :src="blog.hero_image" width="100%" alt="blog-img" />
              </router-link>
            </div>
            <div class="">
              <h5 class="s-h5 pt-4 pb-2 mb-0">
                <router-link :to="blog.url" class="link --text-dark">{{ blog.title
                  }}</router-link>
              </h5>
              <p class="s-h7 mb-0 pb-2 --text-dark">{{ blog.summary }}</p>
              <p class="s-h7 readmore">
                <router-link class="" :to="blog.url">
                  <img src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png" width="25px" />
                </router-link>
              </p>
            </div>
            <div class="clear"></div>
          </div>
          <span class="row-separator"></span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <a class="link --text-dark" @click="loadnews += step" v-if="loadnews < posts.length">
            <div class="text-center mt-4 mb-5"> Load more <div class="text-center">
                <img src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png" alt="arrow" width="24px" />
              </div>
            </div>
          </a>
          <p class="text-center" v-if="posts.length == 0">No records found</p>
        </div>
      </div>
    </div>
  </template>
<script>
import BrowseBlogPosts from "../../mixins/BrowseBlogPosts";
export default {
  name: "affirm-news",
  components: {  },
  mixins: [BrowseBlogPosts],
  data() {
    return {
      type: "news",
      all_blog: '',
      loadnews: 3,
      step: 3,
    };
  },
  methods: {
  },
  computed: {
    visiblenews() {
      return this.posts.slice(0, this.loadnews);
  }
  ,}
};
</script>
