<template>
  <div id="app" class="st-container" :class="iconMobileClass">
    <div class="st-pusher">
      <div class="st-content">
        <au-header></au-header>
        <label id="open-side-bar" class="mobile-menu-label mobileOnly" @click="showMenu = !showMenu"></label>
        <div class="l-content featured-image">
          <div class="bg-block1 mobileHide"></div>
          <slot/>
        </div>
        <div class="l-bottom">
          <div class="wrapper">
            <div class="custom subscribe mobileFullWidthWithPadding">
              <label for="">Love books?</label> 
                <input name="cm-gsdt-gsdt" id="subscribe-to-newsletter" type="email" v-model="newsletterEmail" placeholder="Sign up to our newsletters">
                <input type="submit" value="Subscribe" @click="goToSubscribePage" class="button button--color2">
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <au-footer></au-footer>        
      </div>
    </div>
    <mobile-version></mobile-version>
  </div>
</template>

<script>
import layoutMixin from '../mixins/Layout';
import AuHeader from '../components/AUHeader';
import AuFooter from '../components/AUFooter';
import newsLetterMixin from '../mixins/Newsletter';
import MobileVersion from '../components/MobileVersion';

export default {
  components: {
    AuHeader,
    AuFooter,
    MobileVersion
  },
  mixins: [layoutMixin, newsLetterMixin],
  data(){
    return {
      newsletterEmail: null,
      showMenu: false
    }
  },
  methods: {},

  computed: {
    iconMobileClass() {
      return this.showMenu ? 'st-menu-open' : '';
    }
  }
}
</script>

<style>
.subscribe label{
  height: 3.312em;
}
.featured-image{
  min-height:1920px; 
  overflow: hidden;
}
</style>