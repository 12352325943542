<template>
  <div class="menu">
    <div class="group level1" v-for="menu in footerMenu">
      <div class="item level1" v-for="sub_menu in menu.categories">
        <router-link :to="sub_menu.url">{{ sub_menu.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MenuMixin from "../mixins/Menu";

export default {
  name: "footer-menu",
  mixins: [MenuMixin]
};
</script>

<style>
</style>